<template>
  <quill-editor
    v-model="d_value"
    :id="id"
    class="mr-0 overflow-y-auto"
    :options="quill_options"
    :state="state"
    :disabled="disabled"
    @change="change"
  />
</template>

<script>
// wysiwyg
import Quill from "quill";
let AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import { quillEditor } from "vue-quill-editor";

var Delta = Quill.import("delta");
let Break = Quill.import("blots/break");
let Embed = Quill.import("blots/embed");

function lineBreakMatcher() {
  var newDelta = new Delta();
  newDelta.insert({ break: "" });
  return newDelta;
}

class SmartBreak extends Break {
  length() {
    return 1;
  }
  value() {
    return "\n";
  }
  insertInto(parent, ref) {
    Embed.prototype.insertInto.call(this, parent, ref);
  }
}

SmartBreak.blotName = "break";
SmartBreak.tagName = "BR";

Quill.register(SmartBreak);

export default {
  name: "BwWysiwyg",
  components: {
    quillEditor,
  },
  props: {
    id: { type: String, required: true },
    value: { required: true, nullable: true },
    state: { type: Boolean, default: null },
    placeholder: {
      type: String,
      default: function () {
        return this.$t("wysiwyg.info.insert_text_here");
      },
    },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      d_value: this.value,
      quill_options: {
        placeholder: this.placeholder,
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["link", "image"],
            ["clean"],
          ],
          clipboard: {
            matchers: [["BR", lineBreakMatcher]],
          },
          keyboard: {
            bindings: {
              linebreak: {
                key: 13,
                shiftKey: true,
                handler: function (range) {
                  let currentLeaf = this.quill.getLeaf(range.index)[0];
                  let nextLeaf = this.quill.getLeaf(range.index + 1)[0];

                  this.quill.insertEmbed(range.index, "break", true, "user");

                  // Insert a second break if:
                  // At the end of the editor, OR next leaf has a different parent (<p>)
                  if (
                    nextLeaf === null ||
                    currentLeaf.parent !== nextLeaf.parent
                  ) {
                    this.quill.insertEmbed(range.index, "break", true, "user");
                  }

                  // Now that we've inserted a line break, move the cursor forward
                  this.quill.setSelection(
                    range.index + 1,
                    Quill.sources.SILENT
                  );
                },
              },
            },
          },
        },
      },
    };
  },
  methods: {
    change(value) {
      this.$emit("input", value.html);
    },
  },
  watch: {
    value: function (value) {
      this.d_value = value;
    },
  },
};
</script>
