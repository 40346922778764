<template>
  <b-form-datepicker
    @input="input"
    reset-button
    :hide-header="true"
    :start-weekday="1"
    :labelPrevDecade="$t('delivery_address.model')"
    :labelPrevYear="$t('datepicker.label_prev_year')"
    :labelPrevMonth="$t('datepicker.label_prev_month')"
    :labelCurrentMonth="$t('datepicker.label_current_month')"
    :labelNextMonth="$t('datepicker.label_next_month')"
    :labelNextYear="$t('datepicker.label_next_year')"
    :labelToday="$t('datepicker.label_today')"
    :labelSelected="$t('datepicker.label_selected')"
    :labelNoDateSelected="$t('datepicker.label_no_date_selected')"
    :labelCalendar="$t('datepicker.label_calendar')"
    :labelNav="$t('datepicker.label_nav')"
    :labelHelp="$t('datepicker.label_help')"
    :labelResetButton="$t('global.action.reset')"
    :locale="current_language.code"
    v-bind="$attrs"
  />
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BwDatepicker",
  inheritAttrs: false, // retrieve all attributes pass to parent with "v-bind="$attrs" "
  props: {},
  methods: {
    input($date) {
      this.$emit("input", $date);
    },
  },
  computed: {
    ...mapState({
      current_language: (state) => state.layout.current_language,
    }),
  },
};
</script>
