export default {
    "en": {
        "accounting_export.download.filename": "accounting-export-",
        "accounting_export.error.currently_ongoing": "One {model} is already being exported. Please validate it before trying again.",
        "accounting_export.error.different_company": "The {model} is different from the others {invoice_model}",
        "accounting_export.error.invoice_already_exported": "At least one {model} has already been exported.",
        "accounting_export.error.invoice_not_awaiting_nor_validated": "The {model} is not Awaiting or Validated",
        "accounting_export.error.invoice_not_validated": "The {model} is not Validated",
        "accounting_export.error.invoice_status_not_awaiting_nor_validated": "At least one {model} is not Awaiting nor Validated.",
        "accounting_export.error.invoice_status_not_validated": "At least one {model} is not Validated.",
        "accounting_export.error.missing_accounting_entry": "The {invoice_model} has at least one line without {model}",
        "accounting_export.error.unbalanced_invoice": "Unbalanced {model}",
        "accounting_export.field.account_number": "Account code",
        "accounting_export.field.analitycs": "Axis",
        "accounting_export.field.invoices.error.different_company": "The {company_model} of {invoice_model} must be the same.",
        "accounting_export.field.third_parties": "Third parties",
        "accounting_export.model": "Accounting Export",
        "accounting_export.model.gender": "0",
        "accounting_export.model.plural": "Accounting Exports",
        "accounting_export.pdf.title": "Accounting Export Details",
        "address.field.address1": "Address",
        "address.field.address2": "Address supplement 1",
        "address.field.address3": "Address supplement 2",
        "address.field.company": "Company",
        "address.field.recipient": "Recipient",
        "address.field.zip_code": "Zip code",
        "add_models_massively.error.ambiguous_value_for": "{reference} is ambiguous for {for}, please add it manually",
        "add_models_massively.error.does_not_exists_for": "{reference} does not exist for {for}",
        "add_models_massively.error.is_disabled_for": "{reference} is not active for {for}",
        "admin.dashboard": "Admin",
        "allocations.import.unique.code": "Line {line} : the code {code} of {allocation} is already used with a label other than \"{label}\", the data of the line could not be added.",
        "api.purchase_order.error.back_to_draft.already_billed": "{model} already billed, even partially",
        "api.purchase_order.error.back_to_draft.already_delivered": "{model} already delivered, even partially",
        "api.purchase_order.error.back_to_draft.already_paid": "{model} already paid, even partially",
        "api.purchase_order.error.back_to_draft.already_sent_at": "{model} already sent to the supplier, you can force to back to draft with the \"confirm_when_sent\" parameter set to \"true\" ",
        "api_log.model": "Inbound API Log",
        "api_log.model.plural": "Inbound API Logs",
        "article.catalog.internal": "Internal catalog",
        "article.catalog.punchout": "Punch Out catalog",
        "article.catalog.stock": "Stock catalog",
        "article.catalog.supplier": "Supplier catalog",
        "article.catalog.supplier_group": "Supplier gruop catalog",
        "article.error.change_type": "Unable to change the type of an already used item",
        "article.error.does_not_exist": "{model} not existing. You can create it instead.",
        "article.error.does_not_exist_in_warehouse": "{model} not existing in Warehouse. You can create it instead.",
        "article.error.unbindReferences": "You must delete the affiliate articles of this article before changing its internal state.",
        "article.error.unbindStock": "You must empty the stock of this item before changing its internal state.",
        "article.field.affiliate": "affiliated articles",
        "article.field.link": "Link to supplier product sheet",
        "article.field.ordered_quantity_current_year": "Quantity ordered in the last 12 months",
        "article.field.ordered_quantity_current_year.abbr": "Qty ordered in the last 12 months",
        "article.field.ordered_quantity_last_year": "Quantity ordered in the previous 12 months",
        "article.field.ordered_quantity_last_year.abbr": "Qty ordered in the previous 12 months",
        "article.field.search.favorite": "Only favorite {model}",
        "article.field.search.labeled": "Only \"{label}\" {model}",
        "article.field.type": "Article type",
        "article.internal.model": "Internal {model}",
        "article.internal.model.plural": "Internal {model}",
        "article.rapid_search": "Quick article search",
        "article.title.packing": "Packing",
        "article.type.group": "Group",
        "article.type.internal": "Internal",
        "article.type.supplier": "Supplier",
        "article.unit_price.info": "That's {price} / {unit}",
        "auth.login.index": "Connection",
        "auth.login.sso": "SSO connection",
        "auth.login.standard": "Standard connection",
        "auth.login.with": "Connection with {idp}",
        "auth.login_request.confirmed": "An email containing a login link has been sent to you.",
        "auth.login_request.enter_your_email": "Enter your email address to request a connection",
        "auth.login_request.supplier.title": "Supplier Portal Login Request",
        "auth.register.index": "Registration",
        "bank_account.account_number_type.iban": "IBAN",
        "bank_account.account_number_type.other": "Other",
        "bank_account.export.filename": "bank-accounts",
        "bank_account.field.account_number_type": "Type of number",
        "bank_account.field.country_code": "Country code",
        "bank_account.field.country_code.help": "Two-letter abbreviation of the country, according to ISO-3166 for the address described",
        "bank_account.field.file": "Bank details",
        "bank_account.holder_type.corporate": "corporate",
        "bank_account.holder_type.individual": "individual",
        "bank_account.model": "Bank account",
        "bank_account.model.plural": "Bank accounts",
        "base_model.model": "Model",
        "boomerangweb.action.more_info": "More information",
        "boomerangweb.info.actions": "Control your purchasing process, manage your expenses, validate your purchases.",
        "boomerangweb.info.definition": "The simple and intuitive software for purchasing management.",
        "budget.confirm.generatedFromBudgetAnalysis": "Budgets generated: {count}",
        "budget.duplicate": "Duplication of a budget",
        "budget.error.alreadyExists": "This budget already exists, please select other keys.",
        "budget.field.copy_amounts": "Copy forecast budgets",
        "budget.field.new_tag": "Tag",
        "budget.generateFromBudgetAnalysis": "Generate from budget analysis",
        "budget.generateFromBudgetAnalysis.info": "The list below shows the elements present in the budget analysis and not existing in the budgets.",
        "budget.generateFromBudgetAnalysis.noBudgetAnalysis": "Nothing to import from budget analysis.",
        "budget.generateFromBudgetAnalysis.warning": "Warning: filters applied on page \"{page}\" are applied here as well.",
        "budget.importer.field.file.help": "The name of each column must correspond to the character close to the name of this column in the export above.<br>All the columns \"Code\" are required, the columns \"Label\" are only required in case of creation of an imputation, etc.",
        "budget.info.last_validated": "Last validated: {amount}",
        "budget.model": "Budget",
        "budget.model.plural": "Budgets",
        "budget.overrun.question": "Do you still want to send this {model} for validation ?",
        "budget.overrun.question.in_validation": "Do you still want to validate this {model} ?",
        "budget.overrun.warning": "Warning ! Some budget lines are exceeded!",
        "budget.overrun_action.block": "Notify user and block sending",
        "budget.overrun_action.none": "No action",
        "budget.overrun_action.notification": "Notify user",
        "budget.requiredSearchfield": "Please fill in all search fields",
        "budget.value.undefined": "- by default -",
        "budget_amount.model": "Budget amount",
        "budget_amount.model.plural": "Budget amounts",
        "budget_analysis.filter.stock_type.all": "With stock movements",
        "budget_analysis.filter.stock_type.no_stock": "Without stock movements",
        "budget_analysis.filter.stock_type.only_stock": "Only stock movements",
        "budget_analysis.info.amount_of_models": "amount of {model}",
        "budget_analysis.info.billed": "amount of {modelInvoice} \"{awaiting}\" and \"{validated}\" ",
        "budget_analysis.info.delivered_not_billed": "amount of {modelPO} received but not yet invoiced.",
        "budget_analysis.info.prorated_amounts": "The amounts in italics are pro rated amounts.",
        "budget_analysis.info.validated": "amount of validated {modelPO} not received, not invoiced and not closed.",
        "budget_analysis.model": "Budget analysis",
        "budget_analysis.model.plural": "Budget analyzes",
        "budget_analysis.overrun": "Exceeding budget",
        "budget_version.model": "Version",
        "budget_version.model.plural": "Versions",
        "cart.action.add": "Add to your shopping cart",
        "cart.action.empty": "Delete cart",
        "cart.confirm.converted_to": "The {article_model} selected in the cart have been successfully converted to {model}",
        "cart.error.no_cart_mode": "Cart cannot be converted",
        "cart.error.require_items": "The cart must contain at least one {model}",
        "cart.info.no_selected_items_remain_in_cart": "Unselected {model} will remain in the cart",
        "cart.model": "Order basket",
        "catalog.module": "Catalog Module",
        "catalog.stock.module": "Stock Management|Stocks Management",
        "central.dashboard": "Central data",
        "comment.model": "Comment",
        "comment.model.plural": "Comments",
        "company.download.filename": "purchase_terms",
        "company.field.accounting_export_prefix.help": "Once completed, this field can no longer be changed",
        "config.dashboard": "Configuration",
        "config.info.no_available_module": "No modules available",
        "config.title.available_modules": "Modules available",
        "contact.alert.duplicateLogin": "This contact has an email address that is already used as a user login",
        "contact.alert.email": "The contact must have an email to access Boomerang Web",
        "contact.field.identity": "Identity",
        "contact.field.is_main": "Main contact",
        "contact.field.is_main_billing_contact": "Main contact Invoice",
        "contact.field.is_main_commercial_contact": "Main contact Purchase Order",
        "contact.field.language": "Contact language",
        "contact.field.softwareAccess": "Access To Boomerang Web",
        "contact.field.types": "Types",
        "contact.model.plural": "Contacts",
        "contact.prevent.sendMailToRegistration": "An email will be sent to this contact to access the application.",
        "contact.prevent.sendMailToStopAccess": "This contact will lose access",
        "counter.model": "Counter",
        "counter.model.plural": "Counters",
        "country.model": "Country",
        "country.model.plural": "Country",
        "currency.auto_update_rates": "Update currencies rates",
        "currency.confirm.updated_rates": "Exchange rates update successful",
        "currency.field.rate_for_one_default_currency": "Rate for 1 {currency}",
        "currency.field.symbol": "Symbol",
        "currency.info.updating_rates": "Updating of the exchange rates",
        "currency.model": "Currency",
        "currency.model.gender": "0",
        "currency.model.plural": "Currencies",
        "currency.position.after": "After",
        "currency.position.before": "Before",
        "custom_field.model": "Custom Field",
        "custom_field.model.plural": "Custom Fields",
        "dashboard.chart.comparison": "Comparison of {model} amounts between N-1 and N in {currency} excl. taxes",
        "dashboard.chart.comparison.last_year": "Year N-1",
        "dashboard.chart.comparison.year": "Year N",
        "dashboard.chart.distribution": "Distribution of {model} over the last 3 months by {allocation}",
        "dashboard.chart.distribution.other": "OTHER",
        "dashboard.chart.global": "Total amount",
        "dashboard.chart.global.delivery_awaiting": "{number} {model} pending validation",
        "dashboard.chart.global.invoice_awaiting": "{number} {model} pending validation",
        "dashboard.chart.global.invoice_not_received": "{model} not received",
        "dashboard.chart.global.needs_awaiting": "{number} {model} processing",
        "dashboard.chart.global.purchase_order_awaiting": "{number} {model} pending validation",
        "dashboard.chart.global.purchase_order_not_received": "{model} not received",
        "dashboard.chart.instance": "Your subscription",
        "dashboard.chart.instance.projection_purchase_order": "Projection of {model} until {date}",
        "dashboard.chart.instance.purchase_order": "Number of {model} since {date}",
        "dashboard.chart.instance.storage": "Storage space used",
        "dashboard.chart.instance.subscription": "Renewal of your subscription",
        "dashboard.chart.instance.user": "Number of {model}",
        "dashboard.no_chart": "Use the main menu to navigate",
        "data_accuracy.actions.clean_validators": "Clean these validators",
        "data_accuracy.actions.put_into_main_bank_account": "Put these Bank details into main Bank details",
        "data_accuracy.info.large_problems_detected": "Lots of issues detected (display limit: {count})",
        "data_accuracy.info.problems_detected": "{count} problems detected",
        "data_accuracy.info.too_many_to_check": "There are too many {models} to check ({count})",
        "data_accuracy.stock.in_quantity_awaiting": "In - Awaiting quantity",
        "data_accuracy.stock.in_quantity_validated_not_delivered": "In - Validated quantity not received",
        "data_accuracy.stock.out_quantity_awaiting": "Out - Awaiting quantity",
        "data_accuracy.stock.out_quantity_validated_not_delivered": "Out - Validated quantity not delivered",
        "data_import.action.add_column_before": "Insert a field before",
        "data_import.action.remove_column": "Remove the field and shift the following ones upwards",
        "data_import.action.save_template": "Save template",
        "data_import.columns_from_file": "File columns",
        "data_import.columns_to_import": "Destination fields",
        "data_import.default_template": "Default template",
        "data_import.error.cant_create_allocations": "Unable to create allocation: {allocations}|Unable to create allocations: {allocations}",
        "data_import.error.column.already_exists": "Column \"{field}\" is populated twice",
        "data_import.error.column.contains_errors": "Column \"{field}\" contains errors",
        "data_import.error.column.doesnt_exist_in_params": "Column \"{field}\" does not exist in params",
        "data_import.error.column.missing": "Column \"{field}\" is missing",
        "data_import.error.column.missing_for": "Column \"{field}\" is missing for this Import Type",
        "data_import.error.column.missing_or_not_in_form": "Column \"{field}\" is missing or needs to be selected above",
        "data_import.error.data_type.wrong": "The field {field} is wrong",
        "data_import.error.field.already_in_database": "The value of {field} is already present in the Database",
        "data_import.error.field.already_in_database_for": "The value of {field} is already present in the Database for {for}",
        "data_import.error.field.already_in_import": "The value of {field} is already present in the import",
        "data_import.error.field.does_not_exists": "The value of {field} does not exist",
        "data_import.error.field.does_not_exists_for": "The value of {field} does not exist for {for}",
        "data_import.error.field.must_be_boolean": "\"{field}\" must contain a boolean",
        "data_import.error.field.must_be_duration": "\"{field}\" must contain a duration",
        "data_import.error.field.must_be_email": "\"{field}\" must contain an email address",
        "data_import.error.field.must_be_float": "\"{field}\" must contain a number",
        "data_import.error.field.must_be_int": "\"{field}\" must contain an integer",
        "data_import.error.field.must_be_max": "\"{field}\" must contain at most {max}",
        "data_import.error.field.must_be_min": "\"{field}\" must contain at least {min}",
        "data_import.error.field.required": "Column \"{field}\" is required",
        "data_import.error.field.wrong_data": "Column \"{field}\" contains wrong data",
        "data_import.error.field.wrong_linked_model": "{model} {value} not linked to {for}",
        "data_import.error.forbidden_warehouse": "Forbidden warehouse",
        "data_import.error.line": "Line {line}: ",
        "data_import.error.max_columns_exceeded": "Maximum number of columns exceeded ({max})",
        "data_import.error.must_contain_count_separated_values": "Must contain {count} values separated by \"{separator}\" ",
        "data_import.example_from_file": "Line {nb} of file",
        "data_import.field.data_type": "Data type",
        "data_import.help.global": "An empty cell will not be considered.<br />A cell containing the string \"#null\" will empty the field if possible.",
        "data_import.info.allocations_will_be_created": "Allocations will be created if they do not exist in the system.",
        "data_import.info.article.original_price": "Original price before discount",
        "data_import.info.cant_change_when_template": "Cannot change when a Template is selected",
        "data_import.info.default": "The #default value allows you to enter the default value",
        "data_import.info.links_will_be_created": "Links between allocations will be created if they do not exist in the system.",
        "data_import.info.no_allocation_created": "No allocation will be created if they do not exist in the system.",
        "data_import.info.no_link_created": "No links between allocations will be created if they do not exist in the system.",
        "data_import.info.starts_by_hash": "If starts with \"#\", will be interpreted as an ID",
        "data_import.info.starts_by_underscore": "If starts with \"_\", will be interpreted as a {attribute} of {model}",
        "data_import.param.allowed_values.title": "Allowed values",
        "data_import.param.ignored.title": "This column is ignored",
        "data_import.param.ignored_for_field.title": "This column is ignored for the selected field \"{field}\" ",
        "data_import.param.limits.max.details": "Max. : {value}",
        "data_import.param.limits.max.title": "Max. value",
        "data_import.param.limits.min.details": "Min. : {value}",
        "data_import.param.limits.min.title": "Min. value",
        "data_import.param.list_separator.title": "Values separated by",
        "data_import.param.type.boolean.details": "Boolean : yes/true/1 or no/false/0",
        "data_import.param.type.boolean.title": "Type boolean",
        "data_import.param.type.duration.details": "Duration, for example: P30D for 30 days, P1M for 1 month, P2Y for 2 years",
        "data_import.param.type.duration.title": "Type duration",
        "data_import.param.type.email.details": "Email",
        "data_import.param.type.email.title": "Type email",
        "data_import.param.type.float.details": "Number, can contain decimals",
        "data_import.param.type.float.title": "Type number",
        "data_import.param.type.int.details": "Integer",
        "data_import.param.type.int.title": "Type integer",
        "data_import.save_columns.question": "Do you want to save these elements in a Template?",
        "data_import.title": "Data import",
        "data_import.type.create": "Creation",
        "data_import.type.create_and_replace": "Creation/replacement",
        "data_import.type.create_or_update_from": "Creation/Update by {field}",
        "data_import.type.update_from": "Update by {field}",
        "datepicker.label_calendar": "Calendar",
        "datepicker.label_current_month": "Current month",
        "datepicker.label_help": "Use the arrow keys to navigate the calendar",
        "datepicker.label_nav": "Navigation in the calendar",
        "datepicker.label_next_month": "Next month",
        "datepicker.label_next_year": "Next year",
        "datepicker.label_no_date_selected": "--",
        "datepicker.label_prev_month": "Previous month",
        "datepicker.label_prev_year": "Previous year",
        "datepicker.label_selected": "Selected date",
        "datepicker.label_today": "Today",
        "delegation.confirm.notification_added": "The delegated user will receive email notifications.",
        "delegation.confirm.notification_removed": "The delegated user will no longer receive email notifications.",
        "delegation.field.delegate": "Delegate",
        "delegation.field.notify_delegate": "Notify the delegate regularly by email",
        "delegation.model": "Delegation",
        "delegation.model.gender": "0",
        "delegation.model.plural": "Delegation",
        "delivery.error.amount_exctax_to_deliver.less_than_delivered_amount": "The amount excl. taxes must be more than or equal to {delivered} ({complement})",
        "delivery.error.amount_exctax_to_deliver.more_than_delivered_amount": "The amount excl. taxes must be less than or equal to {delivered} ({complement})",
        "delivery.error.amount_inctax_to_deliver.less_than_delivered_amount": "The amount incl. taxes must be more than or equal to {delivered} ({complement})",
        "delivery.error.amount_inctax_to_deliver.more_than_delivered_amount": "The amount incl. taxes must be less than or equal to {delivered} ({complement})",
        "delivery.error.amount_to_deliver.complement.delivered": "amount yet received{complement}",
        "delivery.error.amount_to_deliver.complement.not_delivered": "amount not received{complement}",
        "delivery.error.total_amount_exctax_to_deliver.must_be_between": "The total amount excl. taxes must be between {left} and {right}",
        "delivery.error.total_amount_inctax_to_deliver.must_be_between": "The total amount incl. taxes must be between {left} and {right}",
        "delivery.field.code": "Internal number",
        "delivery.field.label": "Reception number",
        "delivery.info.has_linked_invoices": "Attention, this {model} is potentially linked to {invoice_model}.",
        "delivery.model": "Reception",
        "delivery.model.plural": "Receptions",
        "delivery.pdf.title": "Receiving report",
        "deliveryLine.model": "Reception line",
        "delivery_address.action.create_address": "Create a delivery address",
        "delivery_address.action.edit_address": "Update the delivery address",
        "delivery_address.address_adds": "Delivery information",
        "delivery_address.deadline": "Delivery deadline",
        "delivery_address.field.address_type.addressbook": "Address book",
        "delivery_address.field.public": "Public ?",
        "delivery_address.from": "{model} address",
        "delivery_address.from.addressbook": "Address book",
        "delivery_address.from.other": "Other address",
        "delivery_address.model": "Delivery Address",
        "delivery_address.model.gender": "0",
        "delivery_address.model.plural": "Delivery Addresses",
        "delivery_address.shipping_date": "Shipping date",
        "delivery_address.type": "Address type",
        "docuware.error.DPIIBoomExport": "There was a problem trying to retrieve document ID {id}",
        "docuware.error.DPIIBoomExport_delay": "There was a problem trying to retrieve document ID {id}, new attempt in {minutes} minutes",
        "docuware.error.GetNotImplementedDWDOCID": "There was a problem trying to retrieve not implemented documents",
        "error.301.description": "It has changed URL and will soon be permanently deleted. Here is the new URL:",
        "error.301.title": "This page no longer exists",
        "error.301.warning": "Please modify your favorites with this new URL",
        "error.401.description": "You are no longer logged in.",
        "error.401.link_text": "Click here to log in again",
        "error.401.title": "Not logged",
        "error.403.description": "This action is not allowed.",
        "error.403.title": "Unauthorized",
        "error.404.description": "This page does not exist.",
        "error.404.title": "Error 404",
        "error.410.title": "Application disabled",
        "error.498.description": "your link has expired",
        "error.498.title": "Error 498",
        "error.500.description": "Server error.",
        "error.500.title": "Error 500",
        "error.502.description": "Bad Gateway",
        "error.503.description": "The site is currently under maintenance.",
        "error.503.get_message": "Additional message: <em>{message} </em>",
        "error.503.retry_after": "Please try again in {delay} seconds.",
        "error.503.retry_later": "Please try again later.",
        "error.503.title": "Site under maintenance",
        "error.cantChangeStatus": "Error while changing status, please try again later.",
        "error.cantCreate": "Error during creation, please try again later.",
        "error.cantDelete": "Error during deletion, please try again later.",
        "error.cantImport": "Error during import, please try again later.",
        "error.cantModify": "Error during the change, please try again later.",
        "error.cantRefuse": "Can not refuse this item.",
        "error.cantSort": "Can not order these items.",
        "error.cantValidate": "Can not validate this item.",
        "error.cant_create_payment": "Error during the creation of the payment, one or more invoices are already paid, waiting for payment or not yet approved.",
        "error.cant_modify": "Error during the change, please try again later.",
        "error.clamav": "Your file probably contains a virus",
        "error.duration.wrong_unit": "The type of period must correspond to an existing choice.",
        "error.field.requireOne": "At least one of these fields is required.",
        "error.file.bad_extension": "The uploaded file is not the right extension.",
        "error.form_errors": "Action not possible: there are errors in the form.",
        "error.form_errors.title": "Error",
        "error.go_back_home": "Back to the home page",
        "error.has_been_deleted": "{attribute} deleted, please choose another one",
        "error.has_been_disabled": "{attribute} disabled, please choose another one",
        "error.import_errors": "Import impossible, click here to see the details of the errors",
        "error.impossible_action": "Impossible action",
        "error.impossible_action.title": "Error {code}",
        "error.maintenance.description": "Current maintenance",
        "error.maintenance.index": "Site under maintenance",
        "error.missing_permission": "You do not have the necessary permissions.",
        "error.module.misconfigured": "Misconfigured module",
        "error.not_compatible": "{attribute} not compatible",
        "error.not_enabled": "{attribute} not active",
        "error.not_exists": "{attribute} doesn't, please choose another one",
        "error.not_found": "{attribute} not found",
        "error.no_model": "There is no {model}",
        "error.no_pdf_viewer": "You do not seem to have a built-in PDF reader in your browser, but you can download the document below.",
        "error.problem_occured": "A problem has occurred",
        "error.unknown": "Unknown error",
        "external_charge.model": "External Charge",
        "external_charge.model.plural": "External Charges",
        "faq.add": "Add a question",
        "faq.all": "See all help",
        "faq.model": "FAQ",
        "feature.model": "Product feature",
        "feature.model.gender": "0",
        "feature.model.plural": "Product features",
        "fieldable.position.bottom": "At the Bottom of BC",
        "fieldable.position.line": "In the lines of the BC",
        "fieldable.position.top": "At the top of  BC",
        "fieldable.visualizeCustomFields": "Visualize render of fields",
        "file.confirm.created": "The document has been sent.",
        "file.confirm.deleted": "The document has been deleted.",
        "file.confirm.statusValidate": "The status of the document has been changed.",
        "file.confirm.updated": "The document has been modified.",
        "file.info.not_available": "File not available.",
        "file.model.gender": "0",
        "file.model.plural": "Files",
        "file_category.model.gender": "0",
        "file_upload.action.tap_to_retry": "Tap to retry",
        "file_upload.error.code": "Error {code}. Please contact your administrator with a screenshot of this page.",
        "file_upload.error.file_already_exists": "File already exists",
        "file_upload.error.file_empty": "The file is empty",
        "file_upload.error.file_processing_error": "Error during upload",
        "file_upload.error.file_too_large": "File is too large",
        "file_upload.error.file_too_large.description": "Maximum file size is {filesize}",
        "file_upload.error.wrong_type": "Invalid file type",
        "file_upload.error.wrong_type.description": "Expects type(s) : {allTypes}",
        "filter.automatic": "Filled automatically",
        "filter.cant_deselect_label": "Can not remove",
        "filter.deselectLabel": "Remove",
        "filter.deselect_label": "Remove",
        "filter.doSearch": "Start typing to search",
        "filter.filter_to_show_more": "Perform a search to refine the results",
        "filter.is_not": "{model} is not",
        "filter.no_options": "The list is empty.",
        "filter.no_result": "No element found.",
        "filter.placeholder": "Select an option",
        "filter.search.contains": "Contains",
        "filter.search.ends_by": "Ends by",
        "filter.search.starts_by": "Starts by",
        "filter.search.title": "Search",
        "filter.selected_label": "Selected",
        "filter.select_label": " ",
        "filter.tag_placeholder": "Press enter to add to research",
        "filter.type_to_search": "Type to search",
        "finance.module": "Finance",
        "fiscal_period.error.code_exists": "This {model} code already exists.",
        "fiscal_period.error.overlap": "There is a {model} overlap.",
        "fiscal_period.field.format": "Code format",
        "fiscal_period.field.format_help_block": "You can use the following tokens (within the limit of 10 characters) :<br>{year4char} : Year (4 digits)<br>{nextYear4char} : Next year (4 digits)<br>{year2char} : Year (2 digits)<br>{nextYear2char} : Next year (2 digits)<br>{month2char} : Month (2 digits)<br>{nextMonth2char}<br>{periodNumber} : Number of the period<br>{day2char} : Day (2 digits)<br>{weekNumber} Week number (2 digits)<br>",
        "geographic_sector.field.parent_id": "Parent",
        "geographic_sector.field.requires_duns": "Requires the DUNS / ID Code",
        "geographic_sector.field.requires_intra_community_vat": "Requires intra-community VAT",
        "geographic_sector.field.requires_siret": "Requires SIRET",
        "geographic_sector.model": "Geographic sector",
        "geographic_sector.model.plural": "Geographic sectors",
        "global.acknowledge_receipt.already_done.content": "This {model} has already been received",
        "global.acknowledge_receipt.confirmation.content": "Thank you for acknowledging receipt of this {model}",
        "global.acknowledge_receipt.confirmation.title": "Acknowledgment of receipt",
        "global.action.access": "Acceder",
        "global.action.actionIrreversible": "This action is irreversible, and can not be undone later.",
        "global.action.add": "Add",
        "global.action.add_all_lines": "Add all lines",
        "global.action.add_article": "Add an article",
        "global.action.add_checked_lines": "Add checked lines",
        "global.action.add_comment": "Add a comment",
        "global.action.add_favorite": "Add to favorites",
        "global.action.add_gap_line": "Add a gap line",
        "global.action.add_in_new_line": "Add in a new line",
        "global.action.add_line": "Add a line",
        "global.action.add_line_from_catalog": "Add from catalog",
        "global.action.add_line_from_punchout_catalog": "PunchOut Catalog",
        "global.action.add_model": "Add a {model}",
        "global.action.add_models": "Add {model}",
        "global.action.add_models_massively": "Add {model} in bulk",
        "global.action.add_to_catalog": "Add to catalog",
        "global.action.add_to_model": "Add to {model}",
        "global.action.add_to_model_catalog": "Add to the catalog of the {model} \"{entity}\" ",
        "global.action.adjust": "Readjust",
        "global.action.adjust_stock": "Adjust stock",
        "global.action.approve": "Approve",
        "global.action.are_you_really_sure": "Are you really sure?",
        "global.action.are_you_sure": "Are you sure?",
        "global.action.autofill": "Autofill",
        "global.action.autofill_new_line": "Autofill in a new line",
        "global.action.back_to_awaiting": "Repass in awaiting for treatment",
        "global.action.back_to_default": "Reset to default",
        "global.action.back_to_draft": "Repass in draft",
        "global.action.back_to_pending": "Repass in awaiting for treatment",
        "global.action.break_down_expenses": "Break down expenses",
        "global.action.browse": "Browse",
        "global.action.cancel": "Cancel",
        "global.action.cancel_and_replace": "Cancel and replace",
        "global.action.cancel_model": "Cancel {model}",
        "global.action.change_exctax": "Display prices excl. taxes",
        "global.action.change_inctax": "Display prices incl. taxes",
        "global.action.change_weight": "Sort elements",
        "global.action.check_all": "Check all",
        "global.action.choice_between": "Choice between {first} and {second}",
        "global.action.choose_image": "Choose this image",
        "global.action.clear": "Clear",
        "global.action.clear_selection": "Clear selection",
        "global.action.close": "Close",
        "global.action.close_dispute": "Close the dispute",
        "global.action.close_something": "Close",
        "global.action.configure": "Configure",
        "global.action.confirm": "Confirm",
        "global.action.confirm_by_checking": "Please check the following box to confirm your action:",
        "global.action.confirm_configuration": "Confirm configuration",
        "global.action.consider_sent": "Consider as sent",
        "global.action.contact_us": "Contact us",
        "global.action.continue": "Continue",
        "global.action.copy": "Copy",
        "global.action.copyAllSuggestions": "Copy all suggestions",
        "global.action.copy_all_suggestions": "Copy all suggestions",
        "global.action.copy_down": "Copy down",
        "global.action.create": "Create",
        "global.action.create_and_add": "Create and Add",
        "global.action.create_anyway": "Create anyway",
        "global.action.create_dispute": "Create a dispute",
        "global.action.create_model": "Create a {model}",
        "global.action.create_model_for": "Create a {model} for :",
        "global.action.create_multiple": "Create multiple",
        "global.action.delete": "Delete",
        "global.action.delete_file": "Delete this file",
        "global.action.delete_image": "Delete this image",
        "global.action.delete_line": "Delete the line",
        "global.action.disable": "Disable",
        "global.action.disable_notification": "Disable notification",
        "global.action.download": "Download",
        "global.action.download_headers": "Download headers",
        "global.action.do_not_close_something": "Do not close",
        "global.action.drop_here": "Drop here ...",
        "global.action.drop_or_clic": "Drop a new file or click to upload",
        "global.action.drop_or_clic_multiple": "Drop new files or click to upload",
        "global.action.duplicate": "Duplicate",
        "global.action.enable": "Enable",
        "global.action.enable_notification": "Enable notification",
        "global.action.export": "Export",
        "global.action.exportSubmissions": "Export submissions",
        "global.action.export_from_ids": "Export from IDs",
        "global.action.export_from_ids.field.ids.help": "Separated by spaces, commas, semicolons or line breaks",
        "global.action.export_sepa": "Export to SEPA format",
        "global.action.force_status": "Force to \"{status}\" ",
        "global.action.force_the_status": "Force status",
        "global.action.forgot_password": "Forgot your password?",
        "global.action.fullSearch": "Full search",
        "global.action.generate": "Generate",
        "global.action.generateFromBudgetAnalysis": "Generate from budget analysis",
        "global.action.generate_field": "Generate the {field}",
        "global.action.get_back_as": "Get back as {user}",
        "global.action.get_login_link": "Get a login link",
        "global.action.go": "Go",
        "global.action.hide_all_lines": "Hide all lines",
        "global.action.hide_content": "Hide content",
        "global.action.hide_file": "Hide file",
        "global.action.hide_models": "Hide {models}",
        "global.action.hide_received_elsewhere": "Hide lines already received",
        "global.action.history": "History",
        "global.action.ignoreSuggestions": "Ignore suggestions",
        "global.action.ignore_suggestions": "Ignore suggestions",
        "global.action.import": "Import",
        "global.action.inject": "Inject",
        "global.action.inject_lines": "Inject lines",
        "global.action.invite": "Invite",
        "global.action.keep_pressed": "Keep pressed",
        "global.action.link_to_line": "Link to a line",
        "global.action.link_to_this_line": "Link to this line",
        "global.action.listSubmissions": "View Submissions",
        "global.action.login": "Log in",
        "global.action.logout": "Disconnect",
        "global.action.log_as": "Log in as",
        "global.action.makeAvailableToAll": "Make available to all",
        "global.action.manage": "Manage",
        "global.action.manageFields": "Manage fields",
        "global.action.manage_files": "{1}Manage files|Manage files",
        "global.action.manage_models": "Manage {models}",
        "global.action.mergeInto": "Merge with ...",
        "global.action.more_details": "More details",
        "global.action.more_options": "More options",
        "global.action.never_see_again": "Never see again",
        "global.action.new_version": "Create a new version",
        "global.action.next": "Next",
        "global.action.notify": "Notify",
        "global.action.ocr_is_running": "OCR is running. You can deactivate it to enter data manually.",
        "global.action.ok": "OK",
        "global.action.open_new_tab": "Open in new tab",
        "global.action.or_create": "Or create a new {model}",
        "global.action.other_actions": "Other actions",
        "global.action.other_actions.abbr": "Other",
        "global.action.override": "Overload",
        "global.action.paste": "Paste",
        "global.action.point": "Point",
        "global.action.preview": "Preview",
        "global.action.previous": "Previous",
        "global.action.print": "Print",
        "global.action.print_without_budget": "Print without budget",
        "global.action.put_as_main": "Put as main",
        "global.action.quick_export": "Simple export",
        "global.action.quit": "Leave",
        "global.action.rebilling_import": "Import rebills",
        "global.action.recalculate_amounts": "Recalculate amounts",
        "global.action.receive_all": "Receive all",
        "global.action.reconcile": "Reconcile",
        "global.action.reconcile_without_lines": "Reconcile without the lines",
        "global.action.refresh": "Refresh",
        "global.action.refresh_from": "Refresh from {label}",
        "global.action.refuse": "Deny",
        "global.action.register": "Create account",
        "global.action.reject": "Reject",
        "global.action.remove": "Delete",
        "global.action.remove_all": "Delete all",
        "global.action.remove_favorite": "Remove from favorites",
        "global.action.renew_pdf": "Regenerate PDF",
        "global.action.replace_file": "Replace file",
        "global.action.report": "Report",
        "global.action.reset": "Reset",
        "global.action.returnTo": "Go to {model}",
        "global.action.reverse": "Reverse",
        "global.action.reverse_selection": "Reverse selection",
        "global.action.save": "Save",
        "global.action.saveWeight": "Save the order",
        "global.action.save_research": "Save research",
        "global.action.save_weight": "Save the order",
        "global.action.search": "Search",
        "global.action.search.advanced": "Advanced search",
        "global.action.search.help.not_full_search": "Search limited to the past month|Search limited to the past {months} months",
        "global.action.search_barcode": "Search by barcode",
        "global.action.search_keyword": "Search by keyword",
        "global.action.see": "See",
        "global.action.see_detail": "See details",
        "global.action.see_in_catalog": "See in the catalog",
        "global.action.see_more": "See more",
        "global.action.select": "Please select an option",
        "global.action.send": "Send",
        "global.action.send_suggestions": "Send suggestions",
        "global.action.send_through_api": "Send through API",
        "global.action.send_to_api": "Send to API",
        "global.action.send_to_api.confirmed": "Sending to API in progress",
        "global.action.share": "Share",
        "global.action.showTraces": "View history",
        "global.action.show_all_lines": "Show all lines",
        "global.action.show_budget_analysis": "See budget status",
        "global.action.show_content": "View content",
        "global.action.show_file": "Show the file",
        "global.action.show_files": "View file(s)",
        "global.action.show_models": "Show {model}",
        "global.action.show_more": "Show more",
        "global.action.show_received_elsewhere": "Show lines already received",
        "global.action.show_traces": "View history",
        "global.action.start_action": "Start action",
        "global.action.start_validation": "Start validation",
        "global.action.start_validation.abbr": "Validation",
        "global.action.submit": "Submit",
        "global.action.summary_pdf": "Print summary",
        "global.action.suspend": "Suspend",
        "global.action.take_charge": "Take charge",
        "global.action.test": "Test",
        "global.action.toggleDropdown": "Toggle dropdown",
        "global.action.transfer_stock": "Inter-stock movement",
        "global.action.transform_into_purchase_order": "Transform into {model}",
        "global.action.unclose_something": "Unclose",
        "global.action.unforce_status": "Cancel forced status \"{status}\" ",
        "global.action.unsuspend": "No longer suspend",
        "global.action.unvalidate": "Invalidate",
        "global.action.update": "Edit",
        "global.action.update_all": "Edit all",
        "global.action.update_allocations": "Edit allocations",
        "global.action.use": "Use",
        "global.action.validate": "Validate",
        "global.action.verify": "Verify",
        "global.action.view_all": "See everything",
        "global.billing_status.complete": "Billed",
        "global.billing_status.complete_less": "Billed (lower)",
        "global.billing_status.complete_more": "Billed (higher)",
        "global.billing_status.no": "Not billed",
        "global.billing_status.non_billable": "Non-billable",
        "global.billing_status.partial": "Partially billed",
        "global.bulk_action.export": "Export",
        "global.bulk_action.export_bank_accounts": "Export bank accounts",
        "global.bulk_action.export_chart_accounting_entry_turnover": "Export Accounting entry Turnover statistics",
        "global.bulk_action.export_chart_annual_rating": "Export Annual rating statistics",
        "global.bulk_action.export_chart_certifications": "Export Certifications statistics",
        "global.bulk_action.export_chart_performance": "Export Performance statistics",
        "global.bulk_action.export_chart_security": "Export Security statistics",
        "global.bulk_action.export_chart_turnover": "Export Turnover statistics",
        "global.bulk_action.export_contacts": "Export contacts",
        "global.bulk_action.export_remaining_to_deliver": "Export of the remainder to be received",
        "global.bulk_action.export_without_details": "Export without detail",
        "global.bulk_action.export_with_details": "Export with detail",
        "global.bulk_action.send_login_password_email": "Send an invitation email with login and password",
        "global.bulk_action.simple_export": "Export (simple)",
        "global.bulk_action.validate": "Mass validate",
        "global.buyer_role.family_buyer": "Family buyer",
        "global.buyer_role.family_purveyor": "Family purveyor",
        "global.buyer_role.family_regional_buyer": "Regional family buyer",
        "global.catchphrase": "purchase orders management.",
        "global.char.comma": "Comma",
        "global.char.pipe": "Pipe",
        "global.char.semicolon": "Semicolon",
        "global.config.allocations": "Allocations",
        "global.config.fields": "Fields",
        "global.config.linksTable": "Links table",
        "global.config.pdf": "PDF",
        "global.confirm.action_done": "Action done",
        "global.confirm.added": "Successful addition",
        "global.confirm.added_entities": "Items added: {count} {model}",
        "global.confirm.added_to_jobs": "Items added to Jobs: {count} {model}",
        "global.confirm.amounts_recalculated": "Amounts recalculated",
        "global.confirm.back_to_awaiting": "Repass in awaiting for treatment successful",
        "global.confirm.back_to_draft": "Return to draft successful",
        "global.confirm.back_to_pending": "Return to pending successful",
        "global.confirm.broken_down_expenses": "Break down successful",
        "global.confirm.cancel": "Successful cancellation",
        "global.confirm.cancel_and_replace": "Cancellation and replacement successful",
        "global.confirm.changedToAllUsers": "Change to All users successful",
        "global.confirm.close": "Successful closing",
        "global.confirm.copied": "Successful copy",
        "global.confirm.created": "Successful creation",
        "global.confirm.deleted": "Successful deletion",
        "global.confirm.deleted_entities": "Items deleted: {count} {model}",
        "global.confirm.disabled": "Successful deactivation",
        "global.confirm.duplicated": "Successful duplication",
        "global.confirm.email_being_sent": "Email being sent",
        "global.confirm.email_sent": "Email sent",
        "global.confirm.enabled": "Successful activation",
        "global.confirm.export_started": "The export has started. It will be sent to you by email when it is finished",
        "global.confirm.file_deleted": "Document successfully deleted",
        "global.confirm.file_uploaded": "Document successfully uploaded",
        "global.confirm.image_uploaded": "Image successfully uploaded",
        "global.confirm.imported": "Successful import",
        "global.confirm.imported.detail": "Import completed successfully:",
        "global.confirm.invitation_sent": "Invitation sent",
        "global.confirm.new_version": "New version created",
        "global.confirm.password_changed": "Password changed successfully",
        "global.confirm.process_ongoing": "Ongoing process",
        "global.confirm.removed": "Successful removing",
        "global.confirm.replaced": "Successful replacement",
        "global.confirm.replaced_number": "{nb} replacement(s) carried out",
        "global.confirm.request_made": "Request made",
        "global.confirm.saved": "Saving done",
        "global.confirm.sorted": "The order has been saved",
        "global.confirm.start_validation": "The validation process has been launched",
        "global.confirm.statusRefuse": "The refusal has been registered",
        "global.confirm.statusValidate": "The validation has been registered",
        "global.confirm.status_changed": "The status has been changed",
        "global.confirm.suspended": "Suspension completed",
        "global.confirm.taken_charge": "Take charge done",
        "global.confirm.transformed": "Transformation done",
        "global.confirm.unclose": "Successful unclosing",
        "global.confirm.unsuspended": "Cancellation of suspension effected",
        "global.confirm.updated": "Successful modification",
        "global.date.day": "day | days",
        "global.date.month": "month | month",
        "global.date.year": "year | years",
        "global.day.friday": "Friday",
        "global.day.monday": "Monday",
        "global.day.saturday": "Saturday",
        "global.day.sunday": "Sunday",
        "global.day.thursday": "Thursday",
        "global.day.tuesday": "Tuesday",
        "global.day.wednesday": "Wednesday",
        "global.delivery_status.complete": "Received",
        "global.delivery_status.forced_complete": "Received (manually)",
        "global.delivery_status.no": "Not received",
        "global.delivery_status.partial": "Partially received",
        "global.display.header": "Header",
        "global.dispute_status.finished": "Finished dispute",
        "global.dispute_status.no": "No dispute",
        "global.dispute_status.ongoing": "Ongoing dispute",
        "global.error.already_exists": "A {model} already exists with the name \"{label}\"|{model} already exist with the name \"{label}\" ",
        "global.error.already_used_in": "The field {attribute} is already used in {model}",
        "global.error.amount_exctax.less_than_billed": "The amount excl. taxes must be greater than or equal to {billed} (amount already invoiced)",
        "global.error.amount_exctax.more_than_billed": "The amount excl. taxes must be less than or equal to {billed} (amount already invoiced)",
        "global.error.amount_inctax.less_than_billed": "The amount incl. taxes must be greater than or equal to {billed} (amount already invoiced)",
        "global.error.amount_inctax.more_than_billed": "The amount incl. taxes must be less than or equal to {billed} (amount already invoiced)",
        "global.error.at_least_one_model": "At least one {model} is necessary",
        "global.error.cant_copy_to_clipboard": "Unable to copy to clipboard",
        "global.error.cant_update_field": "Unable to modify field {field}",
        "global.error.cant_update_line_field": "Unable to modify {field} on line {line}",
        "global.error.currency_must_match": "Currency must match",
        "global.error.deposit.more_than_amount_inctax": "The {attribute} field must be less than or equal to {amount_inctax} (total incl. taxes)",
        "global.error.entity_not_found": "The field {attribute} does not correspond to any entity of type {entity} ({entity_code})",
        "global.error.is_invalid": "{attribute} is invalid",
        "global.error.limit_active_exceeded": "The limit of {limit} active {models} has been exceeded",
        "global.error.line_must_match_model": "The line must match the {model}",
        "global.error.missing_supplier_code": "The {model} code is not specified",
        "global.error.model_duplicated": "Duplicate {model}",
        "global.error.model_is_locked": "The {model} is locked",
        "global.error.must_be_closed": "The {model} must be closed",
        "global.error.must_be_in": "The field {attribute} must be in the values: {array}",
        "global.error.must_be_null": "There must not be a {model}",
        "global.error.must_not_be": "The field {attribute} must not be {value}",
        "global.error.please_check_following": "Please check the following errors:",
        "global.error.quantity.less_than_delivered": "Quantity must be greater than or equal to {delivered} (quantity already received)",
        "global.error.quantity.more_than_delivered": "Quantity must be less than or equal to {delivered} (quantity already received)",
        "global.error.require_json_format": "Requires JSON format",
        "global.error.select_at_least_one_model": "You must select at least one {model}",
        "global.error.status_must_be": "The status must be \"{status}\" ",
        "global.error.value_already_exists": "The value already exists",
        "global.error.year_limit_exceeded": "The limit of {limit} {models} has been exceeded for this year",
        "global.example": "Example",
        "global.field": "Field",
        "global.field.'+field+": "-",
        "global.field.'+ignored_field+": "-",
        "global.field.'+index+": "-",
        "global.field.abbr": "Abbreviation",
        "global.field.accounting_account_number": "Accounting account number",
        "global.field.accounting_account_number.abbreviation": "Account",
        "global.field.accounting_export_prefix": "Accounting Export Piece Number prefix",
        "global.field.account_number": "Account number",
        "global.field.actions": "Actions",
        "global.field.active": "Active",
        "global.field.additional_email_text": "Additional text for sending email",
        "global.field.additional_information": "Additional information from {model}",
        "global.field.additional_model_texts": "Information {model}",
        "global.field.additional_purchase_order_text": "Text displayed on {model}",
        "global.field.additional_purchase_order_text.help": "In case a related {model} is selected",
        "global.field.address": "Address",
        "global.field.alert_emails": "Alert email addresses",
        "global.field.allocations": "Imputations",
        "global.field.already_delivered": "Already received",
        "global.field.amount": "Amount",
        "global.field.amount.abbreviation": "Amt.",
        "global.field.amounts": "Amounts",
        "global.field.amount_": "-",
        "global.field.amount_exctax": "Amount excl. taxes",
        "global.field.amount_exctax.subtotal": "Subtotal excl. taxes",
        "global.field.amount_exctax.total": "Total excl. taxes",
        "global.field.amount_inctax": "Amount incl. taxes",
        "global.field.amount_inctax.subtotal": "Subtotal incl. taxes",
        "global.field.amount_inctax.total": "Total incl. taxes",
        "global.field.amount_taxes.total": "Total VAT",
        "global.field.approval_image": "Approval image",
        "global.field.approved_at": "Approved at",
        "global.field.approved_by": "Approved by",
        "global.field.approved_date": "Approved date",
        "global.field.asker": "Asker",
        "global.field.asker.plural": "Askers",
        "global.field.attachments": "Attachments",
        "global.field.author": "Author",
        "global.field.autogenerated_po_number": "Number of the generated {model}",
        "global.field.auto_send": "Automatic dispatch after validation",
        "global.field.auto_send_po": "Automatic dispatch of Purchase Orders after validation",
        "global.field.available_quantity": "Available quantity",
        "global.field.available_quantity.abbreviation": "Available qty.",
        "global.field.balance": "Balance",
        "global.field.bank_account_number": "Bank account number",
        "global.field.barcode": "Barcode",
        "global.field.bcc": "BCC",
        "global.field.begin_date": "Start date",
        "global.field.bic": "BIC",
        "global.field.billed_amount_exctax": "Billed amount excl. taxes",
        "global.field.billed_amount_exctax.total": "Billed total excl. taxes",
        "global.field.billed_amount_inctax": "Billed amount incl. taxes",
        "global.field.billed_amount_inctax.total": "Billed total incl. taxes",
        "global.field.billed_date": "Billing date",
        "global.field.billed_price_exctax": "Billed price excl. taxes",
        "global.field.billed_price_inctax": "Billed price incl. taxes",
        "global.field.billed_quantity": "Billed quantity",
        "global.field.billed_vat_rate": "Billed VAT",
        "global.field.billing_address": "Billing address",
        "global.field.billing_status": "Billing",
        "global.field.body": "Body",
        "global.field.budget": "Budget",
        "global.field.budget.total": "Total budget",
        "global.field.budgetKeys": "Budget keys",
        "global.field.budget_new": "New budget",
        "global.field.budget_per_unit": "Budget / U",
        "global.field.budget_summary": "Budget summary",
        "global.field.buyer": "Buyer",
        "global.field.buyer.plural": "Buyers",
        "global.field.buyer_of": "{model} buyer",
        "global.field.buyer_role": "Role of the buyer",
        "global.field.calculated_amount": "Calculated amount",
        "global.field.cc": "CC",
        "global.field.charge_date": "Date of charge",
        "global.field.choose_pattern": "Choose a pattern",
        "global.field.city": "City",
        "global.field.code": "Code",
        "global.field.code.error.no_sharp_allowed": "The # is not authorized (except in front of the value of the identifier, here {id})",
        "global.field.code.plural": "Codes",
        "global.field.comments_nb": "{nb} comment|{nb} comments",
        "global.field.commitment_exctax": "Commitment excl. taxes",
        "global.field.commitment_inctax": "Commitment incl. taxes",
        "global.field.company": "Company",
        "global.field.contains_headers": "The file contains headers",
        "global.field.content": "Content",
        "global.field.country": "Country",
        "global.field.created_at": "Created at",
        "global.field.created_by": "Created by",
        "global.field.created_date": "Creation date",
        "global.field.creator": "Creator",
        "global.field.credit": "Credit",
        "global.field.criteria": "Criteria",
        "global.field.criterion": "Criterion",
        "global.field.currency_rate": "Currency rate",
        "global.field.customer": "Customer",
        "global.field.customer_label": "Invoice customer number",
        "global.field.custom_frequency": "Custom frequency",
        "global.field.data": "Data",
        "global.field.dates": "Dates",
        "global.field.deadline": "Delivery deadline",
        "global.field.debit": "Debit",
        "global.field.default_account_number": "Default account number",
        "global.field.default_field": "Default {field}",
        "global.field.deleted": "Deleted",
        "global.field.delivered_amount_exctax": "Delivered amount excl. taxes",
        "global.field.delivered_amount_exctax.total": "Delivered total excl. taxes",
        "global.field.delivered_amount_inctax": "Delivered amount incl. taxes",
        "global.field.delivered_amount_inctax.total": "Delivered total incl. taxes",
        "global.field.delivered_at": "Received at",
        "global.field.delivered_not_billed_amount": "Amount received but not invoiced",
        "global.field.delivered_quantity": "Quantity received",
        "global.field.delivery_address": "Delivery address",
        "global.field.delivery_date": "Delivery date",
        "global.field.delivery_deadline": "Deadline of delivery",
        "global.field.delivery_delay": "Delivery times",
        "global.field.delivery_status": "Delivery",
        "global.field.description": "Description",
        "global.field.detail": "{1}Detail|{2}Details",
        "global.field.disabled": "Disabled",
        "global.field.disabled_date": "Deactivation date",
        "global.field.discount": "Discount",
        "global.field.dismissible": "Users can tick \"{never_see_again}\" ",
        "global.field.display": "Display",
        "global.field.dispute_status": "Dispute",
        "global.field.documents_footer": "PDF footer",
        "global.field.due_date": "Due date",
        "global.field.duration_days": "Number of days",
        "global.field.editable_model": "Editable {model}",
        "global.field.email": "E-mail address",
        "global.field.email.abbr": "E-mail",
        "global.field.email.undefined": "Missing email address",
        "global.field.emails.help": "One email address per line",
        "global.field.enabled": "Enabled",
        "global.field.encoding": "Encoding",
        "global.field.end_date": "End date",
        "global.field.entered_amount": "Entered amount",
        "global.field.errors": "Errors",
        "global.field.evaluated_date": "Date of evaluation",
        "global.field.exported_at": "Exported at",
        "global.field.export_date": "Date of export",
        "global.field.export_status": "Export status",
        "global.field.failed_at": "Failed at",
        "global.field.file": "File",
        "global.field.files": "File(s)",
        "global.field.firstname": "First name",
        "global.field.first_submitted_at": "Date of first submission",
        "global.field.forced_status": "Status forced to",
        "global.field.forced_status_date": "Deadline for forced status",
        "global.field.for_all_tenants": "For all Tenants",
        "global.field.frequency": "Frequency",
        "global.field.gap_exctax": "Gap excl. taxes",
        "global.field.gap_inctax": "Gap incl. taxes",
        "global.field.gender": "Gender",
        "global.field.handling_at": "Handling at",
        "global.field.handling_date": "Fecha de recogida",
        "global.field.hidden": "Hidden",
        "global.field.holder_name": "Bank account holder",
        "global.field.iban": "IBAN",
        "global.field.icon": "Icon",
        "global.field.id": "ID",
        "global.field.ids": "List of IDs",
        "global.field.image_field": "Image from your post",
        "global.field.image_url": "URL of the image",
        "global.field.imported_at": "Imported on",
        "global.field.import_type": "Import type",
        "global.field.incurred": "Incurred",
        "global.field.initial_validated_budget": "Initial budget",
        "global.field.internal_reference": "Internal Reference",
        "global.field.ip_address": "IP adress",
        "global.field.isPrice": "Is a price",
        "global.field.issuer": "Issuer",
        "global.field.job": "Job",
        "global.field.key": "Key",
        "global.field.label": "Label",
        "global.field.lang": "Language",
        "global.field.lastname": "Last name",
        "global.field.last_activity": "Last activity",
        "global.field.last_login_date": "Last login",
        "global.field.last_validated_budget": "Last validated budget",
        "global.field.last_validated_budget_version": "Latest validated version",
        "global.field.less_than_X_days": "{field} < {days} days",
        "global.field.level": "Level",
        "global.field.line": "line",
        "global.field.lines": "Lines",
        "global.field.line_id": "Line IDé",
        "global.field.link": "Link",
        "global.field.location": "Location",
        "global.field.login": "Login",
        "global.field.logo": "Logo",
        "global.field.mark": "Mark",
        "global.field.max_amount": "Max. Amount",
        "global.field.max_amount_per_order": "Maximum amount excl. VAT per {model}",
        "global.field.max_amount_per_year": "Maximum amount excl. VAT per year",
        "global.field.meta": "Meta",
        "global.field.method": "Method",
        "global.field.min_amount": "Min. Amount",
        "global.field.min_quantity_alert": "Replenishment threshold",
        "global.field.mobile": "Portable",
        "global.field.model": "Model",
        "global.field.model.plural": "Models",
        "global.field.model_type": "Type of {abbr}",
        "global.field.movement.cost": "Movement Cost",
        "global.field.multiple": "Multiple",
        "global.field.my_needs": "My {abbr}",
        "global.field.my_purchase_orders": "My orders",
        "global.field.my_suppliers": "Of which I am the manager",
        "global.field.name": "Name",
        "global.field.name_plural": "Plural name",
        "global.field.new_image": "New image",
        "global.field.not_simplifiable": "Not simplifiable",
        "global.field.not_simplifiable.help": "The validator remains in the circuit even if there are simplification conditions",
        "global.field.number_of_periods": "Number of periods",
        "global.field.old_image": "Old image",
        "global.field.only_from_warehouse": "Only {model} from this Warehouse",
        "global.field.operation_label": "Wording of the operation",
        "global.field.options": "Options",
        "global.field.options.key.error.different": "This key already exists",
        "global.field.ordered_at": "Ordered at",
        "global.field.ordered_date": "Order date",
        "global.field.ordered_quantity": "Ordered quantity",
        "global.field.original_price": "Original price",
        "global.field.original_price_exctax": "Original price excl. taxes",
        "global.field.original_price_inctax": "Original price incl. taxes",
        "global.field.other": "Others",
        "global.field.paid_amount_exctax": "Paid amount incl. taxes",
        "global.field.password": "Password",
        "global.field.payment_date": "Payment date",
        "global.field.payment_information": "Payment information",
        "global.field.payment_source": "Payment source",
        "global.field.payment_status": "Payment",
        "global.field.phone": "Phone",
        "global.field.phone.abbr": "Phone",
        "global.field.phones": "Phones",
        "global.field.piece_number": "Piece number",
        "global.field.position": "Position",
        "global.field.possible_value": "Possible value",
        "global.field.price": "Price",
        "global.field.price_exctax": "Price excl. taxes",
        "global.field.price_exctax.new": "New price excl. taxes",
        "global.field.price_inctax": "Price incl. taxes",
        "global.field.processing_status": "Processing",
        "global.field.purchase_terms": "Purchase Terms",
        "global.field.purveyors": "Purveyors",
        "global.field.quantity": "Quantity",
        "global.field.quantity.abbreviation": "Qty.",
        "global.field.quantity.new": "New quantity",
        "global.field.quantity_step": "Quantity step",
        "global.field.read_at": "Read at",
        "global.field.rebilled_amount_exctax": "Amount rebilled excl. taxes",
        "global.field.rebilled_amount_inctax": "Amount rebilled incl. taxes",
        "global.field.rebilling_date": "Rebilling date",
        "global.field.reference": "Reference",
        "global.field.reference.abbreviation": "Ref.",
        "global.field.reference.plural": "References",
        "global.field.reference.plural.help": "One reference per line",
        "global.field.reference_or_label": "Reference/Label",
        "global.field.regional_buyers": "Regional buyers",
        "global.field.remaining_amount_exctax_to_deliver": "Amount excl. taxes remaining to be received",
        "global.field.remaining_amount_inctax_to_deliver": "Amount incl. taxes remaining to be received",
        "global.field.remaining_amount_to_bill": "Remaining amount to bill",
        "global.field.remaining_amount_to_pay.short": "Rest to pay",
        "global.field.remaining_quantity_to_deliver": "Quantity remaining to be received",
        "global.field.required": "Required",
        "global.field.reserved_at": "Reserved at",
        "global.field.reverse_account_number": "Reverse account number",
        "global.field.route_name": "Route name",
        "global.field.sent_at": "Sent at",
        "global.field.short_label": "Short label",
        "global.field.show_model_from": "See the {model} from",
        "global.field.show_parents_children": "Show Parent/Child Links",
        "global.field.signature": "Signature",
        "global.field.siret": "SIRET",
        "global.field.siret.error.regex": "The SIRET number must contain 14 digits",
        "global.field.siret.info.exists": "Companies reporting this issue of Siret",
        "global.field.state": "State",
        "global.field.status": "Status",
        "global.field.stock": "Stock",
        "global.field.stock_type": "Type of movement",
        "global.field.subject": "Subject",
        "global.field.submitted_at": "Submitted at",
        "global.field.submitted_date": "Submission date",
        "global.field.submitted_for": "Submitted for",
        "global.field.submit_order_at": "{abbr} submitted at",
        "global.field.supplier_file": "Supplier Documents",
        "global.field.supported_amount": "Supported amount",
        "global.field.target": "Target",
        "global.field.template": "Template",
        "global.field.tenant": "Tenant",
        "global.field.text": "Text",
        "global.field.timezone": "Time zone",
        "global.field.title": "Title",
        "global.field.to": "To",
        "global.field.total": "Total",
        "global.field.to_approve": "To approve",
        "global.field.to_be_approved_by": "To be approved by",
        "global.field.type": "Type",
        "global.field.unit": "Unit",
        "global.field.unit_number_per_price": "Number of units per price",
        "global.field.unit_price_exctax": "Unit price excl. taxes",
        "global.field.unit_price_inctax": "Unit price incl. taxes",
        "global.field.url": "URL",
        "global.field.usable_in_validation_process": "Usable in Validation Processes",
        "global.field.user_agent": "User agent",
        "global.field.validated_amount": "Validated amount",
        "global.field.validated_at": "Validated at",
        "global.field.validation_date": "Validation date",
        "global.field.validator": "Validator",
        "global.field.validity_duration": "Period of Validity",
        "global.field.value": "Value",
        "global.field.variant": "Variant",
        "global.field.vat": "VAT",
        "global.field.vat_amount": "VAT amount",
        "global.field.vat_rate": "VAT rate",
        "global.field.vat_type": "VAT type",
        "global.field.version": "Version",
        "global.field.version.initial": "Initial version",
        "global.field.versions": "Versions",
        "global.field.weighting": "Weighting coefficient",
        "global.field.where_im_buyer": "Where i am the buyer",
        "global.field.without_attachments": "without attachments",
        "global.field.without_po": "Create without {model}",
        "global.field.with_actions_for_me_to_do": "With actions to do on my behalf",
        "global.field.with_attachments": "with attachments",
        "global.field.with_validations_for_me_anytime": "Where I am part of the validation chart",
        "global.field.with_validations_for_me_to_do": "Waiting for my validation",
        "global.field.year": "Year",
        "global.field.your_email": "Your e-mail address",
        "global.file.required": "File is required",
        "global.filesize.B": "B",
        "global.filesize.EB": "EB",
        "global.filesize.GB": "GB",
        "global.filesize.KB": "KB",
        "global.filesize.MB": "MB",
        "global.filesize.PB": "PB",
        "global.filesize.TB": "TB",
        "global.filesize.YB": "YB",
        "global.filesize.ZB": "ZB",
        "global.font_awesome.link.label": "Find icon",
        "global.form.title": "Form",
        "global.gender.female": "Female",
        "global.gender.male": "Male",
        "global.gender.neutral": "Neutral",
        "global.generate.password": "Generate a new password",
        "global.import.boolean.comment": "1 for yes, 0 for no",
        "global.import.error": "{rowsError}/{rowsCount} row was not imported because : | {rowsError}/{rowsCount} rows were not imported because :",
        "global.import.fakeBic": "Line {line} : The BIC '{fakeBic}' is not valid and the {model} could not be created.",
        "global.import.fakeIban": "Line {line} : The IBAN '{fakeIban}' is not valid and the {model} could not be created.",
        "global.import.help": "Help reading for import",
        "global.import.id.comment": "Leave blank for a creation",
        "global.import.matches.help": "Must match existing {model}",
        "global.import.missingHeaders": "The next column (s) is / are missing, please check your headers.",
        "global.import.missingHeaders.any": "All columns are missing, please check your headers as well as file encoding.",
        "global.import.notFound": "not found",
        "global.import.not_draft": "Line {line}: The {model} of ID {id} is not in draft and therefore could not be modified.",
        "global.import.problem": " However, we have detected the following issues :",
        "global.import.required": "Line {line}: The field '{undefinedValue}' is missing.",
        "global.import.requiredLines": "Line {line} : Field {requiredLinesString} is required. | Line {line} : Fields {requiredLinesString} are required.",
        "global.import.success": "{rowsSuccess}/{rowsCount} row was successfully imported/updated. | {rowsSuccess}/{rowsCount} rows were successfully imported/updated.",
        "global.import.success.detail": "{rowsSuccess}/{rowsCount} lines have been imported successfully ({createdCount} created, {updatedCount} updated).",
        "global.import.undefined": "Line {line}: The field '{undefinedKey}' => '{undefinedValue}' is not recognized.",
        "global.import.undefinedSupplierCode": "Line {line} : The supplier code {undefinedSupplierString} is unknown and the {model} could not be created.",
        "global.import.unique": "Line {line}: The field '{undefinedKey}' => '{undefinedValue}' is already used whereas it should be unique.",
        "global.index.no_result": "There are no results",
        "global.info.abbr_signification": "\"{abbr}\" for \"{for}\" ",
        "global.info.action_perimeter": "Action perimeter",
        "global.info.activated.plural": "Activated",
        "global.info.active_out_of_max": "{count} active out of {max} max.",
        "global.info.added_manually_by": "Added manually by",
        "global.info.article_choice_when_no_supplier": "By choosing an article linked to a {supplier_model}, the {supplier_model} will be applied to the {model}",
        "global.info.attached_models": "Attached models",
        "global.info.auto_send": "Automatic sending after validation",
        "global.info.back_to_pending": "The item will be put back on hold for processing.",
        "global.info.cantSend": "You can not send for the moment",
        "global.info.cant_modify_because_created_without_model": "Can not modify because this {model} was created without {without_model}",
        "global.info.cant_modify_because_model": "Can not change because there are selected {model}",
        "global.info.caution": "Caution",
        "global.info.children_links": "Links with children",
        "global.info.classification": "Classification",
        "global.info.closed": "Closed",
        "global.info.computed": "Computed",
        "global.info.confirmation_request": "Please confirm this action",
        "global.info.copied": "Copied",
        "global.info.create_with_draft": "You have draft {model}",
        "global.info.creation_count": "{count} creation|{count} creations",
        "global.info.creation_disabled_count": "{count} creation (not enbled)|{count} creations (not enabled)",
        "global.info.creation_in_progress": "Creation in progress",
        "global.info.current_value": "Current value: {value}",
        "global.info.definitive_action": "This action is definitive.",
        "global.info.deviation": "+ {deviation}% difference",
        "global.info.difference": "Difference: {value}",
        "global.info.displayed_exctax": "Prices displayed excl. taxes",
        "global.info.displayed_inctax": "Prices displayed incl. taxes",
        "global.info.dispute": "Dispute",
        "global.info.dispute.plural": "Disputes",
        "global.info.dispute_closing": "Closure of dispute",
        "global.info.empty": "empty",
        "global.info.everything_selected_by_default": "Everything is selected by default",
        "global.info.favorite": "Favorite",
        "global.info.features": "Features",
        "global.info.fields_not_updatable_after_creation": "Fields marked with this icon will not be editable after creation:",
        "global.info.field_auto_filled": "This field is filled in automatically",
        "global.info.field_auto_filled_if_empty": "This field is filled in automatically if left empty",
        "global.info.field_translatable": "This field is translatable",
        "global.info.forced_status": "Status forced to {status}",
        "global.info.forced_status_until": "Status forced to {status} until {date}",
        "global.info.from_overrun": "Generated from {model}",
        "global.info.help": "Help",
        "global.info.in": "In",
        "global.info.infos": "Informations",
        "global.info.infos.abbreviation": "Info",
        "global.info.initialization": "Initialization",
        "global.info.invited_by": "Invited by {label}",
        "global.info.in_models": "In {model_plural}",
        "global.info.in_this_model": "In this {model}",
        "global.info.items_excluded_number": "Excluded Items: {number}",
        "global.info.items_number": "Selected items: {number}",
        "global.info.last_update": "Last update",
        "global.info.limited_to_lines": "Limited to {limit} lines",
        "global.info.lines_count": "Number of lines",
        "global.info.loading": "Loading...",
        "global.info.misc": "Misc",
        "global.info.model_already_present": "{model} already present",
        "global.info.new": "New",
        "global.info.new_values": "New values",
        "global.info.nothing_excluded_by_default": "Nothing is excluded by default",
        "global.info.notifications": "Send a notification to the following email addresses:",
        "global.info.not_closed": "Not closed",
        "global.info.not_existing": "Non existant",
        "global.info.not_present": "Not present",
        "global.info.not_proposed": "Not proposed",
        "global.info.not_referenced": "Not referenced",
        "global.info.not_simplifiable": "Not simplifiable",
        "global.info.not_specified": "Not specified",
        "global.info.not_updatable_after_creation": "Not editable after creation",
        "global.info.no_action": "No action",
        "global.info.no_delivery_address": "No address entered",
        "global.info.no_item_selected": "No item selected",
        "global.info.no_line": "No line",
        "global.info.no_list_item": "No items were found",
        "global.info.no_mail_will_be_sent": "No emails will be sent.",
        "global.info.no_problem_detected": "No problem detected",
        "global.info.no_replacement": "No replacement can be made",
        "global.info.optional": "Optional",
        "global.info.options_selected": "{count} option selected|{count} options selected",
        "global.info.original_values": "Original values",
        "global.info.out_of_max": "{count} out of {max} max.",
        "global.info.overriden_email_to": "The global recipient of ALL emails is entered",
        "global.info.parents_links": "Links with parents",
        "global.info.pointed": "Pointed",
        "global.info.read": "Read",
        "global.info.real": "Real",
        "global.info.required": "Required",
        "global.info.required_fields": "Required fields : {fields}",
        "global.info.required_if": "Required if {condition}",
        "global.info.requires_deposit": "Requires a deposit",
        "global.info.save": "Save",
        "global.info.save_model_before_add": "Please save this {model} first",
        "global.info.selectable": "Selectable",
        "global.info.selected": "Selected",
        "global.info.select_displayed_items": "Select displayed items",
        "global.info.show_only_children": "Show only selected children",
        "global.info.show_only_parents": "Show only selected parents",
        "global.info.skipped_by": "Skipped by",
        "global.info.still_be_created_but_not_activated": "You can still create them but not activate them",
        "global.info.suggestedBy": "Value suggested by {user}",
        "global.info.suggested_by": "Value suggested by {user}",
        "global.info.suggestions": "Suggestions",
        "global.info.taxes_excluded": "excl. VAT",
        "global.info.taxes_included": "incl. VAT",
        "global.info.this_model": "This {model}",
        "global.info.this_request": "This request",
        "global.info.to_copy": "To copy",
        "global.info.to_copy_to": "Copy to",
        "global.info.to_replace": "To replace",
        "global.info.to_replace_to": "Replace with",
        "global.info.unavailable.plural": "Not available",
        "global.info.undefined_weight": "Unspecified weight",
        "global.info.unique": "Unique",
        "global.info.update_count": "{count} update|{count} updates",
        "global.info.users_to_notify": "You can also notify a specific user:",
        "global.info.user_interface": "User interface",
        "global.info.validation_process_reset": "The validation circuit will be reset if you continue.",
        "global.info.value_from_config": "Value from Config : {name} => {value}",
        "global.info.value_from_dotenv": "Value from .env : {name} => {value}",
        "global.info.value_from_settings": "Value from Settings : {name} => {value}",
        "global.info.warning": "Warning",
        "global.info.with": "With {text}",
        "global.maintenance.activated": "\"Maintenance\" mode is activated for this customer",
        "global.misc.by": "by",
        "global.misc.for": "for",
        "global.misc.from_model": "from {model}",
        "global.misc.on": "on",
        "global.month.april": "April",
        "global.month.august": "August",
        "global.month.december": "December",
        "global.month.february": "February",
        "global.month.january": "January",
        "global.month.july": "July",
        "global.month.june": "June",
        "global.month.march": "Mars",
        "global.month.may": "May",
        "global.month.november": "November",
        "global.month.october": "October",
        "global.month.september": "September",
        "global.notification.model_sent_to_supplier": "When sending {model_plural} to Suppliers",
        "global.notification.model_submitted": "When submitting {model_plural}",
        "global.notification.model_validated": "When validating {model_plural}",
        "global.obligatory": "Obligatory",
        "global.pdf.order_cancel_and_replace_previous": "This order cancels and replaces the previous order",
        "global.period.from": "of",
        "global.period.to": "to",
        "global.question.continue": "Do you want to continue?",
        "global.status.acknowledged_receipt": "Received by the supplier",
        "global.status.active": "Active",
        "global.status.approved": "Approved",
        "global.status.autogenerated": "Auto-generated",
        "global.status.autovalidated": "Auto-validated",
        "global.status.auto_force_status_paid": "Automatically {status} status",
        "global.status.awaiting": "Awaiting",
        "global.status.awaiting.delivery": "Pending for delivery",
        "global.status.billed": "Billed",
        "global.status.canceled": "Canceled",
        "global.status.closed": "Closed",
        "global.status.delivered_not_billed": "Delivered not billed",
        "global.status.draft": "Draft",
        "global.status.error": "Error",
        "global.status.expired": "Expired",
        "global.status.exported": "Exported",
        "global.status.forbidden": "Forbidden",
        "global.status.imported": "Imported",
        "global.status.inactive": "Not active",
        "global.status.locked": "Locked",
        "global.status.none": "None",
        "global.status.not_acknowledged_receipt": "Not received by the supplier",
        "global.status.not_exported": "Not exported",
        "global.status.not_launched": "Not launched",
        "global.status.not_ongoing": "Not ongoing",
        "global.status.not_sent": "Not sent",
        "global.status.obsolete": "Obsolete",
        "global.status.ongoing": "Ongoing",
        "global.status.paid": "Paid",
        "global.status.partial": "Partial",
        "global.status.pending": "Pending",
        "global.status.planified": "Planified",
        "global.status.processing": "Processing",
        "global.status.prospect": "Prospect",
        "global.status.refused": "Refused",
        "global.status.rejected": "Rejected",
        "global.status.sending": "Sending in progress",
        "global.status.sent": "Sent",
        "global.status.skipped": "Skipped",
        "global.status.suspended": "Suspended",
        "global.status.unvalidated": "Invalid",
        "global.status.validated": "Approved",
        "global.title.confirmation_request": "Confirmation request",
        "global.title.endorsement": "Avenant {model}",
        "global.title.form": "Form",
        "global.title.model_of_2nd_model": "{model} of the {2nd_model}",
        "global.title.relationships": "Relationships",
        "global.type.boolean": "Boolean",
        "global.type.choice": "Choice",
        "global.type.date": "Date",
        "global.type.email": "Email",
        "global.type.iso": "3-character ISO code",
        "global.type.number": "Number",
        "global.type.string": "String",
        "global.type.string.limit": "{number} characters",
        "global.type.stringList": "List of strings",
        "global.type.stringList.help": "Comma separated list",
        "global.type.text": "Text",
        "global.type.textarea": "Long text",
        "global.value.all": "All",
        "global.value.and": "and",
        "global.value.automatic": "Automatic",
        "global.value.auto_added": "Added automatically",
        "global.value.by_default": "Default",
        "global.value.capital": "Capital",
        "global.value.disabled": "Disabled",
        "global.value.enabled": "Enabled",
        "global.value.everyone": "Everyone",
        "global.value.everything": "Everything",
        "global.value.false": "False",
        "global.value.global": "Overall",
        "global.value.has_handled": "took charge",
        "global.value.inbox": "Inbox",
        "global.value.lowercase": "Lowercase",
        "global.value.main": "Main",
        "global.value.monthly": "Monthly",
        "global.value.no": "No",
        "global.value.nobody": "Nobody",
        "global.value.none": "None",
        "global.value.noParent": "No parent",
        "global.value.nothing": "Nothing",
        "global.value.not_approved": "Not approved",
        "global.value.not_exported": "Not exported",
        "global.value.not_found": "Not found",
        "global.value.not_handling": "Not handling",
        "global.value.not_ordered": "Not ordered",
        "global.value.not_sent": "Not sent",
        "global.value.not_submitted": "Not submitted",
        "global.value.not_submitted_order": "{abbr} not submitted",
        "global.value.no_duration": "Without duration",
        "global.value.null": "#null",
        "global.value.number": "Number",
        "global.value.or": "or",
        "global.value.other": "Other",
        "global.value.reason": "Reason",
        "global.value.select": "Select",
        "global.value.special_char": "Special character",
        "global.value.true": "True",
        "global.value.unique": "only one possible value",
        "global.value.unit.abbreviation": "U.",
        "global.value.unknown": "Unknown",
        "global.value.unlimited": "Unlimited",
        "global.value.upload": "Upload",
        "global.value.yearly": "Annualy",
        "global.value.yes": "Yes",
        "header.info.current_tenant_vs_all_tenants": "Current Customer / All Customers",
        "help.file.extensions": "Allowed extensions: {extensions}",
        "help.file.max_size": "Max width: {max_width} px, max height: {max_height} px",
        "help.file.max_weight": "Maximum weight: {max_weight}",
        "help.image.resize_link": "External tool to resize multiple images",
        "help.two_draggable_lists.how_to": "Drag and drop items from the column \"{col1}\" to the column \"{col2}\" in the desired order.",
        "home.index": "Home",
        "image.model": "Image",
        "importer.disclaimer": "Attention, by validating this form you risk to overwrite data in an irreversible way.Make sure to inform it correctly.",
        "importer.disclaimer.msExcel": "Please save your file as 'CSV (semicolon separated) (*.csv)' format.",
        "importer.field.file.error.badHeading": "The imported file contains an invalid column name: {column}",
        "importer.field.file.error.missingColumns": "The imported file is missing required columns: {columns}",
        "importer.field.file.help": "The name of each column must match the character by the name of a column in the selected table.<br>All columns are not required.<br>An empty cell does not will not be taken into account, a cell containing the string \"#null\" will empty the associated field (if possible).",
        "importer.field.model": "Model",
        "importer.field.table": "Table",
        "importer.importModel": "Import models",
        "importer.importTable": "Import tables",
        "incoterm.model": "Incoterm",
        "incoterm.model.plural": "Incoterms",
        "knowledge_base.title": "Knowledge base",
        "language.field.active": "Active",
        "language.field.code": "Language code (ISO 639-1)",
        "language.field.country_code": "Country code (ISO 3166-2)",
        "language.field.currency_position": "Currency position",
        "language.field.date_format": "Date format",
        "language.field.hour_format": "Time format",
        "language.model": "Language",
        "language.model.gender": "0",
        "language.model.plural": "Languages",
        "line.model": "Line",
        "line.model.gender": "0",
        "line.model.plural": "Lines",
        "mail.actions_to_do.intro": "You have actions to do.",
        "mail.actions_to_do_for_delegate.intro": "You have actions to do as a delegate.",
        "mail.hello": "Hello,",
        "mail.overriden_to": "This message has been redirected, here is the original recipient:|This message has been redirected, here are the original recipients:",
        "mail.regards": "Regards",
        "mail.reset_password.action": "Reset Password",
        "mail.reset_password.intro": "You are receiving this email because we received a password reset request for your account.",
        "mail.reset_password.outro1": "This password reset link will expire in {count} minutes.",
        "mail.reset_password.outro2": "If you did not request a password reset, no further action is required.",
        "mail.reset_password.subject": "Reset Password Notification",
        "mail.test.content": "This is a test message.<br>Please forward it to: {support_email}",
        "mail.trouble_on_link": "If you're having trouble clicking the \"{action_text}\" button, copy and paste the URL below into your web browser:",
        "mail.whoops": "Whoops!",
        "mailTemplate.field.available_variables": "Available variables",
        "mailTemplate.override": "Mail template overload",
        "mail_template.model": "Email Template",
        "mail_template.model.plural": "Email Templates",
        "maintenance.action.create_demo": "Create a demo",
        "maintenance.copyDBToDebug": "Copy the database to the test instance",
        "maintenance.dashboard": "Mantenance",
        "maintenance.delegation.cronUpdateDelegations": "{model}: deactivate when the date is exceeded",
        "maintenance.error.noDeletePOs": "not delete",
        "maintenance.field.disable_reason": "Reason for deactivation",
        "maintenance.fixAfterV1Migration": "Patch following the Boomerang Web v1 -> v2 migration",
        "maintenance.form.crons": "Launching crons",
        "maintenance.form.deletePOs": "Delete purchase orders",
        "maintenance.form.demo": "Creation of demo",
        "maintenance.modal.confirmDeletePOs": "Do you want to delete all purchase orders",
        "maintenance.realtimeInfos": "Real-time infos",
        "maintenance.refreshClientStatistic": "Refresh statistical data",
        "maintenance.reset_demo_data": "Reset DEMO data",
        "maintenance.reset_demo_data.warning": "Current data will be overwritten in favor of new demo data, based on the current configuration.",
        "maintenance.sql_query.title": "SQL Query",
        "maintenance.supplier.cronUpdateFamiliesStatuses": "{model}: update the status of {product_families}",
        "maintenance.supplier.cronUpdateForcedStatuses": "{model}: remove forced statuses when the date is exceeded",
        "maintenance.supplier.cronUpdateStatistics": "{model}: update the statistics of the {model} concerned ({count} items, {max} max. at a time)",
        "maintenance.supplier.cronUpdateStatuses": "{model}: update statuses",
        "maintenance.supplier_contract.check_status": "{model}: deactivate when the date is exceeded",
        "maintenance.supplier_file.cronUpdateStatuses": "{model}: deactivate when the date is exceeded",
        "maintenance.title.data_accuracy": "Verify the accuracy of the data",
        "maintenance.title.disable_tenant": "Customer deactivation",
        "maintenance.title.tests": "Tests",
        "maintenance.trace.cronDeleteOldTraces": "{model}: delete when the date is too old",
        "message.field.texts.help": "You can use the following tokens:<br>{firstname}: First name of the logged in user<br>{lastname}: Last name of the logged in user<br>{user_label}: First name + Last name of the logged in user<br>{email} : E-mail address of the logged in user<br >{company}: Customer name<br>",
        "message.info.tokens_may_not_work": "Some tokens might not work on pages without a User logged in",
        "message.model": "Message",
        "message.model.plural": "Messages",
        "metrics.po": "Purchase orders created since the last contract renewal",
        "metrics.projection": "Projection of purchase orders created until the next contract renewal",
        "metrics.storage": "Storage used",
        "metrics.user": "Created users",
        "model.action.manage": "Manage {model}",
        "model.all": "All {model}",
        "model.as_buyer": "{model} of which I am a Buyer",
        "model.back_to_draft.existing_payment_lines": "Also, the {model} has already been paid, but no Payment will be canceled.",
        "model.cancel.cancel_version": "Also, the {model} is a version of the original {model}. Please check the following box to confirm the restoration of the previous version of this {model} :",
        "model.cancel.existing_payment_lines": "Also, the {model} has already been paid, but no Payment will be canceled.",
        "model.create": "Creation",
        "model.delete": "Remove",
        "model.disable": "Deactivate",
        "model.error.can_not_find": "{model} not found",
        "model.favorite": "Favorite {model}",
        "model.info.amount_zero": "The {model} has an amount equal to 0. Do you want to continue?",
        "model.info.cancel": "The {model} will be canceled if you continue.",
        "model.info.cancel_and_replace": "The {model} will be canceled if you continue, and a new draft will be created to replace it.",
        "model.info.cancel_and_replace_of": "Cancel and replace \"{replaced}\" ",
        "model.info.cant_be_deleted": "The {model} cannot be deleted (it is used in various elements) but it can be disabled.",
        "model.info.close": "The {model} will be closed and cannot be modified if you continue.",
        "model.info.has_articles_to_disable": "The {model} has {article_models}, these will be deactivated.",
        "model.info.item_cant_be_deleted": "This element therefore cannot be deleted.",
        "model.info.main": "Main {model}",
        "model.info.mark_as_refused": "You will need to create a new {model} once it is refused.",
        "model.info.new_version": "The {model} will be blocked and a new version will be created in draft if you continue.",
        "model.info.none_available": "No {model} available",
        "model.info.pdf": "PDF of the {model}",
        "model.info.unclose": "The {model} will be unclosed and can be modified again if you continue.",
        "model.info.unkown": "Unkown {model}",
        "model.other": "Other",
        "model.own": "{model} of which I am Creator or Recipient",
        "model.title.configure": "Configure: {model}",
        "model.title.create": "Creation: {model}",
        "model.title.import": "Import : {model}",
        "model.title.update": "Modification: {model}",
        "model.to_replace": "{model} to replace",
        "model.update": "Modification",
        "model_line.model": "{model} line",
        "modification.model": "Modification",
        "modification.model.gender": "0",
        "module.mirroring_database": "Mirroring Database",
        "module.multi_company": "Multi-company",
        "module.multi_currency": "Multi-currency",
        "module.not_enabled": "Module not active",
        "module.supplier.advanced": "Advanced Management",
        "module.supplier.login": "Login",
        "module.supplier_invoice.ocr": "OCR",
        "need.model": "Purchase request",
        "need.model.plural": "Purchase requests",
        "need.status.submitted_purchase_order": "{abbr} submitted",
        "need.status.validated_purchase_order": "{abbr} validated",
        "need.title.choose_model_to_deliver": "Choose a {model} to deliver",
        "notification.label.completed_export": "Export (attached file)",
        "notification.label.delivery.sending": "Sending a {model}",
        "notification.label.failed_export": "Export failed",
        "notification.label.model_expiration_alert": "Expiration of {model}",
        "notification.label.model_expiration_alert_before": "Warning before {model} expire",
        "notification.label.need.awaiting": "A {model} has been issued",
        "notification.label.need.po_submitted": "The {po_model} of a {model} has been submitted",
        "notification.label.need.po_validated": "The {po_model} of a {model} has been validated",
        "notification.label.need.processing": "A {model} has been taken care of",
        "notification.label.need.suspended": "A {model} has been suspended",
        "notification.label.need.suspension_stopped": "A {model} is no longer suspended",
        "notification.label.need.transfered": "A {model} has been transferred",
        "notification.label.new_comment": "A comment has been sent on a {model}",
        "notification.label.payment.line_paid": "A {model} has been indicated as paid (transfer only)",
        "notification.label.purchase_order.sending": "Sending a {model}",
        "notification.label.purchase_order.validated_with_deposit": "Validated {model} requiring a deposit",
        "notification.label.quotation_sending": "Sending a Quotation request",
        "notification.label.rel_supplier_product_family.awaiting": "A supplier family must be validated",
        "notification.label.rel_supplier_product_family.expiration_alert": "Expiration of supplier families",
        "notification.label.rel_supplier_product_family.expiration_alert_before": "Warning before vendor families expire",
        "notification.label.rel_supplier_product_family.refused": "A supplier family was refused",
        "notification.label.rel_supplier_product_family.unactivation_by_file_expiration_alert": "Expiration of supplier families",
        "notification.label.rel_supplier_product_family.validated": "A supplier family has been validated",
        "notification.label.rel_supplier_product_family_unactivation_by_file_expiration_alert": "Expiration of {model} (lack of {file_model})",
        "notification.label.suggested_modifications": "Modifications are to be validated",
        "notification.label.supplier.after_validation": "A Supplier has been validated - sending the email according to configuration",
        "notification.label.supplier.contact_invitation": "Invitation of a Supplier contact",
        "notification.label.supplier.data_creation": "Creation of a {model} while not being the account manager",
        "notification.label.supplier.file_creation": "Creation of a supplier document while not being the account manager",
        "notification.label.supplier.file_expiration_alert": "Expiration of supplier documents",
        "notification.label.supplier.file_expiration_alert_before": "Warning before expiration of supplier documents",
        "notification.label.supplier.login_request": "A Supplier asks to log in",
        "notification.label.supplier_contract.expiration_alert": "Expiration of {models}",
        "notification.label.supplier_contract.expiration_alert_before": "Warning before expiration of {models}",
        "notification.label.supplier_invoice.notification_when_no_delivery": "Receipt request for a Supplier Invoice",
        "notification.label.user.invitation": "Inviting a user",
        "notification.label.validation_awaiting": "A {model} must be validated",
        "notification.label.validation_refused": "A {model} has been refused",
        "notification.label.validation_submitted": "A {model} has been submitted for validation",
        "notification.label.validation_validated": "A {model} has been validated",
        "notification.markAllAsRead": "Mark all as read",
        "notification.model": "Notification",
        "notification.model.gender": "0",
        "notification.model.plural": "Notifications",
        "notification.subject.actions_to_do": "Summary of actions to do",
        "notification.subject.actions_to_do_for_delegate": "Summary of actions to do as a delegate",
        "notification.subject.bank_account.validation_awaiting": "A {model} for the Supplier \"{supplier}\" must be approved and needs your attention",
        "notification.subject.bank_account.validation_refused": "A {model} for the Supplier \"{supplier}\" has just been denied",
        "notification.subject.bank_account.validation_submitted": "The {model} \"{label}\" has been submitted for validation",
        "notification.subject.bank_account.validation_validated": "A {model} for the Supplier \"{supplier}\" has just been validated",
        "notification.subject.completed_export": "Your export is ready",
        "notification.subject.delivery.sending": "{model} \"{delivery}\" ",
        "notification.subject.failed_export": "Your export failed",
        "notification.subject.need.awaiting": "A {model} concerning you has been issued",
        "notification.subject.need.po_submitted": "The {po_model} of a {model} concerning you has been submitted",
        "notification.subject.need.po_validated": "The {po_model} of a {model} concerning you has been validated",
        "notification.subject.need.processing": "A {model} concerning you has been taken care of",
        "notification.subject.need.suspended": "A {model} concerning you has been suspended",
        "notification.subject.need.suspension_stopped": "A {model} concerning you is no longer suspended",
        "notification.subject.need.transfered": "A {model} has been transferred to you",
        "notification.subject.new_comment": "A comment has been sent on a {model} concerning you",
        "notification.subject.payment.line_paid": "Invoice paid",
        "notification.subject.purchase_order.sending": "{model} \"{purchaseOrder}\" ",
        "notification.subject.purchase_order.validated_with_deposit": "New deposit required on a {model}",
        "notification.subject.quotation_sending": "Quotation request",
        "notification.subject.rel_supplier_product_family.awaiting": "The family \"{family}\" must be validated for supplier \"{supplier}\" and needs your attention",
        "notification.subject.rel_supplier_product_family.expiration_alert": "The family \"{productFamily}\" of the supplier \"{supplier}\" has expired",
        "notification.subject.rel_supplier_product_family.expiration_alert_before": "The family \"{productFamily}\" of the supplier \"{supplier}\" expires soon",
        "notification.subject.rel_supplier_product_family.refused": "The family \"{family}\" has just been denied for supplier \"{supplier}\" ",
        "notification.subject.rel_supplier_product_family.unactivation_by_file_expiration_alert": "The family \"{productFamily}\" of the supplier \"{supplier}\" has been disabled",
        "notification.subject.rel_supplier_product_family.validated": "The family \"{family}\" has just been validated for supplier \"{supplier}\" ",
        "notification.subject.suggested_modifications": "Modifications have been suggested",
        "notification.subject.supplier.after_validation": "A new supplier has been validated",
        "notification.subject.supplier.contact_invitation": "Invitation to use Boomerang Web",
        "notification.subject.supplier.file_creation": "A new document has been uploaded for the supplier \"{supplier}\" ",
        "notification.subject.supplier.file_expiration_alert": "The document \"{file}\" of the supplier \"{supplier}\" has expired",
        "notification.subject.supplier.file_expiration_alert_before": "The document \"{file}\" of the supplier \"{supplier}\" expires soon",
        "notification.subject.supplier.login_request": "Login Request",
        "notification.subject.supplier_contract.expiration_alert": "The {model} \"{contract}\" has expired",
        "notification.subject.supplier_contract.expiration_alert_before": "The {model} \"{contract}\" expires soon",
        "notification.subject.supplier_invoice.notification_when_no_delivery": "Receipt request concerning the {model} \"{supplier_invoice}\" ",
        "notification.subject.user.invitation": "Invitation to use Boomerang Web",
        "notification.subject.validation_awaiting": "The element \"{label}\" must be approved and needs your attention",
        "notification.subject.validation_refused": "The element \"{label}\" has just been denied",
        "notification.subject.validation_submitted": "The element \"{label}\" has been submitted for validation",
        "notification.subject.validation_validated": "The element \"{label}\" has just been validated",
        "observation.field.sentBy": "by",
        "observation.field.sentOn": "Posted on",
        "observation.model": "Observation",
        "observation.model.gender": "0",
        "observation.model.plural": "Observations",
        "pagination.model": "Pagination",
        "pagination.next": "Next",
        "pagination.per_page": "Per page",
        "pagination.previous": "Previous",
        "password.action.change": "Change password",
        "passwordChange.error.verifyConfirmation": "Confirmation of the password does not match",
        "password_reset.define": "Set a new password",
        "password_reset.reset": "Reset password",
        "password_reset.send_mail": "Send the password reset link",
        "payment.action.mark_as_paid": "Mark as paid",
        "payment.action.mark_as_refused": "Mark as refused",
        "payment.confirm.line_deleted_due_to_overpayment": "Line of {model} deleted because the amount reached 0",
        "payment.confirm.overpayment_added_to_invoice": "Overpayment attached to {model}",
        "payment.error.amount_inctax.less_than_paid": "The amount incl. taxes must be more than {paid} (amount remaining to be paid)",
        "payment.error.amount_inctax.less_than_zero": "The amount incl. taxes must be more than 0",
        "payment.error.amount_inctax.more_than_paid": "The amount incl. taxes must be less than {paid} (amount remaining to be paid)",
        "payment.error.amount_inctax.more_than_zero": "The amount incl. taxes must be less than 0",
        "payment.error.amount_inctax.nothing_more_to_pay": "There is nothing more to pay",
        "payment.error.currency_not_euro": "SEPA export is only possible with payments in euros.",
        "payment.error.missing_banking_info": "Missing bank details (RIB)!",
        "payment.error.missing_payment_source_banking_info": "Bank details for the payment method are missing, please fill them in before doing the SEPA export.",
        "payment.error.missing_supplier_banking_info": "Bank details for one of the suppliers are missing, thank you for informing them before carrying out the SEPA export.",
        "payment.field.paid_amount_inctax": "Payment amount incl. taxes",
        "payment.info.only_from_currency": "The displayed items are pre-filtered according to the payment currency: {currency}",
        "payment.line.status.title.blocked": "{model} validated, but blocked due to insufficient balance or debit account",
        "payment.line.status.title.canceled": "{model} canceled by user",
        "payment.line.status.title.error": "Error",
        "payment.line.status.title.planified": "{model} validated and pending execution",
        "payment.line.status.title.refused": "{model} refused by the validator",
        "payment.line.status.title.rejected_by_beneficiary_bank": "{model} refused by the beneficiary's bank",
        "payment.line.status.title.rejected_by_holder_bank": "{model} refused by the issuer's bank",
        "payment.mark_as_paid.send_mail": "You can send a confirmation email to each {model}.",
        "payment.model": "Payment",
        "payment.model.plural": "Payments",
        "payments.overpayment": "Overpayment",
        "payments.overpayment.plural": "Overpayments",
        "payment_source.field.organization_id": "SIRET number",
        "payment_source.field.organization_name": "Company Name",
        "payment_source.model": "Payment source",
        "payment_source.model.gender": "0",
        "payment_source.model.plural": "Payment sources",
        "payment_term.field.fixed_day": "Day of the month for payment",
        "payment_term.field.month_end": "End of month?",
        "payment_term.field.on_receipt": "From the date of receipt?",
        "payment_term.field.payment_deadline": "Number of days",
        "payment_term.label.cash": "cash",
        "payment_term.label.fixed_day": "payment on {fixed_day} next",
        "payment_term.label.month_end": "end of month",
        "payment_term.label.on_receipt": "upon receipt",
        "payment_term.label.payment_deadline": "{payment_deadline} days",
        "payment_term.model": "Payment term",
        "payment_term.model.gender": "0",
        "payment_term.model.plural": "Payment terms",
        "payment_type.model": "Type of payment",
        "payment_type.model.plural": "Types of payment",
        "payment_type.type.none": "Not specified",
        "payment_type.type.sepa_transfer": "SEPA transfer",
        "pending_api_call.model": "Outbound API call",
        "pending_api_call.model.plural": "Outbound API calls",
        "pending_supplier_invoice.file.default_original_name": "Imported file",
        "pending_supplier_invoice.model.plural": "Pending Invoices",
        "perm.budgetAnalysis.index": "See budget analyzes",
        "perm.menu.admin": "Access the Admin menu",
        "permission.by_model": "By {model}",
        "permission.details": "Details of permissions",
        "permission.has_permission": "Has permission",
        "permission.model": "Permission",
        "permission.model.plural": "Permissions",
        "prefill_data.model": "Prefilled Datum",
        "prefill_data.model.plural": "Prefilled Data",
        "prefill_data.target.comment_pattern": "Comment template : {model}",
        "print.share_url.info": "You can copy this URL to share the document, which becomes accessible even without being logged in.",
        "productFamily.field.is_main_product_family": "Main",
        "productFamily.field.selectedProductFamilies": "Selected families",
        "product_family.field.approvals": "Approvals",
        "punchout.module": "PunchOut Catalog",
        "purchaseOrder.import.field.update": "Update the information of the purchase orders with an already existing label",
        "purchaseOrder.import.undefined.allocation": "Line {line} : The code {undefinedAllocation} is undefined and the purchase order could therefore not be created.|Line {line} : The codes {undefinedAllocation} are undefined and the purchase order could therefore not be created.",
        "purchaseOrder.import.undefined.incoterm": "Line {line} : The incoterm {undefinedIncoterm} is undefined and the purchase order could therefore not be created.",
        "purchaseOrder.import.undefined.status": "Line {line} : The status {undefinedStatus} is undefined and the purchase order could therefore not be created.",
        "purchaseOrder.import.undefined.supplier": "Line {line} : The supplier code {undefinedSupplier} is undefined and the purchase order could therefore not be created.",
        "purchaseOrder.import.undefined.supplier_contact": "Line {line} : The supplier contact email {undefinedSupplierContact} is undefined and the purchase order could therefore not be created.",
        "purchaseOrder.import.undefined.supplier_contact_default": "Line {line} : This supplier does not have a default contact although a contact is required. The purchase order could not therefore be created.",
        "purchaseOrder.import.undefined.user": "Line {line} : Author's login {undefinedUser} is undefined and the purchase order could therefore not be created.",
        "purchaseOrder.import.unique.code": "Line {line} : An purchase order already exists with the same label and a code different from {uniqueString}.",
        "purchaseOrder.payment_status.complete": "Paid",
        "purchaseOrder.payment_status.no": "Not paid",
        "purchaseOrder.payment_status.partial": "Partially paid",
        "purchaseOrder.pdf.billing_conditions": "IMPORTANT: The Order Number&nbsp;<b>{code}</b> and the reference&nbsp;<b>{reference}</b> must imperatively appear on the invoices.",
        "purchaseOrder.pdf.billing_conditions.title": "Billing Conditions",
        "purchaseOrder.pdf.payment_period": "Payment period",
        "purchaseOrder.pdf.supplier_address": "Supplier address",
        "purchase_order.action.add_contact": "Create a new contact",
        "purchase_order.back_to_draft.already_sent_at": "Also, the {model} has already been sent to the Supplier. Please check the following box to confirm the cancellation of the \"{status}\" status (no notification will be sent to the Supplier):",
        "purchase_order.cancel.already_sent_at": "Also, the {model} has already been sent to the Supplier. Please check the following box to confirm the cancellation of the {model} (no notification will be sent to the Supplier):",
        "purchase_order.error.article.only_enabled": "This article must be active",
        "purchase_order.error.max_amount_per_order.exceeded": "The Supplier limit ({value}/order) is exceeded",
        "purchase_order.error.max_amount_per_year.exceeded": "The Supplier limit ({value}/year) is exceeded",
        "purchase_order.error.stock_exceeded": "Current stock ({current_stock}) exceeded by {exceeded} unit(s)",
        "purchase_order.export.filename": "orders",
        "purchase_order.export_remaining_to_deliver.filename": "orders-to-receive",
        "purchase_order.field.articles_quantity": "Quantity of items",
        "purchase_order.field.code": "PO number",
        "purchase_order.field.deposit": "Deposit requested",
        "purchase_order.field.deposit.abbreviation": "Ad",
        "purchase_order.field.deposit_paid_at": "Deposit paid at",
        "purchase_order.field.id": "PO ID",
        "purchase_order.field.references_number": "Number of references",
        "purchase_order.field.send_mode": "Send mode",
        "purchase_order.field.supplier_comment": "Supplier Comment",
        "purchase_order.field.supplier_contact": "Supplier contact",
        "purchase_order.info.gap_line": "Gap line",
        "purchase_order.info.internal_article": "{model} to be restocked in stock",
        "purchase_order.inject_lines.error": "Error when injecting multiple lines",
        "purchase_order.inject_lines.impossible_relation": "Line {line}: There is no parent-child link between the code {impossibleRelationAllocation} and the other codes of the line.",
        "purchase_order.inject_lines.no_right": "Line {line} : You do not have the necessary permissions to create lines using the code {noRightAllocation}.",
        "purchase_order.inject_lines.undefined.allocation": "Line {line}: The code {undefinedAllocation} is undefined.|Line {line}: The codes {undefinedAllocation} are undefined.",
        "purchase_order.label.warehouse_allocations_adjustment": "Adjustment of imputations",
        "purchase_order.modal.multiple_suppliers.article_from_supplier": "One of your articles is linked to <b>{supplier}</b>",
        "purchase_order.modal.multiple_suppliers.desynchronization_warning": "If you change Supplier, your items will be out of sync",
        "purchase_order.model": "Purchase Order",
        "purchase_order.model.plural": "Purchase Orders",
        "purchase_order.send.already_sent": "This {model} has already been sent",
        "purchase_order.send.can_log_as_supplier": "You can simply log in to submit your invoices:",
        "purchase_order.summary_pdf.title": "Order - Receipt - Billing",
        "purchase_order.unclose.error.missing_quantity": "There are missing items in stock to be able to unclose :",
        "purchase_order.unclose.error.missing_quantity.detail": "{article}: quantity required: {needed}, quantity available: {available}",
        "purchase_order_send_mode.model": "Send mode",
        "purchase_order_send_mode.model.plural": "Send modes",
        "purchase_type.model": "Purchase type",
        "purchase_type.model.plural": "Purchase types",
        "qualification.model.gender": "0",
        "question.model": "Question",
        "question.model.plural": "Questions",
        "quotation_request.download.filename": "quotation-request-",
        "quotation_request.model": "Quotation request",
        "quotation_request.model.gender": "0",
        "registration.action.access": "Access your software",
        "registration.action.add_user": "Add a user",
        "registration.action.remove_user": "Remove user",
        "registration.action.start_now": "Start now!",
        "registration.action.use_different_delivery_address": "Use a different delivery address",
        "registration.error.email_already_registered": "You have already made a request with this email address",
        "registration.error.email_must_be_different": "Email address must be different from {field}",
        "registration.field.message": "Your message",
        "registration.info.already_verified": "Your registration has already been verified",
        "registration.info.disclaimer": "By continuing, you agree to the <a href=\"{link}\" target=\"blank\">Terms of Use</a>.",
        "registration.info.fill_code": "Please enter the code received by email",
        "registration.info.impossible": "Registration impossible, please contact our customer service",
        "registration.info.need_more_configuration": "Do you need more customization (currency, categories, etc.)?",
        "registration.mail.complete.intro1": "Welcome to Boomerang Web, the Purchasing Management platform.",
        "registration.mail.complete.intro2": "Your software is ready to use!",
        "registration.mail.complete.outro1": "All you have to do is enter a password and everything will be ready!",
        "registration.mail.complete.outro2": "This link is only available for one hour, however you can use the following link to perform a password reset.",
        "registration.mail.complete.subject": "Access to Boomerang Web",
        "registration.mail.error.intro": "An error occurred during the creation of a Tenant by a prospect.",
        "registration.mail.error.subject": "New instance: error",
        "registration.mail.need_custom.intro": "A prospect needs help setting up their new instance:",
        "registration.mail.need_custom.outro": "Please contact him again as soon as possible.",
        "registration.mail.need_custom.subject": "New instance: help required",
        "registration.mail.notification_new_tenant.intro": "A new client has just created its instance:",
        "registration.mail.notification_new_tenant.subject": "New instance: creation completed",
        "registration.mail.registration.intro1": "Welcome to Boomerang Web, the Purchasing Management platform.",
        "registration.mail.registration.intro2": "To confirm your registration, please enter the following code after clicking on the link below:",
        "registration.mail.registration.subject": "Registration for Boomerang Web",
        "registration.message.created": "Your configuration is ready.",
        "registration.message.creating": "Your configuration is taken into account. You will receive an email when everything is ready.",
        "registration.message.message_sent": "A message has been sent to our team, we will get back to you soon.",
        "registration.model": "Registration",
        "registration.model.plural": "Registrations",
        "registration.title.company": "Complete your company information:",
        "registration.title.configuration": "Configuration",
        "registration.title.registration": "Registration",
        "registration.title.users": "Users",
        "registration.title.validation": "Who should validate the Purchase Orders?",
        "registration.title.verification": "Verification",
        "relAllocation.import.field.deleteRelation": "Remove existing links between items",
        "relAllocation.import.field.update": "Update the elements whose codes already exist",
        "relAllocations.error.alreadyExists": "This relationship already exists",
        "relAllocations.field.allocation_type": "Allocation type",
        "relAllocations.import.allocationOrProductFamily": "Imputations or families of products to import",
        "relAllocations.import.error.required": "At least one allocation/product family must be selected.",
        "relBuyer.model": "Buyers",
        "relPermission.model": "Relationship with permissions",
        "rel_allocations.action.import": "Import Allocations",
        "rel_allocations.model": "Allocation link",
        "rel_allocations.model.plural": "Allocation links",
        "rel_supplier_product_family.model": "Supplier family",
        "rel_supplier_product_family.model.plural": "Supplier families",
        "rel_supplier_product_family_validation.model": "Supplier family Validation",
        "rel_supplier_product_family_validation.model.gender": "0",
        "rel_supplier_product_family_validation.model.plural": "Supplier family Validations",
        "role.model": "Role",
        "role.model.plural": "Roles",
        "role.value.noRole": "No role",
        "search_saving.model": "Search saving",
        "search_saving.model.plural": "Search savings",
        "secureStorage.field.token": "Secure Storage Token",
        "secure_storage.error.set_token": "Please enter the token in the Secure Storage database",
        "secure_storage.title.modal": "Insert file",
        "session.model": "Session",
        "session.model.plural": "Sessions",
        "setting.config.api": "API",
        "setting.config.customer": "Customer",
        "setting.config.models": "Models",
        "setting.config.model_fields": "Model fields",
        "setting.config.modules": "Modules",
        "setting.customer.allocations.title": "Allocations",
        "setting.customer.contract.title": "Contract",
        "setting.customer.email_sending.title": "Sending emails",
        "setting.customer.files.title": "Files",
        "setting.customer.formats": "Formats",
        "setting.customer.misc.title": "Misc",
        "setting.customer.prices.title": "Prices",
        "setting.field.forced_status_max_duration": "Maximum duration of a Forced status to {status}",
        "setting.field.landing_page": "Redirection after connection",
        "setting.field.layout_striped": "Make every other row stand out in tables",
        "setting.field.supplier_objective_hse": "HSE objective",
        "setting.field.supplier_objective_oqd": "OQD objective",
        "setting.field.supplier_objective_otd": "OTD objective",
        "setting.field.versioning": "Versioning",
        "setting.form.log_filter_query": "Log scopeFilter queries from {model}",
        "setting.form.maintenance": "Maintenance",
        "setting.formField.ATTACHMENT__AUTHORIZED_TYPES": "Allowed file types",
        "setting.formField.ATTACHMENT__DONT_CHECK_FILE_TYPE": "Do not check files type",
        "setting.formField.CURRENCY__AUTOMATIC_RATE": "Automatic update of exchange rates",
        "setting.formField.DASHBOARD__COMPARISON_MONTH_NUMBER": "Number of months for the comparison of the amounts of {model} :",
        "setting.formField.DASHBOARD__DISTRIBUTION_ALLOCATION": "Distribution of {model} by :",
        "setting.formField.DASHBOARD__INSTANCE": "Information about your subscription :",
        "setting.formField.GLOBAL__AMOUNT_DECIMAL_DELIMITER": "Decimal point delimiter",
        "setting.formField.GLOBAL__AMOUNT_DECIMAL_NUMBER": "Number of decimals in the amounts",
        "setting.formField.GLOBAL__DEBUG": "Enable \"DEBUG\" mode",
        "setting.formField.GLOBAL__DISABLE_ALL_EXPORTS": "Disable all exports",
        "setting.formField.GLOBAL__DISABLE_ANTIVIRUS": "Disable antivirus",
        "setting.formField.GLOBAL__DISABLE_CHANGE_PASSWORD": "Disable possibilities to change password",
        "setting.formField.GLOBAL__FASTER_QUERY": "Use faster queries",
        "setting.formField.GLOBAL__LOG_QUERY": "Log ALL queries in file {file}",
        "setting.formField.GLOBAL__MAINTENANCE_MODE": "Activate \"Maintenance\" mode for this customer",
        "setting.formField.GLOBAL__PRICE_DISPLAY": "Price display",
        "setting.formField.GLOBAL__QUERY_MONTHS_DEFAULT_LIMIT": "Default data display limit",
        "setting.formField.MAIL__ALERT_DELAY": "Email alert times",
        "setting.formField.PDF__FONT_FAMILY": "PDF font",
        "setting.formField.PDF__MAIN_COLOR": "PDF main color",
        "setting.formField.PURCHASE_ORDER__USE_DEPARTMENT_PAYMENT_INFOS": "Use of financial information from the {model}",
        "setting.formField.SUPPLIER__AFTER_VALIDATION_EMAILS": "Emails for sending mail \"{mail_title}\" ",
        "setting.formField.SUPPLIER__APPROVAL_DEFAULT_DURATION": "Default activation time of a subfamily without approval",
        "setting.formField.SUPPLIER__CAN_ACCESS_TO_APP": "Give access to suppliers on this instance",
        "setting.formField.SUPPLIER__ENABLE_CLIENT_NUMBER": "Activate \"Client number\" ",
        "setting.formField.SUPPLIER__ENABLE_FORCED_STATUS": "Activate the Forced status",
        "setting.formField.SUPPLIER__ENABLE_PO_INFORMATIONS": "Activate \"Purchase Order infos\" ",
        "setting.formField.SUPPLIER__ENABLE_TAB_CHARTS": "Activate \"{tab}\" tab",
        "setting.formField.SUPPLIER__REQUIRED_FILE_TYPES": "Mandatory file types (indicate IDs)",
        "setting.formField.TENANT__LOGO": "Customer logo (appears in the main menu)",
        "setting.formField.TENANT__MAIN_COLOR": "Customer main color",
        "setting.formField.TRACE__DELETING_DELAY": "Delays before deleting Traces",
        "setting.form_field.BUDGET_ANALYSIS__DEFAULT_FILTER_KEYS_NUMBER": "Number of Key Components displayed in the default filter",
        "setting.form_field.BUDGET__KEY_MILESTONE": "Budget key milestone",
        "setting.form_field.CATALOG__CART_MODE": "Using the shopping cart in the catalog of articles",
        "setting.info.other": "Other settings",
        "setting.info.simplification": "Simplification",
        "setting.maintenance.debug": "Debug",
        "setting.maintenance.mailtrap.title": "Mailtrap",
        "setting.maintenance.other_actions.title": "Other actions",
        "setting.maintenance.send_test_log": "Send a test log",
        "setting.maintenance.send_test_mail": "Send a test email",
        "setting.model": "Configuration",
        "setting.showEnv": "See environment variables",
        "setting.validation_process.amounts.title": "Use of amounts",
        "setting.validation_process.criteria_order.title": "Order of criteria for generating circuits",
        "setting.validation_process.steps_by_criterion.title": "Number of ranks per criterion to keep at the end of the circuit",
        "setting.validation_process.steps_global.title": "Overall number of ranks to keep at the end of the circuit",
        "siret.novalidate": "The Siret number is not valid",
        "statistic.model": "Statistic",
        "statistic.model.plural": "Statistics",
        "stock.action.readjust_from_computed": "Readjust from Computed ({value})",
        "stock.actual.abbreviation": "Actual qty.",
        "stock.adjustment": "Stock adjustment",
        "stock.adjustment_from_import": "Adjustment from import",
        "stock.available": "Available stock",
        "stock.error.article_required": "The article is required",
        "stock.error.exceeded": "Stock exceeded",
        "stock.error.only_internal_article_allowed": "Only internal articles are allowed in a stock exit.",
        "stock.export.filename": "stock-{code}",
        "stock.info.in_warehouse_but_disabled": "{model} already present in Warehouse but currently disabled.",
        "stock.min_quantity_alert.field": "Only {model} below the min threshold.",
        "stock.min_quantity_alert.short_label": "Min. threshold",
        "stock.missing_quantity": "Missing quantity",
        "stock.model": "Stored {model}",
        "stock.model.plural": "Stored {model}",
        "stock.movements": "Stock movements",
        "stock.type.in": "Stock entry",
        "stock.type.in.abbreviation": "Entry",
        "stock.type.movement": "Inter-stock movement",
        "stock.type.movement.abbreviation": "Mvmnt",
        "stock.type.no": "Non-stock purchases",
        "stock.type.out": "Stock exit",
        "stock.type.out.abbreviation": "Exit",
        "supplier.action.reset_approval_file_types": "Adjust required files",
        "supplier.action.unlock": "Unlock supplier",
        "supplier.banner_action.missing_files": "There is a missing or valid file to validated this Supplier|There are {nb} files missing or to validate in order to validated this Supplier",
        "supplier.banner_action.missing_geographic_sector": "You need at least one Geographic sector to validate this Supplier.",
        "supplier.banner_action.missing_product_family": "You need at least one Family to validate this Supplier.",
        "supplier.banner_action.product_family.awaiting_validation": "{product_family} - pending validation of :<ul>{validators}</ul>",
        "supplier.banner_action.product_family.disabled_approval": "Approval {approval} is not active, impossible to activate the {product_family}",
        "supplier.banner_action.product_family.missing_files": "There is a missing or valid file to activate the family {product_family} | There are {nb} files missing or to validate in order to activate the family {product_family}",
        "supplier.banner_action.product_family.missing_validators": "There is a missing validator to activate the family {product_family} | There are {nb} validators missing in order to activate the family {product_family}",
        "supplier.banner_action.product_family.start_validation": "The validation of the family {product_family} can be started",
        "supplier.banner_action.start_validation": "The validation of the Supplier can be started.",
        "supplier.banner_action.validate": "The Supplier can be approved.",
        "supplier.chart.12_rolling_months": "Over 12 rolling months",
        "supplier.chart.accounting_entries_details": "Breakdown by Sub-Family over 12 rolling months",
        "supplier.chart.additional_mark": "Complementary rating",
        "supplier.chart.additional_rating.title": "Complementary rating",
        "supplier.chart.annual_rating.title": "Annual rating",
        "supplier.chart.average_basket": "Average basket",
        "supplier.chart.certification.title": "Certifications",
        "supplier.chart.completed_document": "Completed document",
        "supplier.chart.current_year": "Current year (Y)",
        "supplier.chart.deliveries_amount": "Deliveries amount",
        "supplier.chart.dependency_rate": "Dependency rate",
        "supplier.chart.distributionPurchases": "Distribution of purchases over 12 months",
        "supplier.chart.expiration_date": "Expiration date",
        "supplier.chart.frequency_rate1": "Frequency rate 1",
        "supplier.chart.frequency_rate1.help": "Frequency rate 1 (TF1) = (number of accidents with lost time / number of hours worked) x 1,000,000",
        "supplier.chart.frequency_rate1.short": "TF1",
        "supplier.chart.frequency_rate2": "Frequency rate 2",
        "supplier.chart.frequency_rate2.help": "Frequency rate 2 (TF2) = (number of accidents with and without lost time / number of hours worked) x 1,000,000",
        "supplier.chart.frequency_rate2.short": "TF2",
        "supplier.chart.global_mark": "Global mark",
        "supplier.chart.hse": "Health, Safety, Environment",
        "supplier.chart.hse.short": "HSE",
        "supplier.chart.objective_current_year": "Objective Y",
        "supplier.chart.objective_previous_year": "Objective Y-1",
        "supplier.chart.oqd": "On Quality Delivery",
        "supplier.chart.oqd.short": "OQD",
        "supplier.chart.orders_amount": "Order amount",
        "supplier.chart.orders_count": "Number of orders",
        "supplier.chart.otd": "On Time Delivery",
        "supplier.chart.otd.short": "OTD",
        "supplier.chart.performance": "Performance indicators",
        "supplier.chart.previous_year": "Previous year (Y-1)",
        "supplier.chart.result_current_year": "Result Y",
        "supplier.chart.result_previous_year": "Result Y-1",
        "supplier.chart.security.title": "Security",
        "supplier.chart.severity_rate": "Severity rate",
        "supplier.chart.severity_rate.help": "Severity rate (TG) = (number of days lost / number of hours worked) x 1,000",
        "supplier.chart.severity_rate.short": "TG",
        "supplier.chart.totalOrdersAmounts": "Total orders in euros over 24 months",
        "supplier.chart.turnover": "Turnover",
        "supplier.chart.turnover.title": "Purchasing Turnover",
        "supplier.chart.turnover_and_dependency": "Turnover and dependency ratio",
        "supplier.chart.variation": "Var Y vs Y-1",
        "supplier.evaluation.invitation.intro": "You have been invited to complete an evaluation for Supplier {supplier}.",
        "supplier.evaluation.invitation.subject": "Supplier Evaluation",
        "supplier.field.accounting_code": "Accounting code",
        "supplier.field.account_type": "Account Type",
        "supplier.field.account_type.model": "account type",
        "supplier.field.activity_details": "Activity Details",
        "supplier.field.client_number": "Client number",
        "supplier.field.code": "Supplier code",
        "supplier.field.code.model": "supplier code",
        "supplier.field.company_name": "Company Name",
        "supplier.field.contractual_status": "Contractual status",
        "supplier.field.contractual_status.model": "contractual status",
        "supplier.field.country.model": "country code",
        "supplier.field.duns": "DUNS / ID Code",
        "supplier.field.evaluation_mark": "Evaluation mark",
        "supplier.field.geographicSectors.help": "The choice of sector may condition the obligation to enter the following fields",
        "supplier.field.geographicSectors.model": "geographic sectors codes",
        "supplier.field.geographicSectors.otherSectors": "and {number} other sector (s)",
        "supplier.field.give_access": "Give access",
        "supplier.field.group": "Group",
        "supplier.field.group.model": "group code",
        "supplier.field.intra_community_vat": "Tva-intra.",
        "supplier.field.intra_community_vat.error.regex": "Intra-Community VAT number is not valid",
        "supplier.field.label.error.required_if": "The label is mandatory if there is no document type",
        "supplier.field.main_product_family": "Main buying family",
        "supplier.field.manager": "Account Manager",
        "supplier.field.manager.model": "user login",
        "supplier.field.manage_shipping_date": "Manage shipping date in the {model}",
        "supplier.field.manage_stock": "Stock manager",
        "supplier.field.paymentTerm.model": "payment term code",
        "supplier.field.paymentType.model": "payment type code",
        "supplier.field.po_informations": "Purchase Order infos",
        "supplier.field.productFamilies": "Families",
        "supplier.field.productFamilies.model": "families code",
        "supplier.field.punchout_type": "PunchOut type",
        "supplier.field.punchout_url": "PunchOut URL",
        "supplier.field.qualification": "Qualification",
        "supplier.field.qualification.model": "name of a qualification",
        "supplier.field.remove_access": "Remove access",
        "supplier.field.surveillanceMinAmount": "Minimum amount",
        "supplier.field.tags.model": "tags",
        "supplier.field.underSurveillance": "Under surveillance",
        "supplier.field.website": "Website",
        "supplier.headInfo.activeUntil": "active until",
        "supplier.import": "Import from suppliers :",
        "supplier.import.action.downloadBankAccountHeaders": "Download bank account headers",
        "supplier.import.action.downloadContactsHeaders": "Download contacts headers",
        "supplier.import.action.downloadSupplierHeaders": "Download suppliers headers",
        "supplier.import.bankAccount": "Importing suppliers bank details :",
        "supplier.import.contacts": "Importing suppliers contacts :",
        "supplier.import.field.bank_account_file": "Supplier Bank details import file",
        "supplier.import.field.contact_file": "Supplier contacts import file",
        "supplier.import.field.file.help": "The name of each column must match the character by the name of a column in the selected table.<br>All columns are not required.",
        "supplier.import.field.group": "Group code",
        "supplier.import.field.supplier_file": "Suppliers import file",
        "supplier.import.field.update": "Update suppliers whose codes already exist",
        "supplier.info.difference_between_approval_file_types": "There is a difference between the requested files and the Approval files. Adjusting them can deactivate families for which a file will be missing, and therefore can deactivate the Supplier itself.",
        "supplier.info.has_articles_to_disable": "The supplier has items, these will be deactivated.",
        "supplier.model": "Supplier",
        "supplier.model.plural": "Suppliers",
        "supplier.objectives.title": "Suppliers objectives",
        "supplier.title.contactInformations": "Contact informations",
        "supplier.title.dashboard": "Monitoring and Surveillance",
        "supplier.title.files": "Documents",
        "supplier.title.finance": "Finance",
        "supplier.title.forced_status": "Forced status",
        "supplier.title.identity": "Identity",
        "supplier.title.purchaseInformations": "Purchase informations",
        "supplier.title.surveillance": "Surveillance",
        "supplier.title.validators": "Validators",
        "supplier.use_punchout": "Uses a PunchOut catalog",
        "suppliercontacts.import.requiredOne": "At least one of the fields {field_email}, {field_phone} or {field_mobile} is required.",
        "suppliercontacts.import.requiredOne.error": "Line {line} : At least one of the fields {field_email}, {field_phone} or {field_mobile} is required, the data could not be added.",
        "supplierFile.field.category": "Category of document",
        "supplierFile.field.documentDate": "Document Date",
        "supplierFile.field.file": "Document",
        "supplierFile.field.label.help": "The label fills automatically from the document type",
        "supplierFile.field.sentBy": "Sent by",
        "supplierFile.field.type": "Document type",
        "supplierFile.field.type.value.none": "No type",
        "supplierFile.field.validityDate": "Validity date",
        "supplierGroup.field.label": "Group name",
        "supplierInvoice.accountingVatEntryType.debit_collection_by_month": "Per month: Debit => according to invoice date / Collection => according to due date (ex: CEB)",
        "supplierInvoice.paymentstatus.info.forced_paid": "Status forced to paid.",
        "supplierInvoice.paymentstatus.paid": "Paid",
        "supplier_account_type.model": "Supplier account type",
        "supplier_account_type.model.plural": "Supplier account types",
        "supplier_approval.field.roles": "Roles that must validate",
        "supplier_approval.model": "Supplier Approval",
        "supplier_approval.model.plural": "Supplier Approvals",
        "supplier_chart_accounting_entry_turnover.export.filename": "accounting_entry_turnover",
        "supplier_chart_annual_rating.export.filename": "annual_rating",
        "supplier_chart_certifications.export.filename": "certifications",
        "supplier_chart_performance.export.filename": "performance",
        "supplier_chart_security.export.filename": "security",
        "supplier_chart_turnover.export.filename": "turnover",
        "supplier_contact.model": "Supplier Contact",
        "supplier_contact.model.plural": "Supplier Contacts",
        "supplier_contacts.export.filename": "contacts",
        "supplier_contact_type.model": "Supplier contact type",
        "supplier_contact_type.model.plural": "Supplier contact types",
        "supplier_contract.field.code": "No. of {model}",
        "supplier_contract.field.label": "Subject of {model}",
        "supplier_contract.field.process_code": "Procedure No.",
        "supplier_contract.model": "Supplier Contract",
        "supplier_contract.model.plural": "Supplier Contracts",
        "supplier_contractual_status.model": "Contractual status",
        "supplier_contractual_status.model.plural": "Contractual statuses",
        "supplier_evaluation.model": "Evaluation",
        "supplier_evaluation.model.plural": "Evaluations",
        "supplier_evaluation_criterion.model": "Evaluation criterion",
        "supplier_evaluation_criterion.model.plural": "Evaluation criteria",
        "supplier_file.model": "Supplier File",
        "supplier_file.model.plural": "Supplier Files",
        "supplier_file_": "-",
        "supplier_file_approval.model": "Approval document",
        "supplier_file_approval.model.plural": "Approval documents",
        "supplier_file_category.field.validator_role": "Role of the validator",
        "supplier_file_category.model": "Supplier Document Category",
        "supplier_file_category.model.plural": "Supplier Document Categories",
        "supplier_file_regular.model": "Collaboration document",
        "supplier_file_regular.model.plural": "Collaboration documents",
        "supplier_file_type.field.file_category": "Document Category",
        "supplier_file_type.model": "Type of supplier documents",
        "supplier_file_type.model.plural": "Types of supplier documents",
        "supplier_group.model": "Supplier Group",
        "supplier_group.model.plural": "Supplier Groups",
        "supplier_invoice.action.notify_when_no_delivery": "Request a reception",
        "supplier_invoice.action.show_file": "View Invoice",
        "supplier_invoice.action.submit_invoice": "Submit an Invoice",
        "supplier_invoice.action.submit_your_invoice": "Submit your Invoice",
        "supplier_invoice.back_to_draft.existing_accounting_exports": "Also, the {model_invoice} has already been exported to account, this export will not be modified.",
        "supplier_invoice.back_to_draft.existing_closed_purchase_orders": "Also, the {model_po} have been closed. Please check the following box to confirm the cancellation of the closure:",
        "supplier_invoice.close_purchase_orders": "Can some items be closed?",
        "supplier_invoice.close_purchase_orders.info1": "If you are not waiting for another {model_invoice}, you can close the {model_po}.",
        "supplier_invoice.close_purchase_orders.info2": "Once the {model_po} is closed, only the invoiced amount is included into budget analysis.",
        "supplier_invoice.error.amount_constrained_to_delivery": "The remainder to be billed on this PO line must be less than or equal to the amount received but not billed",
        "supplier_invoice.error.amount_constrained_to_purchase_order": "The remainder to be billed on this PO line must be less than or equal to the unbilled amount",
        "supplier_invoice.error.delivered_amount_zero_line": "You must first perform a delivery before you can invoice this line",
        "supplier_invoice.error.delivered_amount_zero_total": "You must first perform a delivery before you can invoice this {model}",
        "supplier_invoice.error.different_currency": "The currencies of the different bills are not the same",
        "supplier_invoice.error.different_from_invoice": "{attribute} must be equal to {attribute} of the invoice ({amount})",
        "supplier_invoice.error.total_amount_constrained_to_delivery": "The sub-total excl. taxes must be less than or equal to the amount received but not billed",
        "supplier_invoice.error.total_amount_constrained_to_purchase_order": "The sub-total excl. taxes must be less than or equal to the unbilled amount",
        "supplier_invoice.export.filename": "invoices",
        "supplier_invoice.field.label": "Invoice number",
        "supplier_invoice.field.outside_EU": "Outside the EU",
        "supplier_invoice.info.no_purchase_order_to_notify_when_no_delivery": "No user is to be notified.",
        "supplier_invoice.info.users_to_notify": "The following users will be notified:",
        "supplier_invoice.limit.delivered": "Amount received",
        "supplier_invoice.limit.ordered": "Amount ordered",
        "supplier_invoice.modal.multiple_currency.body": "On the same {invoice_model}, you can not select {po_model} with different currencies",
        "supplier_invoice.modal.multiple_currency.title": "Attention to multiple currencies",
        "supplier_invoice.model": "Supplier Invoice",
        "supplier_invoice.model.plural": "Supplier Invoices",
        "supplier_invoice.ocr_confidence.calculated": "Calculated",
        "supplier_invoice.ocr_confidence.manuel_input": "Manually entered",
        "supplier_invoice.pdf.title": "Billing voucher",
        "supplier_observation.model": "Supplier Observation",
        "supplier_observation.model.plural": "Supplier Observations",
        "supplier_qualification.model": "Supplier qualification",
        "supplier_qualification.model.plural": "Supplier qualifications",
        "supplier_statistic.model": "Supplier statistic",
        "supplier_statistic.model.plural": "Supplier statistics",
        "table.field.column": "Column",
        "table.field.columns": "Columns",
        "table.field.line": "Line",
        "tag.error.invalid": "At least one {model} is invalid",
        "tag.level.disabled": "Disabled",
        "tag.level.highlighted": "Highlighted",
        "tag.level.standard": "Standard",
        "tag.level_for_model": "Level for \"{model}\" ",
        "tag.model": "Tag",
        "tag.model.plural": "Tags",
        "tenant.model": "Tenant",
        "tenant.model.plural": "Tenants",
        "tenant.new.mail.error.subject": "New instance: error",
        "trace.action.acknowledged_receipt": "Acknowledgement of receipt",
        "trace.action.activated": "Activation",
        "trace.action.added": "Adding",
        "trace.action.attachment_added": "Attachment added",
        "trace.action.attachment_deleted": "Attachment deleted",
        "trace.action.autogenerated": "Automatic generation",
        "trace.action.awaiting": "Set the processing status to \"Awaiting\" ",
        "trace.action.backup_done": "Backup done",
        "trace.action.backup_failed": "Backup failed",
        "trace.action.back_to_awaiting": "Return to processing status \"Awaiting\" ",
        "trace.action.back_to_draft": "Return to status \"Draft\" ",
        "trace.action.back_to_none": "Deletion of processing status",
        "trace.action.back_to_processing": "Return to processing status \"Processing\" ",
        "trace.action.canceled": "Set the status to \"Canceled\" ",
        "trace.action.cancel_and_replace": "Cancel and replace",
        "trace.action.closed": "Set the status to \"Closed\" ",
        "trace.action.considered_sent": "Set the status to \"Sent\" ",
        "trace.action.created": "Creation",
        "trace.action.deleted": "Deletion",
        "trace.action.delivery_forced_complete": "Forced delivery",
        "trace.action.desactivated": "Desactivation",
        "trace.action.disabled": "Disabling",
        "trace.action.duplicated_from": "Duplicated from",
        "trace.action.enabled": "Activation",
        "trace.action.execute_sql_query": "Execute SQL query",
        "trace.action.export": "Data export",
        "trace.action.export_downloaded": "Export downloaded",
        "trace.action.export_generated": "Export generated",
        "trace.action.export_sepa": "SEPA export",
        "trace.action.export_sepa_downloaded": "SEPA export downloaded",
        "trace.action.export_sepa_generated": "SEPA export generated",
        "trace.action.file_created": "File created",
        "trace.action.file_deleted": "File deleted",
        "trace.action.file_updated": "File updated",
        "trace.action.forced_status_removed": "Forced status removed",
        "trace.action.imported": "Data import",
        "trace.action.manually_changed_to_validated_PO": "Set the processing status manually to \"PO validated\" ",
        "trace.action.merged": "Merged",
        "trace.action.new_comment": "New comment",
        "trace.action.new_version": "New version created",
        "trace.action.overriden": "Override",
        "trace.action.paid": "Set the status to \"Paid\" ",
        "trace.action.password_changed": "Password changed",
        "trace.action.password_generated": "Password generated",
        "trace.action.password_reset": "Password reset",
        "trace.action.permissions_updated": "Updated permissions",
        "trace.action.processing": "Set the processing status to \"Processing\" ",
        "trace.action.refused": "Validation refused",
        "trace.action.removed": "Removed",
        "trace.action.replaced": "Replacement",
        "trace.action.re_enabled": "Reactivation",
        "trace.action.sent": "Sending by email",
        "trace.action.sent_auto": "Automatic sending by email",
        "trace.action.skipped": "Validation skipped",
        "trace.action.sorted": "Change the order",
        "trace.action.start_reconcile": "Reconciliation",
        "trace.action.submitted_po": "Set the processing status to \"PO submitted\" ",
        "trace.action.suspended": "Suspension",
        "trace.action.taken_charge": "Take in charge",
        "trace.action.transferred": "Transferred",
        "trace.action.transformed_into_PO": "Transform into PO",
        "trace.action.transformed_into_purchase_order": "Transform into PO",
        "trace.action.unclosed": "Cancellation of closure",
        "trace.action.unsuspended": "Cancellation of suspension",
        "trace.action.unvalidated": "Cancellation of validation",
        "trace.action.updated": "Update",
        "trace.action.updated_BankDetails": "Change bank information",
        "trace.action.updated_buyers": "Change of Buyers",
        "trace.action.updated_ContactInformations": "Change of contact details",
        "trace.action.updated_Families": "Change of purchasing families",
        "trace.action.updated_Finance": "Change of financial information",
        "trace.action.updated_ForcedStatus": "Change of forced status",
        "trace.action.updated_from_central": "Update from Central",
        "trace.action.updated_GeographicSectors": "Change of geographic areas",
        "trace.action.updated_Identity": "Change of identity information",
        "trace.action.updated_PurchaseInformations": "Change of purchasing information",
        "trace.action.updated_Surveillance": "Change of supervision",
        "trace.action.updated_Tags": "Change of tags",
        "trace.action.updated_Validators": "Change of the account manager",
        "trace.action.update_after_validation": "Change after validation",
        "trace.action.validated": "Validation accepted",
        "trace.action.validated_po": "Set the processing status to \"PO validated\" ",
        "trace.action.validation_launched": "Launch of validation",
        "trace.error.not_sent": "Email not sent",
        "trace.field.action": "Action",
        "trace.field.message": "Message",
        "trace.field.model": "Entity",
        "trace.field.model_id": "ID of the entity",
        "trace.field.severity": "Criticality",
        "trace.model": "Trace",
        "trace.model.gender": "0",
        "trace.model.plural": "Traces",
        "trace.severity.danger": "Danger",
        "trace.severity.debug": "Debug",
        "trace.severity.error": "Error",
        "trace.severity.info": "Info",
        "trace.severity.warning": "Warning",
        "trace.show": "Details of a trace",
        "transportMean.bike": "Two wheels <50 cm3",
        "transportMean.car": "Car",
        "transportMean.motorbike": "Two wheels >50 cm3",
        "unit.model": "Unit",
        "unit.model.plural": "Units",
        "unit.must_correspond": "The Unit must correspond to an existing choice",
        "user.account_disabled": "User account disabled",
        "user.actions_to_do": "Actions to do",
        "user.error.must_contain": "The field {attribute} must contain at least one of the following characters: {requirements}",
        "user.error.new_password_must_be_different": "The field {attribute} cannot be identical to your current password",
        "user.error.wrong_current_password": "The field {attribute} does not correspond to your current password",
        "user.field.activated": "Active user",
        "user.field.can_stock_in": "Can restock Warehouses",
        "user.field.can_stock_out": "Can order from Warehouses",
        "user.field.code": "Registration number",
        "user.field.confirm_password": "Confirm password",
        "user.field.current_password": "Current password",
        "user.field.model.read": "{model} authorized for consultation",
        "user.field.model.write": "{model} authorized for creation",
        "user.field.new_password": "New password",
        "user.field.password_confirmation": "Confirm the password",
        "user.field.recipient": "Included in the list of {model}",
        "user.field.remember_me": "Remember me",
        "user.import.job.example": "Accounting",
        "user.info.cantBeDeleted": "{user} cannot be deleted but can be disabled.",
        "user.info.new_password_end_date": "Your new password will be usable until {date}",
        "user.info.password_end_date": "Your current password can be used until {date}",
        "user.info.password_end_date_expired": "Your current password has expired on {date}",
        "user.model": "User",
        "user.model.plural": "Users",
        "user.preferences": "Preferences",
        "user.profile": "Profile",
        "user.sso.module": "SSO (External Single Sign-On)",
        "userVehicle.model": "User's vehicle",
        "user_assignment.model": "User assignment",
        "user_assignment.model.plural": "User assignments",
        "user_group.model": "User Group",
        "user_group.model.plural": "User Groups",
        "validation.action.add_manually": "Add a validator",
        "validation.action.skip": "Skip the validator",
        "validation.after_or_equal": "The {attribute} must be after or equal to {date}.",
        "validation.all": "All validations",
        "validation.before_or_equal": "The {attribute} must be before or equal to {date}.",
        "validation.between.numeric.short": "The value must be between {min} and {max}.",
        "validation.budget_key_not_valid": "Invalid budget key",
        "validation.confirm.added": "Validation added",
        "validation.confirm.bulk_validated": "Mass validated items: {items}",
        "validation.confirm.no_bulk_validated": "No items have been mass validated",
        "validation.confirm.refused": "The refusal has been done.",
        "validation.confirm.validated": "The validation has been done.",
        "validation.date": "The field {attribute} is not a valid date.",
        "validation.date_format": "The field {attribute} does not correspond to the format {format}.",
        "validation.different_from": "The {attribute} must be different from {value}.",
        "validation.dimensions.short": "The image has invalid dimensions.",
        "validation.emails": "Check the email address format of each line",
        "validation.error.bulk_validated": "Items that cannot be mass validated: {items}",
        "validation.error.cant_validate": "Unable to validate",
        "validation.error.please_validate_manually": "Please validate manually",
        "validation.field.must_be": "The field {attribute} must be \"{value}\" ",
        "validation.field.unauthorized_characters": "The field {attribute} contains unauthorized characters",
        "validation.gt.numeric": "The {attribute} must be greater than {value}.",
        "validation.integer": "The {attribute} must be an integer.",
        "validation.lt.numeric": "The {attribute} must be less than {value}.",
        "validation.max.string": "The {attribute} format is invalid.",
        "validation.model": "Validation",
        "validation.model.gender": "0",
        "validation.model.plural": "Validations",
        "validation.must_be_multiple": "The quantity must be a multiple of {step}",
        "validation.not_link_to": "{item1} is not linked to {item2}",
        "validation.not_regex": "The {attribute} may not be greater than {max} characters.",
        "validation.not_specified": "{attribute} not specified",
        "validation.not_valid": "not valid",
        "validation.orphan": "Inconsistency between : {child} and {parent}",
        "validation.phone": "The {attribute} field must be a valid number.",
        "validation.prohibited_unless.other_is_null": "The {attribute} field should not be populated if {other} is populated.",
        "validation.refuse": "Refusal of validation",
        "validation.refuse.taken": "Refusal of validation awareness",
        "validation.refuse.takenThank": "Your refusal has been taken into consideration.",
        "validation.required": "The {attribute} field is required.",
        "validation.required.short": "Required field",
        "validation.required.valid_iban": "A valid IBAN is required",
        "validation.required_one": "At least one of these fields is required: {attributes}",
        "validation.required_without": "The {attribute} field is required when {values} is not filled",
        "validation.rights": "You do not have sufficient rights to select this item",
        "validation.unique": "The {attribute} has already been taken.",
        "validation.validate.taken": "Validation awareness",
        "validation.validate.takenThank": "Thank you for your validation",
        "validation.without_spaces": "This field must not contain spaces",
        "validation.wrong": "The {attribute} field is not correct",
        "validationCriterion.field.model": "Linked Model",
        "validationProcess.infos.supplier": "Only if the complexity level of supplier activation is \"simple\" ",
        "validationProcess.testIndex": "Validation circuit test",
        "validation_default_chart.copy": "Copy validators",
        "validation_default_chart.create": "Add a validator",
        "validation_default_chart.error.criteria_not_exists": "This criterion is not available",
        "validation_default_chart.field.criterion.error.not_available": "This {attribute} is not/no longer available",
        "validation_default_chart.field.standard": "Standard circuit",
        "validation_default_chart.field.weight": "Rank",
        "validation_default_chart.info.simplified": "Simplified (will not appear in the final circuit)",
        "validation_default_chart.info.weight": "Position relative to other validators of the same criterion",
        "validation_default_chart.model": "Validator",
        "validation_default_chart.model.plural": "Validators",
        "validation_default_chart.remove": "Remove the validator",
        "validation_default_chart.replace": "Replace a validator",
        "validation_default_chart.replace.info": "Replace a validator throughout the \"{model}\" validation circuit",
        "validation_default_chart.update": "Modification of the validator",
        "validation_process.add_overrun_model_process": "If {model} is exceeded: add the {models} validation circuit",
        "validation_process.auto_add": "Automatic addition at the start of the circuit: {value}",
        "validation_process.field.amount_overrun": "If the {model} is exceeded",
        "validation_process.field.budget_overrun": "In case of budget overrun",
        "validation_process.info.not_standard": "Not present in the Standard circuit",
        "validation_process.model": "Validation circuit",
        "validation_process.model.AUTO_ADD_RECIPIENT": "{recipient} of {model}",
        "validation_process.model.plural": "Validation circuits",
        "validation_process.purchase_order.AUTO_ADD_NEEDS_RECIPIENTS": "{recipients} of {need_model}",
        "validation_process.supplier_invoice.AUTO_ADD_PO_RECIPIENTS": "{recipients} of {po_models} of {invoice_model}",
        "validation_process.supplier_invoice.REMOVE_WHEN_SAME_AMOUNT_AS_PO": "No circuit if the amount = amount of {po_models}",
        "variant.label.' + option.label + ": "-",
        "variant.label.danger": "Danger",
        "variant.label.dark": "Dark",
        "variant.label.info": "Info",
        "variant.label.light": "Light",
        "variant.label.primary": "Primary",
        "variant.label.secondary": "Secondary",
        "variant.label.success": "Success",
        "variant.label.warning": "Warning",
        "vat_type.model": "VAT type",
        "vat_type.model.gender": "0",
        "vat_type.model.plural": "VAT types",
        "verify.email.checkLink": "Before proceeding, please check your email for a verification link.",
        "verify.email.confirmation": "A fresh verification link has been sent to your email address.",
        "verify.email.notReceived": "If you did not receive the email",
        "verify.email.requestAnother": "click here to request another",
        "verify.index": "Check your email address",
        "warehouse.action.add_to": "Add to Warehouse",
        "warehouse.confirmation_request.update_allocations": "Modifying the allocations will create a {model} to rebalance the budget analyses.",
        "warehouse.field.storekeeper": "Storekeeper",
        "warehouse.field.storekeeper.plural": "Storekeepers",
        "warehouse.model": "Stock warehouse",
        "warehouse.model.plural": "Stock warehouses",
        "warehouse.other": "Other warehouses",
        "wysiwyg.info.insert_text_here": "Insert text here...",
        "auth": {
            "failed": "Invalid username and/or password",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "prohibited_if": "The {attribute} field is prohibited when the {other} field is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless the {other} field is in {values}.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "name",
                "username": "username",
                "email": "email",
                "first_name": "firstname",
                "last_name": "lastname",
                "password": "password",
                "password_confirmation": "passwoed confirmation ",
                "city": "city",
                "country": "country",
                "address": "address",
                "phone": "phone",
                "mobile": "mobile",
                "age": "age",
                "sex": "sex",
                "gender": "gender",
                "day": "day",
                "month": "month",
                "year": "year",
                "hour": "hour",
                "minute": "minute",
                "second": "second",
                "title": "title",
                "content": "content",
                "description": "description",
                "excerpt": "excerpt",
                "date": "date",
                "time": "time",
                "available": "available",
                "size": "size"
            }
        },
        "vueJS": {
            "april": "April",
            "august": "August",
            "choice_geographic_areas": "Choice of geographic areas",
            "company": "Company",
            "december": "December",
            "distribution_purchases": "Distribution of purchases over 12 months",
            "europe": "Europe",
            "february": "February",
            "france": "France",
            "geographicSector": "Geographic Sector",
            "january": "January",
            "july": "July",
            "june": "June",
            "march": "March",
            "may": "May",
            "november": "November",
            "october": "October",
            "other_countries": "other countries",
            "purchasing_family": "Purchasing family",
            "purchasing_subfamily": "Purchasing subfamily",
            "sector": "Sector",
            "selected_subfamilies": "Selected subfamilies",
            "september": "September",
            "total_orders": "Total orders in euros over 24 months",
            "world": "World",
            "cantDeselectLabel": "Can not remove this value",
            "deselectLabel": "Remove",
            "doSearch": "Start typing to search",
            "noOptions": "The list is empty.",
            "noRemove": "Can't remove this value.",
            "noResult": "No elements found.",
            "placeholder": "Select an option",
            "search": "Search",
            "searchOrAddTag": "Search or add a tag",
            "selectedLabel": "Selected",
            "selectLabel": "Press Enter to select",
            "selectOrSearch": "Select or search",
            "status": "Status",
            "tag-placeholder": "Add this as a new tag",
            "typeToSearch": "Type to search"
        }
    },
    "es": {
        "accounting_export.download.filename": "exportacion-contable-",
        "accounting_export.error.currently_ongoing": "{1}Ya se está exportando un {model}. Valide antes de volver a intentarlo.|{2}Ya se está exportando una {model}. Por favor, valide antes de volver a intentarla.",
        "accounting_export.error.different_company": "{1}El {model} es diferente a los demás {invoice_model}|{2}La {model} es diferente a los demás {invoice_model}",
        "accounting_export.error.invoice_already_exported": "{1}Ya se ha exportado al menos un {model}.|{2}Ya se ha exportado al menos una {model}.",
        "accounting_export.error.invoice_not_awaiting_nor_validated": "{1}El {model} no está Pendiente ni Validado|{2}La {model} no está Pendiente ni Validada",
        "accounting_export.error.invoice_not_validated": "{1}El {model} no está Validado|{2}La {model} no está Validada",
        "accounting_export.error.invoice_status_not_awaiting_nor_validated": "{1}Al menos un {model} no está Pendiente ni Validado.|{2}Al menos una {model} no está Pendiente ni Validada.",
        "accounting_export.error.invoice_status_not_validated": "{1}Al menos un {model} no está Validado.|{2}Al menos una {model} no está Validada.",
        "accounting_export.error.missing_accounting_entry": "{1}El {invoice_model} tiene al menos una línea sin {model}|{2}La {invoice_model} tiene al menos una línea sin {model}",
        "accounting_export.error.unbalanced_invoice": "{1}{model} desequilibrado|{2}{model} desequilibrado",
        "accounting_export.field.account_number": "Código de cuenta",
        "accounting_export.field.analitycs": "Eje",
        "accounting_export.field.invoices.error.different_company": "{1}Los {company_model} de {invoice_model} debe ser los mismos.|{2}Las {company_model} de {invoice_model} deben ser las mismas",
        "accounting_export.field.third_parties": "Cuenta de terceros",
        "accounting_export.model": "Exportación Contable",
        "accounting_export.model.gender": "2",
        "accounting_export.model.plural": "Exportaciones Contables",
        "accounting_export.pdf.title": "Detalles de la Exportación Contable",
        "address.field.address1": "Dirección",
        "address.field.address2": "Complemento de dirección 1",
        "address.field.address3": "Complemento de dirección 2",
        "address.field.company": "Empresa",
        "address.field.recipient": "Recipiente",
        "address.field.zip_code": "Código postal",
        "add_models_massively.error.ambiguous_value_for": "{reference} es ambigua para {for}, agréguelo manualmente",
        "add_models_massively.error.does_not_exists_for": "{reference} no existe para {for}",
        "add_models_massively.error.is_disabled_for": "{reference} no está activo para {for}",
        "admin.dashboard": "Admin",
        "allocations.import.unique.code": "Línea {line} : el código {code} de {allocation} ya está en uso con una etiqueta que no sea \"{etiqueta}\", no se pudieron agregar los datos de la línea.",
        "api.purchase_order.error.back_to_draft.already_billed": "{model} ya facturado/a, incluso parcialmente",
        "api.purchase_order.error.back_to_draft.already_delivered": "{model} ya recibido/a, incluso parcialmente",
        "api.purchase_order.error.back_to_draft.already_paid": "{model} ya pagado/a, incluso parcialmente",
        "api.purchase_order.error.back_to_draft.already_sent_at": "{model} ya enviado al proveedor, puede forzar el re-borrador con el parámetro \"confirm_when_sent\" establecido en \"true\" ",
        "api_log.model": "Registro de API entrante",
        "api_log.model.plural": "Registros de API entrante",
        "article.catalog.internal": "Catálogo interno",
        "article.catalog.punchout": "Catálogo Punch Out",
        "article.catalog.stock": "Catálogo stock",
        "article.catalog.supplier": "Catálogo de proveedor",
        "article.catalog.supplier_group": "Catálogo de grupo de proveedores",
        "article.error.change_type": "No se puede cambiar el tipo de un artículo ya usado",
        "article.error.does_not_exist": "{1}{model} no existente. Puedes crearlo en su lugar.|{2}{model} no existente. Puedes crearla en su lugar.",
        "article.error.does_not_exist_in_warehouse": "{1}{model} no existente en Almacén. Puedes crearlo en su lugar.|{2}{model} no existente en Almacén. Puedes crearla en su lugar.",
        "article.error.unbindReferences": "Debe eliminar los artículos afiliados a este artículo antes de cambiar su estado interno.",
        "article.error.unbindStock": "Debe vaciar el inventario de este artículo antes de cambiar su estado interno.",
        "article.field.affiliate": "articulos afiliados",
        "article.field.link": "Enlace a la hoja de producto del proveedor",
        "article.field.ordered_quantity_current_year": "Cantidad pedida los últimos 12 meses",
        "article.field.ordered_quantity_current_year.abbr": "Ctd. pedida los últimos 12 meses",
        "article.field.ordered_quantity_last_year": "Cantidad pedida en los 12 meses anteriores",
        "article.field.ordered_quantity_last_year.abbr": "Ctd. pedida en los 12 meses anteriores",
        "article.field.search.favorite": "Solo {model} favoritos",
        "article.field.search.labeled": "Solo {model} \"{label}\" ",
        "article.field.type": "Tipo de Artículo",
        "article.internal.model": "{1}{model} interno|{2}{model} interna",
        "article.internal.model.plural": "{1}{model} internos|{2}{model} internas",
        "article.rapid_search": "Búsqueda rápida de artículos",
        "article.title.packing": "Acondicionamiento",
        "article.type.group": "Grupo",
        "article.type.internal": "Interno",
        "article.type.supplier": "Proveedor",
        "article.unit_price.info": "Eso es {price} / {unit}",
        "auth.login.index": "Conexión",
        "auth.login.sso": "Conexión SSO",
        "auth.login.standard": "Conexión estándar",
        "auth.login.with": "Conexión con {idp}",
        "auth.login_request.confirmed": "Se le ha enviado un correo electrónico que contiene un enlace de inicio de sesión.",
        "auth.login_request.enter_your_email": "Ingrese su dirección de correo electrónico para solicitar una conexión",
        "auth.login_request.supplier.title": "Solicitud de conexión al Portal de Proveedores",
        "auth.register.index": "Registro",
        "bank_account.account_number_type.iban": "IBAN",
        "bank_account.account_number_type.other": "Otro",
        "bank_account.export.filename": "cuentas-bancarias",
        "bank_account.field.account_number_type": "Tipo de número",
        "bank_account.field.country_code": "Código de país",
        "bank_account.field.country_code.help": "Abreviatura de dos letras del país, según ISO-3166 para la dirección descrita",
        "bank_account.field.file": "Detalles del banco",
        "bank_account.holder_type.corporate": "Corporativo",
        "bank_account.holder_type.individual": "Individuo",
        "bank_account.model": "Extracto de cuenta",
        "bank_account.model.plural": "Extractos de cuenta",
        "base_model.model": "Modelo",
        "boomerangweb.action.more_info": "Mas información",
        "boomerangweb.info.actions": "Controla tu proceso de compra, gestiona tus gastos, valida tus compras.",
        "boomerangweb.info.definition": "El software simple e intuitivo para la gestión de compras.",
        "budget.confirm.generatedFromBudgetAnalysis": "Presupuestos generados : {count}",
        "budget.duplicate": "Duplicación de un presupuesto",
        "budget.error.alreadyExists": "Este presupuesto ya existe, seleccione otras claves.",
        "budget.field.copy_amounts": "Copiar presupuestos de previsión",
        "budget.field.new_tag": "Tag",
        "budget.generateFromBudgetAnalysis": "Generar a partir del análisis presupuestario",
        "budget.generateFromBudgetAnalysis.info": "La lista a continuación muestra los elementos presentes en el análisis presupuestario y que no existen en los presupuestos.",
        "budget.generateFromBudgetAnalysis.noBudgetAnalysis": "Nada que importar del análisis presupuestario.",
        "budget.generateFromBudgetAnalysis.warning": "Advertencia: los filtros aplicados en la página \"{page}\" también se aplican aquí.",
        "budget.importer.field.file.help": "El nombre de cada columna debe corresponder al carácter cercano al nombre de esta columna en la exportación anterior.<br>Todas las columnas \"Código\" son obligatorias, las columnas \"Etiqueta\" solo son necesarias caso de creación de una imputación, etc.",
        "budget.info.last_validated": "Última validación : {importe}",
        "budget.model": "Presupuesto",
        "budget.model.plural": "Presupuestos",
        "budget.overrun.question": "{1}¿ Todavía desea enviar este {model} para su validación ?|{2}¿ Todavía desea enviar esta {model} para su validación ?",
        "budget.overrun.question.in_validation": "{1}¿ Todavía quieres validar este {model} ?|{2}¿ Todavía quieres validar esta {model} ?",
        "budget.overrun.warning": "Atención ! Algunas líneas presupuestarias están en exceso.",
        "budget.overrun_action.block": "Notificar al usuario y bloquear el envío",
        "budget.overrun_action.none": "Ninguna acción",
        "budget.overrun_action.notification": "Notificar al usuario",
        "budget.requiredSearchfield": "Por favor complete todos los campos de búsqueda",
        "budget.value.undefined": "- por defecto -",
        "budget_amount.model": "Monto de presupuesto",
        "budget_amount.model.plural": "Montos del presupuesto",
        "budget_analysis.filter.stock_type.all": "Con movimientos de stock",
        "budget_analysis.filter.stock_type.no_stock": "Sin movimientos de stock",
        "budget_analysis.filter.stock_type.only_stock": "Solo movimientos de stock",
        "budget_analysis.info.amount_of_models": "total de {model}",
        "budget_analysis.info.billed": "cantidad de {modelInvoice} \"{awaiting}\" y \"{validated}\" ",
        "budget_analysis.info.delivered_not_billed": "{1}total de {modelPO} recibido pero aún no facturado.|{2}total de {modelPO} recibido pero aún no facturado.",
        "budget_analysis.info.prorated_amounts": "Los importes en cursiva son importes pro rata temporis.",
        "budget_analysis.info.validated": "{1}total de  validado {modelPO} no recibido, no facturado y no cerrado.|{2}total de  validado {modelPO} no recibido, no facturado y no cerrado.",
        "budget_analysis.model": "Análisis de presupuesto",
        "budget_analysis.model.plural": "Análisis de presupuesto",
        "budget_analysis.overrun": "Sobre presupuesto",
        "budget_version.model": "Versión",
        "budget_version.model.plural": "Versiones",
        "cart.action.add": "Añadir a el carrito",
        "cart.action.empty": "Eliminar el carrito",
        "cart.confirm.converted_to": "{1}Los {article_model} seleccionados en el carrito se han convertido con éxito a {model}|{2}Las {article_model} seleccionadas en el carrito se han convertido con éxito a {model}",
        "cart.error.no_cart_mode": "El carrito no se puede convertir",
        "cart.error.require_items": "{1}El carrito debe contener al menos un {model}|{2}El carrito debe contener al menos una {model}",
        "cart.info.no_selected_items_remain_in_cart": "{1}Los {model} no seleccionados permanecerán en el carrito|{2}Las {model} no seleccionadas permanecerán en el carrito",
        "cart.model": "Orden de la canasta",
        "catalog.module": "Módulo de Catálogo",
        "catalog.stock.module": "Gestión del Stock|Gestión de Stocks",
        "central.dashboard": "Datos centrales",
        "comment.model": "Comentario",
        "comment.model.plural": "Comentarios",
        "company.download.filename": "condiciones_de_compra",
        "company.field.accounting_export_prefix.help": "Una vez completado, este campo ya no se puede cambiar",
        "config.dashboard": "Configuración",
        "config.info.no_available_module": "No hay módulos disponibles",
        "config.title.available_modules": "Módulos disponibles",
        "contact.alert.duplicateLogin": "Este contacto tiene una dirección de correo electrónico que ya se utiliza como inicio de sesión del usuario",
        "contact.alert.email": "El contacto debe tener un correo electrónico para acceder a Boomerang Web",
        "contact.field.identity": "Identitad",
        "contact.field.is_main": "Contacto principal",
        "contact.field.is_main_billing_contact": "Contacto principal Factura",
        "contact.field.is_main_commercial_contact": "Contacto principal Orden",
        "contact.field.language": "Idioma de contacto",
        "contact.field.softwareAccess": "Acceso al software",
        "contact.field.types": "Tipos",
        "contact.model.plural": "Contactos",
        "contact.prevent.sendMailToRegistration": "Se enviará un correo electrónico a este contacto para acceder a la aplicación",
        "contact.prevent.sendMailToStopAccess": "Este contacto perderá acceso",
        "counter.model": "Contador",
        "counter.model.plural": "Contadores",
        "country.model": "Country",
        "country.model.plural": "país",
        "currency.auto_update_rates": "Actualizar tipos de cambio",
        "currency.confirm.updated_rates": "Actualización de tasas de divisas completa",
        "currency.field.rate_for_one_default_currency": "Tasa por 1 {currency}",
        "currency.field.symbol": "Símbolo",
        "currency.info.updating_rates": "Actualización de tasas de divisas en curso",
        "currency.model": "Moneda",
        "currency.model.gender": "2",
        "currency.model.plural": "Monedas",
        "currency.position.after": "Después",
        "currency.position.before": "Antes",
        "custom_field.model": "Campo Personalizado",
        "custom_field.model.plural": "Campos Personalizados",
        "dashboard.chart.comparison": "Comparación de cantidades de {model} entre N-1 y N en {currency} sin impuestos",
        "dashboard.chart.comparison.last_year": "Año N-1",
        "dashboard.chart.comparison.year": "Año N",
        "dashboard.chart.distribution": "Distribución dos {model} en los últimos 3 meses por {allocation}",
        "dashboard.chart.distribution.other": "OTRO",
        "dashboard.chart.global": "Cantidad total",
        "dashboard.chart.global.delivery_awaiting": "{number} {model} pendiente de validación",
        "dashboard.chart.global.invoice_awaiting": "{number} {model} pendiente de validación",
        "dashboard.chart.global.invoice_not_received": "{model} no recibidas",
        "dashboard.chart.global.needs_awaiting": "{number} {model} en espera de tratamiento",
        "dashboard.chart.global.purchase_order_awaiting": "{number} {model} pendiente de validación",
        "dashboard.chart.global.purchase_order_not_received": "{model} no recibida",
        "dashboard.chart.instance": "Su suscripción",
        "dashboard.chart.instance.projection_purchase_order": "Proyección de {model} hasta que {date}",
        "dashboard.chart.instance.purchase_order": "Número de {model} desde el",
        "dashboard.chart.instance.storage": "Espacio de almacenamiento utilizado",
        "dashboard.chart.instance.subscription": "Renovación de su suscripción",
        "dashboard.chart.instance.user": "Número de {model}",
        "dashboard.no_chart": "Utilice el menú principal para navegar",
        "data_accuracy.actions.clean_validators": "Limpiar estos validadores",
        "data_accuracy.actions.put_into_main_bank_account": "Coloque estas Detalles del banco en las Detalles del banco principales.",
        "data_accuracy.info.large_problems_detected": "Se detectaron muchos problemas (límite de visualización: {count})",
        "data_accuracy.info.problems_detected": "{count} problema detectado|{count} problemas detectados",
        "data_accuracy.info.too_many_to_check": "Hay demasiados {models} para comprobar ({count})",
        "data_accuracy.stock.in_quantity_awaiting": "Entrada - Cantidad pendiente",
        "data_accuracy.stock.in_quantity_validated_not_delivered": "Entrada - Cantidad validada no recibida",
        "data_accuracy.stock.out_quantity_awaiting": "Salida - Cantidad pendiente",
        "data_accuracy.stock.out_quantity_validated_not_delivered": "Salida - Cantidad validada no entregada",
        "data_import.action.add_column_before": "Insertar un campo antes",
        "data_import.action.remove_column": "Retire el campo y mueva los siguientes hacia arriba",
        "data_import.action.save_template": "Guardar la plantilla",
        "data_import.columns_from_file": "Columnas de archivo",
        "data_import.columns_to_import": "Campos de destino",
        "data_import.default_template": "Plantilla predeterminada",
        "data_import.error.cant_create_allocations": "No se puede crear la imputación: {allocations}|No se pueden crear imputaciones: {allocations}",
        "data_import.error.column.already_exists": "La columna \"{field}\" se rellena dos veces",
        "data_import.error.column.contains_errors": "La columna \"{field}\" contiene errores",
        "data_import.error.column.doesnt_exist_in_params": "La columna \"{field}\" no existe en la configuración",
        "data_import.error.column.missing": "Falta la columna \"{field}\" ",
        "data_import.error.column.missing_for": "Falta la columna \"{field}\" para este Tipo de Importación",
        "data_import.error.column.missing_or_not_in_form": "Falta la columna \"{field}\" o debe seleccionarse arriba",
        "data_import.error.data_type.wrong": "El campo {field} es incorrecto",
        "data_import.error.field.already_in_database": "El valor de {field} ya está presente en la Base de Datos",
        "data_import.error.field.already_in_database_for": "El valor de {field} ya está presente en la Base de Datos para {for}",
        "data_import.error.field.already_in_import": "El valor de {field} ya está presente en la importación",
        "data_import.error.field.does_not_exists": "El valor de {field} no existe",
        "data_import.error.field.does_not_exists_for": "El valor de {field} no existe para {for}",
        "data_import.error.field.must_be_boolean": "\"{field}\" debe contener un valor booleano",
        "data_import.error.field.must_be_duration": "\"{field}\" debe contener una duración",
        "data_import.error.field.must_be_email": "\"{field}\" debe contener una dirección de correo electrónico",
        "data_import.error.field.must_be_float": "\"{field}\" debe contener un número",
        "data_import.error.field.must_be_int": "\"{field}\" debe contener un número entero",
        "data_import.error.field.must_be_max": "\"{field}\" debe contener como máximo {max}",
        "data_import.error.field.must_be_min": "\"{field}\" debe contener al menos {min}",
        "data_import.error.field.required": "La columna \"{field}\" es obligatoria",
        "data_import.error.field.wrong_data": "La columna \"{field}\" contiene datos incorrectos",
        "data_import.error.field.wrong_linked_model": "{model} {value} no vinculado a {for}",
        "data_import.error.forbidden_warehouse": "Almacén prohibido",
        "data_import.error.line": "Línea {line} :",
        "data_import.error.max_columns_exceeded": "Se superó el número máximo de columnas ({max})",
        "data_import.error.must_contain_count_separated_values": "Debe contener {count} valores separados por \"{separator}\" ",
        "data_import.example_from_file": "Línea {nb} de archivo",
        "data_import.field.data_type": "Tipo de datos",
        "data_import.help.global": "Una celda vacía no se tendrá en cuenta.<br />Una celda que contenga la cadena \"#null\" vaciará el campo si es posible.",
        "data_import.info.allocations_will_be_created": "Se crearán imputaciones si no existe en el sistema.",
        "data_import.info.article.original_price": "Precio original antes del descuento",
        "data_import.info.cant_change_when_template": "No se puede cambiar cuando se selecciona una Plantilla",
        "data_import.info.default": "El valor #default le permite ingresar el valor predeterminado",
        "data_import.info.links_will_be_created": "Se crearán vínculos entre imputaciones si no existen en el sistema.",
        "data_import.info.no_allocation_created": "No se creará ninguna imputación si no existe en el sistema.",
        "data_import.info.no_link_created": "No se crearán vínculos entre imputaciones si no existen en el sistema.",
        "data_import.info.starts_by_hash": "Si comienza con \"#\", se interpretará como un ID.",
        "data_import.info.starts_by_underscore": "Si comienza con \"_\", se interpretará como un {attribute} de {model}",
        "data_import.param.allowed_values.title": "Valores permitidos",
        "data_import.param.ignored.title": "Esta columna se ignora",
        "data_import.param.ignored_for_field.title": "Esta columna se ignora para el campo \"{field}\" seleccionado",
        "data_import.param.limits.max.details": "Máx. {value}",
        "data_import.param.limits.max.title": "Valor máximo",
        "data_import.param.limits.min.details": "Mín. {value}",
        "data_import.param.limits.min.title": "Valor mínimo",
        "data_import.param.list_separator.title": "Valores separados por",
        "data_import.param.type.boolean.details": "Booleano: sí/verdadero/1 o no/falso/0",
        "data_import.param.type.boolean.title": "Tipo booleano",
        "data_import.param.type.duration.details": "Duración, por ejemplo: P30D por 30 días, P1M por 1 mes, P2Y por 2 años",
        "data_import.param.type.duration.title": "Tipo duración",
        "data_import.param.type.email.details": "Correo electrónico",
        "data_import.param.type.email.title": "Tipo correo electrónico",
        "data_import.param.type.float.details": "Número, puede contener decimales",
        "data_import.param.type.float.title": "Tipo número",
        "data_import.param.type.int.details": "Entero",
        "data_import.param.type.int.title": "Tipo entero",
        "data_import.save_columns.question": "¿Quieres guardar estos elementos en una Plantilla?",
        "data_import.title": "Importación de datos",
        "data_import.type.create": "Creación",
        "data_import.type.create_and_replace": "Creación/reemplazo",
        "data_import.type.create_or_update_from": "Creación/Modificación por {field}",
        "data_import.type.update_from": "Modificación por {field}",
        "datepicker.label_calendar": "Calendario",
        "datepicker.label_current_month": "Mes actual",
        "datepicker.label_help": "Use las teclas de flecha para navegar por el calendario",
        "datepicker.label_nav": "Navegación en el calendario",
        "datepicker.label_next_month": "Mes siguiente",
        "datepicker.label_next_year": "El año que viene",
        "datepicker.label_no_date_selected": "--",
        "datepicker.label_prev_month": "Mes anterior",
        "datepicker.label_prev_year": "Año anterior",
        "datepicker.label_selected": "Fecha seleccionada",
        "datepicker.label_today": "Hoy",
        "delegation.confirm.notification_added": "El usuario delegado recibirá notificaciones por correo electrónico.",
        "delegation.confirm.notification_removed": "El usuario delegado ya no recibirá notificaciones por correo electrónico.",
        "delegation.field.delegate": "Delegado",
        "delegation.field.notify_delegate": "Notificar al delegado regularmente por correo electrónico",
        "delegation.model": "Delegación",
        "delegation.model.gender": "2",
        "delegation.model.plural": "Delegaciones",
        "delivery.error.amount_exctax_to_deliver.less_than_delivered_amount": "El monto sin impuestos debe ser mayor o igual a {delivered} ({complement})",
        "delivery.error.amount_exctax_to_deliver.more_than_delivered_amount": "El monto sin impuestos debe ser menor o igual a {delivered} ({complement})",
        "delivery.error.amount_inctax_to_deliver.less_than_delivered_amount": "El monto con impuestos debe ser mayor o igual a {delivered} ({complement})",
        "delivery.error.amount_inctax_to_deliver.more_than_delivered_amount": "El monto con impuestos debe ser menor o igual a {delivered} ({complement})",
        "delivery.error.amount_to_deliver.complement.delivered": "monto ya recibido{complement}",
        "delivery.error.amount_to_deliver.complement.not_delivered": "monto no recibido{complement}",
        "delivery.error.total_amount_exctax_to_deliver.must_be_between": "El monto sin impuestos total debe ser entre {left} et {right}",
        "delivery.error.total_amount_inctax_to_deliver.must_be_between": "El monto con impuestos total debe ser entre{left} et {right}",
        "delivery.field.code": "Número interno",
        "delivery.field.label": "Recepción número",
        "delivery.info.has_linked_invoices": "{1}Atención, este {model} está potencialmente vinculado a {invoice_model}.|{2}Atención, esta {model} está potencialmente vinculada a {invoice_model}.",
        "delivery.model": "Recepción",
        "delivery.model.plural": "Recepciones",
        "delivery.pdf.title": "Albarán de entrega",
        "deliveryLine.model": "Línea de recepción",
        "delivery_address.action.create_address": "Crear una dirección de entrega",
        "delivery_address.action.edit_address": "Modificar la dirección de entrega",
        "delivery_address.address_adds": "Información de entrega",
        "delivery_address.deadline": "Entrega a más tardar el",
        "delivery_address.field.address_type.addressbook": "Carné de dirección",
        "delivery_address.field.public": "Pública ?",
        "delivery_address.from": "{1}Dirección del {model}|{2}Dirección de la {model}",
        "delivery_address.from.addressbook": "Libreta de direcciones",
        "delivery_address.from.other": "otra dirección",
        "delivery_address.model": "Dirección de entrega",
        "delivery_address.model.gender": "2",
        "delivery_address.model.plural": "Direcciónes de entrega",
        "delivery_address.shipping_date": "Fecha de envío",
        "delivery_address.type": "Tipo de dirección",
        "docuware.error.DPIIBoomExport": "Hubo un problema al intentar recuperar la ID del documento {id}",
        "docuware.error.DPIIBoomExport_delay": "Hubo un problema al intentar recuperar la ID del documento {id}, nuevo intento en {minutes} minutos",
        "docuware.error.GetNotImplementedDWDOCID": "Hubo un problema al intentar recuperar documentos no implementados",
        "error.301.description": "Ha cambiado de URL y pronto se eliminará permanentemente. Aquí está la nueva URL:",
        "error.301.title": "Esta pagina ya no existe",
        "error.301.warning": "Modifique sus favoritos con esta nueva URL",
        "error.401.description": "Ya no estás conectado.",
        "error.401.link_text": "Haz clic aquí para volver a iniciar sesión",
        "error.401.title": "No conectado",
        "error.403.description": "Esta acción no está permitida.",
        "error.403.title": "No autorizado",
        "error.404.description": "Esta página no existe.",
        "error.404.title": "Error 404",
        "error.410.title": "Aplicación deshabilitada",
        "error.498.description": "Su enlace ha expirado",
        "error.498.title": "Error 498",
        "error.500.description": "Error del servidor",
        "error.500.title": "Error 500",
        "error.502.description": "Respuesta del servidor no válida.",
        "error.503.description": "El sitio está actualmente en mantenimiento.",
        "error.503.get_message": "Mensaje adicional : <em>{message}</em>",
        "error.503.retry_after": "Vuelva a intentarlo en {delay} segundos.",
        "error.503.retry_later": "Inténtelo de nuevo más tarde.",
        "error.503.title": "Sitio en mantenimiento",
        "error.cantChangeStatus": "Error al cambiar el estado, inténtelo de nuevo más tarde.",
        "error.cantCreate": "Error durante la creación, inténtelo de nuevo más tarde.",
        "error.cantDelete": "Error durante la eliminación, inténtelo de nuevo más tarde.",
        "error.cantImport": "Error durante la importación, inténtelo de nuevo más tarde.",
        "error.cantModify": "Error durante el cambio, inténtelo de nuevo más tarde.",
        "error.cantRefuse": "No se puede negar que el elemento",
        "error.cantSort": "No se pueden ordenar estos elementos.",
        "error.cantValidate": "No se puede validar el elemento",
        "error.cant_create_payment": "Error durante la creación del pago, una o más facturas ya están pagadas, en espera de pago o aún no están validadas.",
        "error.cant_modify": "Error durante el cambio, inténtelo de nuevo más tarde.",
        "error.clamav": "Su archivo probablemente contiene un virus",
        "error.duration.wrong_unit": "El tipo de período debe corresponder a una elección existente.",
        "error.field.requireOne": "Se requiere al menos uno de estos campos.",
        "error.file.bad_extension": "El archivo cargado no es la extensión correcta.",
        "error.form_errors": "Acción no posible : hay errores en el formulario.",
        "error.form_errors.title": "Error",
        "error.go_back_home": "Volver a la página de inicio",
        "error.has_been_deleted": "{1}{attribute} eliminado, elija otro|{2}{attribute} eliminada, elija otra",
        "error.has_been_disabled": "{1}{attribute} deshabilitado, elija otro|{2}{attribute} deshabilitada, elija otra",
        "error.import_errors": "Importación imposible, haga clic aquí para ver el detalle de los errores",
        "error.impossible_action": "Acción imposible",
        "error.impossible_action.title": "Error {code}",
        "error.maintenance.description": "Mantenimiento en proceso",
        "error.maintenance.index": "Sitio en mantenimiento",
        "error.missing_permission": "No tiene los permisos necesarios.",
        "error.module.misconfigured": "Módulo mal configurado",
        "error.not_compatible": "{attribute} no compatible",
        "error.not_enabled": "{1}{attribute} no activo|{2}{attribute} no activa",
        "error.not_exists": "{1}{attribute} no existe, elija otro|{2}{attribute} no existe, elija otra",
        "error.not_found": "{1}{attribute} no encontrado|{2}{attribute} no encontrada",
        "error.no_model": "No hay {model}",
        "error.no_pdf_viewer": "Parece que no tiene un lector de PDF incorporado en su navegador, pero puede descargar el documento a continuación.",
        "error.problem_occured": "Ha ocurrido un problema",
        "error.unknown": "Error desconocido",
        "external_charge.model": "Carga externa",
        "external_charge.model.plural": "Cargas externas",
        "faq.add": "Añadir una pregunta",
        "faq.all": "Ver toda la ayuda",
        "faq.model": "Preguntas frecuentes",
        "feature.model": "Característica de Producto",
        "feature.model.gender": "2",
        "feature.model.plural": "Características de Producto",
        "fieldable.position.bottom": "Parte inferior de BC",
        "fieldable.position.line": "En las lineas del BC",
        "fieldable.position.top": "Parte superior de BC",
        "fieldable.visualizeCustomFields": "Ver la representación de campos",
        "file.confirm.created": "El documento ha sido enviado.",
        "file.confirm.deleted": "El documento ha sido eliminado.",
        "file.confirm.statusValidate": "El estado del documento ha cambiado.",
        "file.confirm.updated": "El documento ha sido modificado.",
        "file.info.not_available": "Documento no disponible.",
        "file.model.gender": "1",
        "file.model.plural": "Documentos",
        "file_category.model.gender": "2",
        "file_upload.action.tap_to_retry": "Toca para volver a intentarlo",
        "file_upload.error.code": "Error {code}. Póngase en contacto con su administrador con una captura de pantalla de esta página.",
        "file_upload.error.file_already_exists": "El archivo ya existe",
        "file_upload.error.file_empty": "El archivo esta vacio",
        "file_upload.error.file_processing_error": "Error durante la carga",
        "file_upload.error.file_too_large": "El archivo es demasiado grande",
        "file_upload.error.file_too_large.description": "El tamaño máximo de archivo es {filesize}",
        "file_upload.error.wrong_type": "Tipo de archivo inválido",
        "file_upload.error.wrong_type.description": "Espera tipo(s) {allTypes}",
        "filter.automatic": "Llenado automáticamente",
        "filter.cant_deselect_label": "No se puede eliminar",
        "filter.deselectLabel": "Eliminar",
        "filter.deselect_label": "Eliminar",
        "filter.doSearch": "Comience a escribir para buscar",
        "filter.filter_to_show_more": "Realice una búsqueda para refinar los resultados",
        "filter.is_not": "{model} no es",
        "filter.no_options": "La lista está vacía.",
        "filter.no_result": "No se ha encontrado ningún elemento.",
        "filter.placeholder": "Seleccione una opción",
        "filter.search.contains": "Contiene",
        "filter.search.ends_by": "Termina por",
        "filter.search.starts_by": "Comienza por",
        "filter.search.title": "Busqueda",
        "filter.selected_label": "Selected",
        "filter.select_label": " ",
        "filter.tag_placeholder": "Presione enter para agregar a la búsqueda",
        "filter.type_to_search": "Escriba para buscar",
        "finance.module": "Finanzas",
        "fiscal_period.error.code_exists": "Este código de {model} ya existe.",
        "fiscal_period.error.overlap": "Hay una superposición de {model}.",
        "fiscal_period.field.format": "Formato del código",
        "fiscal_period.field.format_help_block": "Puede utilizar los siguientes tokens (dentro del límite de 10 caracteres) :<br>{year4char} : Año de 4 dígitos<br>{nextYear4char} : Próximo año de 4 dígitos<br>{year2char} : Año de 2 dígitos<br>{nextYear2char} : Próximo año de 2 dígitos<br>{month2char} : Mes de 2 dígitos<br>{nextMonth2char} : Próximo mes de 2 dígitos<br>{periodNumber}: número de período <br>{day2char} : Día de 2 dígitos<br>{weekNumber} Número de semana de 2 dígitos<br>",
        "geographic_sector.field.parent_id": "Parent",
        "geographic_sector.field.requires_duns": "Requiere DUNS / ID Código",
        "geographic_sector.field.requires_intra_community_vat": "Requiere IVA intracomunitario",
        "geographic_sector.field.requires_siret": "Requiere SIRET",
        "geographic_sector.model": "Sector geográfico",
        "geographic_sector.model.plural": "Sectores geográficos",
        "global.acknowledge_receipt.already_done.content": "{1}Este {model} ya ha sido recibido|{2}Esta {model} ya ha sido recibida",
        "global.acknowledge_receipt.confirmation.content": "{1}Gracias por acusar recibo de este {model}|{2}Gracias por acusar recibo de esta {model}",
        "global.acknowledge_receipt.confirmation.title": "Acuse de recibo",
        "global.action.access": "Access",
        "global.action.actionIrreversible": "Esta acción es irreversible y no se puede deshacer más tarde",
        "global.action.add": "Añadir",
        "global.action.add_all_lines": "Añadir todas las líneas",
        "global.action.add_article": "Añadir un articulo",
        "global.action.add_checked_lines": "Añadir líneas marcadas",
        "global.action.add_comment": "Añadir un comentario",
        "global.action.add_favorite": "Agregar a los favoritos",
        "global.action.add_gap_line": "Agregar una línea de espacio",
        "global.action.add_in_new_line": "Añadir en una nueva línea",
        "global.action.add_line": "Agregar una línea",
        "global.action.add_line_from_catalog": "Añadir desde el catálogo",
        "global.action.add_line_from_punchout_catalog": "Catálogo PunchOut",
        "global.action.add_model": "{1}Agregar un {model}|{2}Agregar una {model}",
        "global.action.add_models": "Agregar {model}",
        "global.action.add_models_massively": "Añadir {model} masivamente",
        "global.action.add_to_catalog": "Agregar al catálogo",
        "global.action.add_to_model": "{1}Añadir al {model}|{2}Añadir a la {model}",
        "global.action.add_to_model_catalog": "{1}Agregar al catálogo del {model} \"{entity}\"|{2}Agregar al catálogo de la {model} \"{entity}\" ",
        "global.action.adjust": "Reajustar",
        "global.action.adjust_stock": "Ajustar stock",
        "global.action.approve": "Aprobar",
        "global.action.are_you_really_sure": "¿Está realmente seguro?",
        "global.action.are_you_sure": "¿Está seguro?",
        "global.action.autofill": "Autocompletar",
        "global.action.autofill_new_line": "Autocompletar en una nueva línea",
        "global.action.back_to_awaiting": "Repasar en esperando tratamiento",
        "global.action.back_to_default": "Restablecen a los predeterminados",
        "global.action.back_to_draft": "Reescribir",
        "global.action.back_to_pending": "Repasar en esperando tratamiento",
        "global.action.break_down_expenses": "Desglose los gastos",
        "global.action.browse": "Navegar",
        "global.action.cancel": "Anular",
        "global.action.cancel_and_replace": "Cancelar y reemplazar",
        "global.action.cancel_model": "{1}Anular el {model}|{2}Anular la {model}",
        "global.action.change_exctax": "Mostrar precios sin IVA",
        "global.action.change_inctax": "Mostrar precios con IVA",
        "global.action.change_weight": "Ordenar elementos",
        "global.action.check_all": "Todo cocher",
        "global.action.choice_between": "Elección entre {first} y {second}",
        "global.action.choose_image": "Elegir esta imagen",
        "global.action.clear": "Borrar",
        "global.action.clear_selection": "Borrar la selección",
        "global.action.close": "Cerrar",
        "global.action.close_dispute": "Cerrar la disputa",
        "global.action.close_something": "Cerrar",
        "global.action.configure": "Configurar",
        "global.action.confirm": "Confirmar",
        "global.action.confirm_by_checking": "Marque la siguiente casilla para confirmar su acción:",
        "global.action.confirm_configuration": "Confirmar configuración",
        "global.action.consider_sent": "Considerar como enviado",
        "global.action.contact_us": "Contáctenos",
        "global.action.continue": "Seguir",
        "global.action.copy": "Copiar",
        "global.action.copyAllSuggestions": "Copiar todas las sugerencias",
        "global.action.copy_all_suggestions": "Copiar todas las sugerencias",
        "global.action.copy_down": "Copiar desde",
        "global.action.create": "Crear",
        "global.action.create_and_add": "Crear y agregar",
        "global.action.create_anyway": "Crear de todos modos",
        "global.action.create_dispute": "Crear un litigio",
        "global.action.create_model": "{1}Crear un {model}|{2}Crear una {model}",
        "global.action.create_model_for": "{1}Crear un {model} para :|{2}Crear una {model} para :",
        "global.action.create_multiple": "Crear múltiples",
        "global.action.delete": "Delete",
        "global.action.delete_file": "Eliminar este archivo",
        "global.action.delete_image": "Eliminar esta imagen",
        "global.action.delete_line": "Eliminar línea",
        "global.action.disable": "Desactivar",
        "global.action.disable_notification": "Desactivar la notificación",
        "global.action.download": "Descargar",
        "global.action.download_headers": "Descargar encabezados",
        "global.action.do_not_close_something": "No cerrar",
        "global.action.drop_here": "Caer aquí ...",
        "global.action.drop_or_clic": "Suelte un nuevo archivo o haga clic para cargar",
        "global.action.drop_or_clic_multiple": "Suelte nuevos archivos o haga clic para cargar",
        "global.action.duplicate": "Duplicado",
        "global.action.enable": "Activar",
        "global.action.enable_notification": "Activar la notificación",
        "global.action.export": "Exportar",
        "global.action.exportSubmissions": "Exportar envíos",
        "global.action.export_from_ids": "Exportar desde ID",
        "global.action.export_from_ids.field.ids.help": "Separados por espacios, comas, punto y coma o saltos de línea",
        "global.action.export_sepa": "Exportar a formato SEPA",
        "global.action.force_status": "Forzar a \"{status}\" ",
        "global.action.force_the_status": "Forzar el estado",
        "global.action.forgot_password": "¿Olvidó su contraseña?",
        "global.action.fullSearch": "Búsqueda completa",
        "global.action.generate": "Generar",
        "global.action.generateFromBudgetAnalysis": "Generar a partir del análisis presupuestario",
        "global.action.generate_field": "Generar el {field}",
        "global.action.get_back_as": "Regresa como {user}",
        "global.action.get_login_link": "Obtenga un enlace de inicio de sesión",
        "global.action.go": "Go",
        "global.action.hide_all_lines": "Doblar líneas",
        "global.action.hide_content": "Ocultar contenido",
        "global.action.hide_file": "Ocultar el archivo",
        "global.action.hide_models": "Ocultar {models}",
        "global.action.hide_received_elsewhere": "Ocultar líneas ya recibidas",
        "global.action.history": "Historial",
        "global.action.ignoreSuggestions": "Ignorar sugerencias",
        "global.action.ignore_suggestions": "Ignorar sugerencias",
        "global.action.import": "Importar",
        "global.action.inject": "Inyectar",
        "global.action.inject_lines": "Inyectar línea",
        "global.action.invite": "Invitar",
        "global.action.keep_pressed": "Mantener presionado",
        "global.action.link_to_line": "Enlace a una línea",
        "global.action.link_to_this_line": "Enlace a esta línea",
        "global.action.listSubmissions": "Ver envíos",
        "global.action.login": "Iniciar sesión",
        "global.action.logout": "Desconectar",
        "global.action.log_as": "Iniciar sesión como",
        "global.action.makeAvailableToAll": "Poner a disposición de todos",
        "global.action.manage": "Administrar",
        "global.action.manageFields": "Administrar campos",
        "global.action.manage_files": "{1}Administrar archivo|Administrar archivos",
        "global.action.manage_models": "Administrar {models}",
        "global.action.mergeInto": "Combinar con ...",
        "global.action.more_details": "Mas detalles",
        "global.action.more_options": "Mas opciones",
        "global.action.never_see_again": "Nunca volver a ver",
        "global.action.new_version": "Crea una nueva versión",
        "global.action.next": "Siguiente",
        "global.action.notify": "Notificar",
        "global.action.ocr_is_running": "El OCR está en marcha. Puede desactivarlo para introducir los datos manualmente.",
        "global.action.ok": "OK",
        "global.action.open_new_tab": "Abrir en una pestaña nueva",
        "global.action.or_create": "{1}O crea un nuevo {model}|{2}O crea una nueva {model}",
        "global.action.other_actions": "Otras acciones",
        "global.action.other_actions.abbr": "Otras",
        "global.action.override": "Sobrecarga",
        "global.action.paste": "Pegar",
        "global.action.point": "Señalar",
        "global.action.preview": "Ver",
        "global.action.previous": "Previo",
        "global.action.print": "Imprimir",
        "global.action.print_without_budget": "Imprimir sin presupuesto",
        "global.action.put_as_main": "Poner como principal",
        "global.action.quick_export": "Exportación sencilla",
        "global.action.quit": "Dejar",
        "global.action.rebilling_import": "Importar refacturaciones",
        "global.action.recalculate_amounts": "Recalcular cantidades",
        "global.action.receive_all": "Recibir todo",
        "global.action.reconcile": "Reconciliar",
        "global.action.reconcile_without_lines": "Reconciliar sin las líneas",
        "global.action.refresh": "Actualizar",
        "global.action.refresh_from": "Actualizar desde {label}",
        "global.action.refuse": "Deny",
        "global.action.register": "Crear cuenta",
        "global.action.reject": "Rechazar",
        "global.action.remove": "Eliminar",
        "global.action.remove_all": "Eliminar todo",
        "global.action.remove_favorite": "Quitar de favoritos",
        "global.action.renew_pdf": "Regenerar PDF",
        "global.action.replace_file": "Reemplazar el archivo",
        "global.action.report": "Reportar",
        "global.action.reset": "Reiniciar",
        "global.action.returnTo": "{1}Ir a el {model}|{2}Ir a la {model}",
        "global.action.reverse": "Invertir",
        "global.action.reverse_selection": "Invertir selección",
        "global.action.save": "Guardar",
        "global.action.saveWeight": "Guardar el Orden",
        "global.action.save_research": "Guardar búsqueda",
        "global.action.save_weight": "Guardar el Orden",
        "global.action.search": "Buscar",
        "global.action.search.advanced": "Búsqueda avanzada",
        "global.action.search.help.not_full_search": "Búsqueda limitada al mes pasado|Búsqueda limitada a los {months} meses pasados",
        "global.action.search_barcode": "Buscar por código de barras",
        "global.action.search_keyword": "Buscar por palabra clave",
        "global.action.see": "Ver",
        "global.action.see_detail": "Ver detalles",
        "global.action.see_in_catalog": "Ver en el catalogo",
        "global.action.see_more": "Ver más",
        "global.action.select": "Selecciona una opción",
        "global.action.send": "Enviar",
        "global.action.send_suggestions": "Enviar sugerencias",
        "global.action.send_through_api": "Enviar a través de la API",
        "global.action.send_to_api": "Enviar a API",
        "global.action.send_to_api.confirmed": "Envío a API en curso",
        "global.action.share": "Compartir",
        "global.action.showTraces": "Ver historial",
        "global.action.show_all_lines": "Desdobla las líneas",
        "global.action.show_budget_analysis": "Ver estado del presupuesto",
        "global.action.show_content": "Ver contenido",
        "global.action.show_file": "Ver el archivo",
        "global.action.show_files": "Ver el/los documento(s)",
        "global.action.show_models": "{1}Ver los {model}|{2}Ver las {model}",
        "global.action.show_more": "Ver más",
        "global.action.show_received_elsewhere": "Mostrar líneas ya recibidas",
        "global.action.show_traces": "Ver historial",
        "global.action.start_action": "Comienza la acción",
        "global.action.start_validation": "Iniciar la validación",
        "global.action.start_validation.abbr": "Validación",
        "global.action.submit": "Enviar",
        "global.action.summary_pdf": "Imprimir resumen",
        "global.action.suspend": "Suspender",
        "global.action.take_charge": "Hacerse cargo",
        "global.action.test": "Test",
        "global.action.toggleDropdown": "Cambiar desplegable",
        "global.action.transfer_stock": "Movimiento entre acciones",
        "global.action.transform_into_purchase_order": "Transformar en {model}",
        "global.action.unclose_something": "Decerrar",
        "global.action.unforce_status": "Cancelar el estado forzado \"{status}\" ",
        "global.action.unsuspend": "Ya no suspender",
        "global.action.unvalidate": "Invalidate",
        "global.action.update": "Editar",
        "global.action.update_all": "Editar todo",
        "global.action.update_allocations": "Editar imputaciones",
        "global.action.use": "Usar",
        "global.action.validate": "Validar",
        "global.action.verify": "Comprobar",
        "global.action.view_all": "Ver todo",
        "global.billing_status.complete": "Facturado",
        "global.billing_status.complete_less": "Facturado (más bajo)",
        "global.billing_status.complete_more": "Facturado (más alto)",
        "global.billing_status.no": "{1}No facturado|{2}No facturada",
        "global.billing_status.non_billable": "No facturable",
        "global.billing_status.partial": "{1}Parcialmente facturado|{2}Parcialmente facturada",
        "global.bulk_action.export": "Exportar",
        "global.bulk_action.export_bank_accounts": "Exportar cuentas bancarias",
        "global.bulk_action.export_chart_accounting_entry_turnover": "Exportar estadísticas de Volumen de negocios para Entrada contable",
        "global.bulk_action.export_chart_annual_rating": "Exportar estadísticas de Notación anual",
        "global.bulk_action.export_chart_certifications": "Exportar estadísticas de Certificaciones",
        "global.bulk_action.export_chart_performance": "Exportar estadísticas de Rendimiento",
        "global.bulk_action.export_chart_security": "Exportar estadísticas de Seguridad",
        "global.bulk_action.export_chart_turnover": "Exportar estadísticas de Volumen de negocios",
        "global.bulk_action.export_contacts": "Exportar contactos",
        "global.bulk_action.export_remaining_to_deliver": "Exportación del resto a recibir",
        "global.bulk_action.export_without_details": "Exportar sin detalle",
        "global.bulk_action.export_with_details": "Exportar con detalle",
        "global.bulk_action.send_login_password_email": "Enviar un correo electrónico de invitación con nombre de usuario y contraseña",
        "global.bulk_action.simple_export": "Exportar (simplificado)",
        "global.bulk_action.validate": "Validar en masa",
        "global.buyer_role.family_buyer": "Comprador de familia",
        "global.buyer_role.family_purveyor": "Abastecedor de familia",
        "global.buyer_role.family_regional_buyer": "Comprador familia regional",
        "global.catchphrase": "dirección de Procuración.",
        "global.char.comma": "Coma",
        "global.char.pipe": "Pleca",
        "global.char.semicolon": "Punto y coma",
        "global.config.allocations": "Imputaciones",
        "global.config.fields": "Campos",
        "global.config.linksTable": "Tabla de enlaces",
        "global.config.pdf": "PDF",
        "global.confirm.action_done": "Acción efectuada",
        "global.confirm.added": "Adición exitosa",
        "global.confirm.added_entities": "Elementos agregados : {count} {model}",
        "global.confirm.added_to_jobs": "Elementos agregados a Jobs : {count} {model}",
        "global.confirm.amounts_recalculated": "Cantidades recalculadas",
        "global.confirm.back_to_awaiting": "Repasar en esperando tratamiento exitoso",
        "global.confirm.back_to_draft": "Regresar al borrador exitoso",
        "global.confirm.back_to_pending": "Repasar en esperando tratamiento exitoso",
        "global.confirm.broken_down_expenses": "Desglosada realizada",
        "global.confirm.cancel": "Cancelación exitosa",
        "global.confirm.cancel_and_replace": "Cancelación y reemplazo exitosos",
        "global.confirm.changedToAllUsers": "Cambio a Todos los usuarios exitoso",
        "global.confirm.close": "Cierre exitoso",
        "global.confirm.copied": "Copia exitosa",
        "global.confirm.created": "Creación exitosa",
        "global.confirm.deleted": "Eliminación exitosa",
        "global.confirm.deleted_entities": "Elementos eliminados : {count} {model}",
        "global.confirm.disabled": "Desactivación exitosa",
        "global.confirm.duplicated": "Duplicación exitosa",
        "global.confirm.email_being_sent": "Correo electrónico se está enviando",
        "global.confirm.email_sent": "Correo electrónico enviado",
        "global.confirm.enabled": "Activación exitosa",
        "global.confirm.export_started": "La exportación ha comenzado. Se le enviará por correo electrónico cuando haya terminado",
        "global.confirm.file_deleted": "Documento eliminado con éxito",
        "global.confirm.file_uploaded": "Documento transferido con éxito",
        "global.confirm.image_uploaded": "Imagen transferida con éxito",
        "global.confirm.imported": "Importación exitosa",
        "global.confirm.imported.detail": "Importación completada exitosamente :",
        "global.confirm.invitation_sent": "Invitación enviada",
        "global.confirm.new_version": "Nueva versión creada",
        "global.confirm.password_changed": "Contraseña cambiada con éxito",
        "global.confirm.process_ongoing": "Proceso continuo",
        "global.confirm.removed": "Retiro exitoso",
        "global.confirm.replaced": "Reemplazo exitoso",
        "global.confirm.replaced_number": "{nb} reemplazo(s) realizado(s)",
        "global.confirm.request_made": "Solicitud realizada",
        "global.confirm.saved": "Guardar hecha",
        "global.confirm.sorted": "El Orden ha sido guardado",
        "global.confirm.start_validation": "Se ha iniciado el proceso de validación",
        "global.confirm.statusRefuse": "La negativa ha sido registrada",
        "global.confirm.statusValidate": "La validación ha sido registrada",
        "global.confirm.status_changed": "El estado ha sido cambiado",
        "global.confirm.suspended": "Suspensión completada",
        "global.confirm.taken_charge": "Hacerse cargo hecho",
        "global.confirm.transformed": "Transformación hecha",
        "global.confirm.unclose": "Decierre exitoso",
        "global.confirm.unsuspended": "Cancelación de suspensión efectuada",
        "global.confirm.updated": "Modificación exitosa",
        "global.date.day": "día|días",
        "global.date.month": "mes|meses",
        "global.date.year": "año|años",
        "global.day.friday": "Viernes",
        "global.day.monday": "Lunes",
        "global.day.saturday": "Sábado",
        "global.day.sunday": "Domingo",
        "global.day.thursday": "Jueves",
        "global.day.tuesday": "Martes",
        "global.day.wednesday": "Miércoles",
        "global.delivery_status.complete": "{1}Recibido|{2}Recibida",
        "global.delivery_status.forced_complete": "{1}Recibido (manualmente)|{2}Recibida (manualmente)",
        "global.delivery_status.no": "{1}No recibido|{2}No recibida",
        "global.delivery_status.partial": "{1}Recibido parcialmente|{2}Recibida parcialmente",
        "global.display.header": "Encabezado",
        "global.dispute_status.finished": "Conflicto completado",
        "global.dispute_status.no": "Sin conflicto",
        "global.dispute_status.ongoing": "Conflicto continuo",
        "global.error.already_exists": "Ya existe un {model} con el nombre \"{label}\"|{model} ya existen con el nombre \"{label}\" ",
        "global.error.already_used_in": "El campo {attribute} ya se usa en {model}",
        "global.error.amount_exctax.less_than_billed": "El importe sin impuestos debe ser mayor o igual a {billed} (importe ya facturado)",
        "global.error.amount_exctax.more_than_billed": "El importe sin impuestos debe ser menor o igual a {billed} (importe ya facturado)",
        "global.error.amount_inctax.less_than_billed": "El importe con impuestos incluidos debe ser mayor o igual a {billed} (importe ya facturado)",
        "global.error.amount_inctax.more_than_billed": "El importe con impuestos incluidos debe ser menor o igual a {billed} (importe ya facturado)",
        "global.error.at_least_one_model": "{1}Es necesario al menos un {model}|{2}Es necesario al menos una {model}",
        "global.error.cant_copy_to_clipboard": "No se puede copiar al portapapeles",
        "global.error.cant_update_field": "No se puede modificar el campo {field}",
        "global.error.cant_update_line_field": "No se puede modificar {field} en la línea {line}",
        "global.error.currency_must_match": "La moneda debe coincidir",
        "global.error.deposit.more_than_amount_inctax": "El campo {attribute} debe ser menor o igual a {amount_inctax} (total con impuestos)",
        "global.error.entity_not_found": "El campo {attribute} no corresponde a ninguna entidad de tipo {entity} ({entity_code})",
        "global.error.is_invalid": "{attribute} no es válido",
        "global.error.limit_active_exceeded": "{1}Se ha superado el límite de {limit} {models} activos|{2}Se ha superado el límite de {limit} {models} activas",
        "global.error.line_must_match_model": "La línea debe coincidir con {model}",
        "global.error.missing_supplier_code": "{1}No se introduce el código del {model}|{2}No se introduce el código de la {model}",
        "global.error.model_duplicated": "{1}{model} duplicado|{2}{model} duplicada",
        "global.error.model_is_locked": "{1}El {model} está bloqueado|{2}La {model} está bloqueada",
        "global.error.must_be_closed": "{1}El {model} debe estar cerrado|{2}La {model} debe estar cerrada",
        "global.error.must_be_in": "El campo {attribute} debe estar en los valores : {array}",
        "global.error.must_be_null": "{1}No debe haber un {model}|{2}No debe haber una {model}",
        "global.error.must_not_be": "El campo {attribute} no debe ser {value}",
        "global.error.please_check_following": "Por favor verifique los siguientes errores:",
        "global.error.quantity.less_than_delivered": "La cantidad debe ser mayor o igual a {delivered} (cantidad ya recibida)",
        "global.error.quantity.more_than_delivered": "La cantidad debe ser menor o igual a {delivered} (cantidad ya recibida)",
        "global.error.require_json_format": "Requiere formato JSON",
        "global.error.select_at_least_one_model": "{1}Debe seleccionar al menos un {model}|{2}Debe seleccionar al menos una {model}",
        "global.error.status_must_be": "El estado debe ser \"{status}\" ",
        "global.error.value_already_exists": "El valor ya existe",
        "global.error.year_limit_exceeded": "Se ha superado el límite de {limit} {models} para este año",
        "global.example": "Ejemplo",
        "global.field": "Campo",
        "global.field.'+field+": "-",
        "global.field.'+ignored_field+": "-",
        "global.field.'+index+": "-",
        "global.field.abbr": "Abreviatura",
        "global.field.accounting_account_number": "Número de cuenta contable",
        "global.field.accounting_account_number.abbreviation": "Cuenta",
        "global.field.accounting_export_prefix": "Prefijo de número de pieza de Exportación Contable",
        "global.field.account_number": "Número de cuenta",
        "global.field.actions": "Acciones",
        "global.field.active": "Activo",
        "global.field.additional_email_text": "Texto adicional para el envío de correo electrónico",
        "global.field.additional_information": "Información adicional de {model}",
        "global.field.additional_model_texts": "Información {model}",
        "global.field.additional_purchase_order_text": "Texto mostrado en {model}",
        "global.field.additional_purchase_order_text.help": "{1}En el caso de que se seleccione un {model} vinculado|{2}En el caso de que se seleccione una {model} vinculada",
        "global.field.address": "Dirección",
        "global.field.alert_emails": "Direcciones de correo electrónico de alerta",
        "global.field.allocations": "Asignaciones",
        "global.field.already_delivered": "Ya recibido",
        "global.field.amount": "Importe",
        "global.field.amount.abbreviation": "Impt.",
        "global.field.amounts": "Importes",
        "global.field.amount_": "-",
        "global.field.amount_exctax": "Importe sin IVA",
        "global.field.amount_exctax.subtotal": "Importe sin IVA total parcial",
        "global.field.amount_exctax.total": "Total sin IVA",
        "global.field.amount_inctax": "Importe con IVA",
        "global.field.amount_inctax.subtotal": "Importe con IVA total parcial",
        "global.field.amount_inctax.total": "Total con IVA",
        "global.field.amount_taxes.total": "IVA total",
        "global.field.approval_image": "Imagen de aprobación",
        "global.field.approved_at": "{1}Aprobado en|{2}Aprobada en",
        "global.field.approved_by": "Aprobado por",
        "global.field.approved_date": "Fecha de aprobación",
        "global.field.asker": "Solicitante",
        "global.field.asker.plural": "Solicitantes",
        "global.field.attachments": "Adjuntos",
        "global.field.author": "Autor",
        "global.field.autogenerated_po_number": "{1}Número del {model} generado|{2}Número de la {model} generada",
        "global.field.auto_send": "Envío automático después de la validación",
        "global.field.auto_send_po": "Envío automático de Pedidos de Compra después de la validación",
        "global.field.available_quantity": "Cantidad disponible",
        "global.field.available_quantity.abbreviation": "Ctd. disponible",
        "global.field.balance": "Saldo",
        "global.field.bank_account_number": "Número de cuenta bancaria",
        "global.field.barcode": "Código de barras",
        "global.field.bcc": "BCC",
        "global.field.begin_date": "Fecha de inicio",
        "global.field.bic": "BIC",
        "global.field.billed_amount_exctax": "Importe facturado sin IVA",
        "global.field.billed_amount_exctax.total": "Total facturado sin IVA",
        "global.field.billed_amount_inctax": "Importe facturado con IVA",
        "global.field.billed_amount_inctax.total": "Total facturado con IVA",
        "global.field.billed_date": "Fecha de facturación",
        "global.field.billed_price_exctax": "Precio facturado sin IVA",
        "global.field.billed_price_inctax": "Precio facturado con IVA",
        "global.field.billed_quantity": "Cantidad facturada",
        "global.field.billed_vat_rate": "IVA facturado",
        "global.field.billing_address": "Dirección de facturación",
        "global.field.billing_status": "Facturación",
        "global.field.body": "Cuerpo",
        "global.field.budget": "Presupuesto",
        "global.field.budget.total": "Presupuesto total",
        "global.field.budgetKeys": "Claves de presupuesto",
        "global.field.budget_new": "Nuevo presupuesto",
        "global.field.budget_per_unit": "Presupuesto / U",
        "global.field.budget_summary": "Resumen del presupuesto",
        "global.field.buyer": "Comprador",
        "global.field.buyer.plural": "Compradores",
        "global.field.buyer_of": "Comprador de {model}",
        "global.field.buyer_role": "Rol del comprador",
        "global.field.calculated_amount": "Cantidad calculada",
        "global.field.cc": "CC",
        "global.field.charge_date": "Fecha de operación",
        "global.field.choose_pattern": "Elige un patrón",
        "global.field.city": "Ciudad",
        "global.field.code": "Código",
        "global.field.code.error.no_sharp_allowed": "El # no está autorizado (excepto delante del valor del identificador, aquí {id})",
        "global.field.code.plural": "Códigos",
        "global.field.comments_nb": "{nb} comentario|{nb} comentarios",
        "global.field.commitment_exctax": "Compromiso sin IVA",
        "global.field.commitment_inctax": "Compromiso con IVA",
        "global.field.company": "Compañía",
        "global.field.contains_headers": "El archivo contiene encabezados",
        "global.field.content": "Contenido",
        "global.field.country": "País",
        "global.field.created_at": "{1}Creado el|{2}Creada el",
        "global.field.created_by": "{1}Creado por|{2}Creada por",
        "global.field.created_date": "Fecha de creación",
        "global.field.creator": "Creador",
        "global.field.credit": "Crédito",
        "global.field.criteria": "Criterios",
        "global.field.criterion": "Criterio",
        "global.field.currency_rate": "Tasa",
        "global.field.customer": "Client",
        "global.field.customer_label": "Número de factura del cliente",
        "global.field.custom_frequency": "Periodicidad personalizada",
        "global.field.data": "Data",
        "global.field.dates": "Fechas",
        "global.field.deadline": "Entrega a más tardar el",
        "global.field.debit": "Débito",
        "global.field.default_account_number": "Número de cuenta por defecto",
        "global.field.default_field": "{field} predeterminado/a",
        "global.field.deleted": "{1}Eliminado|{2}Eliminada",
        "global.field.delivered_amount_exctax": "Importe recibido sin IVA",
        "global.field.delivered_amount_exctax.total": "Total recibido sin IVA",
        "global.field.delivered_amount_inctax": "Importe recibido con IVA",
        "global.field.delivered_amount_inctax.total": "Total recibido con IVA",
        "global.field.delivered_at": "Recibido en",
        "global.field.delivered_not_billed_amount": "Importe recibido pero no facturado",
        "global.field.delivered_quantity": "Cantidad recibida",
        "global.field.delivery_address": "Dirección de entrega",
        "global.field.delivery_date": "Fecha de entrega",
        "global.field.delivery_deadline": "Plazo de entrega",
        "global.field.delivery_delay": "Tiempos de entrega",
        "global.field.delivery_status": "Recepción",
        "global.field.description": "Descripción",
        "global.field.detail": "{1}Detalle|{2}Detalles",
        "global.field.disabled": "{1}Deshabilitado|{2}Deshabilitada",
        "global.field.disabled_date": "Fecha de desactivación",
        "global.field.discount": "Descuento",
        "global.field.dismissible": "Los usuarios pueden marcar \"{never_see_again}\" ",
        "global.field.display": "Visualización",
        "global.field.dispute_status": "Litigio",
        "global.field.documents_footer": "Pie de página PDF",
        "global.field.due_date": "Fecha de vencimiento",
        "global.field.duration_days": "Número de días",
        "global.field.editable_model": "{model} editable",
        "global.field.email": "Dirección de correo electrónico",
        "global.field.email.abbr": "Correo electrónico",
        "global.field.email.undefined": "Correo electrónico perdido",
        "global.field.emails.help": "Una dirección de correo electrónico por línea",
        "global.field.enabled": "{1}Habilitado|{2}Habilitada",
        "global.field.encoding": "Codificación",
        "global.field.end_date": "Fecha final",
        "global.field.entered_amount": "Cantidad ingresada",
        "global.field.errors": "Errores",
        "global.field.evaluated_date": "Fecha de evaluación",
        "global.field.exported_at": "{1}Exportado el|{2}Exportada el",
        "global.field.export_date": "Fecha de exportación",
        "global.field.export_status": "Estatus de la exportacion",
        "global.field.failed_at": "Error el",
        "global.field.file": "Documento",
        "global.field.files": "Documento(s)",
        "global.field.firstname": "Nombre",
        "global.field.first_submitted_at": "Fecha de primer envío",
        "global.field.forced_status": "Estado forzado a",
        "global.field.forced_status_date": "Fecha límite para el estado forzado",
        "global.field.for_all_tenants": "Para todos los Tenants",
        "global.field.frequency": "Frecuencia",
        "global.field.gap_exctax": "Brecha sin IVA",
        "global.field.gap_inctax": "Brecha con IVA",
        "global.field.gender": "Género",
        "global.field.handling_at": "{1}Apoyado el|{2}Apoyada el",
        "global.field.handling_date": "Handling date",
        "global.field.hidden": "Oculto",
        "global.field.holder_name": "Dueño de cuenta bancaria",
        "global.field.iban": "IBAN",
        "global.field.icon": "Icono",
        "global.field.id": "ID",
        "global.field.ids": "Lista de IDs",
        "global.field.image_field": "Imagen de vosotros computadora",
        "global.field.image_url": "URL de la imagen",
        "global.field.imported_at": "{1}Importado el|{2}Importada el",
        "global.field.import_type": "Tipo de importación",
        "global.field.incurred": "Incurridos",
        "global.field.initial_validated_budget": "Presupuesto inicial",
        "global.field.internal_reference": "Referencia interna",
        "global.field.ip_address": "Dirección IP",
        "global.field.isPrice": "Es un precio",
        "global.field.issuer": "Editor",
        "global.field.job": "Trabajo",
        "global.field.key": "Key",
        "global.field.label": "Fraseología",
        "global.field.lang": "Lengua",
        "global.field.lastname": "Name",
        "global.field.last_activity": "Última actividad",
        "global.field.last_login_date": "Última conexión",
        "global.field.last_validated_budget": "Último presupuesto validado",
        "global.field.last_validated_budget_version": "Última versión validada",
        "global.field.less_than_X_days": "{field} < {days} días",
        "global.field.level": "Nivel",
        "global.field.line": "línea",
        "global.field.lines": "Líneas",
        "global.field.line_id": "ID de línea",
        "global.field.link": "Enlace",
        "global.field.location": "Localización",
        "global.field.login": "Login",
        "global.field.logo": "Logotipo",
        "global.field.mark": "Nota",
        "global.field.max_amount": "Importe máximo",
        "global.field.max_amount_per_order": "Importe máxima sin IVA por {model}",
        "global.field.max_amount_per_year": "Importe máxima sin IVA por año",
        "global.field.meta": "Meta",
        "global.field.method": "Método",
        "global.field.min_amount": "Importe mínimo",
        "global.field.min_quantity_alert": "Umbral de reabastecimiento",
        "global.field.mobile": "Portable",
        "global.field.model": "Modelo",
        "global.field.model.plural": "Modelos",
        "global.field.model_type": "Tipo de {abbr}",
        "global.field.movement.cost": "Costo de movimiento",
        "global.field.multiple": "Multiple",
        "global.field.my_needs": "Mis {abbr}",
        "global.field.my_purchase_orders": "Mis Pedidos",
        "global.field.my_suppliers": "De los cuales soy el administrador",
        "global.field.name": "Nombre",
        "global.field.name_plural": "Nombre plural",
        "global.field.new_image": "Nueva imagen",
        "global.field.not_simplifiable": "No simplificable",
        "global.field.not_simplifiable.help": "El validador permanece en el circuito incluso si existen condiciones de simplificación",
        "global.field.number_of_periods": "número de períodos",
        "global.field.old_image": "Imagen antigua",
        "global.field.only_from_warehouse": "Solo {model} de este Almacén",
        "global.field.operation_label": "Redacción de la operación",
        "global.field.options": "Opciones",
        "global.field.options.key.error.different": "Esta clave ya existe",
        "global.field.ordered_at": "{1}Pedido el|{2}Pedida el",
        "global.field.ordered_date": "Fecha del Pedido",
        "global.field.ordered_quantity": "Cantidad ordenada",
        "global.field.original_price": "Precio bruto",
        "global.field.original_price_exctax": "Precio bruto sin impuestos",
        "global.field.original_price_inctax": "Precio bruto con impuestos",
        "global.field.other": "Otros",
        "global.field.paid_amount_exctax": "Monto con IVA pagado",
        "global.field.password": "Contraseña",
        "global.field.payment_date": "Fecha de pago",
        "global.field.payment_information": "Información del pago",
        "global.field.payment_source": "Fuente de pago",
        "global.field.payment_status": "Pago",
        "global.field.phone": "Teléfono",
        "global.field.phone.abbr": "Tel.",
        "global.field.phones": "Teléfonos",
        "global.field.piece_number": "Número de pieza",
        "global.field.position": "Posición",
        "global.field.possible_value": "Valor posible",
        "global.field.price": "Precio",
        "global.field.price_exctax": "Precio sin IVA",
        "global.field.price_exctax.new": "Nuevo precio sin IVA",
        "global.field.price_inctax": "Precio con IVA",
        "global.field.processing_status": "Procesamiento",
        "global.field.purchase_terms": "Condiciones de compra",
        "global.field.purveyors": "Abastecedores",
        "global.field.quantity": "Cantidad",
        "global.field.quantity.abbreviation": "Ctd.",
        "global.field.quantity.new": "Nueva cantidad",
        "global.field.quantity_step": "Múltiple de venta",
        "global.field.read_at": "{1}Leído el|{2}Leída el",
        "global.field.rebilled_amount_exctax": "Cantidad refacturada HT",
        "global.field.rebilled_amount_inctax": "Cantidad refacturada TTC",
        "global.field.rebilling_date": "Fecha de refacturación",
        "global.field.reference": "Referencia",
        "global.field.reference.abbreviation": "Ref.",
        "global.field.reference.plural": "Referencias",
        "global.field.reference.plural.help": "Una referencia por línea",
        "global.field.reference_or_label": "Referencia/Fraseología",
        "global.field.regional_buyers": "Compradores regionales",
        "global.field.remaining_amount_exctax_to_deliver": "Importe sin impuestos restante por recibir",
        "global.field.remaining_amount_inctax_to_deliver": "Importe con impuestos restante por recibir",
        "global.field.remaining_amount_to_bill": "Importe restante a facturar",
        "global.field.remaining_amount_to_pay.short": "Resto a pagar",
        "global.field.remaining_quantity_to_deliver": "Cantidad restante por recibir",
        "global.field.required": "Requerido",
        "global.field.reserved_at": "Reservado en",
        "global.field.reverse_account_number": "Número de cuenta inversa",
        "global.field.route_name": "Nombre de la carretera",
        "global.field.sent_at": "{1}Enviado en|{2}Enviada en",
        "global.field.short_label": "Etiqueta corta",
        "global.field.show_model_from": "Ver {model} desde",
        "global.field.show_parents_children": "Mostrar enlaces padre / hijo",
        "global.field.signature": "Firma",
        "global.field.siret": "SIRET",
        "global.field.siret.error.regex": "El número de SIRET debe contener 14 dígitos",
        "global.field.siret.info.exists": "Compañías que reportan este problema de Siret",
        "global.field.state": "Estado",
        "global.field.status": "Estatus",
        "global.field.stock": "Stock",
        "global.field.stock_type": "Tipo de movimiento",
        "global.field.subject": "Asunto",
        "global.field.submitted_at": "{1}Sumiso en|{2}Sumisa en",
        "global.field.submitted_date": "Fecha de presentación",
        "global.field.submitted_for": "Sumiso desde",
        "global.field.submit_order_at": "{1}{abbr} sumiso en|{2}{abbr} sumisa en",
        "global.field.supplier_file": "Documentos del Proveedor",
        "global.field.supported_amount": "Cantidad apoyada",
        "global.field.target": "Objetivo",
        "global.field.template": "Plantilla",
        "global.field.tenant": "Tenant",
        "global.field.text": "Text",
        "global.field.timezone": "zona horaria",
        "global.field.title": "Titulo",
        "global.field.to": "A",
        "global.field.total": "Total",
        "global.field.to_approve": "A validar",
        "global.field.to_be_approved_by": "Para ser aprobado por",
        "global.field.type": "Tipo",
        "global.field.unit": "Unidad",
        "global.field.unit_number_per_price": "Número de unidades por precio",
        "global.field.unit_price_exctax": "Precio unitario sin impuestos",
        "global.field.unit_price_inctax": "Precio unitario con impuestos",
        "global.field.url": "URL",
        "global.field.usable_in_validation_process": "Utilizable en Circuitos de Validación",
        "global.field.user_agent": "User agent",
        "global.field.validated_amount": "Importe validado",
        "global.field.validated_at": "{1}Validado el|{2}Validada el",
        "global.field.validation_date": "Fecha de validación",
        "global.field.validator": "validador",
        "global.field.validity_duration": "Periodo de validez",
        "global.field.value": "Valor",
        "global.field.variant": "Variante",
        "global.field.vat": "IVA",
        "global.field.vat_amount": "Importe del IVA",
        "global.field.vat_rate": "Tasa del IVA",
        "global.field.vat_type": "Tipo de IVA",
        "global.field.version": "Versión",
        "global.field.version.initial": "Versión inicial",
        "global.field.versions": "Versiones",
        "global.field.weighting": "Coeficiente de ponderación",
        "global.field.where_im_buyer": "Donde soy el comprador",
        "global.field.without_attachments": "sin archivos adjuntos",
        "global.field.without_po": "Crea sin {model}",
        "global.field.with_actions_for_me_to_do": "Con acciones para hacer en mi nombre",
        "global.field.with_attachments": "con archivos adjuntos",
        "global.field.with_validations_for_me_anytime": "Donde formo parte del circuito de validación",
        "global.field.with_validations_for_me_to_do": "Esperando mi validación",
        "global.field.year": "Año",
        "global.field.your_email": "Su dirección de correo electrónico",
        "global.file.required": "Se requiere archivo",
        "global.filesize.B": "B",
        "global.filesize.EB": "EB",
        "global.filesize.GB": "GB",
        "global.filesize.KB": "KB",
        "global.filesize.MB": "MB",
        "global.filesize.PB": "PB",
        "global.filesize.TB": "TB",
        "global.filesize.YB": "YB",
        "global.filesize.ZB": "ZB",
        "global.font_awesome.link.label": "Encontrar un icono",
        "global.form.title": "Formulario",
        "global.gender.female": "Femenino",
        "global.gender.male": "Masculino",
        "global.gender.neutral": "Neutral",
        "global.generate.password": "Genera una nueva contraseña",
        "global.import.boolean.comment": "1 para sí, 0 para no",
        "global.import.error": "{rowsError}/{rowsCount} fila no se importó porque : | {rowsError}/{rowsCount} filas no se importaron porque :",
        "global.import.fakeBic": "Línea {line} : El BIC '{fakeBic}' no es válido y el {model} no se pudo crear.",
        "global.import.fakeIban": "Línea {line} : El IBAN '{fakeIban}' no es válido y el {model} no se pudo crear.",
        "global.import.help": "Ayuda a leer para importar",
        "global.import.id.comment": "Dejar en blanco para una creación",
        "global.import.matches.help": "Debe coincidir {model} existente",
        "global.import.missingHeaders": "Faltan la(s) siguiente(s) columna(s), compruebe sus encabezados.",
        "global.import.missingHeaders.any": "Faltan todas las columnas, verifique sus encabezados tanto como codificación de archivo.",
        "global.import.notFound": "no encontrado",
        "global.import.not_draft": "Línea {line}: El {model} de ID {id} no está en borrador y por lo tanto no se pudo modificar.",
        "global.import.problem": " Sin embargo, hemos detectado los siguientes problemas :",
        "global.import.required": "Línea {line}: Falta el campo '{undefinedValue}'.",
        "global.import.requiredLines": "Línea {line} : Campo {requiredLinesString} es obligatorio. | Línea {line} : Campos {requiredLinesString} son obligatorios",
        "global.import.success": "{rowsSuccess}/{rowsCount} fila se importó/actualizada correctamente. |{rowsSuccess}/{rowsCount} filas se importaron/actualizadas correctamente.",
        "global.import.success.detail": "{rowsSuccess}/{rowsCount} filas se han importado correctamente ({createdCount} creadas, {updatedCount} actualizadas).",
        "global.import.undefined": "Línea {line}: El campo '{undefinedKey}' => '{undefinedValue}' no se reconoce.",
        "global.import.undefinedSupplierCode": "Línea {line} : el código del proveedor {undefinedSupplierString} es desconocido y no se pudo crear el {model}.",
        "global.import.unique": "Línea {line}: El campo '{undefinedKey}' => '{undefinedValue}' ya se usa, mientras que debería ser único.",
        "global.index.no_result": "No hay resultados",
        "global.info.abbr_signification": "\"{abbr}\" para \"{for}\" ",
        "global.info.action_perimeter": "Perímetro de actuación",
        "global.info.activated.plural": "Activado",
        "global.info.active_out_of_max": "{count} de {max} max.",
        "global.info.added_manually_by": "Agregado manualmente por",
        "global.info.article_choice_when_no_supplier": "{1}Al elegir un artículo vinculado a un {supplier_model}, el {supplier_model} se aplicará al {model}|{2}Al elegir un artículo vinculado a un {supplier_model}, el {supplier_model} se aplicará a la {model}",
        "global.info.attached_models": "Modelos adjuntos",
        "global.info.auto_send": "Envío automático después de la validación",
        "global.info.back_to_pending": "El artículo se pondrá de nuevo en la cola de procesamiento.",
        "global.info.cantSend": "No puedes enviar por el momento",
        "global.info.cant_modify_because_created_without_model": "{1}No se puede modificar porque este {model} se creó sin {without_model}|{2}No se puede modificar porque esta {model} se creó sin {without_model}",
        "global.info.cant_modify_because_model": "{1}No se puede cambiar porque hay {model} seleccionado|{2}No se puede cambiar porque hay {model} seleccionada",
        "global.info.caution": "Advertencia",
        "global.info.children_links": "Conexiones con niños",
        "global.info.classification": "Clasificación",
        "global.info.closed": "{1}Cerrado|{2}Cerrada",
        "global.info.computed": "{1}Calculado|{2}Calculada",
        "global.info.confirmation_request": "Por favor, confirme esta acción",
        "global.info.copied": "Copiado",
        "global.info.create_with_draft": "Tienes borradores de {model}",
        "global.info.creation_count": "{count} creación|{count} creaciones",
        "global.info.creation_disabled_count": "{count} creación (no activado)|{count} creaciones (no activados)",
        "global.info.creation_in_progress": "Creación en progreso",
        "global.info.current_value": "Valor actual : {value}",
        "global.info.definitive_action": "Esta acción es definitiva.",
        "global.info.deviation": "+ {deviation}% diferencia",
        "global.info.difference": "Diferencia : {value}",
        "global.info.displayed_exctax": "Precios mostrados sin IVA",
        "global.info.displayed_inctax": "Precios mostrados con IVA",
        "global.info.dispute": "Litigio",
        "global.info.dispute.plural": "Litigios",
        "global.info.dispute_closing": "Cierre de litigio",
        "global.info.empty": "vacío",
        "global.info.everything_selected_by_default": "Todo está seleccionado por defecto",
        "global.info.favorite": "Favorito",
        "global.info.features": "Características",
        "global.info.fields_not_updatable_after_creation": "Los campos marcados con este ícono no serán editables después de la creación :",
        "global.info.field_auto_filled": "Este campo se completa automáticamente",
        "global.info.field_auto_filled_if_empty": "Este campo se completa automáticamente si se deja vacío",
        "global.info.field_translatable": "Este campo es traducible",
        "global.info.forced_status": "Estado forzado a {status}",
        "global.info.forced_status_until": "Estado forzado a {status} hasta {date}",
        "global.info.from_overrun": "Generado a partir de {model}",
        "global.info.help": "Ayuda",
        "global.info.in": "En",
        "global.info.infos": "Informaciones",
        "global.info.infos.abbreviation": "Info",
        "global.info.initialization": "Inicialización",
        "global.info.invited_by": "Invitado/a por {label}",
        "global.info.in_models": "En {model_plural}",
        "global.info.in_this_model": "{1}En este {model}|{2}En esta {model}",
        "global.info.items_excluded_number": "Elementos excluidos : {number}",
        "global.info.items_number": "Elementos seleccionados : {number}",
        "global.info.last_update": "Última modificación",
        "global.info.limited_to_lines": "Limitado a {limit} líneas",
        "global.info.lines_count": "Número de líneas",
        "global.info.loading": "Cargando...",
        "global.info.misc": "Diverso",
        "global.info.model_already_present": "{model} ya presente",
        "global.info.new": "Nuevo",
        "global.info.new_values": "Nuevos valores",
        "global.info.nothing_excluded_by_default": "Nada está excluido por defecto",
        "global.info.notifications": "Envíe una notificación a las siguientes direcciones de correo electrónico:",
        "global.info.not_closed": "{1}No cerrado|{2}No cerrada",
        "global.info.not_existing": "No existente",
        "global.info.not_present": "No presente",
        "global.info.not_proposed": "No disponible",
        "global.info.not_referenced": "No referenciado",
        "global.info.not_simplifiable": "No simplificable",
        "global.info.not_specified": "No especificado",
        "global.info.not_updatable_after_creation": "No editable después de la creación",
        "global.info.no_action": "Sin acción",
        "global.info.no_delivery_address": "No se ingresó ninguna dirección",
        "global.info.no_item_selected": "Ningún elemento seleccionado",
        "global.info.no_line": "No hay línea",
        "global.info.no_list_item": "No se encontraron elementos",
        "global.info.no_mail_will_be_sent": "No se enviarán correos electrónicos.",
        "global.info.no_problem_detected": "Ningún problema detectado",
        "global.info.no_replacement": "No se puede hacer ningún reemplazo",
        "global.info.optional": "Opcional",
        "global.info.options_selected": "{count} opcion seleccionada|{count} opciones seleccionadas",
        "global.info.original_values": "Valores originales",
        "global.info.out_of_max": "{count} de {max} máx.",
        "global.info.overriden_email_to": "Se ingresa el destinatario global de TODOS los correos electrónicos",
        "global.info.parents_links": "Conexiones con parientes",
        "global.info.pointed": "{1}Señalado|{2}Señalada",
        "global.info.read": "Leído",
        "global.info.real": "Real",
        "global.info.required": "Requerido",
        "global.info.required_fields": "Se requiere los campos : {fields}",
        "global.info.required_if": "Requerido si  {condition}",
        "global.info.requires_deposit": "Requiere un depósito",
        "global.info.save": "Guarda",
        "global.info.save_model_before_add": "{1}Guarde este {model} primero|{2}Guarde esta {model} primero",
        "global.info.selectable": "Seleccionable",
        "global.info.selected": "Seleccionado",
        "global.info.select_displayed_items": "Seleccione los elementos que desea mostrar",
        "global.info.show_only_children": "Mostrar solo niños seleccionados",
        "global.info.show_only_parents": "Mostrar solo los padres seleccionados",
        "global.info.skipped_by": "Omitido por",
        "global.info.still_be_created_but_not_activated": "Todavía puedes crear pero no activar",
        "global.info.suggestedBy": "Valor sugerido por {user}",
        "global.info.suggested_by": "Valor sugerido por {user}",
        "global.info.suggestions": "Sugerencias",
        "global.info.taxes_excluded": "sin IVA",
        "global.info.taxes_included": "con IVA",
        "global.info.this_model": "{1}Este {model}|{2}Esta {model}",
        "global.info.this_request": "Esta solicitud",
        "global.info.to_copy": "Copiar",
        "global.info.to_copy_to": "Copiar a",
        "global.info.to_replace": "Para reemplazar",
        "global.info.to_replace_to": "Reemplazar con",
        "global.info.unavailable.plural": "No disponible",
        "global.info.undefined_weight": "Peso no especificado",
        "global.info.unique": "Unico",
        "global.info.update_count": "{count} actualización|{count} actualizaciones",
        "global.info.users_to_notify": "También puedes notificar a un usuario específico:",
        "global.info.user_interface": "Interfaz de usuario",
        "global.info.validation_process_reset": "El circuito de validación se restablecerá si continúa.",
        "global.info.value_from_config": "Valor de Config : {name} => {value}",
        "global.info.value_from_dotenv": "Valor del .env : {name} => {value}",
        "global.info.value_from_settings": "Valor en Settings : {name} => {value}",
        "global.info.warning": "Advertencia",
        "global.info.with": "Con {text}",
        "global.maintenance.activated": "El modo \"Mantenimiento\" está activado para este cliente",
        "global.misc.by": "por",
        "global.misc.for": "para",
        "global.misc.from_model": "{1}desde el {model}|{2}desde la {model}",
        "global.misc.on": "el",
        "global.month.april": "abril",
        "global.month.august": "agosto",
        "global.month.december": "diciembre",
        "global.month.february": "febrero",
        "global.month.january": "January",
        "global.month.july": "julio",
        "global.month.june": "junio",
        "global.month.march": "Marte",
        "global.month.may": "Mayo",
        "global.month.november": "November",
        "global.month.october": "octubre",
        "global.month.september": "September",
        "global.notification.model_sent_to_supplier": "Al enviar {model_plural} a los Proveedores",
        "global.notification.model_submitted": "A la sumisión de {model_plural}",
        "global.notification.model_validated": "Al validar {model_plural}",
        "global.obligatory": "Obligatorio",
        "global.pdf.order_cancel_and_replace_previous": "Este Pedido cancela y reemplaza el Pedido anterior",
        "global.period.from": "of",
        "global.period.to": "au",
        "global.question.continue": "¿Quieres continuar?",
        "global.status.acknowledged_receipt": "{1}Recibido por el proveedor|{2}Recibida por el proveedor",
        "global.status.active": "{1}Activo|{2}Activa",
        "global.status.approved": "{1}Aprobado|{2}Aprobada",
        "global.status.autogenerated": "{1}Autogenerado|{2}Autogenerada",
        "global.status.autovalidated": "{1}Auto-validado|{2}Auto-validada",
        "global.status.auto_force_status_paid": "Estado {status} automáticamente",
        "global.status.awaiting": "A la espera",
        "global.status.awaiting.delivery": "Esperando recepción",
        "global.status.billed": "{1}Facturado|{2}Facturada",
        "global.status.canceled": "{1}Cancelado|{2}Cancelada",
        "global.status.closed": "{1}Terminado|{2}Terminada",
        "global.status.delivered_not_billed": "entregado no facturado",
        "global.status.draft": "Borrador",
        "global.status.error": "Error",
        "global.status.expired": "{1}Caducado|{2}Caducada",
        "global.status.exported": "{1}Exportado|{2}Exportada",
        "global.status.forbidden": "{1}Prohibido|{2}Prohibida",
        "global.status.imported": "{1}Importado|{2}Importada",
        "global.status.inactive": "{1}No activado|{2}No activada",
        "global.status.locked": "{1}Bloqueado|{2}Bloqueada",
        "global.status.none": "Ningún",
        "global.status.not_acknowledged_receipt": "{1}No recibido por el proveedor|{2}No recibida por el proveedor",
        "global.status.not_exported": "{1}No exportado|{2}No exportada",
        "global.status.not_launched": "{1}No lanzado|{2}No lanzada",
        "global.status.not_ongoing": "No en curso",
        "global.status.not_sent": "{1}No enviado|{2}No enviada",
        "global.status.obsolete": "{1}Obsoleto|{2}Obsoleta",
        "global.status.ongoing": "En curso",
        "global.status.paid": "{1}Pagado|{2}Pagada",
        "global.status.partial": "Parcial",
        "global.status.pending": "A procesar",
        "global.status.planified": "{1}Planificado|{2}Planificada",
        "global.status.processing": "En curso",
        "global.status.prospect": "Prospecto",
        "global.status.refused": "{1}Rechazado|{2}Rechazada",
        "global.status.rejected": "{1}Rechazado|{2}Rechazada",
        "global.status.sending": "Enviando en progreso",
        "global.status.sent": "{1}Enviado|{2}Enviada",
        "global.status.skipped": "{1}Omitido|{2}Omitida",
        "global.status.suspended": "{1}Suspendido|{2}Suspendida",
        "global.status.unvalidated": "{1}Invalidado|{2}Invalidada",
        "global.status.validated": "{1}Validado|{2}Validada",
        "global.title.confirmation_request": "Solicitud de confirmación",
        "global.title.endorsement": "Aveniente {model}",
        "global.title.form": "Formulario",
        "global.title.model_of_2nd_model": "{1}{model} del {2nd_model}|{2}{model} de la {2nd_model}",
        "global.title.relationships": "Relaciones",
        "global.type.boolean": "Boolean",
        "global.type.choice": "Choice",
        "global.type.date": "Fecha",
        "global.type.email": "Correo electrónico",
        "global.type.iso": "Código ISO de 3 caracteres",
        "global.type.number": "Número",
        "global.type.string": "Cadena de caracteres",
        "global.type.string.limit": "{number} caracteres",
        "global.type.stringList": "Lista de cadenas",
        "global.type.stringList.help": "Lista separada por comas",
        "global.type.text": "Texto",
        "global.type.textarea": "Texto largo",
        "global.value.all": "Todos",
        "global.value.and": "y",
        "global.value.automatic": "Automático",
        "global.value.auto_added": "Agregado automáticamente",
        "global.value.by_default": "Default",
        "global.value.capital": "Mayúscula",
        "global.value.disabled": "Minusválido",
        "global.value.enabled": "Habilitado",
        "global.value.everyone": "Todo el mundo",
        "global.value.everything": "Todo",
        "global.value.false": "Falso",
        "global.value.global": "General",
        "global.value.has_handled": "hizo cargo",
        "global.value.inbox": "Inbox",
        "global.value.lowercase": "Minúscula",
        "global.value.main": "Principal",
        "global.value.monthly": "Mensual",
        "global.value.no": "No",
        "global.value.nobody": "Nadie",
        "global.value.none": "Ninguno",
        "global.value.noParent": "No parent",
        "global.value.nothing": "Nada",
        "global.value.not_approved": "{1}No aprobado|{2}No aprobada",
        "global.value.not_exported": "{1}No exportado|{2}No exportada",
        "global.value.not_found": "{1}No encontrado|{2}No encontrada",
        "global.value.not_handling": "{1}No apoyado|{2}No apoyada",
        "global.value.not_ordered": "{1}No pedido|{2}No pedida",
        "global.value.not_sent": "{1}No enviado|{2}No enviada",
        "global.value.not_submitted": "{1}No sumiso|{2}No sumisa",
        "global.value.not_submitted_order": "{1}{abbr} no sumiso|{2}{abbr} no sumisa",
        "global.value.no_duration": "Sin duración",
        "global.value.null": "#vacío",
        "global.value.number": "Cifra",
        "global.value.or": "o",
        "global.value.other": "Otro",
        "global.value.reason": "Razón",
        "global.value.select": "Seleccionar",
        "global.value.special_char": "Carácter especial",
        "global.value.true": "Verdadero",
        "global.value.unique": "solo un valor posible",
        "global.value.unit.abbreviation": "U.",
        "global.value.unknown": "{1}Desconocido|{2}Desconocida",
        "global.value.unlimited": "Ilimitado",
        "global.value.upload": "Upload",
        "global.value.yearly": "Anual",
        "global.value.yes": "Sí",
        "header.info.current_tenant_vs_all_tenants": "Cliente actual / Todos los clientes",
        "help.file.extensions": "Extensiones permitidas : {extensions}",
        "help.file.max_size": "Anchura máxima : {max_width} px, altura máxima : {max_height} px",
        "help.file.max_weight": "Peso máximo : {max_weight}",
        "help.image.resize_link": "Herramienta externa para cambiar el tamaño de varias imágenes",
        "help.two_draggable_lists.how_to": "Arrastre y suelte los elementos de la columna \"{col1}\" a la columna \"{col2}\" en el orden deseado.",
        "home.index": "Inicio",
        "image.model": "Imagen",
        "importer.disclaimer": "Atención, al validar este formulario, se arriesga a sobrescribir los datos de manera irreversible. Asegúrese de informarlos correctamente.",
        "importer.disclaimer.msExcel": "Guarde su archivo como 'CSV (separado por punto y coma) (*.csv)'.",
        "importer.field.file.error.badHeading": "El archivo importado contiene un nombre de columna no válido : {column}",
        "importer.field.file.error.missingColumns": "Al archivo importado le faltan columnas obligatorias : {columns}",
        "importer.field.file.help": "El nombre de cada columna debe coincidir con el carácter por el nombre de una columna en la tabla seleccionada. No todas las columnas son necesarias. Una celda vacía no no se tendrá en cuenta, una celda que contenga la cadena \"#null\" vaciará el campo asociado (si es posible).",
        "importer.field.model": "Objeto",
        "importer.field.table": "Tabla",
        "importer.importModel": "Importar objeto",
        "importer.importTable": "Importar tabla",
        "incoterm.model": "Incoterm",
        "incoterm.model.plural": "Incoterms",
        "knowledge_base.title": "Base de conocimiento",
        "language.field.active": "Activo",
        "language.field.code": "Código idioma (ISO 639-1)",
        "language.field.country_code": "Código país (ISO 3166-2)",
        "language.field.currency_position": "Posición de la moneda",
        "language.field.date_format": "Formato de fecha",
        "language.field.hour_format": "Formato de hora",
        "language.model": "Idioma",
        "language.model.gender": "1",
        "language.model.plural": "Idiomas",
        "line.model": "Línea",
        "line.model.gender": "2",
        "line.model.plural": "Líneas",
        "mail.actions_to_do.intro": "Tienes acciones que realizar.",
        "mail.actions_to_do_for_delegate.intro": "Tienes acciones que realizar como delegado.",
        "mail.hello": "Hola,",
        "mail.overriden_to": "Este mensaje ha sido redirigido, aquí está el destinatario original:|Este mensaje ha sido redirigido, aquí están los destinatarios originales:",
        "mail.regards": "Saludos",
        "mail.reset_password.action": "Restablecer la contraseña",
        "mail.reset_password.intro": "Está recibiendo este correo electrónico porque recibimos una solicitud de restablecimiento de contraseña para su cuenta.",
        "mail.reset_password.outro1": "Este enlace para restablecer contraseña caducará en {count} minutos.",
        "mail.reset_password.outro2": "Si no solicitó un restablecimiento de contraseña, no es necesario realizar ninguna otra acción.",
        "mail.reset_password.subject": "Restablecimiento de contraseña",
        "mail.test.content": "Este es un mensaje de prueba.<br>Reenvíelo a : {support_email}",
        "mail.trouble_on_link": "Si tiene problemas para hacer clic en el botón \"{action_text}\", copie y pegue la siguiente URL en su navegador web:",
        "mail.whoops": "¡Ups!",
        "mailTemplate.field.available_variables": "Variables disponibles",
        "mailTemplate.override": "Sobrecarga de la plantilla de correo",
        "mail_template.model": "Plantilla de correo",
        "mail_template.model.plural": "Plantillas de correo",
        "maintenance.action.create_demo": "Crear una demostración",
        "maintenance.copyDBToDebug": "Copie la base de datos en la instancia de prueba",
        "maintenance.dashboard": "Mantenimiento",
        "maintenance.delegation.cronUpdateDelegations": "{model}: desactivar cuando se supere la fecha",
        "maintenance.error.noDeletePOs": "no eliminado",
        "maintenance.field.disable_reason": "Motivo de la desactivación",
        "maintenance.fixAfterV1Migration": "Parche siguiendo la migración de Boomerang Web v1 -> v2",
        "maintenance.form.crons": "Lanzamiento de crones",
        "maintenance.form.deletePOs": "Eliminar Pedidos de Compra",
        "maintenance.form.demo": "Creación de instancias de demostración",
        "maintenance.modal.confirmDeletePOs": "¿Desea eliminar todos los Pedidos de Compra",
        "maintenance.realtimeInfos": "Info en tiempo real",
        "maintenance.refreshClientStatistic": "Actualizar datos estadísticos",
        "maintenance.reset_demo_data": "Restablecer datos DEMO",
        "maintenance.reset_demo_data.warning": "Los datos actuales se sobrescribirán a favor de los nuevos datos de demostración, según la configuración actual.",
        "maintenance.sql_query.title": "Solicitud SQL",
        "maintenance.supplier.cronUpdateFamiliesStatuses": "{model}: actualizar los estados de {product_families}",
        "maintenance.supplier.cronUpdateForcedStatuses": "{model} : eliminar estados forzados cuando se excede la fecha",
        "maintenance.supplier.cronUpdateStatistics": "{model} : actualizar las estadísticas de los {model} afectados ({count} elementos, {max} máx. a la vez)",
        "maintenance.supplier.cronUpdateStatuses": "{model}: actualizar los estados",
        "maintenance.supplier_contract.check_status": "{model}: desactivar cuando se supere la fecha",
        "maintenance.supplier_file.cronUpdateStatuses": "{model}: desactivar cuando se supere la fecha",
        "maintenance.title.data_accuracy": "Verificar la exactitud de los datos",
        "maintenance.title.disable_tenant": "Desactivación de clientes",
        "maintenance.title.tests": "Pruebas",
        "maintenance.trace.cronDeleteOldTraces": "{model}: eliminar cuando la fecha sea demasiado antigua",
        "message.field.texts.help": "Puede utilizar los siguientes tokens:<br>{firstname}: Nombre del usuario que ha iniciado sesión<br>{lastname}: Apellido del usuario que ha iniciado sesión<br>{user_label}: Nombre + Apellido del que ha iniciado sesión en usuario<br>{email}: Dirección de correo electrónico del usuario que ha iniciado sesión<br >{company}: Nombre del cliente<br>",
        "message.info.tokens_may_not_work": "Es posible que algunos tokens no funcionen en páginas sin que un Usuario haya iniciado sesión",
        "message.model": "Mensaje",
        "message.model.plural": "Mensajes",
        "metrics.po": "Pedidos de Compra creadas desde la última renovación del contrato",
        "metrics.projection": "Proyección de Pedidos de Compra creadas hasta la próxima renovación del contrato",
        "metrics.storage": "Almacenamiento utilizado",
        "metrics.user": "Usuarios creados",
        "model.action.manage": "Administrar {model}",
        "model.all": "{1}todos los {model}|{2}todas las {model}",
        "model.as_buyer": "{1}{model} de los que soy Comprador|{2}{model} de las que soy Comprador",
        "model.back_to_draft.existing_payment_lines": "{1}Además, el {model} ya ha sido pagado, pero no se cancelará ningún pago.|{2}Además, la {model} ya ha sido pagada, pero no se cancelará ningún pago.",
        "model.cancel.cancel_version": "{1}Además, el {model} es una versión del {model} original. Marque la siguiente casilla para confirmar la restauración de la versión anterior de este {model} :|{2}Además, la {model} es una versión de la {model} original. Marque la siguiente casilla para confirmar la restauración de la versión anterior de esta {model} :",
        "model.cancel.existing_payment_lines": "{1}Además, el {model} ya ha sido pagado, pero no se cancelará ningún pago.|{2}Además, la {model} ya ha sido pagada, pero no se cancelará ningún pago.",
        "model.create": "Creación",
        "model.delete": "Eliminar",
        "model.disable": "Desactivar",
        "model.error.can_not_find": "{model} no encontrado",
        "model.favorite": "{1}{model} favorito|{2}{model} favorita",
        "model.info.amount_zero": "{1}El {model} tiene una cantidad igual a 0. ¿Quieres continuar?|{2}La {model} tiene una cantidad igual a 0. ¿Quieres continuar?",
        "model.info.cancel": "{1}El {model} se cancelará si continúa.|{2}La {model} se cancelará si continúa.",
        "model.info.cancel_and_replace": "{1}El {model} se cancelará si continúa, y se creará un nuevo borrador para reemplazarlo.|{2}La {model} se cancelará si continúa, y se creará un nuevo borrador para reemplazarla.",
        "model.info.cancel_and_replace_of": "Deshacer y reemplazar \"{replaced}\" ",
        "model.info.cant_be_deleted": "{1}El {model} no se puede eliminar (se usa en varios elementos) pero se puede desactivar.|{2}La {model} no se puede eliminar (se usa en varios elementos) pero se puede desactivar.",
        "model.info.close": "{1}El {model} se cerrará y no podrá modificarse si continúa.|{2}La {model} se cerrará y no podrá modificarse si continúa.",
        "model.info.has_articles_to_disable": "{1}El {model} tiene {article_models}, estos estarán desactivados.|{2}La {model} tiene {article_models}, estos estarán desactivados.",
        "model.info.item_cant_be_deleted": "Por lo tanto, este elemento no se puede eliminar.",
        "model.info.main": "{model} principal",
        "model.info.mark_as_refused": "{1}Deberás crear un nuevo {model} una vez que se rechace.|{2}Tendrás que crear una nueva {model} una vez que se rechace.",
        "model.info.new_version": "{1}El {model} se bloqueará y se creará una nueva versión en borrador si continúa.|{2}La {model} se bloqueará y se creará una nueva versión en borrador si continúa.",
        "model.info.none_available": "{1}Ninguno {model} disponible|{2}Ninguna: modelo disponible",
        "model.info.pdf": "{1}PDF del {model}|{2}PDF de la {model}",
        "model.info.unclose": "{1}El {model} se decerrará y podrá modificarse nuevamente si continúa.|{2}La {model} se decerrará y podrá modificarse nuevamente si continúa.",
        "model.info.unkown": "{1}{model} desconocido|{2}{model} desconocida",
        "model.other": "{1}Otro {model}|{2}Otra {model}",
        "model.own": "{1}{model} de los que soy Creador o Destinatario|{2}{model} de las que soy Creador o Destinatario",
        "model.title.configure": "Configurar : {model}",
        "model.title.create": "Creación : {model}",
        "model.title.import": "Importar : {model}",
        "model.title.update": "Modificación : {model}",
        "model.to_replace": "{model} a reemplazar",
        "model.update": "Modificación",
        "model_line.model": "Línea de {model}",
        "modification.model": "Cambio",
        "modification.model.gender": "1",
        "module.mirroring_database": "Mirroring Database",
        "module.multi_company": "Multiempresa",
        "module.multi_currency": "Monedas múltiples",
        "module.not_enabled": "Módulo no activo",
        "module.supplier.advanced": "Gestión avanzada",
        "module.supplier.login": "Acceso",
        "module.supplier_invoice.ocr": "OCR",
        "need.model": "Solicitud de compra",
        "need.model.plural": "Solicitudes de compra",
        "need.status.submitted_purchase_order": "{1}{abbr} sumiso|{2}{abbr} sumisa",
        "need.status.validated_purchase_order": "{1}{abbr} validado|{2}{abbr} validada",
        "need.title.choose_model_to_deliver": "{1}Elija un {model} para recibir|{2}Elija una {model} para recibir",
        "notification.label.completed_export": "Exportar (archivo adjunto)",
        "notification.label.delivery.sending": "{1}Envío de un {model}|{2}Envío de una {model}",
        "notification.label.failed_export": "Exportación fallida",
        "notification.label.model_expiration_alert": "Caducidad de {model}",
        "notification.label.model_expiration_alert_before": "Advertencia antes de la caducidad de {model}",
        "notification.label.need.awaiting": "{1}Se ha emitido un {model}|{2}Se ha emitida un {model}",
        "notification.label.need.po_submitted": "{1}Se ha enviado el {po_model} de un {model}|{2}Se ha enviado el {po_model} de una {model}",
        "notification.label.need.po_validated": "{1}El {po_model} de un {model} ha sido validado|{2}El {po_model} de una {model} ha sido validado",
        "notification.label.need.processing": "{1}Se ha admitido un {model}|{2}Se ha admitida una {model}",
        "notification.label.need.suspended": "{1}Un {model} ha sido suspendido|{2}Una {model} ha sida suspendido",
        "notification.label.need.suspension_stopped": "{1}Un {model} ya no está suspendido|{2}Una {model} ya no está suspendida",
        "notification.label.need.transfered": "{1}Se ha transferido un {model}|{2}Se ha transferida una {model}",
        "notification.label.new_comment": "{1}Se envió un comentario en un {model}|{2}Se envió un comentario en una {model}",
        "notification.label.payment.line_paid": "{1}Se ha indicado un {model} como pagado (solo transferencia)|{2}Se ha indicado una {model} como pagada (solo transferencia)",
        "notification.label.purchase_order.sending": "{1}Envío de un {model}|{2}Envío de una {model}",
        "notification.label.purchase_order.validated_with_deposit": "{1}{model} validado que requiere un depósito|{2}{model} validada que requiere un depósito",
        "notification.label.quotation_sending": "Envío de una Solicitud de precio",
        "notification.label.rel_supplier_product_family.awaiting": "Se debe validar una familia de proveedores",
        "notification.label.rel_supplier_product_family.expiration_alert": "Caducidad de familias de proveedores",
        "notification.label.rel_supplier_product_family.expiration_alert_before": "Advertencia antes de que expiren las familias de proveedores",
        "notification.label.rel_supplier_product_family.refused": "Se rechazó una familia de proveedores",
        "notification.label.rel_supplier_product_family.unactivation_by_file_expiration_alert": "Caducidad de familias de proveedores",
        "notification.label.rel_supplier_product_family.validated": "Se ha validado una familia de proveedores",
        "notification.label.rel_supplier_product_family_unactivation_by_file_expiration_alert": "{1}Caducidad de los {model} (falta de {file_model})|{2}Caducidad de las {model} (falta de {file_model})",
        "notification.label.suggested_modifications": "Las modificaciones necesitan ser validadas",
        "notification.label.supplier.after_validation": "Se ha validado un Proveedor - enviando el email según configuración",
        "notification.label.supplier.contact_invitation": "Invitación de un contacto de proveedor",
        "notification.label.supplier.data_creation": "{1}Crear un {model} sin ser el administrador de cuentas|{2}Crear una {model} sin ser el administrador de cuentas",
        "notification.label.supplier.file_creation": "Creación de un documento de proveedor sin ser el administrador de cuentas",
        "notification.label.supplier.file_expiration_alert": "Vencimiento de los documentos del proveedor",
        "notification.label.supplier.file_expiration_alert_before": "Advertencia antes del vencimiento de los documentos del proveedor",
        "notification.label.supplier.login_request": "Un Proveedor solicita conectarse",
        "notification.label.supplier_contract.expiration_alert": "Vencimiento de {models}",
        "notification.label.supplier_contract.expiration_alert_before": "Advertencia antes del vencimiento de {models}",
        "notification.label.supplier_invoice.notification_when_no_delivery": "Solicitud de recibo de una factura de proveedor",
        "notification.label.user.invitation": "Invitar a un usuario",
        "notification.label.validation_awaiting": "{1}Un {model} debe ser validado|{2}Una {model} debe ser validada",
        "notification.label.validation_refused": "{1}Un {model} ha sido rechazado|{2}Una {model} ha sido rechazada",
        "notification.label.validation_submitted": "{1}Se ha enviado un {model} para validación|{2}Se ha enviado una {model} para validación",
        "notification.label.validation_validated": "{1}Un {model} ha sido validado|{2}Una {model} ha sido validada",
        "notification.markAllAsRead": "Marcar todo como leido",
        "notification.model": "Notificación",
        "notification.model.gender": "2",
        "notification.model.plural": "Notificaciones",
        "notification.subject.actions_to_do": "Resumen de acciones a realizar",
        "notification.subject.actions_to_do_for_delegate": "Resumen de acciones a realizar como delegado",
        "notification.subject.bank_account.validation_awaiting": "{1}Un {model} por el Proveedor \"{supplier}\" debe ser validado y necesita su atención|{1}Una {model} por el Proveedor \"{supplier}\" debe ser validada y necesita su atención",
        "notification.subject.bank_account.validation_refused": "{1}Un {model} por el Proveedor \"{supplier}\" acaba de ser denegado|{1}Una {model} por el Proveedor \"{supplier}\" acaba de ser denegada",
        "notification.subject.bank_account.validation_submitted": "{1}El {model} \"{label}\" se ha enviado para validación|{2}La {model} \"{label}\" se ha enviada para validación",
        "notification.subject.bank_account.validation_validated": "{1}Un {model} por el Proveedor \"{supplier}\" acaba de ser validado|{2}Una {model} por el Proveedor \"{supplier}\" acaba de ser validada",
        "notification.subject.completed_export": "Su exportación está lista",
        "notification.subject.delivery.sending": "{model} \"{delivery}\" ",
        "notification.subject.failed_export": "Su exportación falló",
        "notification.subject.need.awaiting": "{1}Se ha emitido un {model} sobre usted|{2}Se ha emitido una {model} sobre usted",
        "notification.subject.need.po_submitted": "{1}Se ha enviado el {po_model} de un {model} sobre usted|{2}Se ha enviado el {po_model} de una {model} sobre usted",
        "notification.subject.need.po_validated": "{1}Se ha validado el {po_model} de un {model} sobre usted|{2}Se ha validado el {po_model} de una {model} sobre usted",
        "notification.subject.need.processing": "{1}Se ha atendido un {model} sobre usted|{2}Se ha atendido una {model} sobre usted",
        "notification.subject.need.suspended": "{1}Se ha suspendido un {model} sobre usted|{2}Se ha suspendido una {model} sobre usted",
        "notification.subject.need.suspension_stopped": "{1}Ya no se suspende un {model} sobre usted|{2}Ya no se suspende una {model} sobre usted",
        "notification.subject.need.transfered": "{1}Se le ha transferido un {model}|{2}Se le ha transferido una {model}",
        "notification.subject.new_comment": "{1}Se ha enviado un comentario sobre un {model} sobre usted|{2}Se ha enviado un comentario sobre una {model} sobre usted",
        "notification.subject.payment.line_paid": "Factura pagada",
        "notification.subject.purchase_order.sending": "{model} \"{purchaseOrder}\" ",
        "notification.subject.purchase_order.validated_with_deposit": "{1}Se requiere un nuevo depósito en un {model}|{2}Se requiere un nuevo depósito en una {model}",
        "notification.subject.quotation_sending": "Solicitud de precio",
        "notification.subject.rel_supplier_product_family.awaiting": "La familia \"{family}\" debe ser validada para el proveedor \"{supplier}\" y necesita su atención",
        "notification.subject.rel_supplier_product_family.expiration_alert": "La familia \"{productFamily}\" del proveedor \"{supplier}\" ha caducado",
        "notification.subject.rel_supplier_product_family.expiration_alert_before": "La familia \"{productFamily}\" del proveedor \"{supplier}\" caducará pronto",
        "notification.subject.rel_supplier_product_family.refused": "La familia \"{family}\" acaba de ser denegada para el proveedor \"{supplier}\" ",
        "notification.subject.rel_supplier_product_family.unactivation_by_file_expiration_alert": "La familia \"{productFamily}\" del proveedor \"{supplier}\" ha sido deshabilitada",
        "notification.subject.rel_supplier_product_family.validated": "La familia \"{family}\" acaba de ser validada para el proveedor \"{supplier}\" ",
        "notification.subject.suggested_modifications": "Se han sugerido modificaciones",
        "notification.subject.supplier.after_validation": "Un nuevo Proveedor ha sido validado",
        "notification.subject.supplier.contact_invitation": "Invitación a utilizar Boomerang Web",
        "notification.subject.supplier.file_creation": "Se ha insertado un nuevo documento para el proveedor \"{supplier}\" ",
        "notification.subject.supplier.file_expiration_alert": "El documento \"{file}\" del proveedor \"{supplier}\" ha caducado",
        "notification.subject.supplier.file_expiration_alert_before": "El documento \"{file}\" del proveedor \"{supplier}\" caducará pronto",
        "notification.subject.supplier.login_request": "Solicitud de inicio de sesión",
        "notification.subject.supplier_contract.expiration_alert": "{1}El {model} \"{contract}\" ha caducado|{2}La {model} \"{contract}\" ha caducada",
        "notification.subject.supplier_contract.expiration_alert_before": "{1}El {model} \"{contract}\" caducará pronto|{2}La {model} \"{contract}\" caducará pronto",
        "notification.subject.supplier_invoice.notification_when_no_delivery": "{1}Solicitud de recibo del {model} \"{supplier_invoice}\"|{2}Solicitud de recibo de la {model} \"{supplier_invoice}\" ",
        "notification.subject.user.invitation": "Invitación a utilizar Boomerang Web",
        "notification.subject.validation_awaiting": "{1}El {model} \"{label}\" debe ser validado y necesita su atención|{2}La {model} \"{label}\" debe ser validada y necesita su atención",
        "notification.subject.validation_refused": "{1}El {model} \"{label}\" acaba de ser denegado|{2}La {model} \"{label}\" acaba de ser denegada",
        "notification.subject.validation_submitted": "{1}El {model} \"{label}\" se ha enviado para validación|{2}La {model} \"{label}\" se ha enviada para validación",
        "notification.subject.validation_validated": "{1}El {model} \"{label}\" acaba de ser validado|{2}La {model} \"{label}\" acaba de ser validada",
        "observation.field.sentBy": "by",
        "observation.field.sentOn": "Publicado en",
        "observation.model": "Observación",
        "observation.model.gender": "2",
        "observation.model.plural": "Observaciones",
        "pagination.model": "Paginación",
        "pagination.next": "Próximo",
        "pagination.per_page": "Por página",
        "pagination.previous": "Anterior",
        "password.action.change": "Cambia la contraseña",
        "passwordChange.error.verifyConfirmation": "La confirmación de la contraseña no coincide",
        "password_reset.define": "Establecer una nueva contraseña",
        "password_reset.reset": "Restablecer contraseña",
        "password_reset.send_mail": "Enviar el enlace para restablecer la contraseña",
        "payment.action.mark_as_paid": "{1}Marcar como pagado|{2}Marcar como pagada",
        "payment.action.mark_as_refused": "{1}Marcar como rechazado|{2}Marcar como rechazada",
        "payment.confirm.line_deleted_due_to_overpayment": "Línea de {model} eliminada porque la cantidad llegó a 0",
        "payment.confirm.overpayment_added_to_invoice": "{1}Sobrepago asociado al {model}|{2}Sobrepago asociado a la {model}",
        "payment.error.amount_inctax.less_than_paid": "El monto con impuestos incluidos debe ser mayor que {paid} (monto restante por pagar)",
        "payment.error.amount_inctax.less_than_zero": "El monto con impuestos incluidos debe ser mayor que 0",
        "payment.error.amount_inctax.more_than_paid": "El monto con impuestos incluidos debe ser menor que {paid} (monto restante por pagar)",
        "payment.error.amount_inctax.more_than_zero": "El monto con impuestos incluidos debe ser menor que 0",
        "payment.error.amount_inctax.nothing_more_to_pay": "No hay nada más que pagar",
        "payment.error.currency_not_euro": "La exportación SEPA solo es posible con pagos en euros.",
        "payment.error.missing_banking_info": "Detalles bancarios faltantes (certificado de identificación bancaria)!",
        "payment.error.missing_payment_source_banking_info": "Faltan los datos bancarios de la cuenta del emisor, rellénelos antes de realizar la exportación SEPA.",
        "payment.error.missing_supplier_banking_info": "Faltan los datos bancarios de uno de los proveedores, gracias por informarles antes de llevar a cabo la exportación SEPA.",
        "payment.field.paid_amount_inctax": "Importe del pago con IVA",
        "payment.info.only_from_currency": "Los elementos mostrados están prefiltrados según la moneda de pago: {currency}",
        "payment.line.status.title.blocked": "{1}{model} validado, pero bloqueado debido a saldo insuficiente o cuenta de débito|{2}{model} validada, pero bloqueada debido a saldo insuficiente o cuenta de débito",
        "payment.line.status.title.canceled": "{1}{model} cancelado por el usuario|{2}{model} cancelada por el usuario",
        "payment.line.status.title.error": "Error",
        "payment.line.status.title.planified": "{1}{model} validado y pendiente de ejecución|{2}{model} validada y pendiente de ejecución",
        "payment.line.status.title.refused": "{1}{model} rechazado por el validador|{2}{model} rechazada por el validador",
        "payment.line.status.title.rejected_by_beneficiary_bank": "{1}{model} rechazado por el banco del beneficiario|{2}{model} rechazada por el banco del beneficiario",
        "payment.line.status.title.rejected_by_holder_bank": "{1}{model} rechazado por el banco del emisor|{2}{model} rechazada por el banco del emisor",
        "payment.mark_as_paid.send_mail": "Puede enviar un correo electrónico de confirmación a cada {model}.",
        "payment.model": "Pago",
        "payment.model.plural": "Pagos",
        "payments.overpayment": "Pago en exceso",
        "payments.overpayment.plural": "Pagos en exceso",
        "payment_source.field.organization_id": "Número SIRET",
        "payment_source.field.organization_name": "Nombre de la Sociedad",
        "payment_source.model": "Fuente de pago",
        "payment_source.model.gender": "2",
        "payment_source.model.plural": "Fuentes de pago",
        "payment_term.field.fixed_day": "Día del mes para el pago",
        "payment_term.field.month_end": "¿Fin de mes?",
        "payment_term.field.on_receipt": "¿A partir de la fecha de recepción?",
        "payment_term.field.payment_deadline": "Número de días",
        "payment_term.label.cash": "efectivo",
        "payment_term.label.fixed_day": "pago en {fixed_day} siguiente",
        "payment_term.label.month_end": "fin de mes",
        "payment_term.label.on_receipt": "al recibir",
        "payment_term.label.payment_deadline": "{payment_deadline} dias",
        "payment_term.model": "Condición de liquidación",
        "payment_term.model.gender": "2",
        "payment_term.model.plural": "Condiciones de pago",
        "payment_type.model": "Tipo de pago",
        "payment_type.model.plural": "Tipos de pago",
        "payment_type.type.none": "No especificado",
        "payment_type.type.sepa_transfer": "Transferencia SEPA",
        "pending_api_call.model": "Llamada API saliente",
        "pending_api_call.model.plural": "Llamadas API salientes",
        "pending_supplier_invoice.file.default_original_name": "Archivo importado",
        "pending_supplier_invoice.model.plural": "Facturas en espera de procesamiento",
        "perm.budgetAnalysis.index": "Ver análisis de presupuesto",
        "perm.menu.admin": "Acceda al menú Admin",
        "permission.by_model": "Por{model}",
        "permission.details": "Detalles de permisos",
        "permission.has_permission": "Tiene permiso",
        "permission.model": "Permiso",
        "permission.model.plural": "Permisos",
        "prefill_data.model": "Dato precompletado",
        "prefill_data.model.plural": "Datos precompletados",
        "prefill_data.target.comment_pattern": "Plantilla de comentario : {model}",
        "print.share_url.info": "Puede copiar esta URL para compartir el documento, al que se podrá acceder incluso sin haber iniciado sesión.",
        "productFamily.field.is_main_product_family": "Main",
        "productFamily.field.selectedProductFamilies": "Subfamilias seleccionadas",
        "product_family.field.approvals": "Aprobaciones",
        "punchout.module": "PunchOut Catalog",
        "purchaseOrder.import.field.update": "Actualice las informaciones de los Pedidos de Compra con una etiqueta ya existente",
        "purchaseOrder.import.undefined.allocation": "Línea {line} : el código {undefinedAllocation} no está definido y, por lo tanto, no se pudo crear el Pedido de Compra.|Línea {line} : Los códigos {undefinedAllocation} no están definido y, por lo tanto, no se pudo crear el Pedido de Compra.",
        "purchaseOrder.import.undefined.incoterm": "Línea {line} : el incotem {undefinedIncoterm} no está definido y, por lo tanto, no se pudo crear el Pedido de Compra.",
        "purchaseOrder.import.undefined.status": "Línea {line} : el estado {undefinedStatus} no está definido y, por lo tanto, no se pudo crear el Pedido de Compra.",
        "purchaseOrder.import.undefined.supplier": "Línea {line} : el código de proveedor {undefinedSupplier} no está definido y, por lo tanto, no se pudo crear el Pedido de Compra.",
        "purchaseOrder.import.undefined.supplier_contact": "Línea {line} : el e-mail del contacto de proveedor {undefinedSupplierContact} no está definido y, por lo tanto, no se pudo crear el Pedido de Compra.",
        "purchaseOrder.import.undefined.supplier_contact_default": "Línea {line} : este proveedor no tiene un contacto predeterminado, aunque se requiere un contacto. Por lo tanto, no se pudo crear el Pedido de Compra.",
        "purchaseOrder.import.undefined.user": "Línea {line} : el inicio de sesión del autor {undefinedUser} no está definido y, por lo tanto, no se pudo crear el Pedido de Compra.",
        "purchaseOrder.import.unique.code": "Línea {line} : ya existe un Pedido de Compra con la misma etiqueta y un código diferente de {uniqueString}.",
        "purchaseOrder.payment_status.complete": "Pagado",
        "purchaseOrder.payment_status.no": "No pagado",
        "purchaseOrder.payment_status.partial": "Parcialmente pagado",
        "purchaseOrder.pdf.billing_conditions": "IMPORTANTE: El número de Pedido&nbsp;<b>{code}</b> y la referencia&nbsp;<b>{reference}</b> deben aparecer obligatoriamente en las facturas.",
        "purchaseOrder.pdf.billing_conditions.title": "Condiciones de facturación",
        "purchaseOrder.pdf.payment_period": "Retraso de pago",
        "purchaseOrder.pdf.supplier_address": "Dirección del proveedor",
        "purchase_order.action.add_contact": "Crear un contacto",
        "purchase_order.back_to_draft.already_sent_at": "{1}Además, el {model} ya ha sido enviada al Proveedor. Marque la siguiente casilla para confirmar la cancelación del estado \"{status}\" (no se enviará ninguna notificación al Proveedor):|{2}Además, la {model} ya ha sida enviado al Proveedor. Marque la siguiente casilla para confirmar la cancelación del estado \"{status}\" (no se enviará ninguna notificación al Proveedor):",
        "purchase_order.cancel.already_sent_at": "{1}Además, el {model} ya ha sido enviada al Proveedor. Marque la siguiente casilla para confirmar la cancelación del {model} (no se enviará ninguna notificación al Proveedor):|{2}Además, la {model} ya ha sida enviado al Proveedor. Marque la siguiente casilla para confirmar la cancelación de la {model} (no se enviará ninguna notificación al Proveedor):",
        "purchase_order.error.article.only_enabled": "Este artículo debe estar activo",
        "purchase_order.error.max_amount_per_order.exceeded": "Se supera el límite del Proveedor ({valor}/pedido)",
        "purchase_order.error.max_amount_per_year.exceeded": "Se supera el límite del Proveedor ({valor}/año)",
        "purchase_order.error.stock_exceeded": "Stock actual ({current_stock}) excedido por {exceeded} unidad(es)",
        "purchase_order.export.filename": "pedidos",
        "purchase_order.export_remaining_to_deliver.filename": "pedidos-a-recibir",
        "purchase_order.field.articles_quantity": "Cantidad de artículos",
        "purchase_order.field.code": "Número de Pedido",
        "purchase_order.field.deposit": "Depósito solicitado",
        "purchase_order.field.deposit.abbreviation": "PI",
        "purchase_order.field.deposit_paid_at": "Depósito pagado en",
        "purchase_order.field.id": "ID OC",
        "purchase_order.field.references_number": "Numero de referencias",
        "purchase_order.field.send_mode": "Modo de envío",
        "purchase_order.field.supplier_comment": "Comentario para el proveedor",
        "purchase_order.field.supplier_contact": "Contacto del proveedor",
        "purchase_order.info.gap_line": "Línea de hueco",
        "purchase_order.info.internal_article": "{model} a reponer en stock",
        "purchase_order.inject_lines.error": "Error al inyectar varias líneas",
        "purchase_order.inject_lines.impossible_relation": "Línea {line} : No hay un enlace padre-hijo entre el código {impossibleRelationAllocation} y los otros códigos de la línea.",
        "purchase_order.inject_lines.no_right": "Línea {line} : no tiene los permisos necesarios para crear líneas utilizando el código {noRightAllocation}.",
        "purchase_order.inject_lines.undefined.allocation": "Línea {line} : el código {undefinedAllocation} no está definido.|Línea {line} : Los códigos {undefinedAllocation} no están definido.",
        "purchase_order.label.warehouse_allocations_adjustment": "Ajuste de imputaciones",
        "purchase_order.modal.multiple_suppliers.article_from_supplier": "Uno de sus artículos es sobre el proveedor <b>{supplier}</b>",
        "purchase_order.modal.multiple_suppliers.desynchronization_warning": "Si cambia de proveedor, sus artículos no estarán sincronizados",
        "purchase_order.model": "Pedido de Compra",
        "purchase_order.model.plural": "Pedidos de Compra",
        "purchase_order.send.already_sent": "{1}Este {model} ya ha sido enviado|{2}Esta {model} ya ha sido enviada",
        "purchase_order.send.can_log_as_supplier": "Simplemente puede iniciar sesión para enviar sus facturas:",
        "purchase_order.summary_pdf.title": "Pedido - Recibo - Facturación",
        "purchase_order.unclose.error.missing_quantity": "Faltan artículos en stock para poder decerrar:",
        "purchase_order.unclose.error.missing_quantity.detail": "{article}: cantidad requerida: {needed}, cantidad disponible: {available}",
        "purchase_order_send_mode.model": "Modo de envío",
        "purchase_order_send_mode.model.plural": "Modos de envío",
        "purchase_type.model": "Tipo de compra",
        "purchase_type.model.plural": "Tipos de compra",
        "qualification.model.gender": "2",
        "question.model": "Pregunta",
        "question.model.plural": "Preguntas",
        "quotation_request.download.filename": "solicitud-precio-",
        "quotation_request.model": "Solicitud de precio",
        "quotation_request.model.gender": "2",
        "registration.action.access": "Accede a su software",
        "registration.action.add_user": "Agregar un usuario",
        "registration.action.remove_user": "Eliminar el usuario",
        "registration.action.start_now": "¡Empezar ahora!",
        "registration.action.use_different_delivery_address": "Utilice una dirección de entrega diferente",
        "registration.error.email_already_registered": "Ya has realizado una solicitud con esta dirección de correo electrónico",
        "registration.error.email_must_be_different": "La dirección de correo electrónico debe ser diferente de {field}",
        "registration.field.message": "Su mensaje",
        "registration.info.already_verified": "Su registro ya ha sido verificado",
        "registration.info.disclaimer": "Al continuar, aceptas los <a href=\"{link}\" target=\"blank\">Términos de uso</a>.",
        "registration.info.fill_code": "Por favor introduce el código recibido por email",
        "registration.info.impossible": "Registro imposible, por favor contacte con nuestro servicio de atención al cliente",
        "registration.info.need_more_configuration": "¿Necesita más personalización (moneda, categorías, etc.)?",
        "registration.mail.complete.intro1": "Bienvenido a Boomerang Web, la plataforma de Gestión de Compras.",
        "registration.mail.complete.intro2": "¡Su software está listo para usar!",
        "registration.mail.complete.outro1": "¡Todo lo que tienes que hacer es ingresar una contraseña y todo estará listo!",
        "registration.mail.complete.outro2": "Este enlace solo está disponible durante una hora; sin embargo, puede utilizar el siguiente enlace para realizar un restablecimiento de contraseña.",
        "registration.mail.complete.subject": "Acceso a Boomerang Web",
        "registration.mail.error.intro": "Se produjo un error durante la creación de un inquilino por parte de un cliente potencial.",
        "registration.mail.error.subject": "Nueva instancia: error",
        "registration.mail.need_custom.intro": "Un cliente potencial necesita ayuda para configurar su nueva instancia:",
        "registration.mail.need_custom.outro": "Por favor contacte con él nuevamente lo antes posible.",
        "registration.mail.need_custom.subject": "Nueva instancia: se requiere ayuda",
        "registration.mail.notification_new_tenant.intro": "Un nuevo cliente acaba de crear su instancia:",
        "registration.mail.notification_new_tenant.subject": "Nueva instancia: creación completada",
        "registration.mail.registration.intro1": "Bienvenido a Boomerang Web, la plataforma de Gestión de Compras.",
        "registration.mail.registration.intro2": "Para confirmar su registro, ingrese el siguiente código después de hacer clic en el siguiente enlace:",
        "registration.mail.registration.subject": "Registro en Boomerang Web",
        "registration.message.created": "Tu configuración está lista.",
        "registration.message.creating": "Se tiene en cuenta su configuración. Recibirás un correo electrónico cuando todo esté listo.",
        "registration.message.message_sent": "Se ha enviado un mensaje a nuestro equipo, nos comunicaremos con usted pronto.",
        "registration.model": "Registro",
        "registration.model.plural": "Registros",
        "registration.title.company": "Complete los datos de su empresa:",
        "registration.title.configuration": "Configuración",
        "registration.title.registration": "Registro",
        "registration.title.users": "Usuarios",
        "registration.title.validation": "¿Quién debe validar las Órdenes de Compra?",
        "registration.title.verification": "Verificación",
        "relAllocation.import.field.deleteRelation": "Eliminar enlaces existentes entre elementos",
        "relAllocation.import.field.update": "Actualice los elementos cuyos códigos ya existen",
        "relAllocations.error.alreadyExists": "Esta relación ya existe",
        "relAllocations.field.allocation_type": "Tipo de enlace de Imputación",
        "relAllocations.import.allocationOrProductFamily": "Imputaciones o Familias de productos para importar",
        "relAllocations.import.error.required": "Se debe seleccionar al menos una imputaciones / familia de productos.",
        "relBuyer.model": "Compradores",
        "relPermission.model": "Relación con los permisos",
        "rel_allocations.action.import": "Importar Imputaciones",
        "rel_allocations.model": "Enlace de Imputación",
        "rel_allocations.model.plural": "Enlaces de Imputación",
        "rel_supplier_product_family.model": "Familia de proveedor",
        "rel_supplier_product_family.model.plural": "Familias de proveedor",
        "rel_supplier_product_family_validation.model": "Validación de Familia de proveedor",
        "rel_supplier_product_family_validation.model.gender": "2",
        "rel_supplier_product_family_validation.model.plural": "Validaciones de Familia de proveedor",
        "role.model": "Rol",
        "role.model.plural": "Roles",
        "role.value.noRole": "No rol",
        "search_saving.model": "Salvaguardia de búsqueda",
        "search_saving.model.plural": "Salvaguardias de búsqueda",
        "secureStorage.field.token": "Token de almacenamiento seguro",
        "secure_storage.error.set_token": "Ingrese el token en la base de datos de Secure Storage",
        "secure_storage.title.modal": "Insertar archivo",
        "session.model": "Sesión",
        "session.model.plural": "Sesiones",
        "setting.config.api": "API",
        "setting.config.customer": "Cliente",
        "setting.config.models": "Modelos",
        "setting.config.model_fields": "Campos de los modelos",
        "setting.config.modules": "Módulos",
        "setting.customer.allocations.title": "Imputaciones",
        "setting.customer.contract.title": "Contrato",
        "setting.customer.email_sending.title": "Correos electrónicos",
        "setting.customer.files.title": "Archivos",
        "setting.customer.formats": "Formatos",
        "setting.customer.misc.title": "Diverso",
        "setting.customer.prices.title": "Precios",
        "setting.field.forced_status_max_duration": "Duración máxima de un Estado forzado a {status}",
        "setting.field.landing_page": "Redirección después de la conexión",
        "setting.field.layout_striped": "Haga que cada dos filas se destaquen en las tablas",
        "setting.field.supplier_objective_hse": "Objetivo HSE",
        "setting.field.supplier_objective_oqd": "Objetivo OQD",
        "setting.field.supplier_objective_otd": "Objetivo OTD",
        "setting.field.versioning": "Control de versiones",
        "setting.form.log_filter_query": "Registrar logs de scopeFilter de {model}",
        "setting.form.maintenance": "Mantenimiento",
        "setting.formField.ATTACHMENT__AUTHORIZED_TYPES": "Tipos de archivo permitidos",
        "setting.formField.ATTACHMENT__DONT_CHECK_FILE_TYPE": "No verifique el tipo de archivo",
        "setting.formField.CURRENCY__AUTOMATIC_RATE": "Actualización automática de los tipos de cambio",
        "setting.formField.DASHBOARD__COMPARISON_MONTH_NUMBER": "Número de meses para la comparación de los importes de {model} :",
        "setting.formField.DASHBOARD__DISTRIBUTION_ALLOCATION": "Distribución de {model} por :",
        "setting.formField.DASHBOARD__INSTANCE": "Información sobre su suscripción :",
        "setting.formField.GLOBAL__AMOUNT_DECIMAL_DELIMITER": "Delimitador de punto decimal",
        "setting.formField.GLOBAL__AMOUNT_DECIMAL_NUMBER": "Número de decimales en los precios",
        "setting.formField.GLOBAL__DEBUG": "Habilite el modo \"DEBUG\" ",
        "setting.formField.GLOBAL__DISABLE_ALL_EXPORTS": "Deshabilitar todas las exportaciones",
        "setting.formField.GLOBAL__DISABLE_ANTIVIRUS": "Desactivar antivirus",
        "setting.formField.GLOBAL__DISABLE_CHANGE_PASSWORD": "Desactiva las posibilidades de cambiar la contraseña",
        "setting.formField.GLOBAL__FASTER_QUERY": "Utilice consultas más rápidas",
        "setting.formField.GLOBAL__LOG_QUERY": "Registra TODAS las queries en el archivo {file}",
        "setting.formField.GLOBAL__MAINTENANCE_MODE": "Active el modo \"Mantenimiento\" para este cliente",
        "setting.formField.GLOBAL__PRICE_DISPLAY": "Visualización de precios",
        "setting.formField.GLOBAL__QUERY_MONTHS_DEFAULT_LIMIT": "Límite de visualización de datos predeterminado",
        "setting.formField.MAIL__ALERT_DELAY": "Tiempos de alerta de correo electrónico",
        "setting.formField.PDF__FONT_FAMILY": "Fuente de los PDF",
        "setting.formField.PDF__MAIN_COLOR": "Color principal de los PDF",
        "setting.formField.PURCHASE_ORDER__USE_DEPARTMENT_PAYMENT_INFOS": "{1}Uso de la información financiera del {model}|{2}Uso de la información financiera de la {model}",
        "setting.formField.SUPPLIER__AFTER_VALIDATION_EMAILS": "Correos electrónicos para enviar correo \"{mail_title}\" ",
        "setting.formField.SUPPLIER__APPROVAL_DEFAULT_DURATION": "Tiempo de activación predeterminado de una subfamilia sin aprobación",
        "setting.formField.SUPPLIER__CAN_ACCESS_TO_APP": "Dar acceso a proveedores en esta instancia",
        "setting.formField.SUPPLIER__ENABLE_CLIENT_NUMBER": "Activar el \"Número de cliente\" ",
        "setting.formField.SUPPLIER__ENABLE_FORCED_STATUS": "Activar Estado forzado",
        "setting.formField.SUPPLIER__ENABLE_PO_INFORMATIONS": "Activar \"Infos de Pedido de Compra\" ",
        "setting.formField.SUPPLIER__ENABLE_TAB_CHARTS": "Activar la pestaña \"{tab}\" ",
        "setting.formField.SUPPLIER__REQUIRED_FILE_TYPES": "Tipos de archivos obligatorios (indicar ID)",
        "setting.formField.TENANT__LOGO": "Logotipo del cliente (aparece en el menú principal)",
        "setting.formField.TENANT__MAIN_COLOR": "Color principal del cliente",
        "setting.formField.TRACE__DELETING_DELAY": "Retrasos antes de eliminar Traces",
        "setting.form_field.BUDGET_ANALYSIS__DEFAULT_FILTER_KEYS_NUMBER": "Número de componentes clave mostrados en el filtro predeterminado",
        "setting.form_field.BUDGET__KEY_MILESTONE": "Hito clave del presupuesto",
        "setting.form_field.CATALOG__CART_MODE": "Uso del carrito de la compra en el catálogo de artículos",
        "setting.info.other": "Otros ajustes",
        "setting.info.simplification": "Simplificación",
        "setting.maintenance.debug": "Debug",
        "setting.maintenance.mailtrap.title": "Mailtrap",
        "setting.maintenance.other_actions.title": "Otras acciones",
        "setting.maintenance.send_test_log": "Enviar un log de prueba",
        "setting.maintenance.send_test_mail": "Enviar un correo electrónico de prueba",
        "setting.model": "Configuración",
        "setting.showEnv": "Ver variables de entorno",
        "setting.validation_process.amounts.title": "Uso de cantidades",
        "setting.validation_process.criteria_order.title": "Orden de criterios para generar circuitos",
        "setting.validation_process.steps_by_criterion.title": "Número de filas por criterio a mantener al final del circuito",
        "setting.validation_process.steps_global.title": "Número total de filas a mantener al final del circuito.",
        "siret.novalidate": "El número Siret no es válido",
        "statistic.model": "Estadística",
        "statistic.model.plural": "Estadísticas",
        "stock.action.readjust_from_computed": "Reajustar desde Calculado ({value})",
        "stock.actual.abbreviation": "Ctd. real",
        "stock.adjustment": "Ajuste de Stock",
        "stock.adjustment_from_import": "Ajuste de importación",
        "stock.available": "Stock disponible",
        "stock.error.article_required": "El artículo es obligatorio",
        "stock.error.exceeded": "Stock excedido",
        "stock.error.only_internal_article_allowed": "Solo se autorizan artículos internos cuando se produzca un agotamiento de stock.",
        "stock.export.filename": "stock-{code}",
        "stock.info.in_warehouse_but_disabled": "{1}{model} ya presente en el Almacén pero actualmente deshabilitado.|{2}{model} ya presente en el Almacén pero actualmente deshabilitada.",
        "stock.min_quantity_alert.field": "Solo {model} por debajo del umbral mínimo.",
        "stock.min_quantity_alert.short_label": "Umbral mínimo",
        "stock.missing_quantity": "Falta cantidad",
        "stock.model": "{1}{model} almacenado|{2}{model} almacenada",
        "stock.model.plural": "{1}{model} almacenados|{2}{model} almacenadas",
        "stock.movements": "Movimientos de stock",
        "stock.type.in": "Entrada de stock",
        "stock.type.in.abbreviation": "Entrada",
        "stock.type.movement": "Movimiento entre acciones",
        "stock.type.movement.abbreviation": "Mvmnto",
        "stock.type.no": "Compras no bursátiles",
        "stock.type.out": "Salida de stock",
        "stock.type.out.abbreviation": "Salida",
        "supplier.action.reset_approval_file_types": "Ajustar los archivos requeridos",
        "supplier.action.unlock": "Desbloquear el proveedor",
        "supplier.banner_action.missing_files": "Hay un archivo faltante o válido para validar est Proveedor|Hay {nb} archivos faltantes o válidos para validar est Proveedor",
        "supplier.banner_action.missing_geographic_sector": "Necesita al menos un Sector geográfico para validar este proveedor",
        "supplier.banner_action.missing_product_family": "Necesita al menos una Familia para validar este proveedor",
        "supplier.banner_action.product_family.awaiting_validation": "{product_family} - pendiente de validación de :<ul>{validators}</ul>",
        "supplier.banner_action.product_family.disabled_approval": "Aprobación {approval} no está activa, imposible activar {product_family}",
        "supplier.banner_action.product_family.missing_files": "Hay un archivo faltante o válido para activar la familia {product_family}|Hay {nb} archivos faltantes o válidos para activar la familia {product_family}",
        "supplier.banner_action.product_family.missing_validators": "Hay un validador faltante para activar la familia {product_family}|Hay {nb} validadors faltantes para activar la familia {product_family}",
        "supplier.banner_action.product_family.start_validation": "La validación de la familia {product_family} puede ser lanzada",
        "supplier.banner_action.start_validation": "La validación del Provedor puede ser lanzada",
        "supplier.banner_action.validate": "El Proveedor puede ser validado.",
        "supplier.chart.12_rolling_months": "12 meses consecutivos",
        "supplier.chart.accounting_entries_details": "Desglose por subfamilia durante 12 meses consecutivos",
        "supplier.chart.additional_mark": "Notación complementaria",
        "supplier.chart.additional_rating.title": "Notación complementaria",
        "supplier.chart.annual_rating.title": "Notación anual",
        "supplier.chart.average_basket": "Cesta mediana",
        "supplier.chart.certification.title": "Certificaciones",
        "supplier.chart.completed_document": "Documento completo",
        "supplier.chart.current_year": "Año actual (A)",
        "supplier.chart.deliveries_amount": "Cantidad de entregas",
        "supplier.chart.dependency_rate": "Tasa de dependencia",
        "supplier.chart.distributionPurchases": "Distribución de compras en 12 meses",
        "supplier.chart.expiration_date": "Fecha de caducidad",
        "supplier.chart.frequency_rate1": "Tasa de frecuencia 1",
        "supplier.chart.frequency_rate1.help": "Tasa de frecuencia 1 (TF1) = (número de accidentes con tiempo perdido / número de horas trabajadas) x 1.000.000",
        "supplier.chart.frequency_rate1.short": "TF1",
        "supplier.chart.frequency_rate2": "Tasa de frecuencia 2",
        "supplier.chart.frequency_rate2.help": "Tasa de frecuencia 2 (TF2) = (número de accidentes con y sin tiempo perdido / número de horas trabajadas) x 1.000.000",
        "supplier.chart.frequency_rate2.short": "TF2",
        "supplier.chart.global_mark": "Marca global",
        "supplier.chart.hse": "Health, Safety, Environment",
        "supplier.chart.hse.short": "HSE",
        "supplier.chart.objective_current_year": "Objetivo A",
        "supplier.chart.objective_previous_year": "Objetivo A-1",
        "supplier.chart.oqd": "On Quality Delivery",
        "supplier.chart.oqd.short": "OQD",
        "supplier.chart.orders_amount": "Total de los Pedidos",
        "supplier.chart.orders_count": "Numero de ordenes",
        "supplier.chart.otd": "On Time Delivery",
        "supplier.chart.otd.short": "OTD",
        "supplier.chart.performance": "Indicadores de rendimiento",
        "supplier.chart.previous_year": "Año anterior (A-1)",
        "supplier.chart.result_current_year": "Resultado A",
        "supplier.chart.result_previous_year": "Resultado A-1",
        "supplier.chart.security.title": "Seguridad",
        "supplier.chart.severity_rate": "Tasa de severidad",
        "supplier.chart.severity_rate.help": "Tasa de severidad (TG) = (número de días perdidos / número de horas trabajadas) x 1,000",
        "supplier.chart.severity_rate.short": "TG",
        "supplier.chart.totalOrdersAmounts": "Total de Pedidos en euros durante 24 meses",
        "supplier.chart.turnover": "Volumen de negocios",
        "supplier.chart.turnover.title": "Volumen de negocios Compras",
        "supplier.chart.turnover_and_dependency": "Volumen de negocios y Tasa de dependencia",
        "supplier.chart.variation": "Var A vs A-1",
        "supplier.evaluation.invitation.intro": "Le han invitado a completar una evaluación para Proveedor {supplier}.",
        "supplier.evaluation.invitation.subject": "Evaluación de Proveedor",
        "supplier.field.accounting_code": "Código contable",
        "supplier.field.account_type": "Tipo de cuenta",
        "supplier.field.account_type.model": "tipo de cuenta",
        "supplier.field.activity_details": "Detalles de la actividad",
        "supplier.field.client_number": "Número de cliente",
        "supplier.field.code": "Código del proveedor",
        "supplier.field.code.model": "un código de proveedor",
        "supplier.field.company_name": "Nombre de la empresa",
        "supplier.field.contractual_status": "Estado contractual",
        "supplier.field.contractual_status.model": "estado contractual",
        "supplier.field.country.model": "un código de país",
        "supplier.field.duns": "DUNS / ID Código",
        "supplier.field.evaluation_mark": "Nota de evaluación",
        "supplier.field.geographicSectors.help": "La elección del sector puede condicionar la obligación de ingresar los siguientes campos",
        "supplier.field.geographicSectors.model": "códigos de áreas geográficas",
        "supplier.field.geographicSectors.otherSectors": "and {number} other sector (s)",
        "supplier.field.give_access": "Dar acceso",
        "supplier.field.group": "Group",
        "supplier.field.group.model": "código de grupo",
        "supplier.field.intra_community_vat": "Tva-intra",
        "supplier.field.intra_community_vat.error.regex": "El número de IVA intracomunitario no es válido",
        "supplier.field.label.error.required_if": "La etiqueta es obligatoria si no hay ningún tipo de documento",
        "supplier.field.main_product_family": "Familia de compras principal",
        "supplier.field.manager": "Account Manager",
        "supplier.field.manager.model": "un inicio de sesión de usuario",
        "supplier.field.manage_shipping_date": "{1}Administrar la fecha de envío en el {model}|{2}Administrar la fecha de envío en la {model}",
        "supplier.field.manage_stock": "Gerente de stock",
        "supplier.field.paymentTerm.model": "un código de condición de pago",
        "supplier.field.paymentType.model": "un código de un tipo de pago",
        "supplier.field.po_informations": "Infos de Pedido de Compra",
        "supplier.field.productFamilies": "Familias",
        "supplier.field.productFamilies.model": "códigos familiar",
        "supplier.field.punchout_type": "Tipo PunchOut",
        "supplier.field.punchout_url": "URL PunchOut",
        "supplier.field.qualification": "Calificación",
        "supplier.field.qualification.model": "un nombre de una calificación",
        "supplier.field.remove_access": "Eliminar acceso",
        "supplier.field.surveillanceMinAmount": "Cantidad minima",
        "supplier.field.tags.model": "etiquetas",
        "supplier.field.underSurveillance": "Bajo vigilancia",
        "supplier.field.website": "Website",
        "supplier.headInfo.activeUntil": "active hasta",
        "supplier.import": "Importar de proveedores :",
        "supplier.import.action.downloadBankAccountHeaders": "Descargar encabezados identificación bancaria",
        "supplier.import.action.downloadContactsHeaders": "Descargar encabezados contactos",
        "supplier.import.action.downloadSupplierHeaders": "Descargar encabezados proveedores",
        "supplier.import.bankAccount": "Importación de detalles del banco de proveedores :",
        "supplier.import.contacts": "Importación de contactos de proveedores :",
        "supplier.import.field.bank_account_file": "Archivo de importación para detalles del banco de proveedor",
        "supplier.import.field.contact_file": "Archivo de importación para contactos de proveedores",
        "supplier.import.field.file.help": "El nombre de cada columna debe coincidir con el carácter por el nombre de una columna en la tabla seleccionada. No todas las columnas son necesarias.",
        "supplier.import.field.group": "Código de grupo",
        "supplier.import.field.supplier_file": "Archivo de importación de proveedores",
        "supplier.import.field.update": "Actualizar proveedores cuyos códigos ya existen",
        "supplier.info.difference_between_approval_file_types": "Hay una diferencia entre los archivos solicitados y los archivos de aprobación. Ajustarlos puede desactivar familias para las que faltará un archivo y, por lo tanto, puede desactivar el propio Proveedor.",
        "supplier.info.has_articles_to_disable": "El proveedor tiene artículos, estos estarán desactivados.",
        "supplier.model": "Proveedor",
        "supplier.model.plural": "Proveedores",
        "supplier.objectives.title": "Objetivos de los Proveedores",
        "supplier.title.contactInformations": "Coordenadas",
        "supplier.title.dashboard": "Monitoreo y vigilancia",
        "supplier.title.files": "Documentos",
        "supplier.title.finance": "Información financiera",
        "supplier.title.forced_status": "Estatus forzado",
        "supplier.title.identity": "Identidad",
        "supplier.title.purchaseInformations": "Información de compra",
        "supplier.title.surveillance": "Vigilancia",
        "supplier.title.validators": "Validadores",
        "supplier.use_punchout": "Utiliza un catálogo PunchOut",
        "suppliercontacts.import.requiredOne": "Al menos uno de los campos {field_email} , {field_phone} o {field_mobile} es obligatorio.",
        "suppliercontacts.import.requiredOne.error": "Línea {line} : Al menos uno de los campos {field_email} , {field_phone} o {field_mobile} es obligatorio, no se pudieron agregar los datos.",
        "supplierFile.field.category": "Categoría de documento",
        "supplierFile.field.documentDate": "Document Date",
        "supplierFile.field.file": "Documento",
        "supplierFile.field.label.help": "La etiqueta se llena automáticamente desde el tipo de documento",
        "supplierFile.field.sentBy": "Publicado por",
        "supplierFile.field.type": "Tipo de documento",
        "supplierFile.field.type.value.none": "No type",
        "supplierFile.field.validityDate": "Fecha de validez",
        "supplierGroup.field.label": "Nombre del grupo",
        "supplierInvoice.accountingVatEntryType.debit_collection_by_month": "Por mes: Débito => según la fecha de facturación / Cobro => según la fecha de vencimiento (ej: CEB)",
        "supplierInvoice.paymentstatus.info.forced_paid": "Estado obligado a pagado",
        "supplierInvoice.paymentstatus.paid": "Pagado",
        "supplier_account_type.model": "Tipo de cuenta del proveedor",
        "supplier_account_type.model.plural": "Tipos de cuenta del proveedor",
        "supplier_approval.field.roles": "Roles que deben validar",
        "supplier_approval.model": "Aprobación de proveedor",
        "supplier_approval.model.plural": "Aprobaciones de proveedores",
        "supplier_chart_accounting_entry_turnover.export.filename": "volumen_negocios_entrada_contable",
        "supplier_chart_annual_rating.export.filename": "notacion_anual",
        "supplier_chart_certifications.export.filename": "certificaciones",
        "supplier_chart_performance.export.filename": "rendimiento",
        "supplier_chart_security.export.filename": "seguridad",
        "supplier_chart_turnover.export.filename": "volumen_negocios",
        "supplier_contact.model": "Contacto del proveedor",
        "supplier_contact.model.plural": "Contactos del proveedor",
        "supplier_contacts.export.filename": "contactos",
        "supplier_contact_type.model": "Tipo de contacto de Proveedor",
        "supplier_contact_type.model.plural": "Tipos de contacto de Proveedores",
        "supplier_contract.field.code": "Número de {model}",
        "supplier_contract.field.label": "{1}Objeto del {model}|{2}Objeto de la {model}",
        "supplier_contract.field.process_code": "Número de trámite",
        "supplier_contract.model": "Contrato de Proveedor",
        "supplier_contract.model.plural": "Contratos de Proveedor",
        "supplier_contractual_status.model": "Estado contractual",
        "supplier_contractual_status.model.plural": "Estados contractuales",
        "supplier_evaluation.model": "Evaluación",
        "supplier_evaluation.model.plural": "Evaluaciones",
        "supplier_evaluation_criterion.model": "Criterio de evaluación",
        "supplier_evaluation_criterion.model.plural": "Criterios de evaluación",
        "supplier_file.model": "Documento de Proveedor",
        "supplier_file.model.plural": "Documentos de Proveedor",
        "supplier_file_": "-",
        "supplier_file_approval.model": "Documento de aprobación",
        "supplier_file_approval.model.plural": "Documentos de aprobación",
        "supplier_file_category.field.validator_role": "Rol del validador",
        "supplier_file_category.model": "Categoría de documento de Proveedor",
        "supplier_file_category.model.plural": "Categorías de documento de Proveedor",
        "supplier_file_regular.model": "Documento de colaboración",
        "supplier_file_regular.model.plural": "Documentos de colaboración",
        "supplier_file_type.field.file_category": "Categoría de documento",
        "supplier_file_type.model": "Tipo de documentos del proveedor",
        "supplier_file_type.model.plural": "Tipos de documentos del proveedor",
        "supplier_group.model": "Grupo de proveedores",
        "supplier_group.model.plural": "Grupos de proveedores",
        "supplier_invoice.action.notify_when_no_delivery": "Solicitar una recepción",
        "supplier_invoice.action.show_file": "Ver la Factura",
        "supplier_invoice.action.submit_invoice": "Enviar une Factura",
        "supplier_invoice.action.submit_your_invoice": "Envía su Factura",
        "supplier_invoice.back_to_draft.existing_accounting_exports": "{1}Además, el {model_invoice} ya se ha exportado a una Exportación Contable, la exportación no se modificará.|{2}Además, la {model_invoice} ya se ha exportada a una Exportación Contable, la exportación no se modificará.",
        "supplier_invoice.back_to_draft.existing_closed_purchase_orders": "{1}Además, los {model_po} se ha cerrados. Marque la siguiente casilla para confirmar la cancelación del cierre:|{2}Además, las {model_po} se ha cerradas. Marque la siguiente casilla para confirmar la cancelación del cierre:",
        "supplier_invoice.close_purchase_orders": "¿Se pueden cerrar algunos artículos?",
        "supplier_invoice.close_purchase_orders.info1": "{1}Si no está esperando otro {model_invoice}, puede cerrar {model_po}.|{2}Si no está esperando otro {model_invoice}, puede cerrar {model_po}.",
        "supplier_invoice.close_purchase_orders.info2": "{1}Una vez que se cierra {model_po}, solo se incluye el monto facturado en el análisis presupuestario.|{2}Una vez que se cierra {model_po}, solo se incluye el monto facturado en el análisis presupuestario",
        "supplier_invoice.error.amount_constrained_to_delivery": "El resto a facturar en esta línea de Pedido de Compra debe ser menor o igual a la cantidad recibida pero no facturada",
        "supplier_invoice.error.amount_constrained_to_purchase_order": "El resto a facturar en esta línea de Pedido de Compra debe ser menor o igual al monto no facturado",
        "supplier_invoice.error.delivered_amount_zero_line": "Primero debe realizar un recibo antes de poder facturar esta línea",
        "supplier_invoice.error.delivered_amount_zero_total": "{1}Primero debe realizar un recibo antes de poder facturar este {model}|{2}Primero debe realizar un recibo antes de poder facturar esta {model}",
        "supplier_invoice.error.different_currency": "Las monedas de los distintos billetes no son las mismas",
        "supplier_invoice.error.different_from_invoice": "{attribute} debe ser igual a {attribute} de la factura ({amount})",
        "supplier_invoice.error.total_amount_constrained_to_delivery": "El subtotal excluyendo impuestos debe ser menor o igual al monto recibido pero no facturado",
        "supplier_invoice.error.total_amount_constrained_to_purchase_order": "El subtotal excluyendo impuestos debe ser menor o igual al monto no facturado",
        "supplier_invoice.export.filename": "facturas",
        "supplier_invoice.field.label": "Número de factura",
        "supplier_invoice.field.outside_EU": "Fuera de la UE",
        "supplier_invoice.info.no_purchase_order_to_notify_when_no_delivery": "No se notificará a ningún usuario.",
        "supplier_invoice.info.users_to_notify": "Se notificará a los siguientes usuarios:",
        "supplier_invoice.limit.delivered": "Cantidad recibida",
        "supplier_invoice.limit.ordered": "Cantidad ordenada",
        "supplier_invoice.modal.multiple_currency.body": "{1}En el mismo {invoice_model}, no puede seleccionar {po_model} con monedas diferentes|{2}En la misma {invoice_model}, no puede seleccionar {po_model} con monedas diferentes",
        "supplier_invoice.modal.multiple_currency.title": "Atención a múltiples monedas.",
        "supplier_invoice.model": "Factura de proveedor",
        "supplier_invoice.model.plural": "Facturas de proveedor",
        "supplier_invoice.ocr_confidence.calculated": "Calculado",
        "supplier_invoice.ocr_confidence.manuel_input": "Introducido manualmente",
        "supplier_invoice.pdf.title": "Comprobante de facturación",
        "supplier_observation.model": "Observación del proveedor",
        "supplier_observation.model.plural": "Observaciones del proveedor",
        "supplier_qualification.model": "Calificación de proveedor",
        "supplier_qualification.model.plural": "Calificaciones de proveedor",
        "supplier_statistic.model": "Estadística de proveedor",
        "supplier_statistic.model.plural": "Estadísticas de proveedor",
        "table.field.column": "Columna",
        "table.field.columns": "Columnas",
        "table.field.line": "Línea",
        "tag.error.invalid": "{1}Al menos uno {model} no es válido|{2}Al menos una {model} no es válida",
        "tag.level.disabled": "Desactivado",
        "tag.level.highlighted": "Resaltado",
        "tag.level.standard": "Estándar",
        "tag.level_for_model": "Nivel para \"{model}\" ",
        "tag.model": "Etiqueta",
        "tag.model.plural": "Etiquetas",
        "tenant.model": "Tenant",
        "tenant.model.plural": "Tenants",
        "tenant.new.mail.error.subject": "Nueva instancia: error",
        "trace.action.acknowledged_receipt": "Acuse de recibo",
        "trace.action.activated": "Activación",
        "trace.action.added": "Añadiendo",
        "trace.action.attachment_added": "Adjunto agregado",
        "trace.action.attachment_deleted": "Adjunto eliminado",
        "trace.action.autogenerated": "Generación automática",
        "trace.action.awaiting": "Modificación del estado de procesamiento en \"A la espera\" ",
        "trace.action.backup_done": "Backup hecha",
        "trace.action.backup_failed": "Backup fallida",
        "trace.action.back_to_awaiting": "Volver al estado de procesamiento \"A la espera\" ",
        "trace.action.back_to_draft": "Volver al estado \"Borrador\" ",
        "trace.action.back_to_none": "Supresión del estado de procesamiento",
        "trace.action.back_to_processing": "Volver al estado de procesamiento \"En curso\" ",
        "trace.action.canceled": "Modificación del estado en \"Cancelado\" ",
        "trace.action.cancel_and_replace": "Deshacer y reemplazar",
        "trace.action.closed": "Modificación del estado en \"Terminado\" ",
        "trace.action.considered_sent": "Modificación del estado en \"Enviado\" ",
        "trace.action.created": "Creación",
        "trace.action.deleted": "Supresión",
        "trace.action.delivery_forced_complete": "Recepción forzada",
        "trace.action.desactivated": "Desactivación",
        "trace.action.disabled": "Desactivación",
        "trace.action.duplicated_from": "Duplicado de",
        "trace.action.enabled": "Activación",
        "trace.action.execute_sql_query": "Ejecutar consulta SQL",
        "trace.action.export": "Exportación de datos",
        "trace.action.export_downloaded": "Exportación descargada",
        "trace.action.export_generated": "Exportación generada",
        "trace.action.export_sepa": "Exportación SEPA",
        "trace.action.export_sepa_downloaded": "Exportación SEPA descargada",
        "trace.action.export_sepa_generated": "Exportación SEPA generada",
        "trace.action.file_created": "Archivo creado",
        "trace.action.file_deleted": "Archivo eliminado",
        "trace.action.file_updated": "Archivo modificado",
        "trace.action.forced_status_removed": "Retirada del estatus forzado",
        "trace.action.imported": "Importación de datos",
        "trace.action.manually_changed_to_validated_PO": "Manual modificación del estado de procesamiento en \"OC validada\" ",
        "trace.action.merged": "Fusión",
        "trace.action.new_comment": "Nuevo comentario",
        "trace.action.new_version": "Nueva versión creada",
        "trace.action.overriden": "Sobrecarga",
        "trace.action.paid": "Modificación del estado en \"Pagado\" ",
        "trace.action.password_changed": "Contraseña cambiada",
        "trace.action.password_generated": "Contraseña generada",
        "trace.action.password_reset": "Restablecimiento de contraseña",
        "trace.action.permissions_updated": "Permisos actualizados",
        "trace.action.processing": "Modificación del estado de procesamiento en \"En curso\" ",
        "trace.action.refused": "Validación rechazada",
        "trace.action.removed": "Supresión",
        "trace.action.replaced": "Reemplazo",
        "trace.action.re_enabled": "Reactivación",
        "trace.action.sent": "Envío por correo electrónico",
        "trace.action.sent_auto": "Envío automático por correo electrónico",
        "trace.action.skipped": "Validación omitida",
        "trace.action.sorted": "Modificación del orden",
        "trace.action.start_reconcile": "Reconciliación",
        "trace.action.submitted_po": "Modificación del estado de procesamiento en \"OC sumisa\" ",
        "trace.action.suspended": "Suspensión",
        "trace.action.taken_charge": "Soportado",
        "trace.action.transferred": "Transferido",
        "trace.action.transformed_into_PO": "Transformado en OC",
        "trace.action.transformed_into_purchase_order": "Transformado en OC",
        "trace.action.unclosed": "Cancelación de cierre",
        "trace.action.unsuspended": "Cancelación de suspensión",
        "trace.action.unvalidated": "Cancelación de validación",
        "trace.action.updated": "Modificación",
        "trace.action.updated_BankDetails": "Modificación la información bancaria",
        "trace.action.updated_buyers": "Modificación de Compradores",
        "trace.action.updated_ContactInformations": "Modificación de datos de contacto",
        "trace.action.updated_Families": "Modificación de familias compradoras",
        "trace.action.updated_Finance": "Modificación de información financiera",
        "trace.action.updated_ForcedStatus": "Modificación del estatus forzado",
        "trace.action.updated_from_central": "Modificación desde Central",
        "trace.action.updated_GeographicSectors": "Modificación de áreas geográficas",
        "trace.action.updated_Identity": "Modificación de identidad",
        "trace.action.updated_PurchaseInformations": "Modificación de la información de compras",
        "trace.action.updated_Surveillance": "Modificación de supervisión",
        "trace.action.updated_Tags": "Modificación de tags",
        "trace.action.updated_Validators": "Modificación del administrador",
        "trace.action.update_after_validation": "Modificación después de la validación",
        "trace.action.validated": "Validación aceptada",
        "trace.action.validated_po": "Modificación del estado de procesamiento en \"OC validada\" ",
        "trace.action.validation_launched": "Lanzamiento del circuito de validación",
        "trace.error.not_sent": "Correo electrónico no enviado",
        "trace.field.action": "Action",
        "trace.field.message": "Mensaje",
        "trace.field.model": "Entity",
        "trace.field.model_id": "ID de la entidad",
        "trace.field.severity": "Criticality",
        "trace.model": "Trace",
        "trace.model.gender": "1",
        "trace.model.plural": "Traces",
        "trace.severity.danger": "Peligro",
        "trace.severity.debug": "Debug",
        "trace.severity.error": "Error",
        "trace.severity.info": "Info",
        "trace.severity.warning": "Advertencia",
        "trace.show": "Detalles de una traza",
        "transportMean.bike": "Dos ruedas <50 cm3",
        "transportMean.car": "Automóvil",
        "transportMean.motorbike": "Dos ruedas >50 cm3",
        "unit.model": "Unidad",
        "unit.model.plural": "Unidades",
        "unit.must_correspond": "El Unidad debe corresponder a una elección existente",
        "user.account_disabled": "Cuenta de usuario no activa",
        "user.actions_to_do": "Acciones a realizar",
        "user.error.must_contain": "El campo {attribute} debe contener al menos uno de los siguientes caracteres : {requirements}",
        "user.error.new_password_must_be_different": "El campo {attribute} no puede ser idéntico a su contraseña actual",
        "user.error.wrong_current_password": "El campo {attribute} no corresponde a su contraseña actual",
        "user.field.activated": "Usuario activo",
        "user.field.can_stock_in": "Puede reabastecer Almacenes",
        "user.field.can_stock_out": "Puede ordenar de Almacenes",
        "user.field.code": "Número de servicio",
        "user.field.confirm_password": "Confirmar contraseña",
        "user.field.current_password": "Contraseña actual",
        "user.field.model.read": "{1}{model} autorizados para consulta|{2}{model} autorizadas para consulta",
        "user.field.model.write": "{1}{model} autorizados en creación|{2}{model} autorizadas en creación",
        "user.field.new_password": "Nueva contraseña",
        "user.field.password_confirmation": "Confirme la contraseña",
        "user.field.recipient": "Parte de la lista de {model}",
        "user.field.remember_me": "Recuérdame",
        "user.import.job.example": "Contable",
        "user.info.cantBeDeleted": "{user} no se puede eliminar pero se puede desactivar.",
        "user.info.new_password_end_date": "Su nueva contraseña se podrá utilizar hasta {date}",
        "user.info.password_end_date": "Su contraseña actual se puede utilizar hasta {date}",
        "user.info.password_end_date_expired": "Su contraseña actual ha caducado el {date}",
        "user.model": "Usuario",
        "user.model.plural": "Usuarios",
        "user.preferences": "Preferencias",
        "user.profile": "Perfil",
        "user.sso.module": "SSO (inicio de sesión único externo)",
        "userVehicle.model": "Vehículo del usuario",
        "user_assignment.model": "Empleo de usuario",
        "user_assignment.model.plural": "Empleos de usuario",
        "user_group.model": "Grupo de usuarios",
        "user_group.model.plural": "Grupos de usuarios",
        "validation.action.add_manually": "Agregar un validador",
        "validation.action.skip": "Omitir el validador",
        "validation.after_or_equal": "{attribute} debe ser posterior o igual a {date}.",
        "validation.all": "Todas las validaciones",
        "validation.before_or_equal": "{attribute} debe ser anterior o igual a {date}.",
        "validation.between.numeric.short": "El valor debe estar entre {min} y {max}.",
        "validation.budget_key_not_valid": "Clave de presupuesto no válida",
        "validation.confirm.added": "Validación agregada",
        "validation.confirm.bulk_validated": "Elementos validados en masa: {items}",
        "validation.confirm.no_bulk_validated": "Ningún elemento ha sido validado en masa",
        "validation.confirm.refused": "El rechazo se ha realizado.",
        "validation.confirm.validated": "La validación se ha realizado.",
        "validation.date": "El campo {attribute} no es una fecha válida.",
        "validation.date_format": "El campo {attribute} no se corresponde con el formato {format}.",
        "validation.different_from": "El valor de {attribute} debe ser diferente de {value}.",
        "validation.dimensions.short": "La imagen tiene dimensiones inválidas",
        "validation.emails": "Compruebe el formato de la dirección de correo electrónico de cada línea",
        "validation.error.bulk_validated": "Elementos imposibles de validar en masa: {items}",
        "validation.error.cant_validate": "No se puede validar",
        "validation.error.please_validate_manually": "Por favor valide manualmente",
        "validation.field.must_be": "El campo {attribute} debe ser \"{value}\" ",
        "validation.field.unauthorized_characters": "El campo {attribute} contiene caracteres no autorizados",
        "validation.gt.numeric": "El valor de {attribute} debe ser mayor que {value}.",
        "validation.integer": "El campo {attribute} debe ser un número entero.",
        "validation.lt.numeric": "El valor de {attribute} debe ser menor que {value}.",
        "validation.max.string": "El texto de {attribute} no puede contener más de {max} caracteres.",
        "validation.model": "Validación",
        "validation.model.gender": "2",
        "validation.model.plural": "Validaciones",
        "validation.must_be_multiple": "La cantidad debe ser un múltiplo de {step}",
        "validation.not_link_to": "{item1} no está relacionado/a con {item2}",
        "validation.not_regex": "El formato del campo {attribute} no es válido.",
        "validation.not_specified": "{1}{attribute} no completado|{2}{attribute} no completada",
        "validation.not_valid": "no válido",
        "validation.orphan": "Inconsistencia entre : {child} y {parent}",
        "validation.phone": "El campo {attribute} debe ser un número válido.",
        "validation.prohibited_unless.other_is_null": "El campo {attribute} no debe completarse si {other} sí lo está.",
        "validation.refuse": "Denegación de validación",
        "validation.refuse.taken": "Denegación de validación consideración",
        "validation.refuse.takenThank": "Su rechazo se ha tomado en consideración.",
        "validation.required": "El campo {attribute} es obligatorio.",
        "validation.required.short": "Campo obligatorio",
        "validation.required.valid_iban": "Se requiere un IBAN válido",
        "validation.required_one": "Al menos uno de estos campos es obligatorio: {attributes}",
        "validation.required_without": "El campo {attribute} es obligatorio cuando {values} ​​​​no está completo.",
        "validation.rights": "No tienes derechos suficientes para seleccionar este elemento",
        "validation.unique": "El valor del campo {attribute} ya está en uso.",
        "validation.validate.taken": "Validación consideración",
        "validation.validate.takenThank": "Gracias por su validacion.",
        "validation.without_spaces": "Este campo no debe contener espacios",
        "validation.wrong": "El campo {attribute} no es correcto",
        "validationCriterion.field.model": "Modelo vinculado",
        "validationProcess.infos.supplier": "Solo si el nivel de complejidad de la activación del proveedor es \"simple\" ",
        "validationProcess.testIndex": "Prueba de circuito de validación",
        "validation_default_chart.copy": "Copiar los validadores",
        "validation_default_chart.create": "Agregar un validador",
        "validation_default_chart.error.criteria_not_exists": "Este criterio no está disponible.",
        "validation_default_chart.field.criterion.error.not_available": "{1}Este {atributo} no está o ya no está disponible|{2}Esta {atributo} no está o ya no está disponible",
        "validation_default_chart.field.standard": "Circuito estándar",
        "validation_default_chart.field.weight": "Rango",
        "validation_default_chart.info.simplified": "Simplificado (no aparecerá en el circuito final)",
        "validation_default_chart.info.weight": "Posición relativa a otros validadores del mismo criterio",
        "validation_default_chart.model": "Validador",
        "validation_default_chart.model.plural": "Validadores",
        "validation_default_chart.remove": "Eliminar el validador",
        "validation_default_chart.replace": "Reemplazar un validador",
        "validation_default_chart.replace.info": "Reemplazar un validador en todo el circuito de validación \"{model}\" ",
        "validation_default_chart.update": "Modificación del validador",
        "validation_process.add_overrun_model_process": "Si se excede {model}: agregue el circuito de validación de {models}",
        "validation_process.auto_add": "Adición automática al inicio del circuito: {value}",
        "validation_process.field.amount_overrun": "{1}Si se excede el {model}|{2}Si se excede la {model}",
        "validation_process.field.budget_overrun": "En caso de sobrepaso del presupuesto",
        "validation_process.info.not_standard": "No presente en el Circuito estándar",
        "validation_process.model": "Circuito de validación",
        "validation_process.model.AUTO_ADD_RECIPIENT": "{recipient} de {model}",
        "validation_process.model.plural": "Circuitos de validación",
        "validation_process.purchase_order.AUTO_ADD_NEEDS_RECIPIENTS": "{recipients} de {need_model}",
        "validation_process.supplier_invoice.AUTO_ADD_PO_RECIPIENTS": "{recipients} de {po_models} de {invoice_model}",
        "validation_process.supplier_invoice.REMOVE_WHEN_SAME_AMOUNT_AS_PO": "No hay circuito si la cantidad = cantidad de {po_models}",
        "variant.label.' + option.label + ": "-",
        "variant.label.danger": "Peligro",
        "variant.label.dark": "Oscuro",
        "variant.label.info": "Información",
        "variant.label.light": "Claro",
        "variant.label.primary": "Primario",
        "variant.label.secondary": "Secundario",
        "variant.label.success": "Éxito",
        "variant.label.warning": "Advertencia",
        "vat_type.model": "Tipo de IVA",
        "vat_type.model.gender": "1",
        "vat_type.model.plural": "Tipos de IVA",
        "verify.email.checkLink": "Antes de continuar, revise su correo electrónico para ver el enlace de verificación",
        "verify.email.confirmation": "Se ha enviado un nuevo enlace de verificación a su dirección de correo electrónico.",
        "verify.email.notReceived": "Si no recibió el correo electrónico",
        "verify.email.requestAnother": "haga clic aquí para solicitar otra",
        "verify.index": "Verifique su dirección de correo electrónico",
        "warehouse.action.add_to": "Agregar al Almacén",
        "warehouse.confirmation_request.update_allocations": "{1}La modificación de las asignaciones creará un {model} para reequilibrar los análisis presupuestarios.|{2}La modificación de las asignaciones creará una {model} para reequilibrar los análisis presupuestarios.",
        "warehouse.field.storekeeper": "Encargado de tienda",
        "warehouse.field.storekeeper.plural": "Encargados de tienda",
        "warehouse.model": "Almacén de stock",
        "warehouse.model.plural": "Almacenes de stock",
        "warehouse.other": "Otros almacenes",
        "wysiwyg.info.insert_text_here": "Insertar texto aquí...",
        "auth": {
            "failed": "Nombre de usuario y/o contraseña inválido(s)",
            "throttle": "Demasiados intentos de conexión. Vuelva a intentarlo en: segundos segundos."
        },
        "passwords": {
            "Hello!": "Buenos dias !",
            "Reset Password Notification": "Restablecer su contraseña",
            "You are receiving this email because we received a password reset request for your account.": "Está recibiendo este correo electrónico porque hemos recibido una solicitud de restablecimiento de contraseña para su cuenta.",
            "Reset Password": "Restablecer la contraseña",
            "This password reset link will expire in {count} minutes.": "Este enlace de restablecimiento caducará en {count} minutos.",
            "If you did not request a password reset, no further action is required.": "Si no solicitó un restablecimiento de contraseña, no se requiere ninguna otra acción.",
            "If you’re having trouble clicking the \"{actionText}\" button, copy and paste the URL below\ninto your web browser:": "Si no puede hacer clic en el botón \"{actionText}\", copie y pegue la siguiente URL\nen su navegador web"
        }
    },
    "fr": {
        "accounting_export.download.filename": "export-compta-",
        "accounting_export.error.currently_ongoing": "{1}Un {model} est déjà en cours d'export. Veuillez le valider avant de recommencer.|{2}Une {model} est déjà en cours d'export. Veuillez la valider avant de recommencer.",
        "accounting_export.error.different_company": "{1}Le {model} est différent des autres {invoice_model}|{2}La {model} est différente des autres {invoice_model}",
        "accounting_export.error.invoice_already_exported": "{1}Au moins un {model} a déjà été exporté.|{2}Au moins une {model} a déjà été exportée.",
        "accounting_export.error.invoice_not_awaiting_nor_validated": "{1}Le {model} n'est pas En attente ni Validé|{2}La {model} n'est pas En attente ni Validée",
        "accounting_export.error.invoice_not_validated": "{1}Le {model} n'est pas Validé|{2}La {model} n'est pas Validée",
        "accounting_export.error.invoice_status_not_awaiting_nor_validated": "{1}Au moins un {model} n'est pas En attente ni Validé.|{2}Au moins une {model} n'est pas En attente ni Validée.",
        "accounting_export.error.invoice_status_not_validated": "{1}Au moins un {model} n'est pas Validé.|{2}Au moins une {model} n'est pas Validée.",
        "accounting_export.error.missing_accounting_entry": "{1}Le {invoice_model} a au moins une ligne sans {model}|{2}La {invoice_model} a au moins une ligne sans {model}",
        "accounting_export.error.unbalanced_invoice": "{1}{model} non équilibré|{2}{model} non équilibrée",
        "accounting_export.field.account_number": "Code compte",
        "accounting_export.field.analitycs": "Axe",
        "accounting_export.field.invoices.error.different_company": "Les {company_model} des {invoice_model} doivent être les mêmes.",
        "accounting_export.field.third_parties": "Compte tiers",
        "accounting_export.model": "Export Compta",
        "accounting_export.model.gender": "1",
        "accounting_export.model.plural": "Exports Compta",
        "accounting_export.pdf.title": "Détails de l'Export Compta",
        "address.field.address1": "Adresse",
        "address.field.address2": "Complément d'adresse 1",
        "address.field.address3": "Complément d'adresse 2",
        "address.field.company": "Société",
        "address.field.recipient": "A l'attention de",
        "address.field.zip_code": "Code postal",
        "add_models_massively.error.ambiguous_value_for": "{reference} est ambigue pour {for}, veuillez l'ajouter manuellement",
        "add_models_massively.error.does_not_exists_for": "{reference} n'existe pas pour {for}",
        "add_models_massively.error.is_disabled_for": "{reference} n'est pas actif pour {for}",
        "admin.dashboard": "Admin",
        "allocations.import.unique.code": "Ligne {line} : le code {code} de {allocation} est déjà utilisé avec un autre libellé que \"{label}\", les données de la ligne n'ont pas pu être ajoutées.",
        "api.purchase_order.error.back_to_draft.already_billed": "{model} déjà facturé(e), même partiellement",
        "api.purchase_order.error.back_to_draft.already_delivered": "{model} déjà reçu(e), même partiellement",
        "api.purchase_order.error.back_to_draft.already_paid": "{model} déjà payé(e), même partiellement",
        "api.purchase_order.error.back_to_draft.already_sent_at": "{model} déjà envoyé(e) au fournisseur, vous pouvez forcer la remise en brouillon avec le paramètre \"confirm_when_sent\" valant \"true\" ",
        "api_log.model": "Log d'API entrante",
        "api_log.model.plural": "Logs d'API entrante",
        "article.catalog.internal": "Catalogue interne",
        "article.catalog.punchout": "Catalogue PunchOut",
        "article.catalog.stock": "Catalogue stock",
        "article.catalog.supplier": "Catalogue fournisseur",
        "article.catalog.supplier_group": "Catalogue groupe fournisseur",
        "article.error.change_type": "Impossible de changer le type d'un article déjà utilisé",
        "article.error.does_not_exist": "{1}{model} non existant. Vous pouvez le créer à la place.|{2}{model} non existante. Vous pouvez la créer à la place.",
        "article.error.does_not_exist_in_warehouse": "{1}{model} non existant dans l'Entrepôt. Vous pouvez le créer à la place.|{2}{model} non existante dans l'Entrepôt. Vous pouvez la créer à la place.",
        "article.error.unbindReferences": "Vous devez supprimer les articles affiliés à cet article avant de changer son état interne.",
        "article.error.unbindStock": "Vous devez vider les stocks de cet article avant de changer son état interne.",
        "article.field.affiliate": "Articles affiliés",
        "article.field.link": "Lien vers la fiche article fournisseur",
        "article.field.ordered_quantity_current_year": "Quantité commandée les 12 derniers mois",
        "article.field.ordered_quantity_current_year.abbr": "Qté cmdée les 12 derniers mois",
        "article.field.ordered_quantity_last_year": "Quantité commandée les 12 mois précédents",
        "article.field.ordered_quantity_last_year.abbr": "Qté cmdée les 12 mois précédents",
        "article.field.search.favorite": "Uniquement les {model} favoris",
        "article.field.search.labeled": "Uniquement les {model} \"{label}\" ",
        "article.field.type": "Type d'Article",
        "article.internal.model": "{model} interne",
        "article.internal.model.plural": "{model} internes",
        "article.rapid_search": "Recherche rapide d'articles",
        "article.title.packing": "Conditionnement",
        "article.type.group": "Groupe",
        "article.type.internal": "Interne",
        "article.type.supplier": "Fournisseur",
        "article.unit_price.info": "Soit {price} / {unit}",
        "auth.login.index": "Connexion",
        "auth.login.sso": "Connexion SSO",
        "auth.login.standard": "Connexion standard",
        "auth.login.with": "Connexion avec {idp}",
        "auth.login_request.confirmed": "Un e-mail contenant un lien de connexion vous a été envoyé.",
        "auth.login_request.enter_your_email": "Renseignez votre adresse e-mail pour demander une connexion",
        "auth.login_request.supplier.title": "Demande de Connexion au Portail Fournisseur",
        "auth.register.index": "Inscription",
        "bank_account.account_number_type.iban": "IBAN",
        "bank_account.account_number_type.other": "Autre",
        "bank_account.export.filename": "ribs",
        "bank_account.field.account_number_type": "Type de numéro",
        "bank_account.field.country_code": "Code pays",
        "bank_account.field.country_code.help": "Abréviation à deux lettres du pays, suivant l'ISO-3166 pour l'adresse décrite",
        "bank_account.field.file": "Relevé d'identité bancaire",
        "bank_account.holder_type.corporate": "corporate",
        "bank_account.holder_type.individual": "individual",
        "bank_account.model": "Compte bancaire",
        "bank_account.model.plural": "Comptes bancaires",
        "base_model.model": "Modèle",
        "boomerangweb.action.more_info": "Plus d'infos",
        "boomerangweb.info.actions": "Maîtrisez votre processus d'achat, pilotez vos dépenses, validez vos achats.",
        "boomerangweb.info.definition": "Le logiciel simple et intuitif pour la gestion des achats.",
        "budget.confirm.generatedFromBudgetAnalysis": "Budgets générés : {count}",
        "budget.duplicate": "Duplication d'un budget",
        "budget.error.alreadyExists": "Ce budget existe déjà, veuillez sélectionner d'autres clés.",
        "budget.field.copy_amounts": "Copier les budgets prévisionnels",
        "budget.field.new_tag": "Tag",
        "budget.generateFromBudgetAnalysis": "Générer depuis l'analyse budgétaire",
        "budget.generateFromBudgetAnalysis.info": "La liste ci-dessous reprend les éléments présents dans l'analyse budgétaire et non existants dans les budgets.",
        "budget.generateFromBudgetAnalysis.noBudgetAnalysis": "Il n'y a rien à importer de l'analyse budgétaire.",
        "budget.generateFromBudgetAnalysis.warning": "Attention : les filtres appliqués sur la page \"{page}\" sont appliqués ici aussi.",
        "budget.importer.field.file.help": "Le nom de chaque colonne doit correspondre au caractère près au nom de cette colonne dans l'export ci-dessus.<br>Toutes les colonnes \"Code\" sont obligatoires, les colonnes \"Libellé\" ne sont requises qu'en cas de création d'une imputation, etc.",
        "budget.info.last_validated": "Dernier validé : {amount}",
        "budget.model": "Budget",
        "budget.model.plural": "Budgets",
        "budget.overrun.question": "{1}Voulez-vous quand même envoyer ce {model} en validation ?|{2}Voulez-vous quand même envoyer cette {model} en validation ?",
        "budget.overrun.question.in_validation": "{1}Voulez-vous quand même valider ce {model} ?|{2}Voulez-vous quand même valider cette {model} ?",
        "budget.overrun.warning": "Attention ! Certaines lignes budgétaires sont en dépassement.",
        "budget.overrun_action.block": "Notifier l'utilisateur et bloquer l'envoi",
        "budget.overrun_action.none": "Aucune action",
        "budget.overrun_action.notification": "Notifier l'utilisateur",
        "budget.requiredSearchfield": "Veuillez remplir tous les champs de recherche",
        "budget.value.undefined": "- par défaut -",
        "budget_amount.model": "Montant de budget",
        "budget_amount.model.plural": "Montants de budget",
        "budget_analysis.filter.stock_type.all": "Avec les mouvements de stock",
        "budget_analysis.filter.stock_type.no_stock": "Sans les mouvements de stock",
        "budget_analysis.filter.stock_type.only_stock": "Seulement les mouvements de stock",
        "budget_analysis.info.amount_of_models": "montant des {model}",
        "budget_analysis.info.billed": "montant des {modelInvoice} \"{awaiting}\" et \"{validated}\" ",
        "budget_analysis.info.delivered_not_billed": "{1}montant des {modelPO} réceptionnés non encore facturés.|{2}montant des {modelPO} réceptionnées non encore facturées.",
        "budget_analysis.info.prorated_amounts": "Les montants en italique sont des montants au Prorata temporis",
        "budget_analysis.info.validated": "{1}montant des {modelPO} validés non réceptionnés, non facturés et non cloturés.|{2}montant des {modelPO} validées non réceptionnées, non facturées et non cloturées.",
        "budget_analysis.model": "Analyse budgétaire",
        "budget_analysis.model.plural": "Analyses budgétaires",
        "budget_analysis.overrun": "Dépassement de budget",
        "budget_version.model": "Version",
        "budget_version.model.plural": "Versions",
        "cart.action.add": "Ajouter au panier",
        "cart.action.empty": "Vider le panier",
        "cart.confirm.converted_to": "{1}Les {article_model} sélectionnés dans le panier ont bien été convertis en {model}|{2}Les {article_model} sélectionnées dans le panier ont bien été converties en {model}",
        "cart.error.no_cart_mode": "Le panier ne peut pas être converti",
        "cart.error.require_items": "{1}Le panier doit contenir au moins un {model}|{2}Le panier doit contenir au moins une {model}",
        "cart.info.no_selected_items_remain_in_cart": "{1}Les {model} non sélectionnés resteront dans le panier|{2}Les {model} non sélectionnées resteront dans le panier",
        "cart.model": "Panier",
        "catalog.module": "Catalogue",
        "catalog.stock.module": "Gestion du stock|Gestion des Stocks",
        "central.dashboard": "Données centrales",
        "comment.model": "Commentaire",
        "comment.model.plural": "Commentaires",
        "company.download.filename": "conditions_generales_d_achat",
        "company.field.accounting_export_prefix.help": "Une fois renseigné, ce champ ne pourra plus être changé",
        "config.dashboard": "Configuration",
        "config.info.no_available_module": "Aucun module disponible",
        "config.title.available_modules": "Modules disponibles",
        "contact.alert.duplicateLogin": "Ce contact a une adresse e-mail qui est déjà utilisée en tant que login d'utilisateur. Pour qu'il puisse accéder à Boomerang Web, veuillez changer son email.",
        "contact.alert.email": "Le contact doit avoir un email pour accéder à Boomerang Web",
        "contact.field.identity": "Identité",
        "contact.field.is_main": "Contact principal",
        "contact.field.is_main_billing_contact": "Contact principal Facture",
        "contact.field.is_main_commercial_contact": "Contact principal Commande",
        "contact.field.language": "Langue du contact",
        "contact.field.softwareAccess": "Accès à Boomerang Web",
        "contact.field.types": "Types",
        "contact.model.plural": "Contacts",
        "contact.prevent.sendMailToRegistration": "Un mail va être envoyé à votre contact pour qu'il puisse accéder à l'application.",
        "contact.prevent.sendMailToStopAccess": "Ce contact va perdre son accès",
        "counter.model": "Compteur",
        "counter.model.plural": "Compteurs",
        "country.model": "Pays",
        "country.model.plural": "Pays",
        "currency.auto_update_rates": "Mettre à jour les taux",
        "currency.confirm.updated_rates": "Mise à jour des taux de change réussie",
        "currency.field.rate_for_one_default_currency": "Taux pour 1 {currency}",
        "currency.field.symbol": "Symbole",
        "currency.info.updating_rates": "Mise à jour des taux de change en cours",
        "currency.model": "Devise",
        "currency.model.gender": "2",
        "currency.model.plural": "Devises",
        "currency.position.after": "Après",
        "currency.position.before": "Avant",
        "custom_field.model": "Champ Personnalisé",
        "custom_field.model.plural": "Champs Personnalisés",
        "dashboard.chart.comparison": "Comparaison des montants des {model} entre N-1 et N en {currency} HT",
        "dashboard.chart.comparison.last_year": "Année N-1",
        "dashboard.chart.comparison.year": "Année N",
        "dashboard.chart.distribution": "Répartition des {model} sur les 3 derniers mois par {allocation}",
        "dashboard.chart.distribution.other": "AUTRE",
        "dashboard.chart.global": "Montant total",
        "dashboard.chart.global.delivery_awaiting": "{number} {model} en attente de validation",
        "dashboard.chart.global.invoice_awaiting": "{number} {model} en attente de validation",
        "dashboard.chart.global.invoice_not_received": "{model} non parvenues",
        "dashboard.chart.global.needs_awaiting": "{number} {model} en attente de traitement",
        "dashboard.chart.global.purchase_order_awaiting": "{number} {model} en attente de validation",
        "dashboard.chart.global.purchase_order_not_received": "{model} non réceptionnés",
        "dashboard.chart.instance": "Votre abonnement",
        "dashboard.chart.instance.projection_purchase_order": "Projection de {model} jusqu'au {date}",
        "dashboard.chart.instance.purchase_order": "Nombre de {model} depuis le {date}",
        "dashboard.chart.instance.storage": "Espace de stockage utilisé",
        "dashboard.chart.instance.subscription": "Renouvellement de votre abonnement",
        "dashboard.chart.instance.user": "Nombre d'{model}",
        "dashboard.no_chart": "Utilisez le menu principal pour naviguer",
        "data_accuracy.actions.clean_validators": "Nettoyer ces valideurs",
        "data_accuracy.actions.put_into_main_bank_account": "Mettre ces RIB en RIB principaux",
        "data_accuracy.info.large_problems_detected": "Beaucoup de problèmes détectés (limite d'affichage: {count})",
        "data_accuracy.info.problems_detected": "{count} problème détecté|{count} problèmes détectés",
        "data_accuracy.info.too_many_to_check": "Il y a trop de {model} pour les vérifier ({count})",
        "data_accuracy.stock.in_quantity_awaiting": "Entrée - Quantité en attente",
        "data_accuracy.stock.in_quantity_validated_not_delivered": "Entrée - Quantité validée non reçue",
        "data_accuracy.stock.out_quantity_awaiting": "Sortie - Quantité en attente",
        "data_accuracy.stock.out_quantity_validated_not_delivered": "Sortie - Quantité validée non livrée",
        "data_import.action.add_column_before": "Insérer un champ avant",
        "data_import.action.remove_column": "Retirer le champ et décaler les suivants vers le haut",
        "data_import.action.save_template": "Sauvegarder le template",
        "data_import.columns_from_file": "Colonnes du fichier",
        "data_import.columns_to_import": "Champs de destination",
        "data_import.default_template": "Template par défaut",
        "data_import.error.cant_create_allocations": "Impossible de créer l'imputation : {allocations}|Impossible de créer les imputations : {allocations}",
        "data_import.error.column.already_exists": "La colonne \"{field}\" est renseignée en double",
        "data_import.error.column.contains_errors": "La colonne \"{field}\" contient des erreurs",
        "data_import.error.column.doesnt_exist_in_params": "La colonne \"{field}\" n'existe pas dans les paramètres",
        "data_import.error.column.missing": "La colonne \"{field}\" est manquante",
        "data_import.error.column.missing_for": "La colonne \"{field}\" est manquante pour ce Type d'import",
        "data_import.error.column.missing_or_not_in_form": "La colonne \"{field}\" est manquante ou doit être sélectionnée ci-dessus",
        "data_import.error.data_type.wrong": "Le champs {field} est erroné",
        "data_import.error.field.already_in_database": "La valeur de {field} est déjà présente dans la Base de Données",
        "data_import.error.field.already_in_database_for": "La valeur de {field} est déjà présente dans la Base de Données pour {for}",
        "data_import.error.field.already_in_import": "La valeur de {field} est déjà présente dans l'import",
        "data_import.error.field.does_not_exists": "La valeur de {field} n'existe pas",
        "data_import.error.field.does_not_exists_for": "La valeur de {field} n'existe pas pour {for}",
        "data_import.error.field.must_be_boolean": "\"{field}\" doit contenir un booléen",
        "data_import.error.field.must_be_duration": "\"{field}\" doit contenir une durée",
        "data_import.error.field.must_be_email": "\"{field}\" doit contenir une adresse email",
        "data_import.error.field.must_be_float": "\"{field}\" doit contenir un nombre",
        "data_import.error.field.must_be_int": "\"{field}\" doit contenir un entier",
        "data_import.error.field.must_be_max": "\"{field}\" doit contenir au maximum {max}",
        "data_import.error.field.must_be_min": "\"{field}\" doit contenir au minimum {min}",
        "data_import.error.field.required": "\"{field}\" est requis",
        "data_import.error.field.wrong_data": "\"{field}\" contient de mauvaises données",
        "data_import.error.field.wrong_linked_model": "{model} {value} non lié à {for}",
        "data_import.error.forbidden_warehouse": "Entrepôt interdit",
        "data_import.error.line": "Ligne {line} : ",
        "data_import.error.max_columns_exceeded": "Nombre maximum de colonnes dépassé ({max})",
        "data_import.error.must_contain_count_separated_values": "Doit contenir {count} valeurs séparées par \"{separator}\" ",
        "data_import.example_from_file": "Ligne {nb} du fichier",
        "data_import.field.data_type": "Type de données",
        "data_import.help.global": "Une cellule vide ne sera pas prise en compte.<br />Une cellule contenant la chaîne \"#null\" videra le champ si possible.",
        "data_import.info.allocations_will_be_created": "Les imputations seront créées si elle n'existe pas dans le système.",
        "data_import.info.article.original_price": "Prix original avant remise",
        "data_import.info.cant_change_when_template": "Impossible de changer lorsqu'un Template est sélectionné",
        "data_import.info.default": "La valeur #default permet de renseigner la valeur par défaut",
        "data_import.info.links_will_be_created": "Les liens entre imputations seront créés s'ils n'existent pas dans le système.",
        "data_import.info.no_allocation_created": "Aucune imputation ne sera créée si elle n'existe pas dans le système.",
        "data_import.info.no_link_created": "Aucuns liens entre imputations ne sera créé s'ils n'existent pas dans le système.",
        "data_import.info.starts_by_hash": "Si commence par \"#\", sera interprêté comme un ID",
        "data_import.info.starts_by_underscore": "Si commence par \"_\", sera interprêté comme un {attribute} de {model}",
        "data_import.param.allowed_values.title": "Valeurs autorisées",
        "data_import.param.ignored.title": "Cette colonne est ignorée",
        "data_import.param.ignored_for_field.title": "Cette colonne est ignorée pour le champ \"{field}\" sélectionné",
        "data_import.param.limits.max.details": "Max. : {value}",
        "data_import.param.limits.max.title": "Valeur max.",
        "data_import.param.limits.min.details": "Min. : {value}",
        "data_import.param.limits.min.title": "Valeur min.",
        "data_import.param.list_separator.title": "Valeurs séparées par",
        "data_import.param.type.boolean.details": "Booléen : oui/vrai/1 ou non/faux/0",
        "data_import.param.type.boolean.title": "Type booléen",
        "data_import.param.type.duration.details": "Durée, par exemple : P30D pour 30 jours, P1M pour 1 mois, P2Y pour 2 ans",
        "data_import.param.type.duration.title": "Type durée",
        "data_import.param.type.email.details": "E-mail",
        "data_import.param.type.email.title": "Type e-mail",
        "data_import.param.type.float.details": "Nombre, peut contenir des décimales",
        "data_import.param.type.float.title": "Type nombre",
        "data_import.param.type.int.details": "Entier",
        "data_import.param.type.int.title": "Type entier",
        "data_import.save_columns.question": "Voulez-vous sauvegarder ces éléments dans un Template ?",
        "data_import.title": "Import de données",
        "data_import.type.create": "Création",
        "data_import.type.create_and_replace": "Création/remplacement",
        "data_import.type.create_or_update_from": "Création/Modification par {field}",
        "data_import.type.update_from": "Modification par {field}",
        "datepicker.label_calendar": "Calendrier",
        "datepicker.label_current_month": "Mois en cours",
        "datepicker.label_help": "Utilisez les touches fléchées pour naviguer dans le calendrier",
        "datepicker.label_nav": "Navigation dans l'agenda",
        "datepicker.label_next_month": "Mois prochain",
        "datepicker.label_next_year": "Année prochaine",
        "datepicker.label_no_date_selected": "--",
        "datepicker.label_prev_month": "Mois précédent",
        "datepicker.label_prev_year": "Année précédente",
        "datepicker.label_selected": "Date sélectionnée",
        "datepicker.label_today": "Aujourd'hui",
        "delegation.confirm.notification_added": "L'utilisateur délégué recevra des notifications par email.",
        "delegation.confirm.notification_removed": "L'utilisateur délégué ne recevra plus de notifications par email.",
        "delegation.field.delegate": "Délégué",
        "delegation.field.notify_delegate": "Notifier régulièrement le délégué par email",
        "delegation.model": "Délégation",
        "delegation.model.gender": "2",
        "delegation.model.plural": "Délégations",
        "delivery.error.amount_exctax_to_deliver.less_than_delivered_amount": "Le montant HT doit être supérieur ou égal à {delivered} ({complement})",
        "delivery.error.amount_exctax_to_deliver.more_than_delivered_amount": "Le montant HT doit être inférieur ou égal à {delivered} ({complement})",
        "delivery.error.amount_inctax_to_deliver.less_than_delivered_amount": "Le montant TTC doit être supérieur ou égal à {delivered} ({complement})",
        "delivery.error.amount_inctax_to_deliver.more_than_delivered_amount": "Le montant TTC doit être inférieur ou égal à {delivered} ({complement})",
        "delivery.error.amount_to_deliver.complement.delivered": "montant déjà réceptionné{complement}",
        "delivery.error.amount_to_deliver.complement.not_delivered": "montant non réceptionné{complement}",
        "delivery.error.total_amount_exctax_to_deliver.must_be_between": "Le montant HT total doit être entre {left} et {right}",
        "delivery.error.total_amount_inctax_to_deliver.must_be_between": "Le montant TTC total doit être entre {left} et {right}",
        "delivery.field.code": "Num. interne",
        "delivery.field.label": "Numéro de BL",
        "delivery.info.has_linked_invoices": "{1}Attention, ce {model} est potentiellement lié à des {invoice_model}.|{2}Attention, cette {model} est potentiellement liée à des {invoice_model}.",
        "delivery.model": "Réception",
        "delivery.model.plural": "Réceptions",
        "delivery.pdf.title": "Bon de Réception",
        "deliveryLine.model": "Ligne de réception",
        "delivery_address.action.create_address": "Créer une adresse de livraison",
        "delivery_address.action.edit_address": "Modifier l'adresse de livraison",
        "delivery_address.address_adds": "Informations de livraison",
        "delivery_address.deadline": "Livraison au plus tard",
        "delivery_address.field.address_type.addressbook": "Carnet d'adresse",
        "delivery_address.field.public": "Publique ?",
        "delivery_address.from": "{1}Adresse du {model}|{2}Adresse de la {model}",
        "delivery_address.from.addressbook": "Carnet d'adresse",
        "delivery_address.from.other": "Autre adresse",
        "delivery_address.model": "Adresse de livraison",
        "delivery_address.model.gender": "2",
        "delivery_address.model.plural": "Adresses de livraison",
        "delivery_address.shipping_date": "Date d'expédition",
        "delivery_address.type": "Type d'adresse",
        "docuware.error.DPIIBoomExport": "Un problème est survenu lors de la tentative de récupération de l'ID de document {id}",
        "docuware.error.DPIIBoomExport_delay": "Un problème est survenu lors de la tentative de récupération de l'ID de document {id}, nouvel essai dans {minutes} minutes",
        "docuware.error.GetNotImplementedDWDOCID": "Un problème est survenu lors de la tentative de récupération des documents non implémentés",
        "error.301.description": "Elle a changé d'URL et sera bientôt définitivement supprimée. Voici la nouvelle URL :",
        "error.301.title": "Cette page n'existe plus",
        "error.301.warning": "Merci de modifier vos favoris avec cette nouvelle URL",
        "error.401.description": "Vous n'êtes plus connecté(e).",
        "error.401.link_text": "Cliquez ici pour vous reconnecter",
        "error.401.title": "Non connecté",
        "error.403.description": "Cette action n'est pas autorisée.",
        "error.403.title": "Non autorisé",
        "error.404.description": "Cette page n'existe pas.",
        "error.404.title": "Erreur 404",
        "error.410.title": "Application désactivée",
        "error.498.description": "Votre lien est expiré",
        "error.498.title": "Erreur 498",
        "error.500.description": "Erreur serveur.",
        "error.500.title": "Erreur 500",
        "error.502.description": "Réponse du serveur non valide.",
        "error.503.description": "Le site est actuellement en maintenance.",
        "error.503.get_message": "Message complémentaire : <em>{message}</em>",
        "error.503.retry_after": "Merci de réessayer dans {delay} secondes.",
        "error.503.retry_later": "Merci de réessayer plus tard.",
        "error.503.title": "Site en maintenance",
        "error.cantChangeStatus": "Erreur pendant la modification du statut, merci de réessayer plus tard.",
        "error.cantCreate": "Erreur pendant la création, merci de réessayer plus tard.",
        "error.cantDelete": "Erreur pendant la suppression, merci de réessayer plus tard.",
        "error.cantImport": "Erreur pendant l'import, merci de réessayer plus tard.",
        "error.cantModify": "Erreur pendant la modification, merci de réessayer plus tard.",
        "error.cantRefuse": "Impossible de refuser cet élément.",
        "error.cantSort": "Impossible d'ordonner ces éléments.",
        "error.cantValidate": "Impossible de valider cet élement.",
        "error.cant_create_payment": "Erreur pendant la création du paiement, une ou plusieurs factures sont déjà payées, en attente de paiement ou ne sont pas encore validées.",
        "error.cant_modify": "Erreur pendant la modification, merci de réessayer plus tard.",
        "error.clamav": "Votre fichier contient probablement un virus",
        "error.duration.wrong_unit": "Le type de période doit correspondre à un choix existant.",
        "error.field.requireOne": "Au moins l'un de ces champs est requis.",
        "error.file.bad_extension": "Le fichier uploadé n'est pas de la bonne extension.",
        "error.form_errors": "Action impossible : il y a des erreurs dans le formulaire.",
        "error.form_errors.title": "Erreur",
        "error.go_back_home": "Retour sur la page d'accueil",
        "error.has_been_deleted": "{1}{attribute} supprimé, merci d'en choisir un autre|{2}{attribute} supprimée, merci d'en choisir une autre",
        "error.has_been_disabled": "{1}{attribute} désactivé, merci d'en choisir un autre|{2}{attribute} désactivée, merci d'en choisir une autre",
        "error.import_errors": "Import impossible, cliquez ici pour voir le détail des erreurs",
        "error.impossible_action": "Action impossible",
        "error.impossible_action.title": "Erreur {code}",
        "error.maintenance.description": "Maintenance en cours",
        "error.maintenance.index": "Site en maintenance",
        "error.missing_permission": "Vous n'avez pas les permissions nécessaires.",
        "error.module.misconfigured": "Module mal configuré",
        "error.not_compatible": "{attribute} non compatible",
        "error.not_enabled": "{1}{attribute} non actif|{2}{attribute} non active",
        "error.not_exists": "{1}{attribute} n'existe pas, merci d'en choisir un autre|{2}{attribute} n'existe pas, merci d'en choisir une autre",
        "error.not_found": "{1}{attribute} non trouvé|{2}{attribute} non trouvée",
        "error.no_model": "Il n'y a pas de {model}",
        "error.no_pdf_viewer": "Vous ne semblez pas avoir de lecteur de PDF intégré à votre navigateur. Vous pouvez cependant télécharger le document ci-dessous.",
        "error.problem_occured": "Un problème est survenu",
        "error.unknown": "Erreur inconnue",
        "external_charge.model": "Charge externe",
        "external_charge.model.plural": "Charges externes",
        "faq.add": "Ajouter une question",
        "faq.all": "Voir toute l'aide",
        "faq.model": "FAQ",
        "feature.model": "Caractéristique d'article",
        "feature.model.gender": "2",
        "feature.model.plural": "Caractéristiques d'article",
        "fieldable.position.bottom": "En bas",
        "fieldable.position.line": "Dans les lignes",
        "fieldable.position.top": "En en-tête",
        "fieldable.visualizeCustomFields": "Visualiser le rendu des champs",
        "file.confirm.created": "Le document a bien été envoyé.",
        "file.confirm.deleted": "Le document a bien été supprimé.",
        "file.confirm.statusValidate": "Le statut du document a bien été changé.",
        "file.confirm.updated": "Le document a bien été modifié.",
        "file.info.not_available": "Fichier non disponible.",
        "file.model.gender": "1",
        "file.model.plural": "Fichiers",
        "file_category.model.gender": "2",
        "file_upload.action.tap_to_retry": "Cliquer pour réessayer",
        "file_upload.error.code": "Erreur {code}. Veuillez contacter votre administrateur avec une capture d'écran de cette page.",
        "file_upload.error.file_already_exists": "Le fichier existe déjà",
        "file_upload.error.file_empty": "Le fichier est vide",
        "file_upload.error.file_processing_error": "Erreur lors du téléchargement",
        "file_upload.error.file_too_large": "Le fichier est trop lourd",
        "file_upload.error.file_too_large.description": "La taille maximum autorisée est {filesize}",
        "file_upload.error.wrong_type": "Type de fichier non valide",
        "file_upload.error.wrong_type.description": "Requiert le(s) type(s) : {allTypes}",
        "filter.automatic": "Rempli automatiquement",
        "filter.cant_deselect_label": "Impossible de retirer",
        "filter.deselectLabel": "Retirer",
        "filter.deselect_label": "Retirer",
        "filter.doSearch": "Commencer à taper pour rechercher",
        "filter.filter_to_show_more": "Effectuez une recherche afin d'affiner les résultats",
        "filter.is_not": "{model} n'est pas",
        "filter.no_options": "La liste est vide.",
        "filter.no_result": "Aucun élément trouvé.",
        "filter.placeholder": "Sélectionner une option",
        "filter.search.contains": "Contient",
        "filter.search.ends_by": "Finit par",
        "filter.search.starts_by": "Commence par",
        "filter.search.title": "Recherche",
        "filter.selected_label": "Sélectionné",
        "filter.select_label": " ",
        "filter.tag_placeholder": "Appuyez sur Entrée pour ajouter à la recherche",
        "filter.type_to_search": "Taper pour rechercher",
        "finance.module": "Finances",
        "fiscal_period.error.code_exists": "Ce code de {model} existe déjà.",
        "fiscal_period.error.overlap": "Il y a un chevauchement de {model}.",
        "fiscal_period.field.format": "Format du code",
        "fiscal_period.field.format_help_block": "Vous pouvez utiliser les jetons suivants (dans la limite de 10 caractères) :<br>{year4char} : Année sur 4 chiffres<br>{nextYear4char} : Année suivante sur 4 chiffres<br>{year2char} : Année sur 2 chiffres<br>{nextYear2char} : Année suivante sur 2 chiffres<br>{month2char} : Mois sur 2 chiffres<br>{nextMonth2char} : Mois suivant sur 2 chiffres<br>{periodNumber} : Numéro de période sur 2 chiffres<br>{day2char} : Jour sur 2 chiffres<br>{weekNumber} Numéro de semaine sur 2 chiffres<br>",
        "geographic_sector.field.parent_id": "Parent",
        "geographic_sector.field.requires_duns": "Requiert le DUNS / ID Code",
        "geographic_sector.field.requires_intra_community_vat": "Requiert la TVA intra-communautaire",
        "geographic_sector.field.requires_siret": "Requiert le SIRET",
        "geographic_sector.model": "Secteur géographique",
        "geographic_sector.model.plural": "Secteurs géographiques",
        "global.acknowledge_receipt.already_done.content": "{1}Ce {model} a déjà été réceptionné.|{2}Cette {model} a déjà été réceptionnée.",
        "global.acknowledge_receipt.confirmation.content": "{1}Merci d'avoir accusé réception de ce {model}.|{2}Merci d'avoir accusé réception de cette {model}.",
        "global.acknowledge_receipt.confirmation.title": "Accusé de réception",
        "global.action.access": "Accéder",
        "global.action.actionIrreversible": "Cette action est irréversible, et ne pourra pas être annulée par la suite.",
        "global.action.add": "Ajouter",
        "global.action.add_all_lines": "Ajouter toutes les lignes",
        "global.action.add_article": "Ajouter un article",
        "global.action.add_checked_lines": "Ajouter les lignes cochées",
        "global.action.add_comment": "Ajouter un commentaire",
        "global.action.add_favorite": "Ajouter aux favoris",
        "global.action.add_gap_line": "Ajouter une ligne d'écart",
        "global.action.add_in_new_line": "Ajouter dans une nouvelle ligne",
        "global.action.add_line": "Ajouter une ligne",
        "global.action.add_line_from_catalog": "Ajouter depuis le catalogue",
        "global.action.add_line_from_punchout_catalog": "Catalogue PunchOut",
        "global.action.add_model": "{1}Ajouter un {model}|{2}Ajouter une {model}",
        "global.action.add_models": "Ajouter des {model}",
        "global.action.add_models_massively": "Ajouter massivement des {model}",
        "global.action.add_to_catalog": "Ajouter dans le catalogue",
        "global.action.add_to_model": "{1}Ajouter au {model}|{2}Ajouter à la {model}",
        "global.action.add_to_model_catalog": "{1}Ajouter dans le catalogue du {model} \"{entity}\"|{2}Ajouter dans le catalogue de la {model} \"{entity}\" ",
        "global.action.adjust": "Réajuster",
        "global.action.adjust_stock": "Ajuster le stock",
        "global.action.approve": "Approuver",
        "global.action.are_you_really_sure": "Êtes-vous vraiment sûr(e) ?",
        "global.action.are_you_sure": "Êtes-vous sûr(e) ?",
        "global.action.autofill": "Remplir automatiquement",
        "global.action.autofill_new_line": "Remplir automatiquement dans une nouvelle ligne",
        "global.action.back_to_awaiting": "Repasser en attente de traitement",
        "global.action.back_to_default": "Remettre par défaut",
        "global.action.back_to_draft": "Repasser en brouillon",
        "global.action.back_to_pending": "Repasser en attente de traitement",
        "global.action.break_down_expenses": "Ventiler les dépenses",
        "global.action.browse": "Parcourir",
        "global.action.cancel": "Annuler",
        "global.action.cancel_and_replace": "Annuler et remplacer",
        "global.action.cancel_model": "{1}Annuler le {model}|{2}Annuler la {model}",
        "global.action.change_exctax": "Afficher les prix en HT",
        "global.action.change_inctax": "Afficher les prix en TTC",
        "global.action.change_weight": "Modifier l'ordre",
        "global.action.check_all": "Tout cocher",
        "global.action.choice_between": "Choix entre {first} et {second}",
        "global.action.choose_image": "Choisir cette image",
        "global.action.clear": "Vider",
        "global.action.clear_selection": "Vider la sélection",
        "global.action.close": "Fermer",
        "global.action.close_dispute": "Clôturer le litige",
        "global.action.close_something": "Clôturer",
        "global.action.configure": "Configurer",
        "global.action.confirm": "Confirmer",
        "global.action.confirm_by_checking": "Merci de cocher la case suivante afin de confirmer votre action :",
        "global.action.confirm_configuration": "Confirmer la configuration",
        "global.action.consider_sent": "Considérer comme envoyé",
        "global.action.contact_us": "Nous contacter",
        "global.action.continue": "Continuer",
        "global.action.copy": "Copier",
        "global.action.copyAllSuggestions": "Copier toutes les suggestions",
        "global.action.copy_all_suggestions": "Copier toutes les suggestions",
        "global.action.copy_down": "Copier vers le bas",
        "global.action.create": "Créer",
        "global.action.create_and_add": "Créer et ajouter",
        "global.action.create_anyway": "Créer quand même",
        "global.action.create_dispute": "Créer un litige",
        "global.action.create_model": "{1}Créer un {model}|{2}Créer une {model}",
        "global.action.create_model_for": "{1}Créer un {model} pour :|{2}Créer une {model} pour :",
        "global.action.create_multiple": "Créer plusieurs",
        "global.action.delete": "Supprimer",
        "global.action.delete_file": "Supprimer ce fichier",
        "global.action.delete_image": "Supprimer cette image",
        "global.action.delete_line": "Supprimer la ligne",
        "global.action.disable": "Désactiver",
        "global.action.disable_notification": "Désactiver la notification",
        "global.action.download": "Télécharger",
        "global.action.download_headers": "Télécharger les en-têtes",
        "global.action.do_not_close_something": "Ne pas clôturer",
        "global.action.drop_here": "Déposez ici ...",
        "global.action.drop_or_clic": "Déposez un nouveau fichier ou cliquez pour télécharger",
        "global.action.drop_or_clic_multiple": "Déposez un ou plusieur nouveaux fichiers ou cliquez pour télécharger",
        "global.action.duplicate": "Dupliquer",
        "global.action.enable": "Activer",
        "global.action.enable_notification": "Activer la notification",
        "global.action.export": "Exporter",
        "global.action.exportSubmissions": "Exporter les soumissions",
        "global.action.export_from_ids": "Exporter depuis des IDs",
        "global.action.export_from_ids.field.ids.help": "Séparés par des espaces, virgules, points-virgules ou retours à la ligne",
        "global.action.export_sepa": "Exporter au format SEPA",
        "global.action.force_status": "Forcer en \"{status}\" ",
        "global.action.force_the_status": "Forcer le statut",
        "global.action.forgot_password": "Mot de passe oublié ?",
        "global.action.fullSearch": "Recherche complète",
        "global.action.generate": "Générer",
        "global.action.generateFromBudgetAnalysis": "Générer depuis l'analyse budgétaire",
        "global.action.generate_field": "Générer le {field}",
        "global.action.get_back_as": "Retourner en tant que {user}",
        "global.action.get_login_link": "Obtenir un lien de connexion",
        "global.action.go": "Aller",
        "global.action.hide_all_lines": "Replier les lignes",
        "global.action.hide_content": "Cacher le contenu",
        "global.action.hide_file": "Cacher le fichier",
        "global.action.hide_models": "Cacher les {model}",
        "global.action.hide_received_elsewhere": "Cacher les lignes déjà reçues",
        "global.action.history": "Historique",
        "global.action.ignoreSuggestions": "Ignorer les suggestions",
        "global.action.ignore_suggestions": "Ignorer les suggestions",
        "global.action.import": "Importer",
        "global.action.inject": "Injecter",
        "global.action.inject_lines": "Injecter des lignes",
        "global.action.invite": "Inviter",
        "global.action.keep_pressed": "Maintenir appuyé",
        "global.action.link_to_line": "Lier à une ligne",
        "global.action.link_to_this_line": "Lier à cette ligne",
        "global.action.listSubmissions": "Voir les soumissions",
        "global.action.login": "Se connecter",
        "global.action.logout": "Se déconnecter",
        "global.action.log_as": "Se connecter en tant que",
        "global.action.makeAvailableToAll": "Rendre disponible pour tous",
        "global.action.manage": "Gérer",
        "global.action.manageFields": "Gérer les champs",
        "global.action.manage_files": "{1}Gérer le fichier|Gérer les fichiers",
        "global.action.manage_models": "Gérer les {model}",
        "global.action.mergeInto": "Fusionner avec...",
        "global.action.more_details": "Plus de détails",
        "global.action.more_options": "Plus d'options",
        "global.action.never_see_again": "Ne plus voir",
        "global.action.new_version": "Créer une nouvelle version",
        "global.action.next": "Suivant",
        "global.action.notify": "Notifier",
        "global.action.ocr_is_running": "L'OCR est en cours d'exécution. Vous pouvez le désactiver pour saisir les données manuellement.",
        "global.action.ok": "OK",
        "global.action.open_new_tab": "Ouvrir dans un nouvel onglet",
        "global.action.or_create": "{1}Ou créez un nouveau {model}|{2}Ou créez une nouvelle {model}",
        "global.action.other_actions": "Autres actions",
        "global.action.other_actions.abbr": "Autres",
        "global.action.override": "Surcharger",
        "global.action.paste": "Coller",
        "global.action.point": "Pointer",
        "global.action.preview": "Visualiser",
        "global.action.previous": "Précédent",
        "global.action.print": "Imprimer",
        "global.action.print_without_budget": "Imprimer sans budget",
        "global.action.put_as_main": "Mettre en principal",
        "global.action.quick_export": "Export simple",
        "global.action.quit": "Quitter",
        "global.action.rebilling_import": "Import des refacturations",
        "global.action.recalculate_amounts": "Recalculer les montants",
        "global.action.receive_all": "Tout réceptionner",
        "global.action.reconcile": "Rapprocher",
        "global.action.reconcile_without_lines": "Rapprocher sans les lignes",
        "global.action.refresh": "Rafraîchir",
        "global.action.refresh_from": "Rafraîchir depuis {label}",
        "global.action.refuse": "Refuser",
        "global.action.register": "Créer son compte",
        "global.action.reject": "Rejeter",
        "global.action.remove": "Supprimer",
        "global.action.remove_all": "Tout supprimer",
        "global.action.remove_favorite": "Retirer des favoris",
        "global.action.renew_pdf": "Régénérer le PDF",
        "global.action.replace_file": "Remplacer le fichier",
        "global.action.report": "Reporter",
        "global.action.reset": "Réinitialiser",
        "global.action.returnTo": "{1}Aller sur le {model}|{2}Aller sur la {model}",
        "global.action.reverse": "Inverser",
        "global.action.reverse_selection": "Inverser la sélection",
        "global.action.save": "Enregistrer",
        "global.action.saveWeight": "Enregistrer l'ordre",
        "global.action.save_research": "Enregistrer la recherche",
        "global.action.save_weight": "Enregistrer l'ordre",
        "global.action.search": "Rechercher",
        "global.action.search.advanced": "Recherche avancée",
        "global.action.search.help.not_full_search": "Recherche limitée au dernier mois|Recherche limitée aux {months} derniers mois",
        "global.action.search_barcode": "Recherche par code barre",
        "global.action.search_keyword": "Recherche par mot clé",
        "global.action.see": "Voir",
        "global.action.see_detail": "Voir le détail",
        "global.action.see_in_catalog": "Voir dans le catalogue",
        "global.action.see_more": "Voir plus",
        "global.action.select": "Sélectionner",
        "global.action.send": "Envoyer",
        "global.action.send_suggestions": "Envoyer les suggestions",
        "global.action.send_through_api": "Envoyer via l'API",
        "global.action.send_to_api": "Envoyer à l'API",
        "global.action.send_to_api.confirmed": "Envoi à l'API en cours",
        "global.action.share": "Partager",
        "global.action.showTraces": "Voir l'historique",
        "global.action.show_all_lines": "Déplier les lignes",
        "global.action.show_budget_analysis": "Voir l'état du budget",
        "global.action.show_content": "Voir le contenu",
        "global.action.show_file": "Voir le fichier",
        "global.action.show_files": "Voir le(s) fichier(s)",
        "global.action.show_models": "Voir les {model}",
        "global.action.show_more": "Voir plus",
        "global.action.show_received_elsewhere": "Afficher les lignes déjà reçues",
        "global.action.show_traces": "Voir l'historique",
        "global.action.start_action": "Lancer l'action",
        "global.action.start_validation": "Lancer la validation",
        "global.action.start_validation.abbr": "Validation",
        "global.action.submit": "Soumettre",
        "global.action.summary_pdf": "Imprimer le résumé",
        "global.action.suspend": "Suspendre",
        "global.action.take_charge": "Prendre en charge",
        "global.action.test": "Tester",
        "global.action.toggleDropdown": "Ouvrir le menu",
        "global.action.transfer_stock": "Mouvement inter stock",
        "global.action.transform_into_purchase_order": "Transformer en {model}",
        "global.action.unclose_something": "Déclôturer",
        "global.action.unforce_status": "Annuler le statut forcé en \"{status}\" ",
        "global.action.unsuspend": "Ne plus suspendre",
        "global.action.unvalidate": "Invalider",
        "global.action.update": "Modifier",
        "global.action.update_all": "Tout modifier",
        "global.action.update_allocations": "Modifier les imputations",
        "global.action.use": "Utiliser",
        "global.action.validate": "Valider",
        "global.action.verify": "Vérifier",
        "global.action.view_all": "Tout voir",
        "global.billing_status.complete": "Facturation complète",
        "global.billing_status.complete_less": "Facturation complète (inférieure)",
        "global.billing_status.complete_more": "Facturation complète (supérieure)",
        "global.billing_status.no": "{1}Non facturé|{2}Non facturée",
        "global.billing_status.non_billable": "Non facturable",
        "global.billing_status.partial": "{1}Facturé partiellement|{2}Facturée partiellement",
        "global.bulk_action.export": "Exporter",
        "global.bulk_action.export_bank_accounts": "Exporter les RIBs",
        "global.bulk_action.export_chart_accounting_entry_turnover": "Exporter les statistiques Chiffre d'affaires par Imputation Comptable",
        "global.bulk_action.export_chart_annual_rating": "Exporter les statistiques Notation annuelle",
        "global.bulk_action.export_chart_certifications": "Exporter les statistiques Certifications",
        "global.bulk_action.export_chart_performance": "Exporter les statistiques Performance",
        "global.bulk_action.export_chart_security": "Exporter les statistiques Sécurité",
        "global.bulk_action.export_chart_turnover": "Exporter les statistiques Chiffre d'affaires",
        "global.bulk_action.export_contacts": "Exporter les contacts",
        "global.bulk_action.export_remaining_to_deliver": "Export du reste à réceptionner",
        "global.bulk_action.export_without_details": "Export sans détail",
        "global.bulk_action.export_with_details": "Export avec détail",
        "global.bulk_action.send_login_password_email": "Envoyer un mail d'invitation avec login et mot de passe",
        "global.bulk_action.simple_export": "Exporter (simple)",
        "global.bulk_action.validate": "Valider en masse",
        "global.buyer_role.family_buyer": "Acheteur famille",
        "global.buyer_role.family_purveyor": "Approvisionneur famille",
        "global.buyer_role.family_regional_buyer": "Acheteur famille régionaux",
        "global.catchphrase": "gestion des achats simple et intuitif.",
        "global.char.comma": "Virgule",
        "global.char.pipe": "Barre verticale",
        "global.char.semicolon": "Point-virgule",
        "global.config.allocations": "Imputations",
        "global.config.fields": "Champs",
        "global.config.linksTable": "Tableau de liaisons",
        "global.config.pdf": "PDF",
        "global.confirm.action_done": "Action effectuée",
        "global.confirm.added": "Ajout réussi",
        "global.confirm.added_entities": "Eléments ajoutés : {count} {model}",
        "global.confirm.added_to_jobs": "Eléments ajoutés au Jobs : {count} {model}",
        "global.confirm.amounts_recalculated": "Montants recalculés",
        "global.confirm.back_to_awaiting": "Retour en attente effectué",
        "global.confirm.back_to_draft": "Retour en brouillon effectué",
        "global.confirm.back_to_pending": "Retour en attente de traitement effectué",
        "global.confirm.broken_down_expenses": "Ventilation effectuée",
        "global.confirm.cancel": "Annulation réussie",
        "global.confirm.cancel_and_replace": "Annulation et remplacement réussis",
        "global.confirm.changedToAllUsers": "Changement vers Tous les utilisateurs réussi",
        "global.confirm.close": "Clôture réussie",
        "global.confirm.copied": "Copie réussie",
        "global.confirm.created": "Création réussie",
        "global.confirm.deleted": "Suppression réussie",
        "global.confirm.deleted_entities": "Eléments supprimés : {count} {model}",
        "global.confirm.disabled": "Désactivation réussie",
        "global.confirm.duplicated": "Duplication réussie",
        "global.confirm.email_being_sent": "E-mail en cours d'envoi",
        "global.confirm.email_sent": "Email envoyé",
        "global.confirm.enabled": "Activation réussie",
        "global.confirm.export_started": "L'export a démarré. Il vous sera envoyé par mail lorsqu'il sera terminé",
        "global.confirm.file_deleted": "Document supprimé avec succès",
        "global.confirm.file_uploaded": "Document transféré avec succès",
        "global.confirm.image_uploaded": "Image transférée avec succès",
        "global.confirm.imported": "Import réussi",
        "global.confirm.imported.detail": "Import réalisé avec succès :",
        "global.confirm.invitation_sent": "Invitation envoyée",
        "global.confirm.new_version": "Nouvelle version créée",
        "global.confirm.password_changed": "Le mot de passe a été changé avec succès",
        "global.confirm.process_ongoing": "Traitement en cours",
        "global.confirm.removed": "Retrait réussi",
        "global.confirm.replaced": "Remplacement réussi",
        "global.confirm.replaced_number": "{nb} remplacement(s) effectué(s)",
        "global.confirm.request_made": "Demande effectuée",
        "global.confirm.saved": "Sauvegarde effectuée",
        "global.confirm.sorted": "L'ordre a bien été enregistré",
        "global.confirm.start_validation": "Le processus de validation a bien été lancé",
        "global.confirm.statusRefuse": "Le refus a bien été enregistré",
        "global.confirm.statusValidate": "La validation a bien été enregistrée",
        "global.confirm.status_changed": "Le statut a bien été changé",
        "global.confirm.suspended": "Suspension effectuée",
        "global.confirm.taken_charge": "Prise en charge effectuée",
        "global.confirm.transformed": "Transformation effectuée",
        "global.confirm.unclose": "Déclôture réussie",
        "global.confirm.unsuspended": "Annulation de la suspension effectuée",
        "global.confirm.updated": "Modification réussie",
        "global.date.day": "jour|jours",
        "global.date.month": "mois|mois",
        "global.date.year": "an|ans",
        "global.day.friday": "Vendredi",
        "global.day.monday": "Lundi",
        "global.day.saturday": "Samedi",
        "global.day.sunday": "Dimanche",
        "global.day.thursday": "Jeudi",
        "global.day.tuesday": "Mardi",
        "global.day.wednesday": "Mercredi",
        "global.delivery_status.complete": "{1}Reçu|{2}Reçue",
        "global.delivery_status.forced_complete": "{1}Reçu (manuellement)|{2}Reçue (manuellement)",
        "global.delivery_status.no": "{1}Non reçu|{2}Non reçue",
        "global.delivery_status.partial": "{1}Reçu partiellement|{2}Reçue partiellement",
        "global.display.header": "En-tête",
        "global.dispute_status.finished": "Litige terminé",
        "global.dispute_status.no": "Aucun litige",
        "global.dispute_status.ongoing": "Litige en cours",
        "global.error.already_exists": "Un {model} existe déjà avec le nom \"{label}\"|Des {model} existent déjà avec le nom \"{label}\" ",
        "global.error.already_used_in": "Le champ {attribute} est déjà utilisé dans {model}",
        "global.error.amount_exctax.less_than_billed": "Le montant HT doit être supérieur ou égal à {billed} (montant déjà facturé)",
        "global.error.amount_exctax.more_than_billed": "Le montant HT doit être inférieur ou égal à {billed} (montant déjà facturé)",
        "global.error.amount_inctax.less_than_billed": "Le montant TTC doit être supérieur ou égal à {billed} (montant déjà facturé)",
        "global.error.amount_inctax.more_than_billed": "Le montant TTC doit être inférieur ou égal à {billed} (montant déjà facturé)",
        "global.error.at_least_one_model": "{1}Au moins un {model} est nécessaire|{2}Au moins une {model} est nécessaire",
        "global.error.cant_copy_to_clipboard": "Impossible de copier dans le presse-papier",
        "global.error.cant_update_field": "Impossible de modifier le champ {field}",
        "global.error.cant_update_line_field": "Impossible de modifier le champ {field} sur la ligne {line}",
        "global.error.currency_must_match": "La devise doit correspondre",
        "global.error.deposit.more_than_amount_inctax": "Le champ {attribute} doit être inférieur ou égal à {amount_inctax} (total TTC)",
        "global.error.entity_not_found": "Le champ {attribute} ne correspond a aucune entité de type {entity} ({entity_code})",
        "global.error.is_invalid": "{attribute} est invalide",
        "global.error.limit_active_exceeded": "{1}La limite de {limit} {models} actifs est dépassée|{2}La limite de {limit} {models} actives est dépassée",
        "global.error.line_must_match_model": "{1}La ligne doit correspondre au {model}|{2}La ligne doit correspondre à la {model}",
        "global.error.missing_supplier_code": "{1}Le code du {model} n'est pas renseigné|{2}Le code de la {model} n'est pas renseigné",
        "global.error.model_duplicated": "{model} en doublon",
        "global.error.model_is_locked": "{1}Le {model} est bloqué|{2}La {model} est bloquée",
        "global.error.must_be_closed": "{1}Le {model} doit être clôturé|{2}La {model} doit être clôturée",
        "global.error.must_be_in": "Le champ {attribute} doit être dans les valeurs : {array}",
        "global.error.must_be_null": "Il ne doit pas y avoir de {model}",
        "global.error.must_not_be": "Le champ {attribute} ne doit pas être {value}",
        "global.error.please_check_following": "Merci de vérifier les erreurs suivantes :",
        "global.error.quantity.less_than_delivered": "La quantité doit être supérieure ou égale à {delivered} (quantité déjà reçue)",
        "global.error.quantity.more_than_delivered": "La quantité doit être inférieure ou égale à {delivered} (quantité déjà reçue)",
        "global.error.require_json_format": "Nécessite un format JSON",
        "global.error.select_at_least_one_model": "{1}Vous devez sélectionner au moins un {model}|{2}Vous devez sélectionner au moins une {model}",
        "global.error.status_must_be": "Le statut doit être \"{status}\" ",
        "global.error.value_already_exists": "La valeur existe déjà",
        "global.error.year_limit_exceeded": "La limite de {limit} {models} est dépassée pour cette année",
        "global.example": "Exemple",
        "global.field": "Champ",
        "global.field.'+field+": "-",
        "global.field.'+ignored_field+": "-",
        "global.field.'+index+": "-",
        "global.field.abbr": "Abbréviation",
        "global.field.accounting_account_number": "Numéro de compte comptable",
        "global.field.accounting_account_number.abbreviation": "Compte",
        "global.field.accounting_export_prefix": "Préfixe du Numéro de Pièce d'Export Compta",
        "global.field.account_number": "Numéro de compte",
        "global.field.actions": "Actions",
        "global.field.active": "Actif",
        "global.field.additional_email_text": "Texte additionnel pour l'envoi d'email",
        "global.field.additional_information": "Informations complémentaires provenant de {model}",
        "global.field.additional_model_texts": "Informations {model}",
        "global.field.additional_purchase_order_text": "Texte affiché sur les {model}",
        "global.field.additional_purchase_order_text.help": "{1}Dans le cas où un {model} lié est sélectionné|{2}Dans le cas où une {model} liée est sélectionnée",
        "global.field.address": "Adresse",
        "global.field.alert_emails": "Adresses e-mail d'alerte",
        "global.field.allocations": "Imputations",
        "global.field.already_delivered": "Déjà réceptionné",
        "global.field.amount": "Montant",
        "global.field.amount.abbreviation": "Mnt.",
        "global.field.amounts": "Montants",
        "global.field.amount_": "-",
        "global.field.amount_exctax": "Montant HT",
        "global.field.amount_exctax.subtotal": "Sous-total HT",
        "global.field.amount_exctax.total": "Total HT",
        "global.field.amount_inctax": "Montant TTC",
        "global.field.amount_inctax.subtotal": "Sous-total TTC",
        "global.field.amount_inctax.total": "Total TTC",
        "global.field.amount_taxes.total": "Total TVA",
        "global.field.approval_image": "Image d'approbation",
        "global.field.approved_at": "{1}Approuvé le|{2}Approuvée le",
        "global.field.approved_by": "{1}Approuvé par|{2}Approuvée par",
        "global.field.approved_date": "Date d'approbation",
        "global.field.asker": "Demandeur",
        "global.field.asker.plural": "Demandeurs",
        "global.field.attachments": "Pièces Jointes",
        "global.field.author": "Auteur",
        "global.field.autogenerated_po_number": "{1}Numéro du {model} généré|{2}Numéro de la {model} générée",
        "global.field.auto_send": "Envoyer automatiquement après validation",
        "global.field.auto_send_po": "Envoyer automatiquement le bon de commande après validation",
        "global.field.available_quantity": "Quantité disponible",
        "global.field.available_quantity.abbreviation": "Qté. disponible",
        "global.field.balance": "Solde",
        "global.field.bank_account_number": "Numéro de compte bancaire",
        "global.field.barcode": "Code barre",
        "global.field.bcc": "CCI",
        "global.field.begin_date": "Date de début",
        "global.field.bic": "BIC",
        "global.field.billed_amount_exctax": "Montant facturé HT",
        "global.field.billed_amount_exctax.total": "Total facturé HT",
        "global.field.billed_amount_inctax": "Montant facturé TTC",
        "global.field.billed_amount_inctax.total": "Total facturé TTC",
        "global.field.billed_date": "Date de facturation",
        "global.field.billed_price_exctax": "Prix HT facturé",
        "global.field.billed_price_inctax": "Prix TTC facturé",
        "global.field.billed_quantity": "Quantité facturée",
        "global.field.billed_vat_rate": "TVA facturée",
        "global.field.billing_address": "Adresse de facturation",
        "global.field.billing_status": "Facturation",
        "global.field.body": "Corps",
        "global.field.budget": "Budget",
        "global.field.budget.total": "Budget total",
        "global.field.budgetKeys": "Clés budgétaires",
        "global.field.budget_new": "Nouveau budget",
        "global.field.budget_per_unit": "Budget / U",
        "global.field.budget_summary": "Récapitulatif du budget",
        "global.field.buyer": "Acheteur",
        "global.field.buyer.plural": "Acheteurs",
        "global.field.buyer_of": "Acheteur {model}",
        "global.field.buyer_role": "Rôle de l'acheteur",
        "global.field.calculated_amount": "Montant calculé",
        "global.field.cc": "CC",
        "global.field.charge_date": "Date d'opération",
        "global.field.choose_pattern": "Choisir un modèle",
        "global.field.city": "Ville",
        "global.field.code": "Code",
        "global.field.code.error.no_sharp_allowed": "Le # n'est pas autorisé (sauf devant la valeur de l'identifiant, ici {id})",
        "global.field.code.plural": "Code",
        "global.field.comments_nb": "{nb} commentaire|{nb} commentaires",
        "global.field.commitment_exctax": "Engagement HT",
        "global.field.commitment_inctax": "Engagement TTC",
        "global.field.company": "Société",
        "global.field.contains_headers": "Le fichier contient des en-têtes",
        "global.field.content": "Contenu",
        "global.field.country": "Pays",
        "global.field.created_at": "{1}Créé le|{2}Créée le",
        "global.field.created_by": "{1}Créé par|{2}Créée par",
        "global.field.created_date": "Date de création",
        "global.field.creator": "Créateur",
        "global.field.credit": "Crédit",
        "global.field.criteria": "Critères",
        "global.field.criterion": "Critère",
        "global.field.currency_rate": "Taux de change",
        "global.field.customer": "Client",
        "global.field.customer_label": "Numéro de facture client",
        "global.field.custom_frequency": "Périodicité personnalisée",
        "global.field.data": "Data",
        "global.field.dates": "Dates",
        "global.field.deadline": "Livraison au plus tard",
        "global.field.debit": "Débit",
        "global.field.default_account_number": "Numéro de compte par défaut",
        "global.field.default_field": "{field} par défaut",
        "global.field.deleted": "{1}Supprimé|{2}Supprimée",
        "global.field.delivered_amount_exctax": "Montant HT réceptionné",
        "global.field.delivered_amount_exctax.total": "Total réceptionné HT",
        "global.field.delivered_amount_inctax": "Montant TTC réceptionné",
        "global.field.delivered_amount_inctax.total": "Total réceptionné TTC",
        "global.field.delivered_at": "Reçu le",
        "global.field.delivered_not_billed_amount": "Montant réceptionné non facturé",
        "global.field.delivered_quantity": "Quantité réceptionnée",
        "global.field.delivery_address": "Adresse de livraison",
        "global.field.delivery_date": "Date de livraison",
        "global.field.delivery_deadline": "Date limite de livraison",
        "global.field.delivery_delay": "Délais de livraison",
        "global.field.delivery_status": "Réception",
        "global.field.description": "Description",
        "global.field.detail": "{1}Détail|{2}Détails",
        "global.field.disabled": "{1}Désactivé|{2}Désactivée",
        "global.field.disabled_date": "Date de désactivation",
        "global.field.discount": "Remise",
        "global.field.dismissible": "Les Utilisateurs peuvent cocher \"{never_see_again}\" ",
        "global.field.display": "Affichage",
        "global.field.dispute_status": "Litige",
        "global.field.documents_footer": "Pied de page des PDF",
        "global.field.due_date": "Date d'échéance",
        "global.field.duration_days": "Nombre de jours",
        "global.field.editable_model": "{model} modifiable",
        "global.field.email": "Adresse e-mail",
        "global.field.email.abbr": "E-mail",
        "global.field.email.undefined": "Adresse e-mail manquante",
        "global.field.emails.help": "Une adresse e-mail par ligne",
        "global.field.enabled": "{1}Actif|{2}Active",
        "global.field.encoding": "Encodage",
        "global.field.end_date": "Date de fin",
        "global.field.entered_amount": "Montant saisi",
        "global.field.errors": "Erreurs",
        "global.field.evaluated_date": "Date de l'évaluation",
        "global.field.exported_at": "{1}Exporté le|{2}Exportée le",
        "global.field.export_date": "Date de l'export",
        "global.field.export_status": "Statut d'export",
        "global.field.failed_at": "Echoué le",
        "global.field.file": "Fichier",
        "global.field.files": "Fichier(s)",
        "global.field.firstname": "Prénom",
        "global.field.first_submitted_at": "Date de première soumission",
        "global.field.forced_status": "Statut forcé à",
        "global.field.forced_status_date": "Date limite du status forcé",
        "global.field.for_all_tenants": "Pour tous les Tenants",
        "global.field.frequency": "Périodicité",
        "global.field.gap_exctax": "Ecart HT",
        "global.field.gap_inctax": "Ecart TTC",
        "global.field.gender": "Genre",
        "global.field.handling_at": "{1}Pris en charge le|{2}Prise en charge le",
        "global.field.handling_date": "Date de prise en charge",
        "global.field.hidden": "Caché",
        "global.field.holder_name": "Titulaire du compte",
        "global.field.iban": "IBAN",
        "global.field.icon": "Icône",
        "global.field.id": "ID",
        "global.field.ids": "Liste d'IDs",
        "global.field.image_field": "Image depuis votre poste",
        "global.field.image_url": "URL de l'image",
        "global.field.imported_at": "{1}Importé le|{2}Importée le",
        "global.field.import_type": "Type d'import",
        "global.field.incurred": "Engagé",
        "global.field.initial_validated_budget": "Budget initial",
        "global.field.internal_reference": "Référence interne",
        "global.field.ip_address": "Adresse IP",
        "global.field.isPrice": "Est un prix",
        "global.field.issuer": "Émetteur",
        "global.field.job": "Fonction",
        "global.field.key": "Clé",
        "global.field.label": "Libellé",
        "global.field.lang": "Langue",
        "global.field.lastname": "Nom",
        "global.field.last_activity": "Dernière activité",
        "global.field.last_login_date": "Dernière connexion",
        "global.field.last_validated_budget": "Dernier budget validé",
        "global.field.last_validated_budget_version": "Dernière version validée",
        "global.field.less_than_X_days": "{field} < {days} jours",
        "global.field.level": "Niveau",
        "global.field.line": "ligne",
        "global.field.lines": "Lignes",
        "global.field.line_id": "ID de ligne",
        "global.field.link": "Lien",
        "global.field.location": "Emplacement",
        "global.field.login": "Login",
        "global.field.logo": "Logo",
        "global.field.mark": "Note",
        "global.field.max_amount": "Montant max.",
        "global.field.max_amount_per_order": "Montant maximal HT par {model}",
        "global.field.max_amount_per_year": "Montant maximal HT par an",
        "global.field.meta": "Meta",
        "global.field.method": "Méthode",
        "global.field.min_amount": "Montant min.",
        "global.field.min_quantity_alert": "Seuil de réapprovisionnement",
        "global.field.mobile": "Portable",
        "global.field.model": "Modèle",
        "global.field.model.plural": "Modèles",
        "global.field.model_type": "Type de {abbr}",
        "global.field.movement.cost": "Coût du mouvement",
        "global.field.multiple": "Multiple",
        "global.field.my_needs": "Mes {abbr}",
        "global.field.my_purchase_orders": "Mes commandes",
        "global.field.my_suppliers": "Dont je suis le responsable",
        "global.field.name": "Nom",
        "global.field.name_plural": "Nom pluriel",
        "global.field.new_image": "Nouvelle image",
        "global.field.not_simplifiable": "Non simplifiable",
        "global.field.not_simplifiable.help": "Le valideur reste dans le circuit même s'il y a des conditions de simplification",
        "global.field.number_of_periods": "Nombre de périodes",
        "global.field.old_image": "Ancienne image",
        "global.field.only_from_warehouse": "Uniquement les {model} de cet Entrepôt",
        "global.field.operation_label": "Libellé de l'opération",
        "global.field.options": "Options",
        "global.field.options.key.error.different": "Cette clé existe déjà",
        "global.field.ordered_at": "{1}Commandé le|{2}Commandée le",
        "global.field.ordered_date": "Date de commande",
        "global.field.ordered_quantity": "Quantité commandée",
        "global.field.original_price": "Prix brut",
        "global.field.original_price_exctax": "Prix brut HT",
        "global.field.original_price_inctax": "Prix brut TTC",
        "global.field.other": "Autres",
        "global.field.paid_amount_exctax": "Montant TTC payé",
        "global.field.password": "Mot de passe",
        "global.field.payment_date": "Date de paiement",
        "global.field.payment_information": "Informations réglement",
        "global.field.payment_source": "Source de paiement",
        "global.field.payment_status": "Paiement",
        "global.field.phone": "Téléphone",
        "global.field.phone.abbr": "Tél.",
        "global.field.phones": "Téléphones",
        "global.field.piece_number": "Numéro de pièce",
        "global.field.position": "Position",
        "global.field.possible_value": "Valeur possible",
        "global.field.price": "Prix",
        "global.field.price_exctax": "Prix HT",
        "global.field.price_exctax.new": "Nouveau prix HT",
        "global.field.price_inctax": "Prix TTC",
        "global.field.processing_status": "Traitement",
        "global.field.purchase_terms": "Conditions Générales d'Achat",
        "global.field.purveyors": "Approvisionneurs",
        "global.field.quantity": "Quantité",
        "global.field.quantity.abbreviation": "Qté.",
        "global.field.quantity.new": "Nouvelle quantité",
        "global.field.quantity_step": "Multiple de vente",
        "global.field.read_at": "{1}Lu le|{2}Lue le",
        "global.field.rebilled_amount_exctax": "Montant refacturé HT",
        "global.field.rebilled_amount_inctax": "Montant refacturé TTC",
        "global.field.rebilling_date": "Date de refacturation",
        "global.field.reference": "Référence",
        "global.field.reference.abbreviation": "Réf.",
        "global.field.reference.plural": "Références",
        "global.field.reference.plural.help": "Une référence par ligne",
        "global.field.reference_or_label": "Référence/Libellé",
        "global.field.regional_buyers": "Acheteurs régionaux",
        "global.field.remaining_amount_exctax_to_deliver": "Montant HT restant à recevoir",
        "global.field.remaining_amount_inctax_to_deliver": "Montant TTC restant à recevoir",
        "global.field.remaining_amount_to_bill": "Montant restant à facturer",
        "global.field.remaining_amount_to_pay.short": "Reste à payer",
        "global.field.remaining_quantity_to_deliver": "Quantité restante à recevoir",
        "global.field.required": "Requis",
        "global.field.reserved_at": "Réservé à",
        "global.field.reverse_account_number": "Compte de contrepartie",
        "global.field.route_name": "Nom de la route",
        "global.field.sent_at": "{1}Envoyé le|{2}Envoyée le",
        "global.field.short_label": "Libellé court",
        "global.field.show_model_from": "Voir les {model} depuis",
        "global.field.show_parents_children": "Afficher les liaisons Parents/Enfants",
        "global.field.signature": "Signature",
        "global.field.siret": "SIRET",
        "global.field.siret.error.regex": "Le numéro SIRET doit contenir 14 chiffres",
        "global.field.siret.info.exists": "Sociétés ayant ce numéro de Siret",
        "global.field.state": "État",
        "global.field.status": "Statut",
        "global.field.stock": "Stock",
        "global.field.stock_type": "Type de mouvement",
        "global.field.subject": "Sujet",
        "global.field.submitted_at": "{1}Soumis le|{2}Soumise le",
        "global.field.submitted_date": "Date de soumission",
        "global.field.submitted_for": "Soumis depuis",
        "global.field.submit_order_at": "{1}{abbr} soumis le|{2}{abbr} soumise le",
        "global.field.supplier_file": "Documents de Fournisseur",
        "global.field.supported_amount": "Montant supporté",
        "global.field.target": "Cible",
        "global.field.template": "Template",
        "global.field.tenant": "Tenant",
        "global.field.text": "Texte",
        "global.field.timezone": "Fuseau horaire",
        "global.field.title": "Titre",
        "global.field.to": "À",
        "global.field.total": "Total",
        "global.field.to_approve": "A valider",
        "global.field.to_be_approved_by": "À approuver par",
        "global.field.type": "Type",
        "global.field.unit": "Unité",
        "global.field.unit_number_per_price": "Nombre d'unités par prix",
        "global.field.unit_price_exctax": "Prix U. HT",
        "global.field.unit_price_inctax": "Prix U. TTC",
        "global.field.url": "URL",
        "global.field.usable_in_validation_process": "Utilisable dans les Circuits de Validation",
        "global.field.user_agent": "User agent",
        "global.field.validated_amount": "Montant validé",
        "global.field.validated_at": "{1}Validé le|{2}Validée le",
        "global.field.validation_date": "Date de validation",
        "global.field.validator": "Valideur",
        "global.field.validity_duration": "Durée de validité",
        "global.field.value": "Valeur",
        "global.field.variant": "Variant",
        "global.field.vat": "TVA",
        "global.field.vat_amount": "Montant de TVA",
        "global.field.vat_rate": "Taux de TVA",
        "global.field.vat_type": "Type de TVA",
        "global.field.version": "Version",
        "global.field.version.initial": "Version initiale",
        "global.field.versions": "Versions",
        "global.field.weighting": "Coefficient de pondération",
        "global.field.where_im_buyer": "Où je suis l'acheteur",
        "global.field.without_attachments": "sans les pièces jointes",
        "global.field.without_po": "Créer sans {model}",
        "global.field.with_actions_for_me_to_do": "Avec des actions à faire de ma part",
        "global.field.with_attachments": "avec toutes les pièces jointes",
        "global.field.with_validations_for_me_anytime": "Où je fais partie du circuit de validation",
        "global.field.with_validations_for_me_to_do": "En attente de ma validation",
        "global.field.year": "Année",
        "global.field.your_email": "Votre adresse e-mail",
        "global.file.required": "Le fichier est requis",
        "global.filesize.B": "o",
        "global.filesize.EB": "Eo",
        "global.filesize.GB": "Go",
        "global.filesize.KB": "Ko",
        "global.filesize.MB": "Mo",
        "global.filesize.PB": "Po",
        "global.filesize.TB": "To",
        "global.filesize.YB": "Yo",
        "global.filesize.ZB": "Zo",
        "global.font_awesome.link.label": "Trouver une icône",
        "global.form.title": "Formulaire",
        "global.gender.female": "Féminin",
        "global.gender.male": "Masculin",
        "global.gender.neutral": "Neutre",
        "global.generate.password": "Générer un nouveau mot de passe",
        "global.import.boolean.comment": "1 pour oui, 0 pour non",
        "global.import.error": "{rowsError}/{rowsCount} ligne n'a pas été importée car : | {rowsError}/{rowsCount} lignes n'ont pas été importées car :",
        "global.import.fakeBic": "Ligne {line} : Le BIC '{fakeBic}' n'est pas valide et le {model} n'a pas pu être créé.",
        "global.import.fakeIban": "Ligne {line} : L' IBAN '{fakeIban}' n'est pas valide et le {model} n'a pas pu être créé.",
        "global.import.help": "Aide à lire pour l'import",
        "global.import.id.comment": "Vide pour une création ou id de l'élément pour une mise à jour",
        "global.import.matches.help": "Doit correspondre à {model} existant(e)(s)",
        "global.import.missingHeaders": "La/Les colonne(s) suivante(s) est/sont manquante(s), veuillez vérifier vos en-têtes.",
        "global.import.missingHeaders.any": "Toutes les colonnes sont manquantes, veuillez vérifier vos en-têtes ainsi que l'encodage du fichier.",
        "global.import.notFound": "non trouvé(e)",
        "global.import.not_draft": "Ligne {line} : Le {model} d'ID {id} n'est pas en brouillon et n'a donc pas pu être modifié.",
        "global.import.problem": " Cependant, nous avons détecté les problèmes suivants :",
        "global.import.required": "Ligne {line} : Le champ '{undefinedValue}' est manquant.",
        "global.import.requiredLines": "Ligne {line} : Le champ {requiredLinesString} est requis. | Ligne {line} : Les champs {requiredLinesString} sont requis.",
        "global.import.success": "{rowsSuccess}/{rowsCount} ligne a été importée/mise à jour avec succès. | {rowsSuccess}/{rowsCount} lignes ont été importées/mises à jour avec succès.",
        "global.import.success.detail": "{rowsSuccess}/{rowsCount} lignes ont été importées avec succès ({createdCount} créées, {updatedCount} mises à jour).",
        "global.import.undefined": "Ligne {line} : Le champ '{undefinedKey}' => '{undefinedValue}' n'est pas reconnu.",
        "global.import.undefinedSupplierCode": "Ligne {line} : Le code fournisseur {undefinedSupplierString} est inconnu et le {model} n'a pas pu être créé.",
        "global.import.unique": "Ligne {line} : Le champ '{undefinedKey}' => '{undefinedValue}' est déjà utilisé alors qu'il devrait etre unique.",
        "global.index.no_result": "Il n'y a aucun résultat",
        "global.info.abbr_signification": "\"{abbr}\" pour \"{for}\" ",
        "global.info.action_perimeter": "Périmètre d'action",
        "global.info.activated.plural": "Activés",
        "global.info.active_out_of_max": "{1}{count} actifs sur {max} max.|{2}{count} actives sur {max} max.",
        "global.info.added_manually_by": "Ajouté manuellement par",
        "global.info.article_choice_when_no_supplier": "{1}En choisissant un article lié à un {supplier_model}, le {supplier_model} sera appliqué au {model}|{2}En choisissant un article lié à un {supplier_model}, le {supplier_model} sera appliqué à la {model}",
        "global.info.attached_models": "Models attachés",
        "global.info.auto_send": "Envoi automatique après validation",
        "global.info.back_to_pending": "L'élément va être remis en attente de traitement",
        "global.info.cantSend": "Vous ne pouvez pas envoyer pour le moment",
        "global.info.cant_modify_because_created_without_model": "{1}Impossible de modifier car ce {model} a été créé sans {without_model}|{2}Impossible de modifier car cette {model} a été créée sans {without_model}",
        "global.info.cant_modify_because_model": "{1}Impossible de modifier car il y a des {model} sélectionnés|{2}Impossible de modifier car il y a des {model} sélectionnées",
        "global.info.caution": "Attention",
        "global.info.children_links": "Liaisons avec des enfants",
        "global.info.classification": "Classification",
        "global.info.closed": "{1}Clôturé|{2}Clôturée",
        "global.info.computed": "{1}Calculé|{2}Calculée",
        "global.info.confirmation_request": "Merci de confirmer cette action",
        "global.info.copied": "Copié",
        "global.info.create_with_draft": "Vous avez des {model} en brouillon",
        "global.info.creation_count": "{count} création|{count} créations",
        "global.info.creation_disabled_count": "{count} création (non activé)|{count} créations (non activés)",
        "global.info.creation_in_progress": "Création en cours",
        "global.info.current_value": "Valeur actuelle : {value}",
        "global.info.definitive_action": "Cette action est définitive.",
        "global.info.deviation": "+ {deviation}% d'écart",
        "global.info.difference": "Différence : {value}",
        "global.info.displayed_exctax": "Prix affichés en HT",
        "global.info.displayed_inctax": "Prix affichés en TTC",
        "global.info.dispute": "Litige",
        "global.info.dispute.plural": "Litiges",
        "global.info.dispute_closing": "Clôture litige",
        "global.info.empty": "vide",
        "global.info.everything_selected_by_default": "Tout est sélectionné par défaut",
        "global.info.favorite": "Favori",
        "global.info.features": "Caractéristiques",
        "global.info.fields_not_updatable_after_creation": "Les champs marqués de cette icône ne seront pas modifiables après la création :",
        "global.info.field_auto_filled": "Ce champ est rempli automatiquement",
        "global.info.field_auto_filled_if_empty": "Ce champ est rempli automatiquement si laissé vide",
        "global.info.field_translatable": "Ce champ est traduisible",
        "global.info.forced_status": "Statut forcé à {status}",
        "global.info.forced_status_until": "Statut forcé à {status} jusqu'au {date}",
        "global.info.from_overrun": "Généré depuis {model}",
        "global.info.help": "Aide",
        "global.info.in": "Dans",
        "global.info.infos": "Informations",
        "global.info.infos.abbreviation": "Infos",
        "global.info.initialization": "Initialisation",
        "global.info.invited_by": "Invité(e) par {label}",
        "global.info.in_models": "Dans des {model_plural}",
        "global.info.in_this_model": "{1}Dans ce {model}|{2}Dans cette {model}",
        "global.info.items_excluded_number": "Éléments exclus : {number}",
        "global.info.items_number": "Éléments sélectionnés : {number}",
        "global.info.last_update": "Dernière modification",
        "global.info.limited_to_lines": "Limité à {limit} lignes",
        "global.info.lines_count": "Nombre de lignes",
        "global.info.loading": "Chargement...",
        "global.info.misc": "Divers",
        "global.info.model_already_present": "{1}{model} déjà présent|{2}{model} déjà présente",
        "global.info.new": "Nouveau",
        "global.info.new_values": "Nouvelles valeurs",
        "global.info.nothing_excluded_by_default": "Rien n'est exclus par défaut",
        "global.info.notifications": "Envoyer une notification aux adresses e-mail suivantes :",
        "global.info.not_closed": "{1}Non clôturé|{2}Non clôturée",
        "global.info.not_existing": "Non existant",
        "global.info.not_present": "Non présent",
        "global.info.not_proposed": "Non proposé",
        "global.info.not_referenced": "Non référencé",
        "global.info.not_simplifiable": "Non simplifiable",
        "global.info.not_specified": "Non spécifié",
        "global.info.not_updatable_after_creation": "Non modifiable après la création",
        "global.info.no_action": "Aucune action",
        "global.info.no_delivery_address": "Aucune adresse renseignée",
        "global.info.no_item_selected": "Aucun élément sélectionné",
        "global.info.no_line": "Aucune ligne",
        "global.info.no_list_item": "Aucun élément n'a été trouvé",
        "global.info.no_mail_will_be_sent": "Aucun e-mail ne sera envoyé.",
        "global.info.no_problem_detected": "Aucun problème détecté",
        "global.info.no_replacement": "Aucun remplacement n'est à effectuer",
        "global.info.optional": "Optionnel",
        "global.info.options_selected": "{count} option sélectionnée|{count} options sélectionnées",
        "global.info.original_values": "Valeurs originales",
        "global.info.out_of_max": "{count} sur {max} max.",
        "global.info.overriden_email_to": "Le destinataire global de TOUS les e-mails est renseigné",
        "global.info.parents_links": "Liaisons avec des parents",
        "global.info.pointed": "{1}Pointé|{2}Pointée",
        "global.info.read": "Lu",
        "global.info.real": "{1}Réel|{2}Réelle",
        "global.info.required": "Requis",
        "global.info.required_fields": "Champs requis : {fields}",
        "global.info.required_if": "Requis si {condition}",
        "global.info.requires_deposit": "Requiert un acompte",
        "global.info.save": "Sauvegarde",
        "global.info.save_model_before_add": "{1}Veuillez d'abord sauvegarder ce {model}|{2}Veuillez d'abord sauvegarder cette {model}",
        "global.info.selectable": "Sélectionnables",
        "global.info.selected": "Sélectionnés",
        "global.info.select_displayed_items": "Sélectionner les éléments affichés",
        "global.info.show_only_children": "Afficher uniquement les enfants selectionnés",
        "global.info.show_only_parents": "Afficher uniquement les parents selectionnés",
        "global.info.skipped_by": "Contourné par",
        "global.info.still_be_created_but_not_activated": "Vous pouvez toujours en créer mais pas en activer",
        "global.info.suggestedBy": "Valeur suggérée par {user}",
        "global.info.suggested_by": "Valeur suggérée par {user}",
        "global.info.suggestions": "Suggestions",
        "global.info.taxes_excluded": "HT",
        "global.info.taxes_included": "TTC",
        "global.info.this_model": "{1}Ce {model}|{2}Cette {model}",
        "global.info.this_request": "Cette demande",
        "global.info.to_copy": "À copier",
        "global.info.to_copy_to": "Copier vers",
        "global.info.to_replace": "À remplacer",
        "global.info.to_replace_to": "Remplacer par",
        "global.info.unavailable.plural": "Non disponibles",
        "global.info.undefined_weight": "Poids indéterminé",
        "global.info.unique": "Unique",
        "global.info.update_count": "{count} mise à jour|{count} mises à jour",
        "global.info.users_to_notify": "Vous pouvez également notifier un utilisateur en particulier :",
        "global.info.user_interface": "Interface utilisateur",
        "global.info.validation_process_reset": "Le circuit de validation va être remis à zéro si vous poursuivez.",
        "global.info.value_from_config": "Valeur de la Config : {name} => {value}",
        "global.info.value_from_dotenv": "Valeur du .env : {name} => {value}",
        "global.info.value_from_settings": "Valeur dans les Settings : {name} => {value}",
        "global.info.warning": "Avertissement",
        "global.info.with": "Avec {text}",
        "global.maintenance.activated": "Le mode \"Maintenance\" est activé pour ce client",
        "global.misc.by": "par",
        "global.misc.for": "pour",
        "global.misc.from_model": "{1}depuis le {model}|{2}depuis la {model}",
        "global.misc.on": "le",
        "global.month.april": "Avril",
        "global.month.august": "Août",
        "global.month.december": "Décembre",
        "global.month.february": "Février",
        "global.month.january": "Janvier",
        "global.month.july": "Juillet",
        "global.month.june": "Juin",
        "global.month.march": "Mars",
        "global.month.may": "Mai",
        "global.month.november": "Novembre",
        "global.month.october": "Octobre",
        "global.month.september": "Septembre",
        "global.notification.model_sent_to_supplier": "Lors de l'envoi des {model_plural} aux Fournisseurs",
        "global.notification.model_submitted": "Lors de la soumission des {model_plural}",
        "global.notification.model_validated": "Lors de la validation des {model_plural}",
        "global.obligatory": "Obligatoire",
        "global.pdf.order_cancel_and_replace_previous": "Cette commande annule et remplace la commande précédente",
        "global.period.from": "du",
        "global.period.to": "au",
        "global.question.continue": "Voulez-vous continuer ?",
        "global.status.acknowledged_receipt": "{1}Reçu par le fournisseur|{2}Reçue par le fournisseur",
        "global.status.active": "{1}Actif|{2}Active",
        "global.status.approved": "{1}Approuvé|{2}Approuvée",
        "global.status.autogenerated": "{1}Autogénéré|{2}Autogénérée",
        "global.status.autovalidated": "{1}Auto-validé|{2}Auto-validée",
        "global.status.auto_force_status_paid": "Statut {status} automatiquement",
        "global.status.awaiting": "En attente",
        "global.status.awaiting.delivery": "En attente de réception",
        "global.status.billed": "{1}Facturé|{2}Facturée",
        "global.status.canceled": "{1}Annulé|{2}Annulée",
        "global.status.closed": "{1}Clôturé|{2}Clôturée",
        "global.status.delivered_not_billed": "{1}Réceptionné non facturé|{2}Réceptionnée non facturée",
        "global.status.draft": "Brouillon",
        "global.status.error": "Erreur",
        "global.status.expired": "{1}Expiré|{2}Expirée",
        "global.status.exported": "{1}Exporté|{2}Exportée",
        "global.status.forbidden": "{1}Interdit|{2}Interdite",
        "global.status.imported": "{1}Importé|{2}Importée",
        "global.status.inactive": "{1}Non actif|{2}Non active",
        "global.status.locked": "{1}Bloqué|{2}Bloquée",
        "global.status.none": "Aucun",
        "global.status.not_acknowledged_receipt": "{1}Non reçu par le fournisseur|{2}Non reçue par le fournisseur",
        "global.status.not_exported": "{1}Non exporté|{2}Non exportée",
        "global.status.not_launched": "{1}Non lancé|{2}Non lancée",
        "global.status.not_ongoing": "Pas en cours",
        "global.status.not_sent": "{1}Non transmis|{2}Non transmise",
        "global.status.obsolete": "Obsolète",
        "global.status.ongoing": "En cours",
        "global.status.paid": "{1}Payé|{2}Payée",
        "global.status.partial": "{1}Partiel|{2}Partielle",
        "global.status.pending": "A traiter",
        "global.status.planified": "{1}Planifié|{2}Planifiée",
        "global.status.processing": "En cours",
        "global.status.prospect": "Prospect",
        "global.status.refused": "{1}Refusé|{2}Refusée",
        "global.status.rejected": "{1}Rejeté|{2}Rejetée",
        "global.status.sending": "Envoi en cours",
        "global.status.sent": "{1}Transmis|{2}Transmise",
        "global.status.skipped": "{1}Contourné|{2}Contournée",
        "global.status.suspended": "{1}Suspendu|{2}Suspendue",
        "global.status.unvalidated": "{1}Invalidé|{2}Invalidée",
        "global.status.validated": "{1}Validé|{2}Validée",
        "global.title.confirmation_request": "Demande de confirmation",
        "global.title.endorsement": "Avenant {model}",
        "global.title.form": "Formulaire",
        "global.title.model_of_2nd_model": "{1}{model} du {2nd_model}|{2}{model} de la {2nd_model}",
        "global.title.relationships": "Relations",
        "global.type.boolean": "Booléen",
        "global.type.choice": "Choix",
        "global.type.date": "Date",
        "global.type.email": "Email",
        "global.type.iso": "Code ISO 3 caractères",
        "global.type.number": "Nombre",
        "global.type.string": "Chaine de caractères",
        "global.type.string.limit": "{number} caractères",
        "global.type.stringList": "Liste de choix",
        "global.type.stringList.help": "Liste séparée par des virgules",
        "global.type.text": "Texte",
        "global.type.textarea": "Texte long",
        "global.value.all": "Tout",
        "global.value.and": "et",
        "global.value.automatic": "Automatique",
        "global.value.auto_added": "Ajouté automatiquement",
        "global.value.by_default": "Par défaut",
        "global.value.capital": "Majuscule",
        "global.value.disabled": "Désactivé",
        "global.value.enabled": "Actif",
        "global.value.everyone": "Tout le monde",
        "global.value.everything": "Tout",
        "global.value.false": "Faux",
        "global.value.global": "Global",
        "global.value.has_handled": "a pris en charge",
        "global.value.inbox": "Inbox",
        "global.value.lowercase": "Minuscule",
        "global.value.main": "Principal",
        "global.value.monthly": "Mensuel",
        "global.value.no": "Non",
        "global.value.nobody": "Personne",
        "global.value.none": "Aucun(e)",
        "global.value.noParent": "Aucun parent",
        "global.value.nothing": "Rien",
        "global.value.not_approved": "{1}Non approuvé|{2}Non approuvée",
        "global.value.not_exported": "{1}Non exporté|{2}Non exportée",
        "global.value.not_found": "{1}Non trouvé|{2}Non trouvée",
        "global.value.not_handling": "{1}Non pris en charge|{2}Non prise en charge",
        "global.value.not_ordered": "{1}Non commandé|{2}Non commandée",
        "global.value.not_sent": "{1}Non envoyé|{2}Non envoyée",
        "global.value.not_submitted": "{1}Non soumis|{2}Non soumise",
        "global.value.not_submitted_order": "{1}{abbr} non soumis|{2}{abbr} non soumise",
        "global.value.no_duration": "Sans durée",
        "global.value.null": "#vide",
        "global.value.number": "Chiffre",
        "global.value.or": "ou",
        "global.value.other": "Autre",
        "global.value.reason": "Raison",
        "global.value.select": "Sélectionner",
        "global.value.special_char": "Caractère spécial",
        "global.value.true": "Vrai",
        "global.value.unique": "une seule valeur possible",
        "global.value.unit.abbreviation": "U.",
        "global.value.unknown": "{1}Inconnu|{2}Inconnue",
        "global.value.unlimited": "Illimité",
        "global.value.upload": "Upload",
        "global.value.yearly": "Annuel",
        "global.value.yes": "Oui",
        "header.info.current_tenant_vs_all_tenants": "Client actuel / Tous les clients",
        "help.file.extensions": "Extensions autorisées : {extensions}",
        "help.file.max_size": "Largeur max. : {max_width} px, hauteur max. : {max_height} px",
        "help.file.max_weight": "Poids maximum : {max_weight}",
        "help.image.resize_link": "Outil externe pour redimensionner plusieurs images",
        "help.two_draggable_lists.how_to": "Glissez-déposez les éléments de la colonne \"{col1}\" vers la colonne \"{col2}\" dans l'ordre souhaité.",
        "home.index": "Accueil",
        "image.model": "Image",
        "importer.disclaimer": "Attention, en validant ce formulaire vous risquez d'écraser des données de façon irréversible. Veillez à le renseigner correctement.",
        "importer.disclaimer.msExcel": "Veuillez enregistrer votre fichier au format 'CSV (séparateur point-virgule) (*.csv)'.",
        "importer.field.file.error.badHeading": "Le fichier importé contient un nom de colonne invalide : {column}",
        "importer.field.file.error.missingColumns": "Il manque des colonnes obligatoires dans le fichier importé : {columns}",
        "importer.field.file.help": "Le nom de chaque colonne doit correspondre au caractère près au nom d'une colonne de la table sélectionnée.<br>Toutes les colonnes ne sont pas obligatoires.<br>Une cellule vide ne sera pas prise en compte, une cellule contenant la chaîne \"#null\" videra le champ associé (si possible).",
        "importer.field.model": "Modèle",
        "importer.field.table": "Table",
        "importer.importModel": "Importer des objets",
        "importer.importTable": "Importer des tables",
        "incoterm.model": "Incoterm",
        "incoterm.model.plural": "Incoterms",
        "knowledge_base.title": "Base de connaissances",
        "language.field.active": "Active",
        "language.field.code": "Code langue (ISO 639-1)",
        "language.field.country_code": "Code pays (ISO 3166-2)",
        "language.field.currency_position": "Position de la devise",
        "language.field.date_format": "Format de date",
        "language.field.hour_format": "Format d'heure",
        "language.model": "Langue",
        "language.model.gender": "2",
        "language.model.plural": "Langues",
        "line.model": "Ligne",
        "line.model.gender": "2",
        "line.model.plural": "Lignes",
        "mail.actions_to_do.intro": "Vous avez des actions à effectuer.",
        "mail.actions_to_do_for_delegate.intro": "Vous avez des actions à effectuer en tant que délégué.",
        "mail.hello": "Bonjour,",
        "mail.overriden_to": "Ce message a été redirigé, voici le destinataire original :|Ce message a été redirigé, voici les destinataires originaux :",
        "mail.regards": "Cordialement",
        "mail.reset_password.action": "Réinitialiser le mot de passe",
        "mail.reset_password.intro": "Vous recevez cet e-mail car nous avons reçu une demande de réinitialisation du mot de passe pour votre compte.",
        "mail.reset_password.outro1": "Ce lien de réinitialisation de mot de passe expirera dans {count} minutes.",
        "mail.reset_password.outro2": "Si vous n'avez pas demandé de réinitialisation du mot de passe, aucune autre action n'est requise.",
        "mail.reset_password.subject": "Réinitialisation du mot de passe",
        "mail.test.content": "Ceci est un message de test.<br>Merci de le transférer à : {support_email}",
        "mail.trouble_on_link": "Si vous rencontrez des difficultés pour cliquer sur le bouton \"{action_text}\", copiez et collez l'URL ci-dessous dans votre navigateur Web :",
        "mail.whoops": "Oups !",
        "mailTemplate.field.available_variables": "Variables disponibles",
        "mailTemplate.override": "Surcharge du template de mail",
        "mail_template.model": "Template de mail",
        "mail_template.model.plural": "Templates de mail",
        "maintenance.action.create_demo": "Créer une démo",
        "maintenance.copyDBToDebug": "Copier la base de données sur l'instance de test",
        "maintenance.dashboard": "Maintenance",
        "maintenance.delegation.cronUpdateDelegations": "{model} : désactiver lorsque la date est dépassée",
        "maintenance.error.noDeletePOs": "non supprimé(s)",
        "maintenance.field.disable_reason": "Raison de la désactivation",
        "maintenance.fixAfterV1Migration": "Patch suite à la migration Boomerang Web v1 -> v2",
        "maintenance.form.crons": "Lancement des crons",
        "maintenance.form.deletePOs": "Supprimer des bons de commandes",
        "maintenance.form.demo": "Création des instances de démo",
        "maintenance.modal.confirmDeletePOs": "Voulez-vous supprimer tous les bons de commande",
        "maintenance.realtimeInfos": "Infos en temps réel",
        "maintenance.refreshClientStatistic": "Rafraîchir les données statistiques",
        "maintenance.reset_demo_data": "Réinitialiser les données de DEMO",
        "maintenance.reset_demo_data.warning": "Les données actuelles seront écrasées au profit de nouvelles données de démonstration, basées sur la configuration actuelle.",
        "maintenance.sql_query.title": "Requête SQL",
        "maintenance.supplier.cronUpdateFamiliesStatuses": "{model} : mettre à jour les statuts des {product_families}",
        "maintenance.supplier.cronUpdateForcedStatuses": "{model} : supprimer les statuts forcés lorsque la date est dépassée",
        "maintenance.supplier.cronUpdateStatistics": "{model} : mettre à jour les statistiques des {model} concernés ({count} éléments, {max} max à la fois)",
        "maintenance.supplier.cronUpdateStatuses": "{model} : mettre à jour les statuts",
        "maintenance.supplier_contract.check_status": "{model} : désactiver lorsque la date est dépassée",
        "maintenance.supplier_file.cronUpdateStatuses": "{model} : désactiver lorsque la date est dépassée",
        "maintenance.title.data_accuracy": "Vérifier l'exactitude des données",
        "maintenance.title.disable_tenant": "Désactivation du Client",
        "maintenance.title.tests": "Tests",
        "maintenance.trace.cronDeleteOldTraces": "{model} : supprimer lorsque la date est trop ancienne",
        "message.field.texts.help": "Vous pouvez utiliser les jetons suivants :<br>{firstname} : Prénom de l'utilisateur connecté<br>{lastname} : Nom de l'utilisateur connecté<br>{user_label} : Prénom + Nom de l'utilisateur connecté<br>{email} : E-mail de l'utilisateur connecté<br>{company} : Nom du client<br>",
        "message.info.tokens_may_not_work": "Certains jetons pourraient ne pas fonctionner sur les pages sans Utilisateur connecté",
        "message.model": "Message",
        "message.model.plural": "Messages",
        "metrics.po": "Bons de commande créés depuis le dernier renouvellement de contrat",
        "metrics.projection": "Projection de bons de commandes créés jusqu'au prochain renouvellement de contrat",
        "metrics.storage": "Stockage utilisé",
        "metrics.user": "Utilisateurs créés",
        "model.action.manage": "Gérer les {model}",
        "model.all": "{1}Tous les {model}|{2}Toutes les {model}",
        "model.as_buyer": "Les {model} dont je suis Acheteur",
        "model.back_to_draft.existing_payment_lines": "{1}De plus, le {model} a déjà été payé, mais aucun Paiement ne sera annulé.|{2}De plus, la {model} a déjà été payée, mais aucun Paiement ne sera annulé.",
        "model.cancel.cancel_version": "{1}De plus, le {model} est une version du {model} d'origine. Merci de cocher la case suivante afin de confirmer le rétablissement de la version précédente de ce {model} :|{2}De plus, la {model} est une version de la {model} d'origine. Merci de cocher la case suivante afin de confirmer le rétablissement de la version précédente de cette {model} :",
        "model.cancel.existing_payment_lines": "{1}De plus, le {model} a déjà été payé, mais aucun Paiement ne sera annulé.|{2}De plus, la {model} a déjà été payée, mais aucun Paiement ne sera annulé.",
        "model.create": "Création",
        "model.delete": "Supprimer",
        "model.disable": "Désactiver",
        "model.error.can_not_find": "{model} impossible à trouver",
        "model.favorite": "{1}{model} favori|{2}{model} favorite",
        "model.info.amount_zero": "{1}Le {model} a un montant égal à 0. Voulez-vous continuer ?|{2}La {model} a un montant égal à 0. Voulez-vous continuer ?",
        "model.info.cancel": "{1}Le {model} va être annulé si vous poursuivez.|{2}La {model} va être annulée si vous poursuivez.",
        "model.info.cancel_and_replace": "{1}Le {model} va être annulé si vous poursuivez, et un nouveau brouillon va être créé pour le remplacer.|{2}La {model} va être annulée si vous poursuivez, et un nouveau brouillon va être créé pour la remplacer.",
        "model.info.cancel_and_replace_of": "Annule et remplace \"{replaced}\" ",
        "model.info.cant_be_deleted": "{1}Le {model} ne peut pas être supprimé (il est utilisé dans divers éléments) mais il peut être désactivé.|{2}La {model} ne peut pas être supprimée (elle est utilisée dans divers éléments) mais elle peut être désactivée.",
        "model.info.close": "{1}Le {model} va être clôturé et ne pourra plus être modifié si vous poursuivez.|{2}La {model} va être clôturée et ne pourra plus être modifiée si vous poursuivez.",
        "model.info.has_articles_to_disable": "{1}Le {model} possède des {article_models}, ceux-ci seront désactivés.|{2}La {model} possède des {article_models}, ceux-ci seront désactivés.",
        "model.info.item_cant_be_deleted": "Cet élément ne peut par conséquent pas être supprimé.",
        "model.info.main": "{1}{model} principal|{2}{model} principale",
        "model.info.mark_as_refused": "{1}Il faudra créer un nouveau {model} une fois celui-ci refusé.|{2}Il faudra créer une nouvelle {model} une fois celle-ci refusée.",
        "model.info.new_version": "{1}Le {model} va être bloqué et une nouvelle version va être créée en brouillon si vous poursuivez.|{2}La {model} va être bloquée et une nouvelle version va être créée en brouillon si vous poursuivez.",
        "model.info.none_available": "{1}Aucun {model} disponible|{2}Aucune {model} disponible",
        "model.info.pdf": "{1}PDF du {model}|{2}PDF de la {model}",
        "model.info.unclose": "{1}Le {model} va être déclôturé et pourra de nouveau être modifié si vous poursuivez.|{2}La {model} va être déclôturée et pourra de nouveau être modifiée si vous poursuivez.",
        "model.info.unkown": "{1}{model} inconnu|{2}{model} inconnue",
        "model.other": "Autre {model}",
        "model.own": "Les {model} dont je suis Créateur ou Destinataire",
        "model.title.configure": "Configurer : {model}",
        "model.title.create": "Création : {model}",
        "model.title.import": "Import : {model}",
        "model.title.update": "Modification : {model}",
        "model.to_replace": "{model} à remplacer",
        "model.update": "Modification",
        "model_line.model": "Ligne de {model}",
        "modification.model": "Modification",
        "modification.model.gender": "2",
        "module.mirroring_database": "Mirroring Database",
        "module.multi_company": "Multi-sociétés",
        "module.multi_currency": "Multi-devises",
        "module.not_enabled": "Module non actif",
        "module.supplier.advanced": "Gestion avancée",
        "module.supplier.login": "Connexion",
        "module.supplier_invoice.ocr": "OCR",
        "need.model": "Demande d'Achat",
        "need.model.plural": "Demandes d'Achat",
        "need.status.submitted_purchase_order": "{1}{abbr} soumis|{2}{abbr} soumise",
        "need.status.validated_purchase_order": "{1}{abbr} validé|{2}{abbr} validée",
        "need.title.choose_model_to_deliver": "{1}Choisir un {model} à réceptionner|{2}Choisir une {model} à réceptionner",
        "notification.label.completed_export": "Export (fichier joint)",
        "notification.label.delivery.sending": "{1}Envoi d'un {model}|{2}Envoi d'une {model}",
        "notification.label.failed_export": "Export échoué",
        "notification.label.model_expiration_alert": "Expiration des {model}",
        "notification.label.model_expiration_alert_before": "Avertissement avant expiration des {model}",
        "notification.label.need.awaiting": "{1}Un {model} a été émis|{2}Une {model} a été émise",
        "notification.label.need.po_submitted": "{1}Le {po_model} d'un {model} a été soumis|{2}Le {po_model} d'une {model} a été soumis",
        "notification.label.need.po_validated": "{1}Le {po_model} d'un {model} a été validé|{2}Le {po_model} d'une {model} a été validé",
        "notification.label.need.processing": "{1}Un {model} a été pris en charge|{2}Une {model} a été prise en charge",
        "notification.label.need.suspended": "{1}Un {model} a été suspendu|{2}Une {model} a été suspendue",
        "notification.label.need.suspension_stopped": "{1}Un {model} n'est plus suspendu|{2}Une {model} n'est plus suspendue",
        "notification.label.need.transfered": "{1}Un {model} a été transféré|{2}Une {model} a été transférée",
        "notification.label.new_comment": "{1}Un commentaire a été envoyé sur un {model}|{2}Un commentaire a été envoyé sur une {model}",
        "notification.label.payment.line_paid": "{1}Un {model} a été indiqué comme payé (virement uniquement)|{2}Une {model} a été indiquée comme payée (virement uniquement)",
        "notification.label.purchase_order.sending": "{1}Envoi d'un {model}|{2}Envoi d'une {model}",
        "notification.label.purchase_order.validated_with_deposit": "{1}{model} validé requérant un acompte|{2}{model} validée requérant un acompte",
        "notification.label.quotation_sending": "Envoi d'une Demande de prix",
        "notification.label.rel_supplier_product_family.awaiting": "Une famille de fournisseur est à valider",
        "notification.label.rel_supplier_product_family.expiration_alert": "Expiration des familles de fournisseurs",
        "notification.label.rel_supplier_product_family.expiration_alert_before": "Avertissement avant expiration des familles de fournisseurs",
        "notification.label.rel_supplier_product_family.refused": "Une famille de fournisseur a été refusée",
        "notification.label.rel_supplier_product_family.unactivation_by_file_expiration_alert": "Expiration des familles de fournisseurs",
        "notification.label.rel_supplier_product_family.validated": "Une famille de fournisseur a été validée",
        "notification.label.rel_supplier_product_family_unactivation_by_file_expiration_alert": "{1}Expiration des {model} (manque de {file_model})|{2}Expiration des {model} (manque de {file_model})",
        "notification.label.suggested_modifications": "Des modifications sont à valider",
        "notification.label.supplier.after_validation": "Un Fournisseur a été validé - envoi du mail selon configuration",
        "notification.label.supplier.contact_invitation": "Invitation d'un contact Fournisseur",
        "notification.label.supplier.data_creation": "{1}Création d'un {model} en n'étant pas le responsable du compte|{2}Création d'une {model} en n'étant pas le responsable du compte",
        "notification.label.supplier.file_creation": "Création d'un document fournisseur en n'étant pas le responsable du compte",
        "notification.label.supplier.file_expiration_alert": "Expiration des documents fournisseurs",
        "notification.label.supplier.file_expiration_alert_before": "Avertissement avant expiration des documents fournisseurs",
        "notification.label.supplier.login_request": "Un Fournisseur demande à se connecter",
        "notification.label.supplier_contract.expiration_alert": "Expiration des {models}",
        "notification.label.supplier_contract.expiration_alert_before": "Avertissement avant expiration des {models}",
        "notification.label.supplier_invoice.notification_when_no_delivery": "{1}Demande de réception concernant un {model}|{2}Demande de réception concernant une {model}",
        "notification.label.user.invitation": "Invitation d'un utilisateur",
        "notification.label.validation_awaiting": "{1}Un {model} est à valider|{2}Une {model} est à valider",
        "notification.label.validation_refused": "{1}Un {model} a été refusé|{2}Une {model} a été refusée",
        "notification.label.validation_submitted": "{1}Un {model} a été soumis en validation|{2}Une {model} a été soumise en validation",
        "notification.label.validation_validated": "{1}Un {model} a été validé|{2}Une {model} a été validée",
        "notification.markAllAsRead": "Tout marquer comme lu",
        "notification.model": "Notification",
        "notification.model.gender": "2",
        "notification.model.plural": "Notifications",
        "notification.subject.actions_to_do": "Récapitulatif des actions à effectuer",
        "notification.subject.actions_to_do_for_delegate": "Récapitulatif des actions à effectuer en tant que délégué",
        "notification.subject.bank_account.validation_awaiting": "{1}Un {model} pour le Fournisseur \"{supplier}\" doit être validé et nécessite votre attention|{2}Une {model} pour le Fournisseur \"{supplier}\" doit être validée et nécessite votre attention",
        "notification.subject.bank_account.validation_refused": "{1}Un {model} pour le Fournisseur \"{supplier}\" vient d'être refusé|{2}Une {model} pour le Fournisseur \"{supplier}\" vient d'être refusée",
        "notification.subject.bank_account.validation_submitted": "{1}Le {model} \"{label}\" a été soumis en validation|{2}La {model} \"{label}\" a été soumise en validation",
        "notification.subject.bank_account.validation_validated": "{1}Un {model} pour le Fournisseur \"{supplier}\" vient d'être validé|{2}Une {model} pour le Fournisseur \"{supplier}\" vient d'être validée",
        "notification.subject.completed_export": "Votre export est prêt",
        "notification.subject.delivery.sending": "{model} \"{delivery}\" ",
        "notification.subject.failed_export": "Votre export a échoué",
        "notification.subject.need.awaiting": "{1}Un {model} vous concernant a été émis|{2}Une {model} vous concernant a été émise",
        "notification.subject.need.po_submitted": "{1}Le {po_model} d'un {model} vous concernant a été soumis|{2}Le {po_model} d'une {model} vous concernant a été soumis",
        "notification.subject.need.po_validated": "{1}Le {po_model} d'un {model} vous concernant a été validé|{2}Le {po_model} d'une {model} vous concernant a été validé",
        "notification.subject.need.processing": "{1}Un {model} vous concernant a été pris en charge|{2}Une {model} vous concernant a été prise en charge",
        "notification.subject.need.suspended": "{1}Un {model} vous concernant a été suspendu|{2}Une {model} vous concernant a été suspendue",
        "notification.subject.need.suspension_stopped": "{1}Un {model} vous concernant n'est plus suspendu|{2}Une {model} vous concernant n'est plus suspendue",
        "notification.subject.need.transfered": "{1}Un {model} vous a été transféré|{2}Une {model} vous a été transférée",
        "notification.subject.new_comment": "{1}Un commentaire a été envoyé sur un {model} vous concernant|{2}Un commentaire a été envoyé sur une {model} vous concernant",
        "notification.subject.payment.line_paid": "Facture payée",
        "notification.subject.purchase_order.sending": "{model} \"{purchaseOrder}\" ",
        "notification.subject.purchase_order.validated_with_deposit": "{1}Nouvel acompte requis sur un {model}|{2}Nouvel acompte requis sur une {model}",
        "notification.subject.quotation_sending": "Demande de prix",
        "notification.subject.rel_supplier_product_family.awaiting": "La famille \"{family}\" doit être validée pour le fournisseur \"{supplier}\" et nécessite votre attention",
        "notification.subject.rel_supplier_product_family.expiration_alert": "La famille \"{productFamily}\" du fournisseur \"{supplier}\" a expiré",
        "notification.subject.rel_supplier_product_family.expiration_alert_before": "La famille \"{productFamily}\" du fournisseur \"{supplier}\" expire bientôt",
        "notification.subject.rel_supplier_product_family.refused": "La famille \"{family}\" vient d'être refusée pour le fournisseur \"{supplier}\" ",
        "notification.subject.rel_supplier_product_family.unactivation_by_file_expiration_alert": "La famille \"{productFamily}\" du fournisseur \"{supplier}\" a été désactivée",
        "notification.subject.rel_supplier_product_family.validated": "La famille \"{family}\" vient d'être validée pour le fournisseur \"{supplier}\" ",
        "notification.subject.suggested_modifications": "Des modifications ont été suggérées",
        "notification.subject.supplier.after_validation": "Un nouveau Fournisseur a été validé",
        "notification.subject.supplier.contact_invitation": "Invitation à utiliser Boomerang Web",
        "notification.subject.supplier.file_creation": "Un nouveau document a été inséré pour le fournisseur \"{supplier}\" ",
        "notification.subject.supplier.file_expiration_alert": "Le document \"{file}\" du fournisseur \"{supplier}\" a expiré",
        "notification.subject.supplier.file_expiration_alert_before": "Le document \"{file}\" du fournisseur \"{supplier}\" expire bientôt",
        "notification.subject.supplier.login_request": "Demande de Connexion",
        "notification.subject.supplier_contract.expiration_alert": "{1}Le {model} \"{contract}\" a expiré|{2}La {model} \"{contract}\" a expiré",
        "notification.subject.supplier_contract.expiration_alert_before": "{1}Le {model} \"{contract}\" expire bientôt|{2}La {model} \"{contract}\" expire bientôt",
        "notification.subject.supplier_invoice.notification_when_no_delivery": "{1}Demande de réception concernant le {model} \"{supplier_invoice}\"|{2}Demande de réception concernant la {model} \"{supplier_invoice}\" ",
        "notification.subject.user.invitation": "Invitation à utiliser Boomerang Web",
        "notification.subject.validation_awaiting": "{1}Le {model} \"{label}\" doit être validé et nécessite votre attention|{2}La {model} \"{label}\" doit être validée et nécessite votre attention",
        "notification.subject.validation_refused": "{1}Le {model} \"{label}\" vient d'être refusé|{2}La {model} \"{label}\" vient d'être refusée",
        "notification.subject.validation_submitted": "{1}Le {model} \"{label}\" a été soumis en validation|{2}La {model} \"{label}\" a été soumise en validation",
        "notification.subject.validation_validated": "{1}Le {model} \"{label}\" vient d'être validé|{2}La {model} \"{label}\" vient d'être validée",
        "observation.field.sentBy": "par",
        "observation.field.sentOn": "Posté le",
        "observation.model": "Observation",
        "observation.model.gender": "2",
        "observation.model.plural": "Observations",
        "pagination.model": "Pagination",
        "pagination.next": "Suivant",
        "pagination.per_page": "Par page",
        "pagination.previous": "Précédent",
        "password.action.change": "Changer le mot de passe",
        "passwordChange.error.verifyConfirmation": "La confirmation du mot de passe ne correspond pas",
        "password_reset.define": "Définir un nouveau mot de passe",
        "password_reset.reset": "Réinitialiser le mot de passe",
        "password_reset.send_mail": "Envoyer le lien de réinitialisation du mot de passe",
        "payment.action.mark_as_paid": "{1}Marquer comme payé|{2}Marquer comme payée",
        "payment.action.mark_as_refused": "{1}Marquer comme refusé|{2}Marquer comme refusée",
        "payment.confirm.line_deleted_due_to_overpayment": "Ligne de {model} supprimée car le montant est arrivé à 0",
        "payment.confirm.overpayment_added_to_invoice": "{1}Trop-perçu attaché au {model}|{2}Trop-perçu attaché à la {model}",
        "payment.error.amount_inctax.less_than_paid": "Le montant TTC doit être supérieur à {paid} (montant restant à payer)",
        "payment.error.amount_inctax.less_than_zero": "Le montant TTC doit être supérieur à 0",
        "payment.error.amount_inctax.more_than_paid": "Le montant TTC doit être inférieur à {paid} (montant restant à payer)",
        "payment.error.amount_inctax.more_than_zero": "Le montant TTC doit être inférieur à 0",
        "payment.error.amount_inctax.nothing_more_to_pay": "Il n'y a plus rien à payer",
        "payment.error.currency_not_euro": "L'export SEPA n'est possible qu'avec des paiements en euro.",
        "payment.error.missing_banking_info": "Informations bancaires manquantes (RIB) !",
        "payment.error.missing_payment_source_banking_info": "Des informations bancaires pour le compte émetteur sont manquantes, merci de les renseigner avant d'effectuer l'export SEPA.",
        "payment.error.missing_supplier_banking_info": "Des informations bancaires pour l'un des fournisseurs sont manquantes, merci de les renseigner avant d'effectuer l'export SEPA.",
        "payment.field.paid_amount_inctax": "Montant TTC du paiement",
        "payment.info.only_from_currency": "Les éléments affichés sont pré-filtrés selon la devise du paiement : {currency}",
        "payment.line.status.title.blocked": "{1}{model} validé, mais bloqué pour cause de solde insuffisant ou compte débiteur|{2}{model} validée, mais bloquée pour cause de solde insuffisant ou compte débiteur",
        "payment.line.status.title.canceled": "{1}{model} annulé par l'utilisateur|{2}{model} annulée par l'utilisateur",
        "payment.line.status.title.error": "Erreur",
        "payment.line.status.title.planified": "{1}{model} validé et en attente d'exécution|{2}{model} validée et en attente d'exécution",
        "payment.line.status.title.refused": "{1}{model} refusé par le valideur|{2}{model} refusée par le valideur",
        "payment.line.status.title.rejected_by_beneficiary_bank": "{1}{model} refusé par la banque du bénéficiaire|{2}{model} refusée par la banque du bénéficiaire",
        "payment.line.status.title.rejected_by_holder_bank": "{1}{model} refusé par la banque de l'émetteur|{2}{model} refusée par la banque de l'émetteur",
        "payment.mark_as_paid.send_mail": "Vous pouvez envoyer un mail de confirmation à chaque {model}.",
        "payment.model": "Paiement",
        "payment.model.plural": "Paiements",
        "payments.overpayment": "Trop-perçu",
        "payments.overpayment.plural": "Trop-perçus",
        "payment_source.field.organization_id": "Numéro de SIRET",
        "payment_source.field.organization_name": "Nom de la société",
        "payment_source.model": "Source de paiement",
        "payment_source.model.gender": "2",
        "payment_source.model.plural": "Sources de paiement",
        "payment_term.field.fixed_day": "Jour du mois pour le paiement",
        "payment_term.field.month_end": "Fin de mois ?",
        "payment_term.field.on_receipt": "À partir de la date de réception ?",
        "payment_term.field.payment_deadline": "Nombre de jours",
        "payment_term.label.cash": "comptant",
        "payment_term.label.fixed_day": "paiement le {fixed_day} suivant",
        "payment_term.label.month_end": "fin de mois",
        "payment_term.label.on_receipt": "à réception de facture",
        "payment_term.label.payment_deadline": "{payment_deadline} jours",
        "payment_term.model": "Condition de règlement",
        "payment_term.model.gender": "2",
        "payment_term.model.plural": "Conditions de règlement",
        "payment_type.model": "Type de règlement",
        "payment_type.model.plural": "Types de règlement",
        "payment_type.type.none": "Non spécifié",
        "payment_type.type.sepa_transfer": "Virement SEPA",
        "pending_api_call.model": "Appel API sortant",
        "pending_api_call.model.plural": "Appels API sortants",
        "pending_supplier_invoice.file.default_original_name": "Fichier importé",
        "pending_supplier_invoice.model.plural": "Factures à traiter",
        "perm.budgetAnalysis.index": "Voir les analyses budgétaires",
        "perm.menu.admin": "Accéder au menu Admin",
        "permission.by_model": "Par {model}",
        "permission.details": "Détail des permissions",
        "permission.has_permission": "Possède la permission",
        "permission.model": "Permission",
        "permission.model.plural": "Permissions",
        "prefill_data.model": "Donnée pré-remplie",
        "prefill_data.model.plural": "Données pré-remplies",
        "prefill_data.target.comment_pattern": "Modèle de commentaire : {model}",
        "print.share_url.info": "Vous pouvez copier cette URL pour partager le document, qui devient accessible même sans être connecté.",
        "productFamily.field.is_main_product_family": "Principale",
        "productFamily.field.selectedProductFamilies": "Familles sélectionnées",
        "product_family.field.approvals": "Agréments",
        "punchout.module": "Catalogue PunchOut",
        "purchaseOrder.import.field.update": "Mettre à jour les informations des bons de commandes avec un libellé déja existant",
        "purchaseOrder.import.undefined.allocation": "Ligne {line} : Le code {undefinedAllocation} est indéfini et le bon de commande n'a donc pas pu être créé.|Ligne {line} : Les codes {undefinedAllocation} sont indéfinis et le bon de commande n'a donc pas pu être créé.",
        "purchaseOrder.import.undefined.incoterm": "Ligne {line} : L'incoterm {undefinedIncoterm} est indéfini et le bon de commande n'a donc pas pu être créé.",
        "purchaseOrder.import.undefined.status": "Ligne {line} : Le statut {undefinedStatus} est indéfini et le bon de commande n'a donc pas pu être créé.",
        "purchaseOrder.import.undefined.supplier": "Ligne {line} : Le code fournisseur {undefinedSupplier} est indéfini et le bon de commande n'a donc pas pu être créé.",
        "purchaseOrder.import.undefined.supplier_contact": "Ligne {line} : L'email du contact fournisseur {undefinedSupplierContact} est indéfini et le bon de commande n'a donc pas pu être créé.",
        "purchaseOrder.import.undefined.supplier_contact_default": "Ligne {line} : Ce fournisseur ne possède pas de contact par défaut alors qu'un contact est requis. Le bon de commande n'a donc pas pu être créé.",
        "purchaseOrder.import.undefined.user": "Ligne {line} : Le login de l'auteur {undefinedUser} est indéfini et le bon de commande n'a donc pas pu être créé.",
        "purchaseOrder.import.unique.code": "Ligne {line} : Un bon de commande existe déja avec le même libellé et un code différent de {uniqueString}.",
        "purchaseOrder.payment_status.complete": "Payé",
        "purchaseOrder.payment_status.no": "Non payé",
        "purchaseOrder.payment_status.partial": "Payé partiellement",
        "purchaseOrder.pdf.billing_conditions": "IMPORTANT: Le Numéro de Commande&nbsp;<b>{code}</b> ainsi que la référence&nbsp;<b>{reference}</b> doivent impérativement figurer sur les factures.",
        "purchaseOrder.pdf.billing_conditions.title": "Conditions de Facturation",
        "purchaseOrder.pdf.payment_period": "Délai de paiement",
        "purchaseOrder.pdf.supplier_address": "Adresse fournisseur",
        "purchase_order.action.add_contact": "Créer un nouveau contact",
        "purchase_order.back_to_draft.already_sent_at": "{1}De plus, le {model} a déjà été envoyé au Fournisseur. Merci de cocher la case suivante afin de confirmer l'annulation du statut \"{status}\" (aucune notification ne sera envoyée au Fournisseur) :|{2}De plus, la {model} a déjà été envoyée au Fournisseur. Merci de cocher la case suivante afin de confirmer l'annulation du statut \"{status}\" (aucune notification ne sera envoyée au Fournisseur) :",
        "purchase_order.cancel.already_sent_at": "{1}De plus, le {model} a déjà été envoyé au Fournisseur. Merci de cocher la case suivante afin de confirmer l'annulation du {model} (aucune notification ne sera envoyée au Fournisseur) :|{2}De plus, la {model} a déjà été envoyée au Fournisseur. Merci de cocher la case suivante afin de confirmer l'annulation du {model} (aucune notification ne sera envoyée au Fournisseur) :",
        "purchase_order.error.article.only_enabled": "Cet article doit être actif",
        "purchase_order.error.max_amount_per_order.exceeded": "Le plafond du Fournisseur ({value}/commande) est dépassé",
        "purchase_order.error.max_amount_per_year.exceeded": "Le plafond du Fournisseur ({value}/an) est dépassé",
        "purchase_order.error.stock_exceeded": "Stock actuel ({current_stock}) dépassé de {exceeded} unité(s)",
        "purchase_order.export.filename": "commandes",
        "purchase_order.export_remaining_to_deliver.filename": "commandes-a-receptionner",
        "purchase_order.field.articles_quantity": "Quantité d'articles",
        "purchase_order.field.code": "Num. de Bon",
        "purchase_order.field.deposit": "Acompte demandé",
        "purchase_order.field.deposit.abbreviation": "A",
        "purchase_order.field.deposit_paid_at": "Acompte payé le",
        "purchase_order.field.id": "ID BC",
        "purchase_order.field.references_number": "Nombre de références",
        "purchase_order.field.send_mode": "Mode d'envoi",
        "purchase_order.field.supplier_comment": "Commentaires Fournisseur",
        "purchase_order.field.supplier_contact": "Contact Fournisseur",
        "purchase_order.info.gap_line": "Ligne d'écart",
        "purchase_order.info.internal_article": "{model} à réapproviser dans le stock",
        "purchase_order.inject_lines.error": "Erreur lors de l'injection de plusieurs lignes",
        "purchase_order.inject_lines.impossible_relation": "Ligne {line} : Il n'existe pas de lien parent-enfant entre le code {impossibleRelationAllocation} et les autres codes de la ligne.",
        "purchase_order.inject_lines.no_right": "Ligne {line} : Vous n'avez pas les autorisations nécessaires pour créer des lignes en utilisant le code {noRightAllocation}.",
        "purchase_order.inject_lines.undefined.allocation": "Ligne {line} : Le code {undefinedAllocation} est indéfini.|Ligne {line} : Les codes {undefinedAllocation} sont indéfinis.",
        "purchase_order.label.warehouse_allocations_adjustment": "Ajustement des imputations",
        "purchase_order.modal.multiple_suppliers.article_from_supplier": "Un de vos articles concerne le fournisseur <b>{supplier}</b>",
        "purchase_order.modal.multiple_suppliers.desynchronization_warning": "Si vous changez de fournisseur, vos articles seront désynchronisés",
        "purchase_order.model": "Bon de Commande",
        "purchase_order.model.plural": "Bons de Commande",
        "purchase_order.send.already_sent": "{1}Ce {model} a déjà été envoyé|{2}Cette {model} a déjà été envoyée",
        "purchase_order.send.can_log_as_supplier": "Vous pouvez vous connecter simplement afin de déposer vos factures :",
        "purchase_order.summary_pdf.title": "Commande - Réception - Facturation",
        "purchase_order.unclose.error.missing_quantity": "Il manque des articles en stock pour pouvoir déclôturer :",
        "purchase_order.unclose.error.missing_quantity.detail": "{article} : quantité requise : {needed}, quantité disponible : {available}",
        "purchase_order_send_mode.model": "Mode d'envoi",
        "purchase_order_send_mode.model.plural": "Modes d'envoi",
        "purchase_type.model": "Type d'achat",
        "purchase_type.model.plural": "Types d'achat",
        "qualification.model.gender": "2",
        "question.model": "Question",
        "question.model.plural": "Questions",
        "quotation_request.download.filename": "demande-prix-",
        "quotation_request.model": "Demande de prix",
        "quotation_request.model.gender": "2",
        "registration.action.access": "Accéder à votre logiciel",
        "registration.action.add_user": "Ajouter un utilisateur",
        "registration.action.remove_user": "Retirer l'utilisateur",
        "registration.action.start_now": "Commencer maintenant !",
        "registration.action.use_different_delivery_address": "Utiliser une adresse de livraison différente",
        "registration.error.email_already_registered": "Vous avez déjà effectué une demande avec cette adresse e-mail",
        "registration.error.email_must_be_different": "L'adresse e-mail doit être différente de {field}",
        "registration.field.message": "Votre message",
        "registration.info.already_verified": "Votre inscription a déjà été vérifiée",
        "registration.info.disclaimer": "En continuant, vous acceptez les <a href=\"{link}\" target=\"blank\">Conditions Générales d'Utilisation</a>.",
        "registration.info.fill_code": "Merci de renseigner le code reçu par e-mail",
        "registration.info.impossible": "Inscription impossible, merci de vous rapprocher de notre service client",
        "registration.info.need_more_configuration": "Vous avez besoin de plus de personnalisation (devise, catégories, ...) ?",
        "registration.mail.complete.intro1": "Bienvenue sur Boomerang Web, la plateforme de Gestion des Achats.",
        "registration.mail.complete.intro2": "Votre logiciel est prêt à être utilisé !",
        "registration.mail.complete.outro1": "Vous n'avez qu'à renseigner un mot de passe et tout sera prêt !",
        "registration.mail.complete.outro2": "Ce lien n'est disponible qu'une heure vous pouvez cependant utiliser le lien suivant pour effectuer une réinitialitation du mot de passe.",
        "registration.mail.complete.subject": "Accès à Boomerang Web",
        "registration.mail.error.intro": "Une erreur est survenue lors de la création d'un Tenant par un prospect.",
        "registration.mail.error.subject": "Nouvelle instance : erreur",
        "registration.mail.need_custom.intro": "Un prospect a besoin d'aide pour configurer sa nouvelle instance :",
        "registration.mail.need_custom.outro": "Merci de le recontacter dans les plus brefs délais.",
        "registration.mail.need_custom.subject": "Nouvelle instance : aide requise",
        "registration.mail.notification_new_tenant.intro": "Un nouveau client vient de créer son instance :",
        "registration.mail.notification_new_tenant.subject": "Nouvelle instance : création effectuée",
        "registration.mail.registration.intro1": "Bienvenue sur Boomerang Web, la plateforme de Gestion des Achats.",
        "registration.mail.registration.intro2": "Afin de confirmer votre inscription, merci de saisir le code suivant après avoir cliqué sur le lien ci-après :",
        "registration.mail.registration.subject": "Inscription à Boomerang Web",
        "registration.message.created": "Votre configuration est prête.",
        "registration.message.creating": "Votre configuration est prise en compte. Vous allez recevoir un e-mail lorsque tout sera prêt.",
        "registration.message.message_sent": "Un message a été envoyé à notre équipe, nous revenons vers vous prochainement.",
        "registration.model": "Inscription",
        "registration.model.plural": "Inscriptions",
        "registration.title.company": "Complétez les informations de votre société :",
        "registration.title.configuration": "Configuration",
        "registration.title.registration": "Inscription",
        "registration.title.users": "Utilisateurs",
        "registration.title.validation": "Qui devra valider les Bons de Commande ?",
        "registration.title.verification": "Vérification",
        "relAllocation.import.field.deleteRelation": "Supprimer les liens existants entre les éléments",
        "relAllocation.import.field.update": "Mettre à jour les éléments dont les codes sont déjà existants",
        "relAllocations.error.alreadyExists": "Cette relation existe déjà",
        "relAllocations.field.allocation_type": "Type liaison imputations",
        "relAllocations.import.allocationOrProductFamily": "Imputations ou Familles d'articles à importer",
        "relAllocations.import.error.required": "Au moins une imputation/famille d'articles doit être sélectionnée.",
        "relBuyer.model": "Acheteurs",
        "relPermission.model": "Relations avec les permissions",
        "rel_allocations.action.import": "Importer des Imputations",
        "rel_allocations.model": "Liaison imputations",
        "rel_allocations.model.plural": "Liaisons imputations",
        "rel_supplier_product_family.model": "Famille de fournisseur",
        "rel_supplier_product_family.model.plural": "Familles de fournisseur",
        "rel_supplier_product_family_validation.model": "Validation de Famille de fournisseur",
        "rel_supplier_product_family_validation.model.gender": "2",
        "rel_supplier_product_family_validation.model.plural": "Validations de Famille de fournisseur",
        "role.model": "Rôle",
        "role.model.plural": "Rôles",
        "role.value.noRole": "Pas de role",
        "search_saving.model": "Sauvegarde de recherche",
        "search_saving.model.plural": "Sauvegardes de recherche",
        "secureStorage.field.token": "Token de Secure Storage",
        "secure_storage.error.set_token": "Veuillez renseigner le token dans la BDD de Secure Storage",
        "secure_storage.title.modal": "Insérer un fichier",
        "session.model": "Session",
        "session.model.plural": "Sessions",
        "setting.config.api": "API",
        "setting.config.customer": "Client",
        "setting.config.models": "Modèles",
        "setting.config.model_fields": "Champs des modèles",
        "setting.config.modules": "Modules",
        "setting.customer.allocations.title": "Imputations",
        "setting.customer.contract.title": "Contrat",
        "setting.customer.email_sending.title": "Envoi des e-mails",
        "setting.customer.files.title": "Fichiers",
        "setting.customer.formats": "Formats",
        "setting.customer.misc.title": "Divers",
        "setting.customer.prices.title": "Prix",
        "setting.field.forced_status_max_duration": "Durée maximum d'un Statut forcé à {status}",
        "setting.field.landing_page": "Redirection après la connexion",
        "setting.field.layout_striped": "Faire ressortir une ligne sur deux dans les tableaux",
        "setting.field.supplier_objective_hse": "Objectif HSE",
        "setting.field.supplier_objective_oqd": "Objectif OQD",
        "setting.field.supplier_objective_otd": "Objectif OTD",
        "setting.field.versioning": "Versioning",
        "setting.form.log_filter_query": "Logguer les queries de scopeFilter des {model}",
        "setting.form.maintenance": "Maintenance",
        "setting.formField.ATTACHMENT__AUTHORIZED_TYPES": "Types de fichiers autorisés",
        "setting.formField.ATTACHMENT__DONT_CHECK_FILE_TYPE": "Ne pas vérifier le type des fichiers",
        "setting.formField.CURRENCY__AUTOMATIC_RATE": "Mise à jour automatique des taux de change",
        "setting.formField.DASHBOARD__COMPARISON_MONTH_NUMBER": "Nombre de mois pour la comparaison des montants des {model} :",
        "setting.formField.DASHBOARD__DISTRIBUTION_ALLOCATION": "Répartition des {model} par :",
        "setting.formField.DASHBOARD__INSTANCE": "Information sur votre abonnement :",
        "setting.formField.GLOBAL__AMOUNT_DECIMAL_DELIMITER": "Délimiteur de décimales",
        "setting.formField.GLOBAL__AMOUNT_DECIMAL_NUMBER": "Nombre de décimales dans les montants",
        "setting.formField.GLOBAL__DEBUG": "Activer le mode \"DEBUG\" ",
        "setting.formField.GLOBAL__DISABLE_ALL_EXPORTS": "Désactiver tous les exports",
        "setting.formField.GLOBAL__DISABLE_ANTIVIRUS": "Désactiver l'antivirus",
        "setting.formField.GLOBAL__DISABLE_CHANGE_PASSWORD": "Désactiver les possibilités de changer de mot de passe",
        "setting.formField.GLOBAL__FASTER_QUERY": "Utiliser des requêtes plus rapides",
        "setting.formField.GLOBAL__LOG_QUERY": "Logguer TOUTES les queries dans le fichier {file}",
        "setting.formField.GLOBAL__MAINTENANCE_MODE": "Activer le mode \"Maintenance\" pour ce client",
        "setting.formField.GLOBAL__PRICE_DISPLAY": "Affichage des prix",
        "setting.formField.GLOBAL__QUERY_MONTHS_DEFAULT_LIMIT": "Limite d'affichage des données par défaut",
        "setting.formField.MAIL__ALERT_DELAY": "Délais d'alerte par email",
        "setting.formField.PDF__FONT_FAMILY": "Police des PDF",
        "setting.formField.PDF__MAIN_COLOR": "Couleur principale des PDF",
        "setting.formField.PURCHASE_ORDER__USE_DEPARTMENT_PAYMENT_INFOS": "{1}Utilisation des informations financières du {model}|{2}Utilisation des informations financières de la {model}",
        "setting.formField.SUPPLIER__AFTER_VALIDATION_EMAILS": "Emails pour l'envoi du mail \"{mail_title}\" ",
        "setting.formField.SUPPLIER__APPROVAL_DEFAULT_DURATION": "Durée d'activation par défaut d'une sous-famille sans agrément",
        "setting.formField.SUPPLIER__CAN_ACCESS_TO_APP": "Donner l'accès aux fournisseurs sur cette instance",
        "setting.formField.SUPPLIER__ENABLE_CLIENT_NUMBER": "Activer le \"Numéro client\" ",
        "setting.formField.SUPPLIER__ENABLE_FORCED_STATUS": "Activer le Statut forcé",
        "setting.formField.SUPPLIER__ENABLE_PO_INFORMATIONS": "Activer les \"Infos Bon de Commande\" ",
        "setting.formField.SUPPLIER__ENABLE_TAB_CHARTS": "Activer l'onglet \"{tab}\" ",
        "setting.formField.SUPPLIER__REQUIRED_FILE_TYPES": "Types de fichiers obligatoires (indiquer les IDs)",
        "setting.formField.TENANT__LOGO": "Logo du client (apparaît dans le menu principal)",
        "setting.formField.TENANT__MAIN_COLOR": "Couleur principale du client",
        "setting.formField.TRACE__DELETING_DELAY": "Délais avant de supprimer les Traces",
        "setting.form_field.BUDGET_ANALYSIS__DEFAULT_FILTER_KEYS_NUMBER": "Nombre de Composantes de la clé affichées dans le filtre par défaut",
        "setting.form_field.BUDGET__KEY_MILESTONE": "Jalon de la clé budgétaire",
        "setting.form_field.CATALOG__CART_MODE": "Utilisation du panier dans le catalogue d'articles",
        "setting.info.other": "Autres paramètres",
        "setting.info.simplification": "Simplification",
        "setting.maintenance.debug": "Debug",
        "setting.maintenance.mailtrap.title": "Mailtrap",
        "setting.maintenance.other_actions.title": "Autres actions",
        "setting.maintenance.send_test_log": "Envoyer un log de test",
        "setting.maintenance.send_test_mail": "Envoyer un mail de test",
        "setting.model": "Configuration",
        "setting.showEnv": "Voir les variables d'environnement",
        "setting.validation_process.amounts.title": "Utilisation des montants",
        "setting.validation_process.criteria_order.title": "Ordre des critères pour la génération des circuits",
        "setting.validation_process.steps_by_criterion.title": "Nombre de rangs par critère à conserver en fin de circuit",
        "setting.validation_process.steps_global.title": "Nombre de rangs global à conserver en fin de circuit",
        "siret.novalidate": "Le numéro Siret n'est pas valide",
        "statistic.model": "Statistique",
        "statistic.model.plural": "Statistiques",
        "stock.action.readjust_from_computed": "Réajuster depuis le Calculé ({value})",
        "stock.actual.abbreviation": "Qté. réelle",
        "stock.adjustment": "Ajustement de stock",
        "stock.adjustment_from_import": "Ajustement depuis l'import",
        "stock.available": "Stock disponible",
        "stock.error.article_required": "L'article est obligatoire",
        "stock.error.exceeded": "Stock dépassé",
        "stock.error.only_internal_article_allowed": "Seuls les articles internes sont autorisés lors d'une sortie de stock.",
        "stock.export.filename": "stock-{code}",
        "stock.info.in_warehouse_but_disabled": "{1}{model} déjà présent dans l'Entrepôt mais il est actuellement désactivé.|{2}{model} déjà présente dans l'Entrepôt mais elle est actuellement désactivée.",
        "stock.min_quantity_alert.field": "Uniquement les {model} sous le seuil min.",
        "stock.min_quantity_alert.short_label": "Seuil min.",
        "stock.missing_quantity": "Quantité manquante",
        "stock.model": "{1}{model} stocké|{2}{model} stockée",
        "stock.model.plural": "{1}{model} stockés|{2}{model} stockées",
        "stock.movements": "Mouvements de stock",
        "stock.type.in": "Entrée de stock",
        "stock.type.in.abbreviation": "Entrée",
        "stock.type.movement": "Mouvement inter stock",
        "stock.type.movement.abbreviation": "Mvmnt",
        "stock.type.no": "Achat hors stock",
        "stock.type.out": "Sortie de stock",
        "stock.type.out.abbreviation": "Sortie",
        "supplier.action.reset_approval_file_types": "Ajuster les fichiers requis",
        "supplier.action.unlock": "Déverrouiller le fournisseur",
        "supplier.banner_action.missing_files": "Il y a un fichier manquant ou à valider afin de pouvoir valider le Fournisseur|Il y a {nb} fichiers manquants ou à valider afin de pouvoir valider le Fournisseur",
        "supplier.banner_action.missing_geographic_sector": "Il faut au moins un Secteur géographique pour valider ce Fournisseur.",
        "supplier.banner_action.missing_product_family": "Il faut au moins une Famille pour valider ce Fournisseur.",
        "supplier.banner_action.product_family.awaiting_validation": "{product_family} - en attente de la validation de :<ul>{validators}</ul>",
        "supplier.banner_action.product_family.disabled_approval": "L'Agrément {approval} n'est pas actif, impossible d'activer la famille {product_family}",
        "supplier.banner_action.product_family.missing_files": "Il y a un fichier manquant ou à valider afin d'activer la famille {product_family}|Il y a {nb} fichiers manquants ou à valider afin d'activer la famille {product_family}",
        "supplier.banner_action.product_family.missing_validators": "Il y a un valideur manquant afin d'activer la famille {product_family}|Il y a {nb} valideurs manquants afin d'activer la famille {product_family}",
        "supplier.banner_action.product_family.start_validation": "La validation de la famille {product_family} peut être lancée",
        "supplier.banner_action.start_validation": "La validation du Fournisseur peut être lancée.",
        "supplier.banner_action.validate": "Le Fournisseur peut être validé.",
        "supplier.chart.12_rolling_months": "Sur 12 mois glissants",
        "supplier.chart.accounting_entries_details": "Détail par Sous-Famille sur 12 mois glissants",
        "supplier.chart.additional_mark": "Notation complémentaire",
        "supplier.chart.additional_rating.title": "Notation complémentaire",
        "supplier.chart.annual_rating.title": "Notation annuelle",
        "supplier.chart.average_basket": "Panier moyen",
        "supplier.chart.certification.title": "Certifications",
        "supplier.chart.completed_document": "Document renseigné",
        "supplier.chart.current_year": "Année en cours (N)",
        "supplier.chart.deliveries_amount": "Montant des réceptions",
        "supplier.chart.dependency_rate": "Taux de dépendance",
        "supplier.chart.distributionPurchases": "Répartition des achats sur 12 mois",
        "supplier.chart.expiration_date": "Date de fin de validité",
        "supplier.chart.frequency_rate1": "Taux de fréquence 1",
        "supplier.chart.frequency_rate1.help": "Taux de Fréquence 1 (TF1) = (nombre d'accidents avec arrêt / nombre d'heures travaillées) x 1 000 000",
        "supplier.chart.frequency_rate1.short": "TF1",
        "supplier.chart.frequency_rate2": "Taux de fréquence 2",
        "supplier.chart.frequency_rate2.help": "Taux de Fréquence 2 (TF2) = (nombre d'accidents avec et sans arrêt / nombre d'heures travaillées) x 1 000 000",
        "supplier.chart.frequency_rate2.short": "TF2",
        "supplier.chart.global_mark": "Note globale",
        "supplier.chart.hse": "Hygiène, Sécurité, Environnement",
        "supplier.chart.hse.short": "HSE",
        "supplier.chart.objective_current_year": "Objectif N",
        "supplier.chart.objective_previous_year": "Objectif N-1",
        "supplier.chart.oqd": "On Quality Delivery",
        "supplier.chart.oqd.short": "OQD",
        "supplier.chart.orders_amount": "Montant des commandes",
        "supplier.chart.orders_count": "Nombre de commandes",
        "supplier.chart.otd": "On Time Delivery",
        "supplier.chart.otd.short": "OTD",
        "supplier.chart.performance": "Indicateurs de performance",
        "supplier.chart.previous_year": "Année précédente (N-1)",
        "supplier.chart.result_current_year": "Résultat N",
        "supplier.chart.result_previous_year": "Résultat N-1",
        "supplier.chart.security.title": "Sécurité",
        "supplier.chart.severity_rate": "Taux de Gravité",
        "supplier.chart.severity_rate.help": "Taux de Gravité (TG) = (nombre de journées perdues / nombre d'heures travaillées) x 1 000",
        "supplier.chart.severity_rate.short": "TG",
        "supplier.chart.totalOrdersAmounts": "Total des commandes en euros sur 24 mois",
        "supplier.chart.turnover": "Chiffre d'affaires",
        "supplier.chart.turnover.title": "Chiffre d'affaires Achats",
        "supplier.chart.turnover_and_dependency": "Chiffre d'Affaires et Taux de dépendance",
        "supplier.chart.variation": "Var N vs N-1",
        "supplier.evaluation.invitation.intro": "Vous avez été invité à remplir une évaluation pour le Fournisseur {supplier}.",
        "supplier.evaluation.invitation.subject": "Evaluation d'un Fournisseur",
        "supplier.field.accounting_code": "Code comptable",
        "supplier.field.account_type": "Type de compte",
        "supplier.field.account_type.model": "un type de compte",
        "supplier.field.activity_details": "Détails de l'activité",
        "supplier.field.client_number": "Numéro client",
        "supplier.field.code": "Code fournisseur",
        "supplier.field.code.model": "un code fournisseur",
        "supplier.field.company_name": "Raison sociale",
        "supplier.field.contractual_status": "Statut contractuel",
        "supplier.field.contractual_status.model": "un statut contractuel",
        "supplier.field.country.model": "un code pays",
        "supplier.field.duns": "DUNS / ID Code",
        "supplier.field.evaluation_mark": "Note d'évaluation",
        "supplier.field.geographicSectors.help": "Le choix du secteur peut conditionner l'obligation de saisir les champs suivants",
        "supplier.field.geographicSectors.model": "des codes de secteurs géographiques",
        "supplier.field.geographicSectors.otherSectors": "et {number} autre(s) secteur(s)",
        "supplier.field.give_access": "Donner l'accès",
        "supplier.field.group": "Groupe",
        "supplier.field.group.model": "un code de groupes",
        "supplier.field.intra_community_vat": "TVA-intra.",
        "supplier.field.intra_community_vat.error.regex": "Le numéro TVA intracommunautaire n'est pas valide",
        "supplier.field.label.error.required_if": "Le libellé est obligatoire s'il n'y a pas de type de document",
        "supplier.field.main_product_family": "Famille d'achat principale",
        "supplier.field.manager": "Responsable du compte",
        "supplier.field.manager.model": "un login d'un utilisateur",
        "supplier.field.manage_shipping_date": "{1}Gérer la date d'expédition sur le {model}|{2}Gérer la date d'expédition sur la {model}",
        "supplier.field.manage_stock": "Gestionnaire de stock",
        "supplier.field.paymentTerm.model": "un code d'une condition de règlement",
        "supplier.field.paymentType.model": "un code d'un type de règlement",
        "supplier.field.po_informations": "Infos Bon de Commande",
        "supplier.field.productFamilies": "Familles d'articles",
        "supplier.field.productFamilies.model": "des codes familles",
        "supplier.field.punchout_type": "Type de PunchOut",
        "supplier.field.punchout_url": "URL de PunchOut",
        "supplier.field.qualification": "Qualification",
        "supplier.field.qualification.model": "un nom d'une qualification",
        "supplier.field.remove_access": "Retirer l'accès",
        "supplier.field.surveillanceMinAmount": "Montant minimal",
        "supplier.field.tags.model": "des tags",
        "supplier.field.underSurveillance": "Sous surveillance",
        "supplier.field.website": "Site internet",
        "supplier.headInfo.activeUntil": "active jusqu'au",
        "supplier.import": "Import des fournisseurs :",
        "supplier.import.action.downloadBankAccountHeaders": "Télécharger les en-têtes des RIB",
        "supplier.import.action.downloadContactsHeaders": "Télécharger les en-têtes des contacts",
        "supplier.import.action.downloadSupplierHeaders": "Télécharger les en-têtes des fournisseurs",
        "supplier.import.bankAccount": "Import des RIB des fournisseurs :",
        "supplier.import.contacts": "Import des contacts des fournisseurs :",
        "supplier.import.field.bank_account_file": "Fichier d'import des RIB des fournisseurs",
        "supplier.import.field.contact_file": "Fichier d'import des contacts des fournisseurs",
        "supplier.import.field.file.help": "Le nom de chaque colonne doit correspondre au caractère près au nom d'une colonne de la table sélectionnée.<br>Toutes les colonnes ne sont pas obligatoires.",
        "supplier.import.field.group": "Code de groupe",
        "supplier.import.field.supplier_file": "Fichier d'import des fournisseurs",
        "supplier.import.field.update": "Mettre à jour les fournisseurs dont les codes sont déjà existants",
        "supplier.info.difference_between_approval_file_types": "Il existe une différence entre les fichiers demandés et les fichiers des Agréments. Les ajuster peut désactiver les familles pour lesquelles il manquera un fichier, et peut par conséquent désactiver le Fournisseur lui-même.",
        "supplier.info.has_articles_to_disable": "Le fournisseur possède des articles, ceux-ci seront désactivés.",
        "supplier.model": "Fournisseur",
        "supplier.model.plural": "Fournisseurs",
        "supplier.objectives.title": "Objectifs des Fournisseurs",
        "supplier.title.contactInformations": "Coordonnées",
        "supplier.title.dashboard": "Suivi et Surveillance",
        "supplier.title.files": "Documents",
        "supplier.title.finance": "Informations Finances",
        "supplier.title.forced_status": "Forcer le statut",
        "supplier.title.identity": "Identité",
        "supplier.title.purchaseInformations": "Informations Achats",
        "supplier.title.surveillance": "Surveillance",
        "supplier.title.validators": "Valideurs",
        "supplier.use_punchout": "Utilise un catalogue PunchOut",
        "suppliercontacts.import.requiredOne": "Au moins l'un des champs {field_email}, {field_phone} ou {field_mobile} est requis.",
        "suppliercontacts.import.requiredOne.error": "Ligne {line} : Au moins l'un des champs {field_email}, {field_phone} ou {field_mobile} est requis, les données n'ont pas pu être ajoutées.",
        "supplierFile.field.category": "Catégorie de document",
        "supplierFile.field.documentDate": "Date du document",
        "supplierFile.field.file": "Document",
        "supplierFile.field.label.help": "Le label se remplit automatiquement à partir du type de document",
        "supplierFile.field.sentBy": "Envoyé par",
        "supplierFile.field.type": "Type de document",
        "supplierFile.field.type.value.none": "Aucun type",
        "supplierFile.field.validityDate": "Date de validité",
        "supplierGroup.field.label": "Nom du groupe",
        "supplierInvoice.accountingVatEntryType.debit_collection_by_month": "Par mois : Débit => selon date de facture / Encaissement => selon date d'échéance (ex: CEB)",
        "supplierInvoice.paymentstatus.info.forced_paid": "Statut forcé à Payé",
        "supplierInvoice.paymentstatus.paid": "Payée",
        "supplier_account_type.model": "Type de compte de Fournisseur",
        "supplier_account_type.model.plural": "Types de compte de Fournisseur",
        "supplier_approval.field.roles": "Rôles qui doivent valider",
        "supplier_approval.model": "Agrément de Fournisseur",
        "supplier_approval.model.plural": "Agréments de Fournisseur",
        "supplier_chart_accounting_entry_turnover.export.filename": "chiffre_d_affaires_imputation",
        "supplier_chart_annual_rating.export.filename": "notation_annuelle",
        "supplier_chart_certifications.export.filename": "certifications",
        "supplier_chart_performance.export.filename": "performance",
        "supplier_chart_security.export.filename": "securite",
        "supplier_chart_turnover.export.filename": "chiffre_d_affaires",
        "supplier_contact.model": "Contact du Fournisseur",
        "supplier_contact.model.plural": "Contacts du Fournisseur",
        "supplier_contacts.export.filename": "contacts",
        "supplier_contact_type.model": "Type de contact de Fournisseur",
        "supplier_contact_type.model.plural": "Types de contact de Fournisseur",
        "supplier_contract.field.code": "N° de {model}",
        "supplier_contract.field.label": "{1}Objet du {model}|{2}Objet de la {model}",
        "supplier_contract.field.process_code": "N° de procédure",
        "supplier_contract.model": "Contrat Fournisseur",
        "supplier_contract.model.plural": "Contrats Fournisseur",
        "supplier_contractual_status.model": "Statut contractuel",
        "supplier_contractual_status.model.plural": "Statuts contractuels",
        "supplier_evaluation.model": "Évaluation",
        "supplier_evaluation.model.plural": "Évaluations",
        "supplier_evaluation_criterion.model": "Critère d'évaluation",
        "supplier_evaluation_criterion.model.plural": "Critères d'évaluation",
        "supplier_file.model": "Document de Fournisseur",
        "supplier_file.model.plural": "Documents de Fournisseur",
        "supplier_file_": "-",
        "supplier_file_approval.model": "Document d'agrément",
        "supplier_file_approval.model.plural": "Documents d'agrément",
        "supplier_file_category.field.validator_role": "Rôle du valideur",
        "supplier_file_category.model": "Catégorie de documents de Fournisseur",
        "supplier_file_category.model.plural": "Catégories de documents de Fournisseur",
        "supplier_file_regular.model": "Document de collaboration",
        "supplier_file_regular.model.plural": "Documents de collaboration",
        "supplier_file_type.field.file_category": "Catégorie de document",
        "supplier_file_type.model": "Type de documents de Fournisseur",
        "supplier_file_type.model.plural": "Types de documents de Fournisseur",
        "supplier_group.model": "Groupe de Fournisseurs",
        "supplier_group.model.plural": "Groupes de Fournisseurs",
        "supplier_invoice.action.notify_when_no_delivery": "Demander une réception",
        "supplier_invoice.action.show_file": "Voir la Facture",
        "supplier_invoice.action.submit_invoice": "Déposer une Facture",
        "supplier_invoice.action.submit_your_invoice": "Déposez votre Facture",
        "supplier_invoice.back_to_draft.existing_accounting_exports": "{1}De plus, le {model_invoice} a déjà été exporté en compta, l'export ne sera pas modifié.|{2}De plus, la {model_invoice} a déjà été exportée en compta, l'export ne sera pas modifié.",
        "supplier_invoice.back_to_draft.existing_closed_purchase_orders": "{1}De plus, les {model_po} ont été clôturés. Merci de cocher la case suivante afin de confirmer l'annulation de la clôture :|{2}De plus, les {model_po} ont été clôturées. Merci de cocher la case suivante afin de confirmer l'annulation de la clôture :",
        "supplier_invoice.close_purchase_orders": "Certains éléments peuvent être clôturés ?",
        "supplier_invoice.close_purchase_orders.info1": "{1}Si vous n'attendez pas d'autres {model_invoice}, vous pouvez clôturer le {model_po}.|{2}Si vous n'attendez pas d'autres {model_invoice}, vous pouvez clôturer la {model_po}.",
        "supplier_invoice.close_purchase_orders.info2": "{1}Une fois le {model_po} clôturé, seul le montant facturé est pris en compte dans l'analyse budgétaire.|{2} Une fois la {model_po} clôturée, seul le montant facturé est pris en compte dans l'analyse budgétaire.",
        "supplier_invoice.error.amount_constrained_to_delivery": "Le reste à facturer sur cette ligne de BC doit être inférieur ou égal au montant reçu non facturé",
        "supplier_invoice.error.amount_constrained_to_purchase_order": "Le reste à facturer sur cette ligne de BC doit être inférieur ou égal au montant non facturé",
        "supplier_invoice.error.delivered_amount_zero_line": "Vous devez d'abord effectuer une réception avant de pouvoir facturer cette ligne",
        "supplier_invoice.error.delivered_amount_zero_total": "{1}Vous devez d'abord effectuer une réception avant de pouvoir facturer ce {model}|{2}Vous devez d'abord effectuer une réception avant de pouvoir facturer cette {model}",
        "supplier_invoice.error.different_currency": "Les devises des différentes factures ne sont pas les mêmes",
        "supplier_invoice.error.different_from_invoice": "{attribute} doit être égal à {attribute} de la facture ({amount})",
        "supplier_invoice.error.total_amount_constrained_to_delivery": "Le sous-total HT doit être inférieur ou égal au montant reçu non facturé",
        "supplier_invoice.error.total_amount_constrained_to_purchase_order": "Le sous-total HT doit être inférieur ou égal au montant non facturé",
        "supplier_invoice.export.filename": "factures",
        "supplier_invoice.field.label": "Numéro de facture",
        "supplier_invoice.field.outside_EU": "Hors UE",
        "supplier_invoice.info.no_purchase_order_to_notify_when_no_delivery": "Aucun utilisateur n'est à notifier.",
        "supplier_invoice.info.users_to_notify": "Les utilisateurs suivants seront notifiés :",
        "supplier_invoice.limit.delivered": "Montant réceptionné",
        "supplier_invoice.limit.ordered": "Montant commandé",
        "supplier_invoice.modal.multiple_currency.body": "{1}Sur un même {invoice_model}, vous ne pouvez pas sélectionner des {po_model} avec des devises différentes|{2}Sur une même {invoice_model}, vous ne pouvez pas sélectionner des {po_model} avec des devises différentes",
        "supplier_invoice.modal.multiple_currency.title": "Attention aux devises multiples",
        "supplier_invoice.model": "Facture de Fournisseur",
        "supplier_invoice.model.plural": "Factures de Fournisseur",
        "supplier_invoice.ocr_confidence.calculated": "Calculé",
        "supplier_invoice.ocr_confidence.manuel_input": "Saisi manuellement",
        "supplier_invoice.pdf.title": "Bon de facturation",
        "supplier_observation.model": "Observation de Fournisseur",
        "supplier_observation.model.plural": "Observations de Fournisseur",
        "supplier_qualification.model": "Qualification de Fournisseur",
        "supplier_qualification.model.plural": "Qualifications de Fournisseur",
        "supplier_statistic.model": "Statistique de Fournisseur",
        "supplier_statistic.model.plural": "Statistiques de Fournisseur",
        "table.field.column": "Colonne",
        "table.field.columns": "Colonnes",
        "table.field.line": "Ligne",
        "tag.error.invalid": "{1}Au moins un {model} est invalide|{2}Au moins une {model} est invalide",
        "tag.level.disabled": "Désactivé",
        "tag.level.highlighted": "Mise en avant",
        "tag.level.standard": "Standard",
        "tag.level_for_model": "Niveau pour \"{model}\" ",
        "tag.model": "Tag",
        "tag.model.plural": "Tags",
        "tenant.model": "Tenant",
        "tenant.model.plural": "Tenants",
        "tenant.new.mail.error.subject": "Nouvelle instance : erreur",
        "trace.action.acknowledged_receipt": "Accusé de réception",
        "trace.action.activated": "Activation",
        "trace.action.added": "Ajout",
        "trace.action.attachment_added": "Pièce jointe ajoutée",
        "trace.action.attachment_deleted": "Pièce jointe supprimée",
        "trace.action.autogenerated": "Génération automatique",
        "trace.action.awaiting": "Changement du statut de traitement en \"En attente\" ",
        "trace.action.backup_done": "Backup effectué",
        "trace.action.backup_failed": "Backup échoué",
        "trace.action.back_to_awaiting": "Remise en statut de traitement \"En attente\" ",
        "trace.action.back_to_draft": "Remise en statut \"Brouillon\" ",
        "trace.action.back_to_none": "Suppression du statut de traitement",
        "trace.action.back_to_processing": "Remise en statut de traitement \"En cours\" ",
        "trace.action.canceled": "Changement du statut en \"Annulé\" ",
        "trace.action.cancel_and_replace": "Annule et remplace",
        "trace.action.closed": "Changement du statut en \"Clôturé\" ",
        "trace.action.considered_sent": "Changement du statut en \"Envoyé\" ",
        "trace.action.created": "Création",
        "trace.action.deleted": "Suppression",
        "trace.action.delivery_forced_complete": "Réception forcée",
        "trace.action.desactivated": "Désactivation",
        "trace.action.disabled": "Désactivation",
        "trace.action.duplicated_from": "Dupliqué depuis",
        "trace.action.enabled": "Activation",
        "trace.action.execute_sql_query": "Exécute requête SQL",
        "trace.action.export": "Export des données",
        "trace.action.export_downloaded": "Export téléchargé",
        "trace.action.export_generated": "Export généré",
        "trace.action.export_sepa": "Export SEPA",
        "trace.action.export_sepa_downloaded": "Export SEPA téléchargé",
        "trace.action.export_sepa_generated": "Export SEPA généré",
        "trace.action.file_created": "Fichier créé",
        "trace.action.file_deleted": "Fichier supprimé",
        "trace.action.file_updated": "Fichier modifié",
        "trace.action.forced_status_removed": "Status forcé retiré",
        "trace.action.imported": "Import de données",
        "trace.action.manually_changed_to_validated_PO": "Changement manuel du statut de traitement en \"BC validé\" ",
        "trace.action.merged": "Fusion",
        "trace.action.new_comment": "Nouveau commentaire",
        "trace.action.new_version": "Nouvelle version créée",
        "trace.action.overriden": "Surcharge",
        "trace.action.paid": "Changement du statut en \"Payé\" ",
        "trace.action.password_changed": "Mot de passe changé",
        "trace.action.password_generated": "Mot de passe généré",
        "trace.action.password_reset": "Mot de passe réinitialisé",
        "trace.action.permissions_updated": "Permissions mises à jour",
        "trace.action.processing": "Changement du statut de traitement en \"En cours\" ",
        "trace.action.refused": "Validation refusée",
        "trace.action.removed": "Suppression",
        "trace.action.replaced": "Remplacement",
        "trace.action.re_enabled": "Réactivation",
        "trace.action.sent": "Envoi par email",
        "trace.action.sent_auto": "Envoi automatique par email",
        "trace.action.skipped": "Validation contournée",
        "trace.action.sorted": "Modification de l'ordre",
        "trace.action.start_reconcile": "Rapprochement",
        "trace.action.submitted_po": "Changement du statut de traitement en \"BC soumis\" ",
        "trace.action.suspended": "Suspension",
        "trace.action.taken_charge": "Prise en charge",
        "trace.action.transferred": "Transféré",
        "trace.action.transformed_into_PO": "Transformation en BC",
        "trace.action.transformed_into_purchase_order": "Transformation en BC",
        "trace.action.unclosed": "Annulation de la clôture",
        "trace.action.unsuspended": "Annulation de la suspension",
        "trace.action.unvalidated": "Annulation de la validation",
        "trace.action.updated": "Modification",
        "trace.action.updated_BankDetails": "Modification des informations bancaires",
        "trace.action.updated_buyers": "Modification des Acheteurs",
        "trace.action.updated_ContactInformations": "Modification des coordonnées",
        "trace.action.updated_Families": "Modification des familles d'achat",
        "trace.action.updated_Finance": "Modification des informations finances",
        "trace.action.updated_ForcedStatus": "Modification du statut forcé",
        "trace.action.updated_from_central": "Modification depuis le Central",
        "trace.action.updated_GeographicSectors": "Modification des secteurs géographiques",
        "trace.action.updated_Identity": "Modification de l'identité",
        "trace.action.updated_PurchaseInformations": "Modification des informations achats",
        "trace.action.updated_Surveillance": "Modification de la surveillance",
        "trace.action.updated_Tags": "Modification des tags",
        "trace.action.updated_Validators": "Modification du responsable du compte",
        "trace.action.update_after_validation": "Modification après validation",
        "trace.action.validated": "Validation acceptée",
        "trace.action.validated_po": "Changement du statut de traitement en \"BC validé\" ",
        "trace.action.validation_launched": "Lancement du circuit de validation",
        "trace.error.not_sent": "E-mail non envoyé",
        "trace.field.action": "Action",
        "trace.field.message": "Message",
        "trace.field.model": "Entité",
        "trace.field.model_id": "ID de l'entité",
        "trace.field.severity": "Criticité",
        "trace.model": "Trace",
        "trace.model.gender": "2",
        "trace.model.plural": "Traces",
        "trace.severity.danger": "Danger",
        "trace.severity.debug": "Debug",
        "trace.severity.error": "Erreur",
        "trace.severity.info": "Info",
        "trace.severity.warning": "Avertissement",
        "trace.show": "Détails d'une trace",
        "transportMean.bike": "Deux roues <50 cm3",
        "transportMean.car": "Voiture",
        "transportMean.motorbike": "Deux roues >50 cm3",
        "unit.model": "Unité",
        "unit.model.plural": "Unités",
        "unit.must_correspond": "L'Unité doit correspondre à un choix existant",
        "user.account_disabled": "Compte utilisateur non actif",
        "user.actions_to_do": "Actions à effectuer",
        "user.error.must_contain": "Le champ {attribute} doit contenir au moins un des caractères suivants : {requirements}",
        "user.error.new_password_must_be_different": "Le champ {attribute} ne peut pas être identique à votre mot de passe actuel",
        "user.error.wrong_current_password": "Le champ {attribute} ne correspond pas à votre mot de passe actuel",
        "user.field.activated": "Utilisateur actif",
        "user.field.can_stock_in": "Peut réapprovisionner les Entrepôts",
        "user.field.can_stock_out": "Peut commander dans les Entrepôts",
        "user.field.code": "Matricule",
        "user.field.confirm_password": "Confirmation du mot de passe",
        "user.field.current_password": "Mot de passe actuel",
        "user.field.model.read": "{1}{model} autorisés en consultation|{2}{model} autorisées en consultation",
        "user.field.model.write": "{1}{model} autorisés en création|{2}{model} autorisées en création",
        "user.field.new_password": "Nouveau mot de passe",
        "user.field.password_confirmation": "Confirmez le mot de passe",
        "user.field.recipient": "Fait partie de la liste des {model}",
        "user.field.remember_me": "Se souvenir de moi",
        "user.import.job.example": "Comptable",
        "user.info.cantBeDeleted": "{user} ne peut pas être supprimé(e) mais peut être désactivé(e).",
        "user.info.new_password_end_date": "Votre nouveau mot de passe sera utilisable jusqu'au {date}",
        "user.info.password_end_date": "Votre mot de passe actuel est utilisable jusqu'au {date}",
        "user.info.password_end_date_expired": "Votre mot de passe actuel a expiré le {date}",
        "user.model": "Utilisateur",
        "user.model.plural": "Utilisateurs",
        "user.preferences": "Préférences",
        "user.profile": "Profil",
        "user.sso.module": "SSO (Authentification unique externe)",
        "userVehicle.model": "Véhicule de l'utilisateur",
        "user_assignment.model": "Fonction d'utilisateur",
        "user_assignment.model.plural": "Fonctions d'utilisateur",
        "user_group.model": "Groupe d'utilisateurs",
        "user_group.model.plural": "Groupes d'utilisateurs",
        "validation.action.add_manually": "Ajouter un valideur",
        "validation.action.skip": "Contourner le valideur",
        "validation.after_or_equal": "Le champ {attribute} doit être postérieur ou égal à {date}.",
        "validation.all": "Toutes les validations",
        "validation.before_or_equal": "Le champ {attribute} doit être antérieur ou égal à {date}.",
        "validation.between.numeric.short": "La valeur doit être comprise entre {min} et {max}.",
        "validation.budget_key_not_valid": "Clé de budget non valide",
        "validation.confirm.added": "Validation ajoutée",
        "validation.confirm.bulk_validated": "Éléments validés en masse : {items}",
        "validation.confirm.no_bulk_validated": "Aucun élément n'a été validé en masse",
        "validation.confirm.refused": "Le refus a bien été effectué.",
        "validation.confirm.validated": "La validation a bien été effectuée.",
        "validation.date": "Le champ {attribute} n'est pas une date valide.",
        "validation.date_format": "Le champ {attribute} ne correspond pas au format {format}.",
        "validation.different_from": "La valeur de {attribute} doit être différente de {value}.",
        "validation.dimensions.short": "La taille de l'image n'est pas conforme.",
        "validation.emails": "Vérifiez le format d'adresses email de chaque ligne",
        "validation.error.bulk_validated": "Éléments impossible à valider en masse : {items}",
        "validation.error.cant_validate": "Impossible de valider",
        "validation.error.please_validate_manually": "Veuillez valider manuellement",
        "validation.field.must_be": "Le champ {attribute} doit être \"{value}\" ",
        "validation.field.unauthorized_characters": "Le champ {attribute} contient des caractères non autorisés",
        "validation.gt.numeric": "La valeur de {attribute} doit être supérieure à {value}.",
        "validation.integer": "Le champ {attribute} doit être un entier.",
        "validation.lt.numeric": "La valeur de {attribute} doit être inférieure à {value}.",
        "validation.max.string": "Le texte de {attribute} ne peut contenir plus de {max} caractères.",
        "validation.model": "Validation",
        "validation.model.gender": "2",
        "validation.model.plural": "Validations",
        "validation.must_be_multiple": "La quantité doit être un multiple de {step}",
        "validation.not_link_to": "{item1} n'est pas lié(e) à {item2}",
        "validation.not_regex": "Le format du champ {attribute} n'est pas valide.",
        "validation.not_specified": "{1}{attribute} non renseigné|{2}{attribute} non renseignée",
        "validation.not_valid": "non valide",
        "validation.orphan": "Incohérence entre {child} et {parent}",
        "validation.phone": "Le champ {attribute} doit être un numéro valide.",
        "validation.prohibited_unless.other_is_null": "Le champ {attribute} ne doit pas être rempli si {other} est rempli.",
        "validation.refuse": "Refus de validation",
        "validation.refuse.taken": "Refus de validation pris en compte",
        "validation.refuse.takenThank": "Votre refus a bien été pris en compte.",
        "validation.required": "Le champ {attribute} est obligatoire.",
        "validation.required.short": "Champ obligatoire",
        "validation.required.valid_iban": "Un IBAN valide est obligatoire",
        "validation.required_one": "Au moins l'un de ces champs est requis : {attributes}",
        "validation.required_without": "Le champ {attribute} est obligatoire quand {values} n'est pas rempli.",
        "validation.rights": "Vous n'avez pas les droits suffisants pour selectionner cet élément",
        "validation.unique": "La valeur du champ {attribute} est déjà utilisée.",
        "validation.validate.taken": "Validation prise en compte",
        "validation.validate.takenThank": "Votre validation a bien été prise en compte. Merci",
        "validation.without_spaces": "Ce champ ne doit pas contenir d'espaces",
        "validation.wrong": "Le champ {attribute} n'est pas correct",
        "validationCriterion.field.model": "Modèle lié",
        "validationProcess.infos.supplier": "Uniquement si le niveau de complexité de l'activation des fournisseurs est \"simple\" ",
        "validationProcess.testIndex": "Test du circuit de validation",
        "validation_default_chart.copy": "Copier les valideurs",
        "validation_default_chart.create": "Ajouter un valideur",
        "validation_default_chart.error.criteria_not_exists": "Ce critère n'est pas disponible",
        "validation_default_chart.field.criterion.error.not_available": "{1}Ce {attribute} n'est pas/plus disponible|{2}Cette {attribute} n'est pas/plus disponible",
        "validation_default_chart.field.standard": "Circuit standard",
        "validation_default_chart.field.weight": "Rang",
        "validation_default_chart.info.simplified": "Simplifié (n'apparaîtra pas dans le circuit final)",
        "validation_default_chart.info.weight": "Position par rapport aux autres valideurs du même critère",
        "validation_default_chart.model": "Valideur",
        "validation_default_chart.model.plural": "Valideurs",
        "validation_default_chart.remove": "Retirer le valideur",
        "validation_default_chart.replace": "Remplacer un valideur",
        "validation_default_chart.replace.info": "Remplacer un valideur dans tout le circuit de validation \"{model}\" ",
        "validation_default_chart.update": "Modification du valideur",
        "validation_process.add_overrun_model_process": "En cas de dépassement de {model} : ajouter le circuit de validation des {models}",
        "validation_process.auto_add": "Ajout automatique au début du circuit : {value}",
        "validation_process.field.amount_overrun": "{1}En cas de dépassement du {model}|{2}En cas de dépassement de la {model}",
        "validation_process.field.budget_overrun": "En cas de dépassement budgétaire",
        "validation_process.info.not_standard": "Non présent dans le Circuit standard",
        "validation_process.model": "Circuit de validation",
        "validation_process.model.AUTO_ADD_RECIPIENT": "{1}{recipient} du {model}|{2}{recipient} de la {model}",
        "validation_process.model.plural": "Circuits de validation",
        "validation_process.purchase_order.AUTO_ADD_NEEDS_RECIPIENTS": "{recipients} des {need_model}",
        "validation_process.supplier_invoice.AUTO_ADD_PO_RECIPIENTS": "{1}{recipients} des {po_models} du {invoice_model}|{2}{recipients} des {po_models} de la {invoice_model}",
        "validation_process.supplier_invoice.REMOVE_WHEN_SAME_AMOUNT_AS_PO": "Pas de circuit si le montant = montant des {po_models}",
        "variant.label.' + option.label + ": "-",
        "variant.label.danger": "Danger",
        "variant.label.dark": "Sombre",
        "variant.label.info": "Info",
        "variant.label.light": "Clair",
        "variant.label.primary": "Primaire",
        "variant.label.secondary": "Secondaire",
        "variant.label.success": "Succès",
        "variant.label.warning": "Avertissement",
        "vat_type.model": "Type de TVA",
        "vat_type.model.gender": "1",
        "vat_type.model.plural": "Types de TVA",
        "verify.email.checkLink": "Before proceeding, please check your email for a verification link.",
        "verify.email.confirmation": "A fresh verification link has been sent to your email address.",
        "verify.email.notReceived": "If you did not receive the email",
        "verify.email.requestAnother": "click here to request another",
        "verify.index": "Vérifiez votre adresse e-mail",
        "warehouse.action.add_to": "Ajouter à l'Entrepôt",
        "warehouse.confirmation_request.update_allocations": "{1}Modifier les imputations créera un {model} pour rééquilibrer les analyses budgétaires.|{2}Modifier les imputations créera une {model} pour rééquilibrer les analyses budgétaires.",
        "warehouse.field.storekeeper": "Magasinier",
        "warehouse.field.storekeeper.plural": "Magasiniers",
        "warehouse.model": "Entrepôt de stock",
        "warehouse.model.plural": "Entrepôts de stock",
        "warehouse.other": "Autres entrepôts",
        "wysiwyg.info.insert_text_here": "Insérez du texte ici...",
        "auth": {
            "failed": "Identifiant et/ou mot de passe invalide(s)",
            "throttle": "Tentatives de connexion trop nombreuses. Veuillez essayer de nouveau dans {seconds} secondes."
        },
        "passwords": {
            "password": "Les mots de passe doivent contenir au moins six caractères et être identiques.",
            "reset": "Votre mot de passe a été réinitialisé !",
            "sent": "Nous vous avons envoyé par email le lien de réinitialisation du mot de passe !",
            "throttled": "Trop de tentatives, veuillez réessayer plus tard.",
            "token": "Ce jeton de réinitialisation du mot de passe n'est pas valide.",
            "user": "Aucun utilisateur n'a été trouvé avec cette adresse email.",
            "Hello!": "Bonjour !",
            "Reset Password Notification": "Réinitialiser votre mot de passe",
            "You are receiving this email because we received a password reset request for your account.": "Vous recevez cet e-mail car nous avons reçu une demande de réinitialisation du mot de passe pour votre compte.",
            "Reset Password": "Réinitialiser le mot de passe",
            "This password reset link will expire in {count} minutes.": "Ce lien de réinitialisation expirera dans {count} minutes.",
            "If you did not request a password reset, no further action is required.": "Si vous n'avez pas demandé de réinitialisation de mot de passe, aucune autre action n'est requise.",
            "If you’re having trouble clicking the \"{actionText}\" button, copy and paste the URL below\ninto your web browser:": "Si vous ne parvenez pas à cliquer sur le bouton \"{actionText}\", copiez et collez l'URL ci-dessous\ndans votre navigateur Web"
        },
        "validation": {
            "accepted": "Le champ {attribute} doit être accepté.",
            "active_url": "Le champ {attribute} n'est pas une URL valide.",
            "after": "Le champ {attribute} doit être une date postérieure à {date}.",
            "after_or_equal": "Le champ {attribute} doit être une date postérieure ou égale à {date}.",
            "alpha": "Le champ {attribute} doit contenir uniquement des lettres.",
            "alpha_dash": "Le champ {attribute} doit contenir uniquement des lettres, des chiffres et des tirets.",
            "alpha_num": "Le champ {attribute} doit contenir uniquement des chiffres et des lettres.",
            "array": "Le champ {attribute} doit être un tableau.",
            "before": "Le champ {attribute} doit être une date antérieure à {date}.",
            "before_or_equal": "Le champ {attribute} doit être une date antérieure ou égale à {date}.",
            "between": {
                "numeric": "La valeur de {attribute} doit être comprise entre {min} et {max}.",
                "file": "La taille du fichier de {attribute} doit être comprise entre {min} et {max} kilo-octets.",
                "string": "Le texte {attribute} doit contenir entre {min} et {max} caractères.",
                "array": "Le tableau {attribute} doit contenir entre {min} et {max} éléments."
            },
            "boolean": "Le champ {attribute} doit être vrai ou faux.",
            "confirmed": "Le champ de confirmation {attribute} ne correspond pas.",
            "date": "Le champ {attribute} n'est pas une date valide.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "Le champ {attribute} ne correspond pas au format {format}.",
            "different": "Les champs {attribute} et {other} doivent être différents.",
            "digits": "Le champ {attribute} doit contenir {digits} chiffres.",
            "digits_between": "Le champ {attribute} doit contenir entre {min} et {max} chiffres.",
            "dimensions": "La taille de l'image {attribute} n'est pas conforme.",
            "distinct": "Le champ {attribute} a une valeur en double.",
            "email": "Le champ {attribute} doit être une adresse email valide.",
            "exists": "Le champ {attribute} sélectionné est invalide.",
            "file": "Le champ {attribute} doit être un fichier.",
            "filled": "Le champ {attribute} doit avoir une valeur.",
            "gt": {
                "numeric": "La valeur de {attribute} doit être supérieure à {value}.",
                "file": "La taille du fichier de {attribute} doit être supérieure à {value} kilo-octets.",
                "string": "Le texte {attribute} doit contenir plus de {value} caractères.",
                "array": "Le tableau {attribute} doit contenir plus de {value} éléments."
            },
            "gte": {
                "numeric": "La valeur de {attribute} doit être supérieure ou égale à {value}.",
                "file": "La taille du fichier de {attribute} doit être supérieure ou égale à {value} kilo-octets.",
                "string": "Le texte {attribute} doit contenir au moins {value} caractères.",
                "array": "Le tableau {attribute} doit contenir au moins {value} éléments."
            },
            "image": "Le champ {attribute} doit être une image.",
            "in": "Le champ {attribute} est invalide.",
            "in_array": "Le champ {attribute} n'existe pas dans {other}.",
            "integer": "Le champ {attribute} doit être un entier.",
            "ip": "Le champ {attribute} doit être une adresse IP valide.",
            "ipv4": "Le champ {attribute} doit être une adresse IPv4 valide.",
            "ipv6": "Le champ {attribute} doit être une adresse IPv6 valide.",
            "json": "Le champ {attribute} doit être un document JSON valide.",
            "lt": {
                "numeric": "La valeur de {attribute} doit être inférieure à {value}.",
                "file": "La taille du fichier de {attribute} doit être inférieure à {value} kilo-octets.",
                "string": "Le texte {attribute} doit contenir moins de {value} caractères.",
                "array": "Le tableau {attribute} doit contenir moins de {value} éléments."
            },
            "lte": {
                "numeric": "La valeur de {attribute} doit être inférieure ou égale à {value}.",
                "file": "La taille du fichier de {attribute} doit être inférieure ou égale à {value} kilo-octets.",
                "string": "Le texte {attribute} doit contenir au plus {value} caractères.",
                "array": "Le tableau {attribute} doit contenir au plus {value} éléments."
            },
            "max": {
                "numeric": "La valeur de {attribute} ne peut être supérieure à {max}.",
                "file": "La taille du fichier de {attribute} ne peut pas dépasser {max} kilo-octets.",
                "string": "Le texte de {attribute} ne peut contenir plus de {max} caractères.",
                "array": "Le tableau {attribute} ne peut contenir plus de {max} éléments."
            },
            "mimes": "Le champ {attribute} doit être un fichier de type : {values}.",
            "mimetypes": "Le champ {attribute} doit être un fichier de type : {values}.",
            "min": {
                "numeric": "La valeur de {attribute} doit être supérieure ou égale à {min}.",
                "file": "La taille du fichier de {attribute} doit être supérieure à {min} kilo-octets.",
                "string": "Le texte {attribute} doit contenir au moins {min} caractères.",
                "array": "Le tableau {attribute} doit contenir au moins {min} éléments."
            },
            "not_in": "Le champ {attribute} sélectionné n'est pas valide.",
            "not_regex": "Le format du champ {attribute} n'est pas valide.",
            "numeric": "Le champ {attribute} doit contenir un nombre.",
            "present": "Le champ {attribute} doit être présent.",
            "prohibited_if": "Le champ {attribute} est interdit quand la valeur de {other} est {value}.",
            "prohibited_unless": "Le champ {attribute} est interdit sauf si la valeur de {other} est {values}.",
            "regex": "Le format du champ {attribute} est invalide.",
            "required": "Le champ {attribute} est obligatoire.",
            "required_if": "Le champ {attribute} est obligatoire quand la valeur de {other} est {value}.",
            "required_unless": "Le champ {attribute} est obligatoire sauf si {other} est {values}.",
            "required_with": "Le champ {attribute} est obligatoire quand {values} est présent.",
            "required_with_all": "Le champ {attribute} est obligatoire quand {values} sont présents.",
            "required_without": "Le champ {attribute} est obligatoire quand {values} n'est pas présent.",
            "required_without_all": "Le champ {attribute} est requis quand aucun de {values} n'est présent.",
            "same": "Les champs {attribute} et {other} doivent être identiques.",
            "size": {
                "numeric": "La valeur de {attribute} doit être {size}.",
                "file": "La taille du fichier de {attribute} doit être de {size} kilo-octets.",
                "string": "Le texte de {attribute} doit contenir {size} caractères.",
                "array": "Le tableau {attribute} doit contenir {size} éléments."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}",
            "string": "Le champ {attribute} doit être une chaîne de caractères.",
            "timezone": "Le champ {attribute} doit être un fuseau horaire valide.",
            "unique": "La valeur du champ {attribute} est déjà utilisée.",
            "uploaded": "Le fichier du champ {attribute} n'a pu être téléversé.",
            "url": "Le format de l'URL de {attribute} n'est pas valide.",
            "uuid": "Le champ {attribute} doit être un UUID valide",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "nom",
                "username": "nom d'utilisateur",
                "email": "adresse email",
                "first_name": "prénom",
                "last_name": "nom",
                "password": "mot de passe",
                "password_confirmation": "confirmation du mot de passe",
                "city": "ville",
                "country": "pays",
                "address": "adresse",
                "phone": "téléphone",
                "mobile": "portable",
                "age": "âge",
                "sex": "sexe",
                "gender": "genre",
                "day": "jour",
                "month": "mois",
                "year": "année",
                "hour": "heure",
                "minute": "minute",
                "second": "seconde",
                "title": "titre",
                "content": "contenu",
                "description": "description",
                "excerpt": "extrait",
                "date": "date",
                "time": "heure",
                "available": "disponible",
                "size": "taille"
            }
        }
    }
}
