<template>
  <header class="app-header navbar">
    <button
      v-if="current_user"
      id="navbar-toggler"
      class="navbar-toggler d-lg-none mr-auto"
      type="button"
      @click="toggleSidebar()"
    >
      <span class="navbar-toggler-icon">
      </span>
    </button>

    <router-link
      to="/"
      class="navbar-brand font-sm"
    >
      <img
        alt="Boomerang Web"
        class="navbar-brand-full"
        src="/img/boomerang_web.svg"
      >
      <img
        alt="Boomerang Web"
        class="navbar-brand-minimized"
        src="/img/boomerang_web.svg"
      >
    </router-link>

    <button
      v-if="current_user"
      id="navbar-toggler"
      class="navbar-toggler d-md-down-none"
      type="button"
      @click="toggleSidebar('lg')"
    >
      <span class="navbar-toggler-icon">
      </span>
    </button>

    <template v-if="header_infos">
      <b-badge
        v-if="header_infos.env"
        :variant="header_infos.env.type ? header_infos.env.type : 'info'"
        class="d-md-down-none mr-1"
      >{{ header_infos.env.text }}</b-badge>

      <b-badge
        v-if="header_infos.tenant_disabled"
        variant="danger"
        class="d-md-down-none mr-1"
        :to="{ name: 'maintenance_disable_tenant' }"
      >TENANT DÉSACTIVÉ</b-badge>

      <b-badge
        v-if="header_infos.mailtrap"
        :variant="header_infos.mailtrap.type ? header_infos.mailtrap.type : 'info'"
        class="d-md-down-none mr-1"
      >{{ header_infos.mailtrap.text }}</b-badge>

      <b-badge
        v-if="header_infos.disk"
        class="d-md-down-none mr-1"
        :variant="header_infos.disk.type ? header_infos.disk.type : 'info'"
        :title="header_infos.disk.bucket"
        v-b-tooltip
      >
        {{ header_infos.disk.text }}
      </b-badge>

      <b-badge
        v-if="header_infos.git"
        class="d-md-down-none mr-1"
        :title="header_infos.git.commit"
        v-b-tooltip
      >
        {{ header_infos.git.tag ? header_infos.git.tag : header_infos.git.branch }}
      </b-badge>

      <b-button
        v-if="header_infos.online_users"
        :href="header_infos.online_users.url"
        target="_blank"
        class="badge d-md-down-none mr-1"
        :variant="header_infos.online_users.type ? header_infos.online_users.type : 'info'"
        :title="header_infos.online_users.title"
        v-b-tooltip
      >
        {{ header_infos.online_users.text }}
      </b-button>

      <b-badge
        v-if="header_infos.debug_email"
        variant="warning"
        class="d-md-down-none mr-1"
        :title="$t('global.info.overriden_email_to')"
        v-b-tooltip
      >
        <font-awesome-icon
          v-if="icons"
          :icon="['fal', icons.info.warning]"
        />
        {{ header_infos.debug_email }}
      </b-badge>
    </template>

    <ul
      class="nav navbar-nav ml-auto"
      style="padding-right: 12px;"
    >
      <li
        v-if="current_user && actions_to_do && actions_to_do.length > 0"
        class="nav-item d-md-down-none px-2"
      >
        <ActionsToDo />
        <HeaderMessages v-if="current_user.not_external" />
      </li>

      <li
        v-if="current_user"
        class="nav-item d-md-down-none"
      >
        <HeaderUser />
      </li>

      <li
        v-if="available_languages && available_languages.length > 1"
        class="nav-item"
      >
        <LanguageSelector />
      </li>

      <li
        v-if="current_user && available_currencies && available_currencies.length > 0"
        class="nav-item"
      >
        <CurrencySelector />
      </li>

      <li
        v-if="header_infos && header_infos.faq_enabled"
        class="nav-item"
      >
        <FaqButton />
      </li>
    </ul>
  </header>
</template>

<script>
import ActionsToDo from "./ActionsToDo.vue";
import CurrencySelector from "./CurrencySelector.vue";
import FaqButton from "./FaqButton.vue";
import HeaderMessages from "./HeaderMessages.vue";
import HeaderUser from "./HeaderUser.vue";
import LanguageSelector from "./LanguageSelector.vue";
import { mapState } from "vuex";

export default {
  name: "MainHeader",
  components: {
    ActionsToDo,
    CurrencySelector,
    FaqButton,
    HeaderMessages,
    HeaderUser,
    LanguageSelector,
  },
  created() {
    this.$store.dispatch("layout/getHeaderInfos");
    if (this.actions_to_do === null) {
      this.$store.dispatch("layout/getActionsToDo");
    }
    if (
      this.available_currencies === null ||
      this.available_languages === null
    ) {
      this.$store.dispatch("layout/getInitInfos");
    }
    if (this.current_user === null) {
      this.$store.dispatch("user/getCurrentUser");
    }
  },
  mounted() {
    if (!this.$root._events["hide-main-menu"]) {
      this.$root.$on("hide-main-menu", () => {
        this.hideSidebar("lg");
      });
    }
  },
  destroyed() {
    this.$root.$off("hide-main-menu");
  },
  methods: {
    hideSidebar(size) {
      this.toggleSidebar(size, true);
    },
    toggleSidebar(size, force_hide = null) {
      let body = document.body;
      if (size && size == "lg") {
        if (
          !force_hide &&
          body.classList.contains("brand-minimized") &&
          body.classList.contains("sidebar-minimized")
        ) {
          body.classList.remove("brand-minimized");
          body.classList.remove("sidebar-minimized");
        } else {
          body.classList.add("brand-minimized");
          body.classList.add("sidebar-minimized");
        }
      } else {
        if (body.classList.contains("sidebar-show")) {
          body.classList.remove("sidebar-show");
        } else {
          body.classList.add("sidebar-show");
        }
      }
    },
  },
  computed: {
    ...mapState({
      actions_to_do: (state) => state.layout.actions_to_do,
      available_currencies: (state) => state.layout.available_currencies,
      available_languages: (state) => state.layout.available_languages,
      current_currency: (state) => state.layout.current_currency,
      current_language: (state) => state.layout.current_language,
      current_user: (state) => state.user.current_user,
      header_infos: (state) => state.layout.header_infos,
      icons: (state) => state.layout.icons,
    }),
  },
};
</script>
