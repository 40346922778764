import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import _ from 'lodash';

// Bootstrap Vue
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);

// // Perfect Scrollbar 
// -> lancer npm install vue2-perfect-scrollbar
// -> pour l'usage, voir dans MainMenu.vue
// import PerfectScrollbar from 'vue2-perfect-scrollbar'
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
// Vue.use(PerfectScrollbar)

// Custom plugin for global functions
import CustomFunctions from './plugins/custom-functions'
Vue.use(CustomFunctions);

// Circular Progress
import VueEllipseProgress from 'vue-ellipse-progress';
Vue.use(VueEllipseProgress);

// Websockets
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

// Import SASS file
import './assets/scss/app.scss'

// Font Awesome
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons' // TODO: sélectionner uniquement les icones utilisées
import { fad } from '@fortawesome/pro-duotone-svg-icons' // TODO: sélectionner uniquement les icones utilisées
import { fal } from '@fortawesome/pro-light-svg-icons' // TODO: sélectionner uniquement les icones utilisées
import { fas } from '@fortawesome/pro-solid-svg-icons' // TODO: sélectionner uniquement les icones utilisées
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
library.add(fab)
library.add(fad)
library.add(fal)
library.add(fas)
library.add(faExclamationTriangle)

// custom global components
import BwDatepicker from "./components/global/BwDatepicker.vue";
Vue.component('bw-datepicker', BwDatepicker)
import BwExpandable from "./components/global/BwExpandable.vue";
Vue.component('bw-expandable', BwExpandable)
import BwMultiselect from "./components/global/BwMultiselect.vue";
Vue.component('bw-multiselect', BwMultiselect)
import BwWysiwyg from "./components/global/BwWysiwyg.vue";
Vue.component('bw-wysiwyg', BwWysiwyg)
import IconAndTitle from "./components/global/IconAndTitle.vue";
Vue.component('icon-and-title', IconAndTitle)
import IconAutofilled from "./components/global/icon/Autofilled.vue";
Vue.component('icon-autofilled', IconAutofilled)
import IconNotUpdatableAfterCreation from "./components/global/icon/NotUpdatableAfterCreation.vue";
Vue.component('icon-not-updatable-after-creation', IconNotUpdatableAfterCreation)
import IconRequired from "./components/global/icon/Required.vue";
Vue.component('icon-required', IconRequired)
import IconTranslatable from "./components/global/icon/Translatable.vue";
Vue.component('icon-translatable', IconTranslatable)
import TranslationSelect from "./components/global/TranslationSelect.vue";
Vue.component('translation-select', TranslationSelect)

// vue-json-excel
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

Vue.config.productionTip = false

export const app = new Vue({
  i18n,
  router,
  store,
  created() {
    // let pusher_key = localStorage.getItem('pusher_key');

    // if (pusher_key !== null) {
    //   this.launchWebSockets(pusher_key);
    // } else {
    //   this.$store
    //     .dispatch("layout/getInitInfos")
    //     .then((data) => {
    //       localStorage.setItem('pusher_key', data.pusher_key)
    //       this.launchWebSockets(data.pusher_key)
    //     });
    // }
  },
  methods: {
    launchWebSockets(pusher_key) {
      // let api_url = new URL(window.location.protocol + '//' + window.location.hostname)
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: pusher_key,
        // wsHost: api_url.hostname,
        wsHost: window.location.hostname,
        wsPort: 6001,
        // wsPort: 3030,
        forceTLS: false,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
      });

      // // add authorization token for private channel
      // window.Echo.connector.pusher.connection.bind('connected', function () {
      //     console.log('launchWebSockets X-Socket-Id=' + window.Echo.socketId());
      //     axios.defaults.headers.common['X-Socket-Id'] = window.Echo.socketId();
      // });
    }
  },
  render: h => h(App)
}).$mount('#app')

// Custom mixins
Vue.mixin({
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    },
    getViewport() {
      // https://stackoverflow.com/a/8876069
      const width = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      )
      if (width <= 576) return 'xs';
      if (width <= 768) return 'sm';
      if (width <= 992) return 'md';
      if (width <= 1200) return 'lg';
      if (width <= 1400) return 'xl';
      return 'xxl';
    },
    isViewportFrom(viewport) {
      const width = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      )
      let viewports = [];
      viewports.push('xs');
      if (width >= 576) viewports.push('sm');
      if (width >= 768) viewports.push('md');
      if (width >= 992) viewports.push('lg');
      if (width >= 1200) viewports.push('xl');
      if (width >= 1400) viewports.push('xxl');

      return viewports.includes(viewport);
    },
    isViewportUnder(viewport) {
      const width = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      )
      let viewports = [];
      if (width <= 576) viewports.push('xs');
      if (width <= 768) viewports.push('sm');
      if (width <= 992) viewports.push('md');
      if (width <= 1200) viewports.push('lg');
      if (width <= 1400) viewports.push('xl');
      viewports.push('xxl');

      return viewports.includes(viewport);
    },

    isEmpty: obj => [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length,

    // format numbers with decimals
    number_format: function (number, decimals = this.$store.state.layout.settings.GLOBAL__AMOUNT_DECIMAL_NUMBER) {
      // use 2 times round() to avoid float accuracy hazard
      // Example:
      // in real life, 1.07950 x 50 = 53.975
      // with float, 1.07950 x 50 = 53.974999999999994
      // when we round with 2 décimal in real life => 53.98 but with float => 53.97
      let fixedFloat = _.round(Number.parseFloat(number), 6);
      return _.round(fixedFloat, decimals)
    },
    // format numbers into money
    money_format: function (number, currency = this.$store.state.layout.current_currency.code) {
      if (currency === '') {
        currency = this.$store.state.layout.current_currency.code
      }

      if (currency === '') {
        currency = this.$store.state.layout.default_currency.code
      }

      let options = {
        style: 'currency',
        currency: currency,
      };

      return new Intl.NumberFormat(this.$store.state.layout.current_language.code + "-" + this.$store.state.layout.current_language.country_code, options).format(Number.parseFloat(number));
    },
    iban_format(value) {
      value = value.toUpperCase().replace(/[^0-9A-Z]/g, "");
      value = value.substr(0, 4) + " " + value.substr(4, 4) + " " + value.substr(8, 4) + " " + value.substr(12, 4) + " " + value.substr(16, 4) + " " + value.substr(20, 4) + " " + value.substr(24, 4) + " " + value.substr(28);
      return value.trim();
    },
    calc_inctax(price, rate) {
      return price * (1 + rate / 100);
    },
    calc_exctax(price, rate) {
      return price / (1 + rate / 100);
    },
    format_bytes(a, b) { if (0 == a) return '0' + this.$t('global.filesize.B'); var c = 1024, d = b || 2, e = [this.$t('global.filesize.B'), this.$t('global.filesize.KB'), this.$t('global.filesize.MB'), this.$t('global.filesize.GB'), this.$t('global.filesize.TB'), this.$t('global.filesize.PB'), this.$t('global.filesize.EB'), this.$t('global.filesize.ZB'), this.$t('global.filesize.YB')], f = Math.floor(Math.log(a) / Math.log(c)); return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f] },
    column_letter_from_int(i) {
      let count = Math.floor(i / 26);
      let modulo = i % 26;

      let prev = ''
      if (count > 0) {
        prev = this.column_letter_from_int(count - 1)
      }
      return prev + String.fromCharCode(65 + modulo);
    },
    // toasts
    toast_notification(content, title = null, params = {}) {
      let variant = params.hasOwnProperty('variant') ? params.variant : 'info';
      let auto_hide = params.hasOwnProperty('auto_hide') ? params.auto_hide : true;

      this.$root.$bvToast.toast(content, {
        title: title ? title : this.$t('notification.model'),
        variant: variant,
        noAutoHide: !auto_hide,
      });
    },
    toast_info(content, title = null, params = {}) {
      let auto_hide = params.hasOwnProperty('auto_hide') ? params.auto_hide : true;

      this.$root.$bvToast.toast(content, {
        title: title,
        variant: 'info',
        noAutoHide: !auto_hide,
      });
    },
    toast_success(content, title = null, params = {}) {
      let auto_hide = params.hasOwnProperty('auto_hide') ? params.auto_hide : true;

      this.$root.$bvToast.toast(content, {
        title: title,
        variant: 'success',
        noAutoHide: !auto_hide,
      });
    },
    toast_error(content = null, title = null, code = null, params = {}) {
      let toast_href = params.hasOwnProperty('toast_href') ? params.toast_href : null;
      let toast_to = params.hasOwnProperty('toast_to') ? params.toast_to : null;
      let auto_hide = params.hasOwnProperty('auto_hide') ? params.auto_hide : true;
      let close_button = params.hasOwnProperty('close_button') ? params.close_button : true;
      let id = params.hasOwnProperty('id') ? params.id : null;

      if (content === null) {
        switch (code) {
          case 401:
            this.$store.dispatch("user/reinitLayout");

            title = this.$t('error.401.title');
            content = this.$t('error.401.description') + ' ' + this.$t('error.401.link_text');
            toast_href = '/bwapp/login?redirect=' + this.$router.currentRoute.fullPath;
            auto_hide = false;
            close_button = false;
            break;
          case 403:
            title = this.$t('error.403.title');
            content = this.$t('error.403.description');
            break;
          case 404:
            content = this.$t('error.404.description');
            break;
          case 422:
            title = this.$t("error.form_errors.title");
            content = this.$t("error.form_errors");
            break;
          case 498:
            content = this.$t('error.498.description');
            break;
          case 500:
            content = this.$t('error.500.description');
            break;
          case 502:
            content = this.$t('error.502.description');
            break;
          case 503:
            content = this.$t('error.503.description');
            break;
          default:
            content = this.$t("error.impossible_action");
            break;
        }
      }

      this.$root.$bvToast.toast(content, {
        id: id,
        href: toast_href,
        to: toast_to,
        title: title ? title : this.$t("error.impossible_action.title", {
          code: code,
        }),
        variant: 'danger',
        noAutoHide: !auto_hide,
        noCloseButton: !close_button,
      });
    },
    catch_failure(failure) {
      this.toast_error(null, null, failure.status)
    },
    msg_box_ok(content = null, title = null) {
      this.$root.$bvModal
        .msgBoxOk(content ? content : this.$t("error.impossible_action"), {
          title: title ? title : this.$t("error.form_errors.title"),
          size: "sm",
          okVariant: "warning",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
    },
    msg_box_confirm(content = null, title = null, callback) {
      this.$root.$bvModal
        .msgBoxConfirm(content ? content : this.$t("global.info.confirmation_request"), {
          title: title ? title : this.$t("global.title.confirmation_request"),
          okTitle: this.$t("global.action.confirm"),
          cancelTitle: this.$t("global.action.cancel"),
          centered: true,
        })
        .then(value => {
          callback(value);
        })
        .catch(err => {
        });
    },
    forceFileDownloadFromResponse(response) {
      let filename = null;
      let disposition = response.headers['content-disposition'];
      if (disposition && disposition.indexOf('attachment') !== -1) {
        let filename_regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filename_regex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      this.forceFileDownload(response.data, filename)
    },
    forceFileDownload(content, filename = null) {
      if (filename === null) { filename = 'download' }
      const url = window.URL.createObjectURL(new Blob([content]));
      this.forceFileDownloadFromUrl(url, filename)
    },
    forceFileDownloadFromUrl(url, filename = null) {
      const link = document.createElement('a');
      link.href = url;
      if (filename) {
        link.setAttribute('download', filename);
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  }
})
